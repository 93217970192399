import "./WinLostByPlayer.scss";
import { useTranslation } from "react-i18next";
import {
  Button,
  Checkbox,
  Col,
  Dropdown,
  Image,
  Input,
  Popover,
  Radio,
  Row,
  Select,
} from "antd";
import { useEffect, useMemo, useState } from "react";

import { CSVLink } from "react-csv";

import moment from "moment";
import { Link } from "react-router-dom";
import { apis } from "../../../apis";

import {
  DownOutlined,
  Loading3QuartersOutlined,
  RightOutlined,
} from "@ant-design/icons";
import { httpService } from "../../../apis/httpService";

import { CustomDateRangePicker } from "../../../common/components/date-range-picker";
import { getCurrencyList } from "../../../common/helpers/utils";
import { paths } from "../../../routes/path";
const WinLostByPlayer = () => {
  const { Option } = Select;
  const { t } = useTranslation();
  const columns = [
    {
      title: t("content.winLostByPlayer.col.username"),
      dataIndex: "username",
      key: "username",
    },
    {
      title: t("content.winLostByPlayer.col.turnover"),
      dataIndex: "turnover",
      key: "turnover",
    },

    {
      title: t("content.winLostByPlayer.col.netTurnover"),
      dataIndex: "netTurnover",
      key: "netTurnover",
    },

    {
      title: t("content.winLostByPlayer.col.currency"),
      dataIndex: "currency",
      key: "currency",
    },
    {
      title: t("content.winLostByPlayer.col.betCount"),
      dataIndex: "betCount",
      key: "betCount",
    },
    {
      title: t("content.winLostByPlayer.col.winLost"),
      dataIndex: "winLost",
      key: "winLost",
    },
    {
      title: t("content.winLostByPlayer.col.commission"),
      dataIndex: "commission",
      key: "commission",
    },
    {
      title: t("content.winLostByPlayer.col.memberTotal"),
      dataIndex: "memberTotal",
      key: "memberTotal",
    },
    {
      title: t("content.winLostByPlayer.col.company"),
      dataIndex: "company",
      key: "company",
    },
  ];

  const columnsCredit = [
    {
      title: t("content.winLostByPlayer.col.username"),
      dataIndex: "username",
      key: "username",
      render: (value: any, record: any) => {
        return (
          <div className="flex align-center">
            <Link
              className="bold"
              to={paths.USER_DETAIL.replace(":id", record.userId)}
            >
              {value}
            </Link>
          </div>
        );
      },
    },
    {
      title: t("content.winLostByPlayer.col.turnover"),
      dataIndex: "turnover",
      key: "turnover",
    },

    {
      title: t("content.winLostByPlayer.col.netTurnover"),
      dataIndex: "netTurnover",
      key: "netTurnover",
    },

    {
      title: t("content.winLostByPlayer.col.currency"),
      dataIndex: "currency",
      key: "currency",
    },
    {
      title: t("content.winLostByPlayer.col.betCount"),
      dataIndex: "betCount",
      key: "betCount",
    },
    {
      title: t("content.winLostByPlayer.col.grossCommission"),
      dataIndex: "grossCommission",
      key: "grossCommission",
    },
    {
      title: t("content.winLostByPlayer.col.memberWinLost"),
      dataIndex: "memberWinLost",
      key: "memberWinLost",
    },
    {
      title: t("content.winLostByPlayer.col.memberCommission"),
      dataIndex: "memberCommission",
      key: "memberCommission",
    },
    {
      title: t("content.winLostByPlayer.col.memberTotal"),
      dataIndex: "memberTotal",
      key: "memberTotal",
    },
    {
      title: t("content.winLostByPlayer.col.associateWinLost"),
      dataIndex: "associateWinLost",
      key: "associateWinLost",
    },
    {
      title: t("content.winLostByPlayer.col.associateCommission"),
      dataIndex: "associateCommission",
      key: "associateCommission",
    },
    {
      title: t("content.winLostByPlayer.col.associateTotal"),
      dataIndex: "associateTotal",
      key: "associateTotal",
    },
    {
      title: t("content.winLostByPlayer.col.maWinLost"),
      dataIndex: "maWinLost",
      key: "maWinLost",
    },
    {
      title: t("content.winLostByPlayer.col.maCommission"),
      dataIndex: "maCommission",
      key: "maCommission",
    },
    {
      title: t("content.winLostByPlayer.col.maTotal"),
      dataIndex: "maTotal",
      key: "maTotal",
    },
    {
      title: t("content.winLostByPlayer.col.smaWinLost"),
      dataIndex: "smaWinLost",
      key: "smaWinLost",
    },
    {
      title: t("content.winLostByPlayer.col.smaCommission"),
      dataIndex: "smaCommission",
      key: "smaCommission",
    },
    {
      title: t("content.winLostByPlayer.col.smaTotal"),
      dataIndex: "smaTotal",
      key: "smaTotal",
    },

    {
      title: t("content.winLostByPlayer.col.ssmaWinLost"),
      dataIndex: "ssmaWinLost",
      key: "ssmaWinLost",
    },
    {
      title: t("content.winLostByPlayer.col.ssmaCommission"),
      dataIndex: "ssmaCommission",
      key: "ssmaCommission",
    },
    {
      title: t("content.winLostByPlayer.col.ssmaTotal"),
      dataIndex: "ssmaTotal",
      key: "ssmaTotal",
    },

    {
      title: t("content.winLostByPlayer.col.shWinLost"),
      dataIndex: "shWinLost",
      key: "shWinLost",
    },
    {
      title: t("content.winLostByPlayer.col.shCommission"),
      dataIndex: "shCommission",
      key: "shCommission",
    },
    {
      title: t("content.winLostByPlayer.col.shTotal"),
      dataIndex: "shTotal",
      key: "shTotal",
    },

    {
      title: t("content.winLostByPlayer.col.company"),
      dataIndex: "company",
      key: "company",
    },
  ];
  const contentStatisticalChildren = (
    <div>{t("content.winLostByPlayer.contentStatisticalChildren")}</div>
  );
  const tabs = [
    t("content.winLostByPlayer.credit"),
    t("content.winLostByPlayer.cash"),
  ];

  const [username, setUsername] = useState("");
  const [currency, setCurrency] = useState("");
  const [voidBets, setVoidBets] = useState();
  const [decimalPointNumbers, setDecimalPointNumbers] = useState();
  const [valuesStake, setValuesStake] = useState(false);

  const [showData, setShowData] = useState(false);
  const [loadings, setLoadings] = useState(false);
  const [activeTab, setActiveTab] = useState(t("content.winLostByPlayer.cash"));
  const [checkColumnCash, setCheckColumnCash] = useState(() =>
    columns.map((item) => item.key)
  );
  const [checkColumnCredit, setCheckColumnCredit] = useState(() =>
    columnsCredit.map((item) => item.key)
  );
  const [dataGameCash, setDataGameCash] = useState<any[]>();

  const handleCheckColum = (key: string) => {
    if (activeTab === t("content.winLostByPlayer.cash")) {
      const isChecked = checkColumnCash.includes(key);
      if (isChecked) {
        const newState = checkColumnCash.filter((item) => item !== key);
        setCheckColumnCash(newState);
      } else {
        setCheckColumnCash([...checkColumnCash, key]);
      }
    } else {
      const isChecked = checkColumnCredit.includes(key);
      if (isChecked) {
        const newState = checkColumnCredit.filter((item) => item !== key);
        setCheckColumnCredit(newState);
      } else {
        setCheckColumnCredit([...checkColumnCash, key]);
      }
    }
  };

  const handleShowAllData = () => {
    setShowData(!showData);
  };

  const currentDate = new Date();
  const fromDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate() - 30
  );

  const [filterDate, setFilterDate] = useState({
    fromDate: fromDate,
    toDate: currentDate,
  });
  const [total, setTotal] = useState();

  const getWinLostList = async () => {
    const payload = {
      fromDate: filterDate.fromDate.toISOString(),
      toDate: filterDate.toDate.toISOString(),
      username: username ? username : undefined,
      currency: currency ? currency : undefined,
    };
    try {
      const res = await httpService.post(apis.report.winLostByPlayer, payload);
      setDataGameCash(res.data?.data);

      const total = res.data?.total;
      delete total?.username;
      setTotal(total);
    } catch (error) {
      setDataGameCash([]);
      console.log("error", error);
    }
  };

  useEffect(() => {
    getWinLostList();
  }, [currency, filterDate.fromDate, filterDate.toDate]);

  const onUsernameChange = (e: any) => {
    setUsername(e.target.value);
  };

  const onCurrencyChange = (value: any) => {
    setCurrency(value);
  };

  const onChangeVoidBets = (e: any) => {
    setVoidBets(e.target.value);
  };

  const onChangeDecimalPointNumbers = (e: any) => {
    setDecimalPointNumbers(e.target.value);
  };

  const handleChangeStake = () => {
    setValuesStake(!valuesStake);
  };

  const handleClickTab = (tab: any) => {
    setActiveTab(tab);
  };

  const onClickApplyDate = (event: any, picker: any) => {
    const fromDate = moment(picker.startDate).toDate();
    const toDate = moment(picker.endDate).toDate();
    setFilterDate({
      ...filterDate,
      fromDate,
      toDate,
    });
  };

  const clearDate = () => {
    setFilterDate({
      ...filterDate,
      fromDate: fromDate,
      toDate: currentDate,
    });
  };

  const enterLoading = async () => {
    setLoadings(true);
    await getWinLostList();
    setLoadings(false);
  };

  const stakeSize = useMemo(() => {
    let size = 0;
    if (
      checkColumnCash.includes("turnover") &&
      checkColumnCash.includes("netTurnover")
    ) {
      return (size = 2);
    }
    if (
      checkColumnCash.includes("turnover") ||
      checkColumnCash.includes("netTurnover")
    ) {
      return (size = 1);
    }

    return size;
  }, [checkColumnCash]);

  const [currencyList, setCurrencyList] = useState<any[]>([]);
  useEffect(() => {
    if (!currencyList?.length) {
      const fetchCurrencyList = async () => {
        try {
          const res = await getCurrencyList();
          setCurrencyList(res.data);
        } catch (error) {
          console.log(error);
        }
      };

      fetchCurrencyList();
    }
  }, []);

  return (
    <div>
      <div className="win-lost">
        <div className="filter-main">
          <div className="win-lost__header">
            <div className="header-name">
              {t("content.winLostByPlayer.title")}
            </div>
            <div className="header-export">
              <Button>
                <Image
                  width={20}
                  src="/excel_icon.png"
                  style={{ marginBottom: 4, paddingRight: 2 }}
                />
                <CSVLink
                  filename={"Expense_Table.csv"}
                  data={dataGameCash || []}
                  className="btn btn-primary"
                >
                  <span> {t("content.winLostByPlayer.export")}</span>
                </CSVLink>
              </Button>
            </div>
          </div>
          <Row className="filter-bar">
            <Col className="date-range-transaction-history">
              <div>{t("content.winLostByPlayer.statisticalDate")}</div>
              <Popover content={contentStatisticalChildren}>
                <Image
                  preview={false}
                  width={16}
                  src="/question_mark_icon.svg"
                />
              </Popover>
              <CustomDateRangePicker
                onApply={onClickApplyDate}
                onCancel={clearDate}
                containerStyles={{
                  width: "214px",
                  padding: "3px 0px",
                  paddingLeft: "4px",
                  borderRadius: "6px",
                  border: "1px solid #d9d9d9",
                }}
                fromDate={filterDate.fromDate}
                toDate={filterDate.toDate}
                resultToString
                opens={"center"}
                readOnly
              />
            </Col>

            <Col>
              <div>{t("content.winLostByPlayer.currency")}</div>
              <Select
                defaultValue={t("content.winLostByPlayer.all")}
                style={{ width: 120 }}
                onChange={onCurrencyChange}
              >
                <Option value="">{t("content.winLostByPlayer.all")}</Option>
                {currencyList?.map((item, index) => (
                  <Option value={item?.currency} key={index}>
                    {item?.currency}
                  </Option>
                ))}
              </Select>
            </Col>

            {activeTab === t("content.winLostByPlayer.cash") && (
              <Col>
                <div>{t("content.winLostByPlayer.username")}</div>
                <Input
                  placeholder={t("content.winLostByPlayer.username")}
                  onChange={onUsernameChange}
                  style={{ width: 150 }}
                />
              </Col>
            )}

            <Col>
              <div>{t("content.winLostByPlayer.includeExcludeVoid.title")}</div>
              <Radio.Group
                className="flex align-center"
                defaultValue="ind"
                onChange={onChangeVoidBets}
                value={voidBets}
              >
                <Radio value="ind">
                  {t(
                    "content.winLostByPlayer.includeExcludeVoid.type.indVoidBets"
                  )}
                </Radio>
                <Radio value="excl">
                  {t(
                    "content.winLostByPlayer.includeExcludeVoid.type.exclVoidBets"
                  )}
                </Radio>
              </Radio.Group>
            </Col>
            <Col>
              <div>{t("content.winLostByPlayer.decimalPointNumbers")}</div>
              <Radio.Group
                className="flex align-center"
                defaultValue={2}
                onChange={onChangeDecimalPointNumbers}
                value={decimalPointNumbers}
              >
                <Radio value={2}>2</Radio>
                <Radio value={3}>3</Radio>
                <Radio value={4}>4</Radio>
                <Radio value={5}>5</Radio>
                <Radio value={6}>6</Radio>
              </Radio.Group>
            </Col>
            <Col style={{ marginBottom: 4 }}>
              <Button
                className="submit-btn"
                type="primary"
                size="small"
                onClick={getWinLostList}
              >
                {loadings && (
                  <tr className="loading" style={{ marginRight: 20 }}>
                    <Loading3QuartersOutlined
                      className="loading-icon"
                      style={{
                        marginRight: 20,
                        width: "10px",
                        color: "white",
                      }}
                    />
                  </tr>
                )}
                <span>{t("content.winLostByPlayer.submit")}</span>
              </Button>
            </Col>
          </Row>

          <div className="filter-table" style={{ padding: "4px" }}>
            <div className="type-money">
              {tabs.map((tab) => (
                <div
                  onClick={() => handleClickTab(tab)}
                  className={
                    activeTab === tab
                      ? "type-money__active"
                      : "type-money__not-active"
                  }
                  key={tab}
                >
                  {tab}
                </div>
              ))}
            </div>

            <Dropdown
              dropdownRender={() => (
                <div className="filter-table__dropdown">
                  {activeTab === t("content.winLostByPlayer.cash") &&
                    columns.map((item: any, index: any) => (
                      <div
                        onClick={() => handleCheckColum(item.key)}
                        style={{
                          display: "flex",
                          padding: "6px 10px",
                          backgroundColor: "#fff",
                        }}
                      >
                        <Checkbox
                          style={{ marginRight: 10 }}
                          checked={checkColumnCash.includes(item.key)}
                        />
                        <div key={index}>{item?.title}</div>
                      </div>
                    ))}
                  {activeTab === t("content.winLostByPlayer.credit") &&
                    columnsCredit.map((item, index) => (
                      <div
                        onClick={() => handleCheckColum(item.key)}
                        style={{
                          display: "flex",
                          padding: "6px 10px",
                          backgroundColor: "#fff",
                        }}
                      >
                        <Checkbox
                          style={{ marginRight: 10 }}
                          checked={checkColumnCredit.includes(item.key)}
                        />
                        <div key={index}>{item?.title}</div>
                      </div>
                    ))}
                </div>
              )}
            >
              <a onClick={(e) => e.preventDefault()}>
                <div className="bars">
                  <img width={16} src="/bars_icon.svg"></img>
                </div>
              </a>
            </Dropdown>
          </div>
        </div>

        <div className="table-main">
          {loadings && <div className="layout-table"></div>}
          <table>
            <thead>
              <tr>
                <td colSpan={2}></td>
                {stakeSize > 0 && (
                  <td colSpan={stakeSize}>
                    <div className="stake-btn btn" onClick={handleChangeStake}>
                      {valuesStake ? t("content.winLostByPlayer.col.byStake") : t("content.winLostByPlayer.col.byActualStake")}
                    </div>
                  </td>
                )}

                <td
                  colSpan={
                    activeTab === t("content.winLostByPlayer.credit")
                      ? columnsCredit.length
                      : columns.length
                  }
                ></td>
              </tr>

              <tr>
                <td>
                  <div>#</div>
                </td>

                {activeTab === t("content.winLostByPlayer.cash") &&
                  columns.map((item, index) => {
                    const isChecked = checkColumnCash.includes(item.key);
                    if (isChecked) {
                      return <td key={index}>{item?.title}</td>;
                    }
                  })}
                {activeTab === t("content.winLostByPlayer.credit") &&
                  columnsCredit.map((item, index) => {
                    const isChecked = checkColumnCredit.includes(item.key);
                    if (isChecked) {
                      return <td key={index}>{item?.title}</td>;
                    }
                  })}
              </tr>
            </thead>
            {loadings && (
              <tr className="loading">
                <Loading3QuartersOutlined className="loading-icon" />
              </tr>
            )}

            {!dataGameCash?.length && activeTab === t("content.winLostByPlayer.cash") && (
              <div className="no-data">
                <p style={{ color: "#888" }}>
                  {t("content.winLostByPlayer.col.noData")}
                </p>
              </div>
            )}

            {activeTab === t("content.winLostByPlayer.cash") && (
              <>
                <tbody>
                  <tr>
                    <td
                      className="name-game"
                      onClick={() => handleShowAllData()}
                      colSpan={columns.length + 1}
                    >
                      {showData ? <DownOutlined /> : <RightOutlined />}
                      <span>{t("content.winLostByPlayer.col.allProduct")}</span>
                    </td>
                  </tr>
                  {showData &&
                    dataGameCash?.map((item: any, index: any) => (
                      <tr>
                        <td style={{ textAlign: "center" }}>
                          <span>{index + 1}</span>
                        </td>

                        {columns.map((col: any) => {
                          const isChecked = checkColumnCash.includes(col.key);

                          if (isChecked) {
                            if (col.key === "username") {
                              return (
                                <td>
                                  <Link
                                    to={paths.USER_DETAIL.replace(
                                      ":id",
                                      item[col.key]
                                    )}
                                  >
                                    <span>{item[col.key]}</span>
                                  </Link>
                                </td>
                              );
                            }
                            if (
                              col.key === "company" ||
                              col.key === "memberTotal" ||
                              col.key === "winLost"
                            ) {
                              if (item[col.key] < 0) {
                                return (
                                  <td
                                    style={{
                                      color: "red",
                                      textAlign: "right",
                                    }}
                                  >
                                    <span>{item[col.key]}</span>
                                  </td>
                                );
                              }
                            }
                            if (
                              col.key === "betCount" ||
                              col.key === "currency"
                            ) {
                              return (
                                <td
                                  style={{
                                    textAlign: "center",
                                  }}
                                >
                                  <span>{item[col.key]}</span>
                                </td>
                              );
                            }

                            return (
                              <td
                                style={{
                                  textAlign: "right",
                                }}
                              >
                                <span>{item[col.key]}</span>
                              </td>
                            );
                          }
                        })}
                      </tr>
                    ))}
                </tbody>
                <tfoot>
                  {activeTab === t("content.winLostByPlayer.cash") && (
                    <>
                      <tr className="grand-total-table">
                        <td
                          colSpan={2}
                          style={{
                            textAlign: "left",
                          }}
                        >
                          {t("content.winLostByPlayer.col.grandTotal")}
                        </td>

                        {total &&
                          columns.map((col: any) => {
                            const key = col.key;
                            if (key !== "username") {
                              const isChecked = checkColumnCash.includes(key);
                              if (isChecked) {
                                if (key === "betCount" || key === "currency") {
                                  return (
                                    <td
                                      style={{
                                        textAlign: "center",
                                      }}
                                    >
                                      <span>{total[key]}</span>
                                    </td>
                                  );
                                }

                                if (
                                  key === "company" ||
                                  key === "memberTotal" ||
                                  key === "winLost"
                                ) {
                                  if (total[key] < 0) {
                                    return (
                                      <td
                                        style={{
                                          color: "red",
                                          textAlign: "right",
                                        }}
                                      >
                                        <span>{total[key]}</span>
                                      </td>
                                    );
                                  }
                                }
                                return (
                                  <td
                                    style={{
                                      textAlign: "right",
                                    }}
                                  >
                                    <span>{total[key]}</span>
                                  </td>
                                );
                              }
                            }
                          })}
                      </tr>
                    </>
                  )}
                </tfoot>
              </>
            )}

            {activeTab === t("content.winLostByPlayer.credit") && (
              <div style={{ padding: 16, width: "100px" }}>
                <p style={{ color: "#888" }}>
                  {t("content.winLostByPlayer.col.noData")}
                </p>
              </div>
            )}
          </table>
        </div>
      </div>
    </div>
  );
};

export default WinLostByPlayer;
