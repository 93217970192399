import { Button, Image, Pagination, Table, TableProps, Tooltip } from "antd"
import moment from "moment"
import { useMemo, useState } from "react"
import { CSVLink } from "react-csv"
import { useTranslation } from "react-i18next"
import { CustomDateRangePicker } from "../../../common/components/date-range-picker"
import { PromotionCurrencySelect, PromotionStatusSelect, PromotionTypeSelect } from "../components"
import QuestionSvg from "../components/icons/question-svg"
import { PromotionStatus, PromotionType, currentDate } from "../constants"
import { useOptions } from "../hooks"
import { getPromotionStatistics } from "../services"

interface IPromotionStatistic {
    id: string
    title: string
    code: string
    currency: string
    startDate: string
    endDate: string
    status: string
    totalRequest: number
    uniqueUserCount: number
    totalRequestApproved: number
    totalRequestRejected: number
    totalBonusGiven: number
    promotionType: PromotionType
}

const PromotionStatistics = () => {
    const [data, setData] = useState<IPromotionStatistic[]>([])
    const [filterMeta, setFilterMeta] = useState({
        take: 25,
        totalItem: 0,
        startDate: currentDate,
        endDate: currentDate,
        currency: ["*"],
        promotionType: ["*"],
        status: ["*"],
        username: "",
        errorID: "",
    })
    const { t } = useTranslation()
    const { PromotionStatusLabel } = useOptions()
    const fixedColumns: TableProps<IPromotionStatistic>["columns"] = useMemo(
        () => [
            {
                title: (
                    <div className='mx-auto w-fit'>
                        <p className='w-fit'>
                            {t("content.promotions.create.form.field.promotionTitle")}
                        </p>
                        <p className='w-fit'>
                            {t("content.promotions.create.form.field.promotionCode")}
                        </p>
                    </div>
                ),
                width: 360,
                render: (_, record) => {
                    return (
                        <div>
                            <p>{record.title}</p>
                            <p>{record.code}</p>
                        </div>
                    )
                },
            },

            {
                title: t("content.promotions.create.form.field.promotionType"),
                width: 446,
                dataIndex: "promotionType",
                render: (promotionType) => (
                    <span className='capitalize'>
                        {t(`content.promotions.create.type.${promotionType}.title`)}
                    </span>
                ),
            },

            {
                title: t("content.promotions.create.form.field.currency"),
                width: 178,
                dataIndex: "currency",
                render: (currency: string) => (
                    <span className='font-bold text-[#596d86]'>{currency}</span>
                ),
                align: "center",
            },
            {
                title: (
                    <div className='mx-auto w-fit'>
                        <p className='w-fit'>
                            {t("content.promotions.create.form.field.startDate")}
                        </p>
                        <p className='w-fit'>{t("content.promotions.create.form.field.endDate")}</p>
                    </div>
                ),
                width: 250,
                render: (_, record) => {
                    return (
                        <div>
                            <p>{moment(record.startDate).format("DD/MM/YYYY")}</p>
                            <p>{moment(record.endDate).format("DD/MM/YYYY")}</p>
                        </div>
                    )
                },
            },
            {
                title: (
                    <div className='flex flex-col items-end'>
                        <div className='flex items-center gap-1'>
                            <span>{t("content.promotions.management.totalUniquePlayer")}</span>
                            <Tooltip
                                placement='left'
                                title={t("content.promotions.management.tooltip.totalUniquePlayer")}
                            >
                                <div className='flex items-center justify-center'>
                                    <QuestionSvg />
                                </div>
                            </Tooltip>
                        </div>
                        <div className='flex items-center gap-1'>
                            <span>{t("content.promotions.management.totalRequest")}</span>
                            <Tooltip
                                placement='left'
                                title={t("content.promotions.management.tooltip.totalRequest")}
                            >
                                <div className='flex items-center justify-center'>
                                    <QuestionSvg />
                                </div>
                            </Tooltip>
                        </div>
                    </div>
                ),
                width: 250,
                render: (_, record) => {
                    return (
                        <div className='text-right'>
                            <p>{record.uniqueUserCount}</p>
                            <p>{record.totalRequest}</p>
                        </div>
                    )
                },
            },
            {
                title: (
                    <div className='flex flex-col items-end'>
                        <div className='flex items-center gap-1'>
                            <span>{t("content.promotions.management.totalRequestApproved")}</span>
                            <Tooltip
                                placement='left'
                                title={t(
                                    "content.promotions.management.tooltip.totalRequestApproved"
                                )}
                            >
                                <div className='flex items-center justify-center'>
                                    <QuestionSvg />
                                </div>
                            </Tooltip>
                        </div>
                        <div className='flex items-center gap-1'>
                            <span>{t("content.promotions.management.totalRequestRejected")}</span>
                            <Tooltip
                                placement='left'
                                title={t(
                                    "content.promotions.management.tooltip.totalRequestRejected"
                                )}
                            >
                                <div className='flex items-center justify-center'>
                                    <QuestionSvg />
                                </div>
                            </Tooltip>
                        </div>
                    </div>
                ),
                width: 357,
                render: (_, record) => {
                    return (
                        <div className='text-right'>
                            <p>{record.totalRequestApproved}</p>
                            <p>{record.totalRequestRejected}</p>
                        </div>
                    )
                },
            },
            {
                title: (
                    <div className='flex items-center justify-center gap-1'>
                        <span>{t("content.promotions.management.totalBonusGiven")}</span>
                        <Tooltip
                            placement='left'
                            title={t("content.promotions.management.tooltip.totalBonusGiven")}
                        >
                            <div className='flex items-center justify-center'>
                                <QuestionSvg />
                            </div>
                        </Tooltip>
                    </div>
                ),
                dataIndex: "totalBonusGiven",
                width: 250,
                render: (totalBonusGiven: number) => (
                    <div className='text-right'>{totalBonusGiven}</div>
                ),
            },
            {
                title: t("content.promotions.create.form.field.status"),
                dataIndex: "status",
                width: 200,
                render: (status: PromotionStatus) => (
                    <span className='font-bold text-[#00c781]'>{PromotionStatusLabel[status]}</span>
                ),
                align: "center",
            },
        ],
        [t]
    )
    const mergedColumns = useMemo<typeof fixedColumns>(() => {
        return fixedColumns.map((col) => ({ ...col, onCell: undefined }))
    }, [fixedColumns])

    const handleSearchPromotionStatistics = async (meta?: { page: number; take: number }) => {
        const requestParams: {
            currency?: string
            status?: string
            type?: string
            requestTimeTo?: string
            requestTimeFrom?: string
            take: number
            page: number
            promotionState?: string
        } = {
            take: meta?.take ?? 25,
            page: meta?.page ?? 1,
            requestTimeFrom: moment(filterMeta.startDate).format(),
            requestTimeTo: moment(filterMeta.endDate).format(),
        }

        if (filterMeta.promotionType.length > 0 && !filterMeta.promotionType.includes("*")) {
            requestParams.type = filterMeta.promotionType.join(",")
        }
        if (filterMeta.status.length > 0 && !filterMeta.status.includes("*")) {
            requestParams.status = filterMeta.status.join(",")
        }
        if (filterMeta.currency.length > 0 && !filterMeta.currency.includes("*")) {
            requestParams.currency = filterMeta.currency.join(",")
        }

        const res = await getPromotionStatistics(requestParams)
        setData(res.data.result)
    }

    return (
        <div className='promotions-create__container'>
            <div id='promotion-list-id' className='production-content'>
                <div className='promotions-container'>
                    <div className='header'>
                        <div className='header__container'>
                            <div className='header__item'>
                                {t("content.promotions.promotionStatistics.title")}
                                <Tooltip
                                    placement='right'
                                    title={t(
                                        "content.promotions.management.tooltip.promotionStatistics"
                                    )}
                                    className='ml-2'
                                >
                                    <div className='flex items-center justify-center'>
                                        <QuestionSvg />
                                    </div>
                                </Tooltip>
                            </div>
                        </div>
                        <div className='flex justify-center items-center bg-[#dee3ec] p-3'>
                            <div>
                                <Button>
                                    <Image
                                        width={20}
                                        src='/excel_icon.png'
                                        style={{
                                            marginBottom: 4,
                                            paddingRight: 2,
                                        }}
                                    />
                                    <CSVLink
                                        filename={t("content.promotions.promotionStatistics.title")}
                                        data={data}
                                        className='btn btn-primary'
                                    >
                                        <span>Export</span>
                                    </CSVLink>
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div className='content'>
                        <div className='flex gap-2 justify-end'>
                            {/* Approved On */}
                            <div className='flex gap-2 items-center '>
                                <div>{t("content.promotions.management.approvedOn")}</div>
                                <CustomDateRangePicker
                                    onApply={(event: any, picker: any) => {
                                        const newStartDate = moment(picker.startDate).toDate()
                                        const newEndDate = moment(picker.endDate).toDate()

                                        setFilterMeta((current) => ({
                                            ...current,
                                            startDate: newStartDate,
                                            endDate: newEndDate,
                                        }))
                                    }}
                                    onCancel={() => {}}
                                    containerStyles={{
                                        width: "214px",
                                        padding: "3px 0px",
                                        paddingLeft: "4px",
                                        borderRadius: "6px",
                                        border: "1px solid #d9d9d9",
                                    }}
                                    fromDate={filterMeta.startDate}
                                    toDate={filterMeta.endDate}
                                    resultToString
                                    opens={"center"}
                                    readOnly
                                />
                            </div>
                            <Button
                                type='primary'
                                htmlType='button'
                                onClick={() => {
                                    handleSearchPromotionStatistics()
                                }}
                            >
                                {t("common.search")}
                            </Button>
                        </div>
                        <div className='flex gap-2 justify-end py-4'>
                            <div className='flex gap-2 overflow-x-scroll'>
                                {/* Promotion Type */}
                                <PromotionTypeSelect
                                    value={filterMeta.promotionType}
                                    onChange={(value) => {
                                        setFilterMeta({
                                            ...filterMeta,
                                            promotionType: value,
                                        })
                                    }}
                                />
                                {/* Promotion Status */}
                                <PromotionStatusSelect
                                    value={filterMeta.status}
                                    onChange={(value) => {
                                        setFilterMeta({
                                            ...filterMeta,
                                            status: value,
                                        })
                                    }}
                                />
                                {/* Currency */}
                                <PromotionCurrencySelect
                                    value={filterMeta.currency}
                                    onChange={(value) => {
                                        setFilterMeta({
                                            ...filterMeta,
                                            currency: value,
                                        })
                                    }}
                                />
                            </div>
                        </div>
                        <Table
                            id='table-data'
                            bordered
                            columns={mergedColumns}
                            scroll={{ x: 2000, y: 400 }}
                            rowKey='id'
                            dataSource={data}
                            pagination={false}
                        />
                        <div className='mt-4'>
                            <Pagination
                                defaultCurrent={1}
                                total={filterMeta.totalItem}
                                showQuickJumper
                                showSizeChanger
                                defaultPageSize={25}
                                pageSizeOptions={["25", "50", "100"]}
                                onChange={(page) => {
                                    setFilterMeta((current) => ({
                                        ...current,
                                        page,
                                    }))
                                    handleSearchPromotionStatistics({
                                        take: filterMeta.take,
                                        page,
                                    })
                                }}
                                onShowSizeChange={(currentPage, size) => {
                                    setFilterMeta((current) => ({
                                        ...current,
                                        take: size,
                                        page: currentPage,
                                    }))
                                    handleSearchPromotionStatistics({
                                        page: currentPage,
                                        take: size,
                                    })
                                }}
                                style={{ fontSize: 13 }}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PromotionStatistics
