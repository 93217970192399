import { useTranslation } from "react-i18next";
import "./SubAccountList.scss";

import { Button, Col, Input, Pagination, PaginationProps, Row, Select, Table } from "antd";
import { useEffect, useState } from "react";

import { apis } from "../../../apis";

import { EditOutlined, Loading3QuartersOutlined } from "@ant-design/icons";
import { httpService } from "../../../apis/httpService";
import { ModalEditSubAccount } from "../../../common/components/modal-edit-sub-account";

import ModalCreateSubAccount from "../../../common/components/modal-create-sub-account/ModalCreateSubAccount";
import { UserStatus } from "../../../common/constants/user-status.enum";

const SubAccountList = () => {
    const { t } = useTranslation();

    const { Option } = Select;
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [dataEdit, setDataEdit] = useState();
    const showModal = (record?: any) => {
        setDataEdit(record);
        setIsModalOpen(true);
    };

    const [isModalCreateOpen, setIsModalCreateOpen] = useState(false);
    const showModalCreate = (record?: any) => {
        setIsModalCreateOpen(true);
    };

    const columnSubAccount = [
        {
            title: "#",
            dataIndex: "index",
            key: "index",
            render: (text: any, record: any, index: number) => index + 1,
        },
        {
            title: t("content.subAccountList.col.username"),
            dataIndex: "username",
            key: "username",
        },
        // {
        //     title: t("content.subAccountList.col.loginName"),
        //     dataIndex: "loginName",
        //     key: "loginName",
        // },
        {
            title: t("content.subAccountList.col.edit"),
            dataIndex: "edit",
            key: "edit",
            render: (value: any, record: any) => {
                return (
                    <div
                        className="edit-account"
                        // style={{
                        //     textDecoration: "underline",
                        //     justifyContent: "flex-start",
                        //     color: "blue",
                        //     cursor:'pointer'
                        // }}
                        onClick={() => showModal(record)}
                    >
                        <span>
                            <EditOutlined />
                        </span>
                    </div>
                );
            },
        },
        {
            title: t("content.subAccountList.col.status"),
            dataIndex: "status",
            key: "status",
            // render: (value: any, record: any) => {
            //     if (value == "Open") {
            //         return <div style={{ color: "#00c781" }}>{value}</div>;
            //     }
            // },
        },
        {
            title: t("content.subAccountList.col.suspend"),
            dataIndex: "suspend",
            key: "suspend",
            render: (value: any, record: any) => {
                if (value == false) {
                    return <div style={{ color: "#00c781" }}>No</div>;
                } else {
                    if (value == false) {
                        return <div style={{ color: "#00c781" }}>Suspend</div>;
                    }
                }
            },
        },
        {
            title: t("content.subAccountList.col.firstName"),
            dataIndex: "firstName",
            key: "firstName",
        },

        {
            title: t("content.subAccountList.col.lastName"),
            dataIndex: "lastName",
            key: "lastName",
        },
        {
            title: t("content.subAccountList.col.phone"),
            dataIndex: "phone",
            key: "phone",
        },
        {
            title: t("content.subAccountList.col.createOn"),
            dataIndex: "createdAt",
            key: "createdAt",
        },
        {
            title: t("content.subAccountList.col.lastLoginTime"),
            dataIndex: "lastTimeLogin",
            key: "lastTimeLogin",
        },
        // {
        //     title: t("content.subAccountList.col.lastLoginIp"),
        //     dataIndex: "lastLoginIp",
        //     key: "lastLoginIp",
        // },
    ];

    //emtydata
    const [emptyText, setEmptyText] = useState("No Data");
    let locale = {
        emptyText,
    };
    const [username, setUsername] = useState("");
    const [totalPage, setTotalPage] = useState(10);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [loadings, setLoadings] = useState(false);
    // const [suspend, setSuspend] = useState(undefined);
    const [status, setStatus] = useState("");
    const [permissionGroupSelected, setPermissionGroupSelected] = useState("");
    const [subAccountList, setSubAccountList] = useState([]);
    const [permissionGroup, setPermissionGroup] = useState<any[]>([]);
    const getSubAccounts = async () => {
        setLoadings(true);
        try {
            let query = `${apis.subAccount.base}/?page=${page}&take=${pageSize}`;

            if (username) {
                query += `&username=${username}`;
            }
            if (permissionGroupSelected) {
                query += `&roleIds=${permissionGroupSelected}`;
            }
            if (status) {
                query += `&statuses=${status}`;
            }

            const res = await httpService.get(query);

            setSubAccountList(res.data.result);
            setTotalPage(res?.data?.meta.itemCount);
        } catch (error) {
            console.log("error", error);
        }
        setLoadings(false);
    };

    const getPermissionGroup = async () => {
        try {
            const res = await httpService.get(apis.permission.groups);
            if (res.data?.result?.length) {
                setPermissionGroup(res.data.result);
            }
        } catch (error) {
            console.log("error", error);
        }
    };

    const onUsernameChange = (e: any) => {
        setUsername(e.target.value);
    };

    useEffect(() => {
        getSubAccounts();
    }, [page, pageSize, isModalCreateOpen]);

    useEffect(() => {
        getPermissionGroup();
    }, []);

    const onChangePage: PaginationProps["onChange"] = (pageNumber: number, pageSize: number) => {
        setPage(pageNumber);
        setPageSize(pageSize);
    };

    const onClickReset = async () => {
        setUsername("");
        await getSubAccounts();
    };

    const onChangePermissionGroup = (id: string) => {
        setPermissionGroupSelected(id);
    };
    const onChangeStatus = (type: string) => {
        setStatus(type);
    };
    return (
        <div>
            <Col id="sub-account-list">
                <div className="filter">
                    <div className="filter-header">
                        <div className="filter-header__name">{t("content.subAccountList.title")}</div>
                        <div className="modify-btn">
                            <Button size="small" type="primary" onClick={showModalCreate}>
                                <span style={{ padding: "0 6px" }}>{t("content.subAccountList.createSubAccount")}</span>
                            </Button>
                        </div>
                    </div>
                </div>
                <Row className="filter-bar">
                    <Col>
                        <div>{t("content.subAccountList.username")}</div>
                        <Input
                            placeholder={t("content.subAccountList.username")}
                            onChange={onUsernameChange}
                            style={{ width: 150 }}
                        />
                    </Col>

                    <Col>
                        <div>{t("content.subAccountList.permissionGroup.title")}</div>
                        <Select defaultValue="All" style={{ width: 120 }} onChange={onChangePermissionGroup}>
                            <Option value="">{t("content.subAccountList.permissionGroup.type.all")}</Option>
                            {permissionGroup?.map((item, index) => (
                                <Option value={item?.id} key={index}>
                                    {item?.name}
                                </Option>
                            ))}
                        </Select>
                    </Col>
                    <Col>
                        <div>{t("content.subAccountList.status.title")}</div>
                        <Select defaultValue="All" style={{ width: 120 }} onChange={onChangeStatus}>
                            <Option value="">{t("content.subAccountList.status.type.all")}</Option>
                            <Option value={UserStatus.ACTIVE}>Active</Option>
                            <Option value={UserStatus.DISABLED}>Disable</Option>
                            <Option value={UserStatus.PENDING}>Pending</Option>
                        </Select>
                    </Col>
                    {/* <Col>
                        <div>{t("content.subAccountList.suspend.title")}</div>
                        <Select
                            defaultValue="All"
                            style={{ width: 120 }}
                            // onChange={onCurrencyChange}
                        >
                            <Option value="">{t("content.subAccountList.suspend.type.all")}</Option>
                            <Option value="yes">{t("content.subAccountList.suspend.type.yes")}</Option>
                            <Option value="no">{t("content.subAccountList.suspend.type.no")}</Option>
                        </Select>
                    </Col> */}

                    <Col>
                        <Button className="submit-btn" type="primary" onClick={getSubAccounts}>
                            {loadings && (
                                <tr className="loading-btn" style={{ marginRight: 20 }}>
                                    <Loading3QuartersOutlined className="loading-icon-btn" />
                                </tr>
                            )}
                            <span>{t("content.subAccountList.submit")}</span>
                        </Button>
                        <Button style={{ marginLeft: 6 }} onClick={onClickReset}>
                            {t("content.subAccountList.reset")}
                        </Button>
                    </Col>
                </Row>
                <div id="sub-account-list-table">
                    {loadings && (
                        <div className="loading">
                            <Loading3QuartersOutlined className="loading-icon" />
                        </div>
                    )}
                    {!loadings && (
                        <>
                            <div id="table-data" style={{ marginTop: 6 }}>
                                <Table columns={columnSubAccount} locale={locale} dataSource={subAccountList} />
                            </div>
                            <div className="footer-table">
                                <Pagination
                                    defaultCurrent={1}
                                    total={totalPage}
                                    showQuickJumper
                                    onChange={onChangePage}
                                    style={{ fontSize: 13 }}
                                />
                            </div>
                        </>
                    )}
                </div>
            </Col>

            <ModalEditSubAccount isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} data={dataEdit} />
            <ModalCreateSubAccount isModalOpen={isModalCreateOpen} setIsModalOpen={setIsModalCreateOpen} />
        </div>
    );
};

export default SubAccountList;
