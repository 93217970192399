import { useMemo } from "react";
import CustomSelect from "./custom-select";
import { usePromotion } from "../hooks";
import { useTranslation } from "react-i18next";

const PromotionStatusSelect = ({
    value,
    onChange,
}: {
    value: string[];
    onChange: (value: string[]) => void;
}) => {
    const { t } = useTranslation();
    const { currencyList } = usePromotion();
    // Options for Currency
    const CurrencyOptions = useMemo(
        () =>
            currencyList.map((item: any) => ({
                label: item.currency,
                value: item.currency,
            })),
        [currencyList]
    );
    return (
        <div className="flex gap-2 items-center ">
            <span>{t("common.currency")}</span>
            <CustomSelect
                className="flex-1"
                withAllOption
                mode="multiple"
                allowClear
                style={{
                    width: 160,
                }}
                options={CurrencyOptions}
                value={value}
                onChange={(value) => {
                    if (!value.length) {
                        onChange(["*"]);
                    } else {
                        onChange(value);
                    }
                }}
                maxTagCount="responsive"
            />
        </div>
    );
};

export default PromotionStatusSelect;
