import React from "react";
import { BlankLayout } from "../common/components/layouts/blank";
import { LightLayout } from "../common/components/layouts/light";
import { Login } from "../pages/auth/login";
import BankAccountList from "../pages/bank/accountList/BankAccountList";
import AddBank from "../pages/bank/addBank/AddBank";
import AddBankAccount from "../pages/bank/addBankAccount/AddBankAccount";
import { BankList } from "../pages/bank/list";
import { Currency } from "../pages/company/currency";
import { Language } from "../pages/company/language";
import { GameList } from "../pages/game-and-provider/game-list";
import { GameProvider } from "../pages/game-and-provider/game-provider";
import { HomePage } from "../pages/home";
import { NotFound } from "../pages/not-found";
import { PaymentGatewayBankSetting } from "../pages/payment-gateway/payment-gateway-bank-setting";
import { ManualPaymentTransaction } from "../pages/payment-gateway/payment-gateway-bank-setting/manual-payment-transaction";
import { PaymentTransaction } from "../pages/payment-gateway/payment-gateway-bank-setting/payment-transaction";
import { PermissionGroup } from "../pages/permission/group";
import {
    ApplicationRecord,
    ApplicationVerify,
    CreatePromotion,
    PromotionList,
    PromotionStatistics,
} from "../pages/promotions";
import { BettingRecord } from "../pages/report/betting-record";
import { DailyReport } from "../pages/report/daily";
import { PlayerSummary } from "../pages/report/player-sumary";
import { RevenueStatement } from "../pages/report/revenue-statement";
import { WinLost } from "../pages/report/win-lost";
import { WinLostByPlayerPage } from "../pages/report/win-lost-by-player-page";
import WinLostByPlayer from "../pages/report/win-lost-by-player/WinLostByPlayer";
import { ChangeLoginName } from "../pages/security/change-login-name";
import { ChangePassword } from "../pages/security/change-password";
import { ConnectSetting } from "../pages/setting/connect";
import { DesignSetting } from "../pages/setting/design";
import { TrackingSetting } from "../pages/setting/tracking";
import { Manage } from "../pages/sub-account/manage/Manage";
import { SubAccountList } from "../pages/sub-account/sub-account-list";
import { UserDetail } from "../pages/users/detail";
import { UserList } from "../pages/users/list";
import { AdminControls } from "../pages/web-admin/admin-controls";
import { CustomizeThemes } from "../pages/web-admin/customize-themes";
import Deposit from "../txn/deposit/Deposit";
import PlayerBankGroup from "../txn/player-bank-group/PlayerBankGroup";
import TransactionHistory from "../txn/transaction-history/TransactionHistory";
import { Transactions } from "../txn/transactions";
import Withdraw from "../txn/withdraw/Withdraw";
import { paths } from "./path";

export interface IRouteItem {
    path: string;
    auth: boolean;
    component: React.FC;
    layout: React.FC<any>;
}

export const routes: IRouteItem[] = [
    {
        path: paths.LOGIN,
        auth: false,
        component: Login,
        layout: BlankLayout,
    },
    {
        path: paths.HOME,
        auth: true,
        component: HomePage,
        layout: LightLayout,
    },
    {
        path: paths.REPORT.WIN_LOST,
        auth: true,
        component: WinLost,
        layout: LightLayout,
    },
    {
        path: paths.REPORT.WIN_LOST_BY_PLAYER,
        auth: true,
        component: WinLostByPlayer,
        layout: LightLayout,
    },
    {
        path: paths.REPORT.WIN_LOST_BY_PLAYER_PAGE,
        auth: true,
        component: WinLostByPlayerPage,
        layout: LightLayout,
    },
    {
        path: paths.REPORT.Daily_Report,
        auth: true,
        component: DailyReport,
        layout: LightLayout,
    },
    {
        path: paths.REPORT.REVENUE_STATEMENT,
        auth: true,
        component: RevenueStatement,
        layout: LightLayout,
    },
    {
        path: paths.REPORT.BETTING_RECORD,
        auth: true,
        component: BettingRecord,
        layout: LightLayout,
    },
    {
        path: paths.REPORT.PLAYER_SUMMARY,
        auth: true,
        component: PlayerSummary,
        layout: LightLayout,
    },
    {
        path: paths.SECURITY.CHANGE_PASSWORD,
        auth: true,
        component: ChangePassword,
        layout: LightLayout,
    },
    {
        path: paths.SECURITY.CHANGE_LOGIN_NAME,
        auth: true,
        component: ChangeLoginName,
        layout: LightLayout,
    },

    {
        path: paths.SUB_ACCOUNT.SUB_ACCOUNT_LIST,
        auth: true,
        component: SubAccountList,
        layout: LightLayout,
    },
    {
        path: paths.GAME_AND_PROVIDER.GAME_PROVIDER,
        auth: true,
        component: GameProvider,
        layout: LightLayout,
    },
    {
        path: paths.GAME_AND_PROVIDER.GAME_LIST,
        auth: true,
        component: GameList,
        layout: LightLayout,
    },
    {
        path: paths.WEB_ADMIN.CUSTOMIZE_THEME,
        auth: true,
        component: CustomizeThemes,
        layout: LightLayout,
    },
    {
        path: paths.WEB_ADMIN.ADMIN_CONTROL,
        auth: true,
        component: AdminControls,
        layout: LightLayout,
    },
    {
        path: paths.COMPANY.LANGUAGE,
        auth: true,
        component: Language,
        layout: LightLayout,
    },
    {
        path: paths.COMPANY.CURRENCY,
        auth: true,
        component: Currency,
        layout: LightLayout,
    },
    {
        path: paths.PAYMENT_GATEWAY.BANK_SETTING,
        auth: true,
        component: PaymentGatewayBankSetting,
        layout: LightLayout,
    },
    {
        path: paths.PAYMENT_GATEWAY.PAYMENT_TRANSACTION,
        auth: true,
        component: PaymentTransaction,
        layout: LightLayout,
    },
    {
        path: paths.PAYMENT_GATEWAY.MANUAL_PAYMENT_TRANSACTION,
        auth: true,
        component: ManualPaymentTransaction,
        layout: LightLayout,
    },
    {
        path: paths.USER_LIST,
        auth: true,
        component: UserList,
        layout: LightLayout,
    },
    {
        path: paths.USER_DETAIL,
        auth: true,
        component: UserDetail,
        layout: LightLayout,
    },

    {
        path: paths.SETTING_TRACKING,
        auth: true,
        component: TrackingSetting,
        layout: LightLayout,
    },
    {
        path: paths.SETTING_CONNECT,
        auth: true,
        component: ConnectSetting,
        layout: LightLayout,
    },

    {
        path: paths.SETTING_DESIGN,
        auth: true,
        component: DesignSetting,
        layout: LightLayout,
    },
    {
        path: paths.BANK_LIST,
        auth: true,
        component: BankList,
        layout: LightLayout,
    },
    {
        path: paths.ADD_BANK,
        auth: true,
        component: AddBank,
        layout: LightLayout,
    },
    {
        path: paths.BANK_LIST_ACCOUNT,
        auth: true,
        component: BankAccountList,
        layout: LightLayout,
    },
    {
        path: paths.ADD_BANK_ACCOUNT,
        auth: true,
        component: AddBankAccount,
        layout: LightLayout,
    },
    {
        path: paths.TXN.DEPOSIT,
        auth: true,
        component: Deposit,
        layout: LightLayout,
    },
    {
        path: paths.TXN.WITHDRAW,
        auth: true,
        component: Withdraw,
        layout: LightLayout,
    },
    {
        path: paths.TXN.PLAYER_BANK_GROUP,
        auth: true,
        component: PlayerBankGroup,
        layout: LightLayout,
    },
    {
        path: paths.TXN.TRANSACTIONS,
        auth: true,
        component: Transactions,
        layout: LightLayout,
    },
    {
        path: paths.TXN.TXN_HISTORY,
        auth: true,
        component: TransactionHistory,
        layout: LightLayout,
    },
    {
        path: paths.PERMISSION_GROUP,
        auth: true,
        component: PermissionGroup,
        layout: LightLayout,
    },
    {
        path: paths.PROMOTION.CREATE,
        auth: true,
        component: CreatePromotion,
        layout: LightLayout,
    },
    {
        path: paths.PROMOTION.LIST,
        auth: true,
        component: PromotionList,
        layout: LightLayout,
    },
    {
        path: paths.PROMOTION.APPLICATION_VERIFY,
        auth: true,
        component: ApplicationVerify,
        layout: LightLayout,
    },
    {
        path: paths.PROMOTION.APPLICATION_RECORD,
        auth: true,
        component: ApplicationRecord,
        layout: LightLayout,
    },
    {
        path: paths.PROMOTION.PROMOTION_STATISTICS,
        auth: true,
        component: PromotionStatistics,
        layout: LightLayout,
    },
    {
        path: paths.RESOURCE.MANAGE,
        auth: true,
        component: Manage,
        layout: LightLayout,
    },

    {
        path: "*",
        auth: true,
        component: NotFound,
        layout: LightLayout,
    },
];
