import { ExclamationCircleFilled } from "@ant-design/icons"
import { Checkbox, Col, Row, Select, Table, TableProps } from "antd"
import { Option } from "antd/es/mentions"
import { useMemo, useState } from "react"
import { toast } from "react-toastify"
import { apis } from "../../../../../apis"
import { httpService } from "../../../../../apis/httpService"
import "../../TabGateway.scss"

interface IPaymentGateway {
    // id: string
    // createdAt: string
    // updatedAt: string
    // createdBy: string
    // updatedBy: string
    // deletedAt: string
    // deletedBy: string
    bankName: string
    paymentMethod: string
    currency: string
    transactionType: string
    minDeposit: number
    paymentDepositMin: number
    maxDeposit: number
    paymentDepositMax: number
    minWithdrawal: number
    paymentWithdrawMin: number
    maxWithdrawal: number
    paymentWithdrawMax: number
    isEnable: boolean
    modified: string
}

const HTPay = () => {
    const [data, setData] = useState<IPaymentGateway[]>([
        {
            bankName: "ACB",
            paymentMethod: "Offline",
            currency: "VND",
            transactionType: "Deposit,Withdrawal",
            minDeposit: 0,
            paymentDepositMin: 1000,
            maxDeposit: 0,
            paymentDepositMax: 0,
            minWithdrawal: 0,
            paymentWithdrawMin: 0,
            maxWithdrawal: 0,
            paymentWithdrawMax: 2412,
            isEnable: true,
            modified: "2024-01-31 12:21:14 ",
        },
        {
            bankName: "ACB",
            paymentMethod: "Offline",
            currency: "VND",
            transactionType: "Deposit",
            minDeposit: 0,
            paymentDepositMin: 1000,
            maxDeposit: 0,
            paymentDepositMax: 0,
            minWithdrawal: 0,
            paymentWithdrawMin: 0,
            maxWithdrawal: 0,
            paymentWithdrawMax: 2412,
            isEnable: true,
            modified: "2024-01-31 12:21:14 ",
        },
        {
            bankName: "ACB",
            paymentMethod: "Offline",
            currency: "VND",
            transactionType: "Deposit",
            minDeposit: 0,
            paymentDepositMin: 1000,
            maxDeposit: 0,
            paymentDepositMax: 0,
            minWithdrawal: 0,
            paymentWithdrawMin: 0,
            maxWithdrawal: 0,
            paymentWithdrawMax: 2412,
            isEnable: true,
            modified: "2024-01-31 12:21:14 ",
        },
        {
            bankName: "ACB",
            paymentMethod: "Offline",
            currency: "VND",
            transactionType: "Deposit",
            minDeposit: 0,
            paymentDepositMin: 1000,
            maxDeposit: 0,
            paymentDepositMax: 0,
            minWithdrawal: 0,
            paymentWithdrawMin: 0,
            maxWithdrawal: 0,
            paymentWithdrawMax: 2412,
            isEnable: true,
            modified: "2024-01-31 12:21:14 ",
        },
        {
            bankName: "ACB",
            paymentMethod: "Offline",
            currency: "VND",
            transactionType: "Deposit",
            minDeposit: 0,
            paymentDepositMin: 1000,
            maxDeposit: 0,
            paymentDepositMax: 0,
            minWithdrawal: 0,
            paymentWithdrawMin: 0,
            maxWithdrawal: 0,
            paymentWithdrawMax: 2412,
            isEnable: true,
            modified: "2024-01-31 12:21:14 ",
        },
        {
            bankName: "ACB",
            paymentMethod: "Offline",
            currency: "VND",
            transactionType: "Deposit,Withdrawal",
            minDeposit: 0,
            paymentDepositMin: 1000,
            maxDeposit: 0,
            paymentDepositMax: 0,
            minWithdrawal: 0,
            paymentWithdrawMin: 0,
            maxWithdrawal: 0,
            paymentWithdrawMax: 2412,
            isEnable: true,
            modified: "2024-01-31 12:21:14 ",
        },
        {
            bankName: "ACB",
            paymentMethod: "Offline",
            currency: "VND",
            transactionType: "Deposit",
            minDeposit: 0,
            paymentDepositMin: 1000,
            maxDeposit: 0,
            paymentDepositMax: 0,
            minWithdrawal: 0,
            paymentWithdrawMin: 0,
            maxWithdrawal: 0,
            paymentWithdrawMax: 2412,
            isEnable: true,
            modified: "2024-01-31 12:21:14 ",
        },
        {
            bankName: "ACB",
            paymentMethod: "Offline",
            currency: "VND",
            transactionType: "Deposit",
            minDeposit: 0,
            paymentDepositMin: 1000,
            maxDeposit: 0,
            paymentDepositMax: 0,
            minWithdrawal: 0,
            paymentWithdrawMin: 0,
            maxWithdrawal: 0,
            paymentWithdrawMax: 2412,
            isEnable: true,
            modified: "2024-01-31 12:21:14 ",
        },
        {
            bankName: "ACB",
            paymentMethod: "Offline",
            currency: "VND",
            transactionType: "Deposit",
            minDeposit: 0,
            paymentDepositMin: 1000,
            maxDeposit: 0,
            paymentDepositMax: 0,
            minWithdrawal: 0,
            paymentWithdrawMin: 0,
            maxWithdrawal: 0,
            paymentWithdrawMax: 2412,
            isEnable: true,
            modified: "2024-01-31 12:21:14 ",
        },
        {
            bankName: "ACB",
            paymentMethod: "Offline",
            currency: "VND",
            transactionType: "Deposit",
            minDeposit: 0,
            paymentDepositMin: 1000,
            maxDeposit: 0,
            paymentDepositMax: 0,
            minWithdrawal: 0,
            paymentWithdrawMin: 0,
            maxWithdrawal: 0,
            paymentWithdrawMax: 2412,
            isEnable: true,
            modified: "2024-01-31 12:21:14 ",
        },
        {
            bankName: "ACB",
            paymentMethod: "Offline",
            currency: "VND",
            transactionType: "Deposit",
            minDeposit: 0,
            paymentDepositMin: 1000,
            maxDeposit: 0,
            paymentDepositMax: 0,
            minWithdrawal: 0,
            paymentWithdrawMin: 0,
            maxWithdrawal: 0,
            paymentWithdrawMax: 2412,
            isEnable: true,
            modified: "2024-01-31 12:21:14 ",
        },
    ])
    const fixedColumns: TableProps<IPaymentGateway>["columns"] = useMemo(
        () => [
            {
                title: "#",
                width: 60,
                render: (_, __, index) => {
                    return <p className='text-center'>{index + 1}</p>
                },
            },
            {
                title: "Bank Name",
                dataIndex: "bankName",
                minWidth: 150,
            },
            {
                title: "Payment Method",
                dataIndex: "paymentMethod",
                minWidth: 150,
            },
            {
                title: "Currency",
                dataIndex: "currency",
                minWidth: 60,
                render: (value, record) => {
                    return <div className='text-center'>{value}</div>
                },
            },
            {
                title: "Transaction Type",
                dataIndex: "transactionType",
                width: 190,
                render: (value, record) => {
                    if (value) {
                        const data = value.split(",")
                        return data.map((item: string, index: number) => {
                            if (item.trim() === "Deposit") {
                                return (
                                    <span key={index} className='deposit-btn'>
                                        {item}
                                    </span>
                                )
                            }
                            if (item.trim() === "Withdrawal") {
                                return (
                                    <span key={index} className='withdrawal-btn'>
                                        {item}
                                    </span>
                                )
                            }
                        })
                    }
                },
            },
            {
                title: (
                    <div>
                        <p>Min Deposit</p>
                        <p>Payment Deposit Min</p>
                    </div>
                ),
                width: 220,
                render: (_, record) => {
                    return (
                        <div className='text-right'>
                            <p>{record.minDeposit}</p>
                            <p>{record.paymentDepositMin}</p>
                        </div>
                    )
                },
            },
            {
                title: (
                    <div>
                        <p>Max Deposit</p>
                        <p>Payment Deposit Max</p>
                    </div>
                ),
                width: 200,
                render: (_, record) => {
                    return (
                        <div className='text-right'>
                            <p>{record.maxDeposit}</p>
                            <p>{record.paymentDepositMax}</p>
                        </div>
                    )
                },
            },
            {
                title: (
                    <div>
                        <p>Min Withdrawal</p>
                        <p>Payment Withdraw Min</p>
                    </div>
                ),
                width: 215,
                render: (_, record) => {
                    return (
                        <div className='text-right'>
                            <p>{record.minWithdrawal}</p>
                            <p>{record.paymentWithdrawMin}</p>
                        </div>
                    )
                },
            },
            {
                title: (
                    <div>
                        <p>Max Withdrawal</p>
                        <p>Payment Withdraw Max</p>
                    </div>
                ),
                width: 250,
                render: (_, record) => {
                    return (
                        <div className='text-right'>
                            <p>{record.maxWithdrawal}</p>
                            <p>{record.paymentWithdrawMax}</p>
                        </div>
                    )
                },
            },
            {
                title: "Is Enabled",
                dataIndex: "isEnable",
                render: (_, record) => {
                    return (
                        <div className='text-center'>
                            <Checkbox defaultChecked={record.isEnable}></Checkbox>
                        </div>
                    )
                },
                width: 160,
            },
            {
                title: "Modified",
                dataIndex: "modified",
                width: 160,
            },
        ],
        []
    )

    const [isEdit, setIsEdit] = useState(false)
    const [canSave, setCanSave] = useState(false)
    const [dataSave, setDataSave] = useState<any>([])

    const onClickEdit = () => {
        setIsEdit(true)
    }
    const onClickCancel = () => {
        setIsEdit(false)
    }

    const onClickSave = async () => {
        try {
            const res = await httpService.patch(apis.company.language, dataSave)

            if (res?.data) {
                setIsEdit(false)
                toast.success("Success")
            }
        } catch (error) {
            toast.error("Update error")

            console.log("error", error)
        }
    }

    return (
        <div className='paypro'>
            <div className='win-lost'>
                <div>
                    <Row className='filter-bar'>
                        <Col>
                            <div>Payment Method</div>
                            <Select
                                defaultValue='all'
                                style={{ width: 100 }}
                                // onChange={onChangePlatform}
                            >
                                <Option value='all'>All</Option>
                                <Option value='offline'>Offline</Option>
                            </Select>
                        </Col>

                        <Col>
                            <div>Currency</div>
                            <Select
                                defaultValue='all'
                                style={{ width: 100 }}
                                // onChange={onChangePlatform}
                            >
                                <Option value='all'>All</Option>
                                <Option value='VND'>VND</Option>
                            </Select>
                        </Col>
                        <Col>
                            <div>Transaction Type</div>
                            <Select
                                defaultValue='all'
                                style={{ width: 100 }}
                                // onChange={onChangePlatform}
                            >
                                <Option value='all'>All</Option>
                                <Option value='offline'>Offline</Option>
                            </Select>
                        </Col>
                    </Row>

                    <div className='remark-content'>
                        <p>
                            <span style={{ width: 20, marginRight: 2 }}>
                                <ExclamationCircleFilled />
                            </span>
                            <span className='remark-title'>Remark</span>
                        </p>
                        <li className='remark-text'>
                            - The payment value mean the limitation set by payment provider, you
                            might not be able to set min max over that value. (if value is 0 means
                            the payment provider not set limitation)
                        </li>
                    </div>
                </div>

                <Table
                    id='table-data'
                    bordered
                    columns={fixedColumns}
                    scroll={{ x: 2000, y: "58vh" }}
                    rowKey='id'
                    dataSource={data}
                    pagination={false}
                />
            </div>
        </div>
    )
}

export default HTPay
