import { configureStore } from "@reduxjs/toolkit";
import { authReducer } from "./slices/auth";
import languageReducer from "./slices/language";
import { default as permissionReducer } from "./slices/permission";
import { default as promotionReducer } from "./slices/promotions";

// Never remove this for type typescript thunk

const reducer = {
    auth: authReducer,
    language: languageReducer,
    promotion: promotionReducer,
    permission: permissionReducer,
};

const store = configureStore({
    reducer: reducer,
    devTools: true,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
});

export default store;
