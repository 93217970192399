import { Loading3QuartersOutlined } from "@ant-design/icons";
import { Button, Card, Checkbox, Col, Image, Pagination, Row, Select, Table, Tabs } from "antd";
import { CheckboxChangeEvent } from "antd/es/checkbox";
import TextArea from "antd/es/input/TextArea";
import TabPane from "antd/es/tabs/TabPane";
import { isArray } from "lodash";
import moment from "moment";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { paths } from "../../../routes/path";
import { TxnState } from "../../constants/txn";
import { CustomDateRangePicker } from "../date-range-picker";
import "./TransactionTable.scss";

interface TxnProps {
    cardTitle: string;
    data: TxnData[];
    onChangeTab: (state: string) => void;
}
interface TxnData {
    key: string;
    txnNo: string;
    username: string;
    requestTime: string;
    beforeBalance: string;
    afterBalance: string;
    currency: string;
    amount: string;
    remark: string;
    modifiedBy: string;
}

const TransactionTable = ({ cardTitle, data, onChangeTab }: TxnProps) => {
    const { t } = useTranslation();
    const { Option } = Select;

    const headerWithdraw = [
        {
            key: TxnState.Waiting,
            label: t("content.txn.header.waiting"),
        },
        {
            key: TxnState.Verified,
            label: t("content.txn.header.verified"),
        },
        {
            key: TxnState.Approved,
            label: t("content.txn.header.approved"),
        },
        {
            key: TxnState.Completed,
            label: t("content.txn.header.rejected"),
        },
        {
            key: TxnState.Rejected,
            label: t("content.txn.header.rejected"),
        },
        {
            key: TxnState.Cancelled,
            label: t("content.txn.header.cancelled"),
        },

        {
            key: TxnState.PaymentProcessing,
            label: t("content.txn.header.paymentProcessing"),
        },
        {
            key: TxnState.PaymentError,
            label: t("content.txn.header.paymentError"),
        },
        {
            key: "manual",
            label: t("content.txn.header.manual"),
        },
    ];
    const header = [
        {
            key: TxnState.Waiting,
            label: t("content.txn.header.waiting"),
        },
        {
            key: TxnState.Verified,
            label: t("content.txn.header.verified"),
        },
        {
            key: TxnState.Approved,
            label: t("content.txn.header.approved"),
        },

        {
            key: TxnState.Rejected,
            label: t("content.txn.header.rejected"),
        },
        {
            key: TxnState.Cancelled,
            label: t("content.txn.header.cancelled"),
        },

        {
            key: TxnState.PaymentProcessing,
            label: t("content.txn.header.paymentProcessing"),
        },
        {
            key: TxnState.PaymentError,
            label: t("content.txn.header.paymentError"),
        },
        {
            key: "manual",
            label: t("content.txn.header.manual"),
        },
    ];

    const tableCol = [
        {
            title: t("content.txn.col.txnNo"),
            dataIndex: "txnNo",
            key: "txnNo",
        },
        {
            title: t("content.txn.col.username"),
            dataIndex: "username",
            key: "username",
            render: (value: any, record: any) => {
                return (
                    <div>
                        <Link
                            className="bold"
                            to={paths.USER_DETAIL.replace(":id", record.userId)}
                            style={{ margin: "2px" }}
                        >
                            {value}
                        </Link>
                    </div>
                );
            },
        },
        {
            title: t("content.txn.col.requestTime"),
            dataIndex: "requestTime",
            key: "requestTime",
        },
        {
            title: (
                <div style={{ display: "block" }}>
                    <div>{t("content.txn.col.beforeBalance")}</div>
                    <div>{t("content.txn.col.afterBalance")}</div>
                </div>
            ),

            dataIndex: "balance",
            key: "balance",
            render: (text: any, record: any) => (
                <div style={{ display: "block", textAlign: "right" }}>
                    <div>{record.beforeBalance}</div>
                    <div>{record.afterBalance}</div>
                </div>
            ),
        },

        {
            title: (
                <div style={{ display: "block" }}>
                    <div>{t("content.txn.col.currency")}</div>
                    <div>{t("content.txn.col.amount")}</div>
                </div>
            ),

            dataIndex: "currency",
            key: "currency",
            render: (text: any, record: any) => (
                <div style={{ display: "block", textAlign: "center" }}>
                    <div>{record.currency}</div>
                    <div>{record.amount}</div>
                </div>
            ),
        },

        {
            title: t("content.txn.col.remark"),
            dataIndex: "remark",
            key: "remark",
            render: (text: any) => <TextArea defaultValue={text} />,
        },
    ];

    const colModifiedBy = [
        ...tableCol,
        {
            title: t("content.txn.col.modifiedBy"),
            dataIndex: "modifiedBy",
            key: "modifiedBy",
        },
    ];
    const [totalPage, setTotalPage] = useState(500);

    const [checkedCheckBox, setCheckedCheckBox] = useState(false);
    const [loadings, setLoadings] = useState(false);
    const dataPlayer = [
        {
            key: "1",
            bankName: "abc",
            branchName: "chi nhanh binh tan",
            bankAccount: "09090909",
            bankAccountNumber: "abc",
        },
    ];
    const dataCompany = [
        {
            key: "1",
            bankName: "abc",
            bankAccountName: "09090909",
            bankAccountNumber: "213018231340",
            bankBeforeBalance: "213018231340",
            bankAfterBalance: "213018231340",
        },
    ];

    const expandedRowRender = () => {
        return (
            <div className="more-info">
                <Col className="more-info__page1">
                    <div className="more-info__card">
                        <div className="more-info__title">{t("content.txn.playerBank.title")}</div>
                        {dataPlayer.map((item: any, index) => {
                            return (
                                <div key={index}>
                                    <div className="more-info-item">
                                        <div>{t("content.txn.playerBank.item.bankName")}</div>
                                        <div>{item.bankName}</div>
                                    </div>
                                    <div className="more-info-item">
                                        <div>{t("content.txn.playerBank.item.branchName")}</div>
                                        <div>{item.branchName}</div>
                                    </div>
                                    <div className="more-info-item">
                                        <div>{t("content.txn.playerBank.item.bankAccount")}</div>
                                        <div>{item.bankAccount}</div>
                                    </div>
                                    <div className="more-info-item">
                                        <div>{t("content.txn.playerBank.item.bankAccountNumber")}</div>
                                        <div>{item.bankAccountNumber}</div>
                                    </div>
                                    <div className="more-info-item">
                                        <div>{t("content.txn.playerBank.item.bankAccountName")}</div>
                                        <div>{item.bankAccountName}</div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    <div className="more-info__card">
                        <div className="more-info__title">{t("content.txn.companyBank.title")}</div>
                        {dataCompany.map((item: any, index) => {
                            return (
                                <div key={index}>
                                    <div className="more-info-item">
                                        <div>{t("content.txn.companyBank.item.bankName")}</div>
                                        <div>{item.bankName}</div>
                                    </div>
                                    <div className="more-info-item">
                                        <div>{t("content.txn.companyBank.item.bankAccountNumber")}</div>
                                        <div>{item.bankAccountNumber}</div>
                                    </div>
                                    <div className="more-info-item">
                                        <div>{t("content.txn.companyBank.item.bankAccountName")}</div>
                                        <div>{item.bankAccountName}</div>
                                    </div>
                                    <div className="more-info-item">
                                        <div>{t("content.txn.companyBank.item.bankBeforeBalance")}</div>
                                        <div>{item.bankBeforeBalance}</div>
                                    </div>
                                    <div className="more-info-item">
                                        <div>{t("content.txn.companyBank.item.bankAfterBalance")}</div>
                                        <div>{item.bankAfterBalance}</div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </Col>
                <Col className="more-info__page2">
                    <div className="more-info__title">{t("content.txn.slipUpload.title")}</div>
                    <div className="image-slot">
                        <Image src="/no_icon.svg" className="image-slot-icon" width={35} preview={false} />
                    </div>
                </Col>
                <Col className="more-info__page3">
                    <div className="left">
                        <div className="more-info__card">
                            <div className="more-info__title">{t("content.txn.playerInfo.title")}</div>
                            <div>
                                <div className="more-info-item">
                                    <div>{t("content.txn.playerInfo.item.registerTime")}</div>
                                    <div>2024-03-01 01:27:40 AM (-4)</div>
                                </div>
                                <div className="more-info-item">
                                    <div>{t("content.txn.playerInfo.item.lastDepositTime")}</div>
                                    <div>2024-03-01 06:30:32 AM (-4)</div>
                                </div>
                                <div className="more-info-item">
                                    <div>{t("content.txn.playerInfo.item.lastWithdrawalTime")}</div>
                                    <div>2024-03-02 01:35:30 AM (-4)</div>
                                </div>
                                <div className="more-info-item">
                                    <div>{t("content.txn.playerInfo.item.lastApplyPromotionTime")}</div>
                                    <div></div>
                                </div>
                                <div className="more-info-item">
                                    <div>{t("content.txn.playerInfo.item.phone")}</div>
                                    <div>0968129736</div>
                                </div>
                            </div>
                        </div>
                        <div className="more-info__card">
                            <div className="more-info__title">{t("content.txn.transactionsSummary.title")}</div>
                            <div>
                                <div className="more-info-item">
                                    <div>{t("content.txn.transactionsSummary.item.totalDepositAmount")}</div>
                                    <div>500.00</div>
                                </div>
                                <div className="more-info-item">
                                    <div>{t("content.txn.transactionsSummary.item.totalWithdrawalAmount")}</div>
                                    <div>1,000.00</div>
                                </div>
                                <div className="more-info-item">
                                    <div>{t("content.txn.transactionsSummary.item.totalPromotionBonus")}</div>
                                    <div>0.00</div>
                                </div>
                            </div>
                        </div>
                        <div className="more-info__card">
                            <div className="more-info__title ">{t("content.txn.multiAccountsChecking.title")}</div>
                            <div className="checking">
                                <div className="tag">{t("content.txn.multiAccountsChecking.item.registerIP")}: 0</div>
                                <div className="tag">{t("content.txn.multiAccountsChecking.item.lastLoginIP")}: 0</div>
                                <div className="tag">{t("content.txn.multiAccountsChecking.item.registerFP")}: 0</div>
                                <div className="tag">{t("content.txn.multiAccountsChecking.item.lastLoginFP")}: 0</div>
                                <div className="tag">
                                    {t("content.txn.multiAccountsChecking.item.bankAccountNumber")}: 0
                                </div>
                                <div className="tag">{t("content.txn.multiAccountsChecking.item.phone")}: 0</div>
                            </div>
                        </div>
                    </div>
                    <div className="divider"></div>
                    <div className="right">
                        <div className="more-info__title">{t("content.deposit.link.title")}</div>
                        <div className="upload">
                            <Button>{t("content.txn.link.item.deposit")}</Button>
                            <Button>{t("content.txn.link.item.withdrawal")}</Button>
                            <Button>{t("content.txn.link.item.bettingRecord")}</Button>
                            <Button>{t("content.txn.link.item.balanceHistory")}</Button>
                            <Button>{t("content.txn.link.item.betList")}</Button>
                            <Button>{t("content.txn.link.item.promotionList")}</Button>
                            <Button>{t("content.txn.link.item.loginLog")}</Button>
                        </div>
                    </div>
                </Col>
            </div>
        );
    };
    const findCol = (key: string) => {
        return key === "waiting" ? colModifiedBy : tableCol;
    };
    const onChangeCheckbox = (e: CheckboxChangeEvent) => {
        console.log(`checked = ${e.target.checked}`);
        setCheckedCheckBox(!checkedCheckBox);
    };

    //date
    const currentDate = new Date();
    const fromDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - 30);

    const [filterDate, setFilterDate] = useState({
        fromDate: fromDate,
        toDate: currentDate,
    });

    const onClickApplyDate = (event: any, picker: any) => {
        const fromDate = moment(picker.startDate).toDate();
        const toDate = moment(picker.endDate).toDate();
        setFilterDate({
            ...filterDate,
            fromDate,
            toDate,
        });
    };

    const clearDate = () => {
        setFilterDate({
            ...filterDate,
            fromDate: fromDate,
            toDate: currentDate,
        });
    };
    //emtydata
    const [emptyText, setEmptyText] = useState(t("content.txn.clickSearchtoSeeInformation"));
    let locale = {
        emptyText,
    };

    return (
        <Card id="transaction-table">
            <Row className="txn-table">
                <Col style={{ width: "100%" }}>
                    <Col className="tab">
                        <Tabs defaultActiveKey="1" onChange={(value) => onChangeTab(value)}>
                            {(cardTitle === "Withdraw" ? headerWithdraw : header).map((item, index) => {
                                return (
                                    <TabPane tab={item.label} key={index}>
                                        <Row className="filter-header">
                                            <Col>
                                                <div className="filter-header__name">{t("content.txn.currency")}</div>
                                                <Select placeholder="Select" style={{ width: 120 }} allowClear>
                                                    <Option value="AED">AED</Option>
                                                    <Option value="AUD">AUD</Option>
                                                    <Option value="BDT">BDT</Option>
                                                    <Option value="BND">BND</Option>
                                                    <Option value="BRL">BRL</Option>
                                                    <Option value="CAD">CAD</Option>
                                                    <Option value="CHF">CHF</Option>
                                                    <Option value="CNY">CNY</Option>
                                                    <Option value="EUR">EUR</Option>
                                                    <Option value="GBP">GBP</Option>
                                                    <Option value="HKD">HKD</Option>
                                                    <Option value="IDR">IDR</Option>
                                                    <Option value="INR">INR</Option>
                                                    <Option value="JPY">JPY</Option>
                                                    <Option value="KRW">KRW</Option>
                                                    <Option value="LAK">LAK</Option>
                                                    <Option value="MMK">MMK</Option>
                                                    <Option value="MXN">MXN</Option>
                                                    <Option value="MYR">MYR</Option>
                                                    <Option value="NOK">NOK</Option>
                                                    <Option value="NPR">NPR</Option>
                                                    <Option value="NZD">NZD</Option>
                                                    <Option value="PHP">PHP</Option>
                                                    <Option value="RUB">RUB</Option>
                                                    <Option value="SEK">SEK</Option>
                                                    <Option value="THB">THB</Option>
                                                    <Option value="UCC">UCC</Option>
                                                    <Option value="USD">USD</Option>
                                                    <Option value="VND">VND</Option>
                                                    <Option value="ZAR">ZAR</Option>
                                                </Select>
                                            </Col>
                                            {item.key === TxnState.Waiting ||
                                            item.key === TxnState.Approved ||
                                            item.key === TxnState.Rejected ||
                                            item.key === TxnState.Cancelled ? (
                                                <Col>
                                                    <div className="filter-header__name">
                                                        {t("content.txn.depositType.title")}
                                                    </div>
                                                    <Select defaultValue="manual" style={{ width: 160 }} allowClear>
                                                        <Option value="manual">
                                                            {t("content.txn.depositType.type.manualDeposit")}
                                                        </Option>
                                                        <Option value="auto">
                                                            {t("content.txn.depositType.type.autoDeposit")}
                                                        </Option>
                                                    </Select>
                                                </Col>
                                            ) : null}
                                            {item.key === TxnState.Verified || item.key === TxnState.Approved ? (
                                                <Col className="date-range-transaction-history">
                                                    <div className="filter-header__name">
                                                        {t("content.txn.statisticalDate")}
                                                    </div>
                                                    <div style={{ display: "flex" }}>
                                                        <CustomDateRangePicker
                                                            onApply={onClickApplyDate}
                                                            onCancel={clearDate}
                                                            containerStyles={{
                                                                width: "214px",
                                                                padding: "3px 0px",
                                                                paddingLeft: "4px",
                                                                borderRadius: "6px",
                                                                border: "1px solid #d9d9d9",
                                                            }}
                                                            fromDate={filterDate.fromDate}
                                                            toDate={filterDate.toDate}
                                                            resultToString
                                                            opens={"center"}
                                                            readOnly
                                                        />
                                                        <Button style={{ marginLeft: "12px" }}>
                                                            {t("content.txn.all")}
                                                        </Button>
                                                    </div>
                                                </Col>
                                            ) : null}
                                        </Row>
                                        {!isArray(data?.length) && (
                                            <Row className="pagination pagination__top">
                                                <Pagination
                                                    defaultCurrent={1}
                                                    total={totalPage}
                                                    showQuickJumper
                                                    style={{ fontSize: 13 }}
                                                />
                                            </Row>
                                        )}

                                        <Row>
                                            <Table
                                                className="txn-table-main"
                                                key={index}
                                                columns={findCol(item.key)}
                                                dataSource={data}
                                                style={{ width: "100%" }}
                                                locale={locale}
                                                expandable={{ expandedRowRender }}
                                            />
                                        </Row>
                                        {!isArray(data?.length) && (
                                            <Row className="pagination pagination__bottom">
                                                <Pagination
                                                    defaultCurrent={1}
                                                    total={totalPage}
                                                    showQuickJumper
                                                    style={{ fontSize: 13 }}
                                                />
                                            </Row>
                                        )}
                                        {loadings && (
                                            <div className="loading-mask-table">
                                                <div className="loading-icon-table">
                                                    <Loading3QuartersOutlined className="loading-icon" />
                                                </div>
                                            </div>
                                        )}
                                    </TabPane>
                                );
                            })}
                        </Tabs>
                        <Col className="refresh">
                            <Checkbox
                                onChange={onChangeCheckbox}
                                checked={checkedCheckBox}
                                className={checkedCheckBox ? "refresh__checkox checked" : "refresh__checkox"}
                            >
                                <div style={{ color: " #7b7b7b " }}>{t("content.txn.autoRefresh")}</div>
                            </Checkbox>
                            <Button className="refresh__btn">
                                <Image
                                    className="refresh__icon"
                                    src="/circular_icon.svg"
                                    style={{ width: 14, marginBottom: 4, paddingRight: 2 }}
                                    preview={false}
                                />
                                {t("content.txn.refresh")}
                            </Button>
                        </Col>
                    </Col>
                </Col>
            </Row>
        </Card>
    );
};

export default TransactionTable;
