import { Button, Divider, Form, Input, Modal, Select } from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { apis } from "../../../apis";
import { httpService } from "../../../apis/httpService";
import "./ModalCreateSubAccount.scss";

type FieldType = {
    isModalOpen: boolean;
    setIsModalOpen: (value: any) => void;
};

const ModalCreateSubAccount = ({ setIsModalOpen, isModalOpen }: FieldType) => {
    const [form] = Form.useForm();
    const { t } = useTranslation();
    const { Option } = Select;

    const [errorMess, setErrorMess] = useState("");
    const [validatePassword, setValidatePassword] = useState("");
    const onFinish = async (values: any) => {
        if (values.password !== values.confirmPassword) {
            setValidatePassword("Password not match");
            return;
        }
        try {
            const payload = {
                username: values.username,
                password: values.password,
                confirmPassword: values.confirmPassword,
                firstName: values.firstName,
                lastName: values.lastName,
                phone: values.phone,
                mobile: values.mobile,
                roleIds: [values.permissionGroup],
            };
            await httpService.post(apis.subAccount.base, payload);
            toast.success("Success!");
            form.resetFields();
            setIsModalOpen(false);
        } catch (error: any) {
            console.log("error", error);
            toast.error("Create failed!");
            if (error.response?.data?.messageCode === "USER_ALREADY_EXIST") {
                setErrorMess("Username has already existed");
            }
        }
    };
    const onFinishFailed = (errorInfo: any) => {
        console.log("Failed:", errorInfo);
    };
    const [confirmLoading, setConfirmLoading] = useState(false);

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const [permissionGroup, setPermissionGroup] = useState<any[]>([]);
    const getPermissionGroup = async () => {
        try {
            const res = await httpService.get(apis.permission.groups);
            if (res.data?.result?.length) {
                setPermissionGroup(res.data.result);
            }
        } catch (error) {
            console.log("error", error);
        }
    };
    useEffect(() => {
        getPermissionGroup();
    }, [isModalOpen]);

    return (
        <div className="modal-create-sub-account__container">
            <Modal
                title={t("content.subAccountList.createSubAccountModal.title")}
                open={isModalOpen}
                onCancel={handleCancel}
                confirmLoading={confirmLoading}
                footer={null}
                className="modal-create-sub-account"
            >
                <div style={{ marginTop: 20 }}>
                    <Form
                        name="basic"
                        labelCol={{ span: 8 }}
                        wrapperCol={{ span: 12 }}
                        style={{ textAlign: "center" }}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        form={form}
                    >
                        <Form.Item
                            label={t("content.subAccountList.createSubAccountModal.username")}
                            name="username"
                            validateStatus={errorMess ? "error" : ""}
                            help={errorMess}
                            rules={[{ required: true }]}
                        >
                            <Input required />
                        </Form.Item>

                        <Form.Item
                            label={t("content.subAccountList.createSubAccountModal.password")}
                            name="password"
                            rules={[{ required: true }]}
                        >
                            <Input.Password required />
                        </Form.Item>
                        <Form.Item
                            label={t("content.subAccountList.createSubAccountModal.confirmPassword")}
                            name="confirmPassword"
                            rules={[{ required: true }]}
                            validateStatus={validatePassword ? "error" : ""}
                            help={validatePassword}
                        >
                            <Input.Password required />
                        </Form.Item>
                        <Form.Item
                            label={t("content.subAccountList.createSubAccountModal.permissionGroup.title")}
                            name="permissionGroup"
                            rules={[{ required: true }]}
                        >
                            <Select>
                                {permissionGroup?.map((item, index) => (
                                    <Option value={item?.id} key={index}>
                                        {item?.name}
                                    </Option>
                                ))}
                            </Select>
                        </Form.Item>

                        <Divider />
                        <Form.Item
                            label={t("content.subAccountList.createSubAccountModal.firstName")}
                            name="firstName"
                            rules={[{ required: true }]}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label={t("content.subAccountList.createSubAccountModal.lastName")}
                            name="lastName"
                            rules={[{ required: true }]}
                        >
                            <Input />
                        </Form.Item>

                        <Form.Item label={t("content.subAccountList.createSubAccountModal.phone")} name="phone">
                            <Input type="number" />
                        </Form.Item>

                        <Form.Item wrapperCol={{ offset: 8, span: 8 }} style={{ marginTop: 26 }}>
                            <Button type="primary" htmlType="submit">
                                {t("content.subAccountList.createSubAccountModal.save")}
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </Modal>
            <ToastContainer autoClose={1500} />
        </div>
    );
};

export default ModalCreateSubAccount;
