import Divider from "./divider";

const FormHeader = ({ title }: { title: string }) => {
    return (
        <div className="form-header__container">
            <div className="form-header__title">{title}</div>
            <Divider dimension="horizontal" className="my-4" />
        </div>
    );
};

export default FormHeader;
