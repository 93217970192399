import { useTranslation } from "react-i18next";
import "./PlayerSummary.scss";

import {
  Button,
  Checkbox,
  Col,
  Dropdown,
  Image,
  Input,
  Pagination,
  PaginationProps,
  Row,
  Select,
  Table,
  Tooltip,
} from "antd";
import { useEffect, useMemo, useState } from "react";

import { CSVLink } from "react-csv";

import moment from "moment";
import { Link } from "react-router-dom";
import { apis } from "../../../apis";

import {
  Loading3QuartersOutlined,
  QuestionCircleFilled,
} from "@ant-design/icons";
import { httpService } from "../../../apis/httpService";
import { ISort } from "../../../interfaces/Global";
import { paths } from "../../../routes/path";

import { CustomDateRangePicker } from "../../../common/components/date-range-picker";
import { PlayerSummaryAmountFilter } from "../../../common/constants/enum";
import { StatisticalDate } from "../../../common/constants/statical-date";
import { getCurrencyList } from "../../../common/helpers/utils";
const PlayerSummary = () => {
  const { t } = useTranslation();

  const { Option } = Select;

  const columnsSource = [
    {
      title: t("content.playerSummary.col.memberWinLost"),
      dataIndex: "memberWinLost",
      key: "memberWinLost",
    },
    {
      title: t("content.playerSummary.col.promotionBonus"),
      dataIndex: "promotionBonus",
      key: "promotionBonus",
    },
    {
      title: t("content.playerSummary.col.companyWinLost"),
      dataIndex: "companyWinLost",
      key: "companyWinLost",
    },
    {
      title: t("content.playerSummary.col.status"),
      dataIndex: "status",
      key: "status",
    },
    {
      title: t("content.playerSummary.col.suspend"),
      dataIndex: "suspend",
      key: "suspend",
    },
  ];

  function makeColumnsDataSource() {
    const columns = [
      {
        title: "#",
        dataIndex: "index",
        key: "index",
        render: (text: any, record: any, index: number) => index + 1,
      },
      {
        title: t("content.playerSummary.col.username"),
        dataIndex: "username",
        key: "username",
        render: (value: any, record: any) => {
          return (
            <div
              className="flex"
              style={{
                textDecoration: "underline",
                justifyContent: "flex-start",
                color: "blue",
              }}
            >
              <Link to={paths.USER_DETAIL.replace(":id", record.userId)}>
                {value}
              </Link>
            </div>
          );
        },
      },

      {
        title: t("content.playerSummary.col.agent"),
        dataIndex: "agent",
        key: "agent",
        render: (value: any, record: any) => {
          return (
            <div
              className="flex"
              style={{
                textDecoration: "underline",
                justifyContent: "flex-start",
                color: "blue",
              }}
            >
              <Link to={paths.USER_DETAIL.replace(":id", record.userId)}>
                {value}
              </Link>
            </div>
          );
        },
      },

      {
        title: t("content.playerSummary.col.registerTime"),
        dataIndex: "registerTime",
        key: "registerTime",
      },
      {
        title: t("content.playerSummary.col.registerDomain"),
        dataIndex: "registerDomain",
        key: "registerDomain",
      },

      {
        title: t("content.playerSummary.col.currency"),
        dataIndex: "currency",
        key: "currency",
      },

      {
        title: t("content.playerSummary.col.currentBalance"),
        dataIndex: "currentBalance",
        key: "currentBalance",
      },

      {
        title: t("content.playerSummary.col.firstDepositTime"),
        dataIndex: "firstDepositTime",
        key: "firstDepositTime",
      },

      {
        title: t("content.playerSummary.col.depositCount"),
        dataIndex: "depositCount",
        key: "depositCount",
      },
      {
        title: t("content.playerSummary.col.withdrawCount"),
        dataIndex: "withdrawCount",
        key: "withdrawCount",
      },
      {
        title: t("content.playerSummary.col.withdrawAmount"),
        dataIndex: "withdrawAmount",
        key: "withdrawAmount",
      },
      {
        title: t("content.playerSummary.col.turnover"),
        dataIndex: "turnover",
        key: "turnover",
      },
    ];

    for (const col of columnsSource) {
      if (col.key !== "status" && col.key !== "suspend") {
        columns.push({
          title: col.title,
          key: col.key,
          dataIndex: col.dataIndex,
          render: (value: any, record: any) => {
            if (parseInt(value) < 0) {
              return (
                <div style={{ color: "red", alignItems: "right" }}>{value}</div>
              );
            } else if (parseInt(value) > 0) {
              return (
                <div style={{ color: "blue", alignItems: "right" }}>
                  {value}
                </div>
              );
            } else {
              return (
                <div style={{ color: "#7b7b7b", alignItems: "right" }}>
                  {value}
                </div>
              );
            }
          },
        });
      }
      if (col.key === "status") {
        columns.push({
          title: col.title,
          key: col.key,
          dataIndex: col.dataIndex,
          render: (value: any, record: any) => {
            if (value == "Open") {
              return <div style={{ color: "#67c23a" }}>{value}</div>;
            } else {
              return <div style={{ color: "red" }}>{value}</div>;
            }
          },
        });
      }
      if (col.key === "suspend") {
        columns.push({
          title: col.title,
          key: col.key,
          dataIndex: col.dataIndex,
          render: (value: any, record: any) => {
            if (value == "No") {
              return <div style={{ color: "#67c23a" }}>{value}</div>;
            } else {
              return <div style={{ color: "#red" }}>{value}</div>;
            }
          },
        });
      }
    }

    columns.push({
      title: t("content.report.playerSummary.lastLoginTime"),
      dataIndex: "lastLoginTime",
      key: "lastLoginTime",
    });

    return columns;
  }

  const [filterByAmount, setFilterByAmount] = useState("");
  const [disableFilterAmount, setDisableFilterAmount] = useState(true);
  const onFilterAmountChange = (value: string) => {
    setFilterByAmount(value);
    setDisableFilterAmount(false);
  };

  const [minNumber, setMinNumber] = useState(-1);
  const [maxNumber, setMaxNumber] = useState(-1);

  const [playerSummaryData, setPlayerSummaryData] = useState([]);

  const columnsSourceMade = useMemo(
    () => makeColumnsDataSource(),
    [playerSummaryData]
  );

  const [username, setUsername] = useState();
  const [currency, setCurrency] = useState<string[]>([]);
  const [statisticalDate, setStatisticalDate] = useState(
    StatisticalDate.LastLogin
  );
  const [checkColumn, setCheckColumn] = useState(() =>
    columnsSourceMade.map((item) => item.key)
  );

  const handleCheckColum = (key: any) => {
    const isChecked = checkColumn.includes(key);
    if (isChecked) {
      const newState = checkColumn.filter((item) => item !== key);
      setCheckColumn(newState);
    } else {
      setCheckColumn([...checkColumn, key]);
    }
  };
  //filter columns table data
  const columnsData = columnsSourceMade.filter((i) =>
    checkColumn.includes(i.key)
  );
  const columnsInDropdown = useMemo(
    () => columnsSourceMade.filter((i) => i.key !== "index"),
    []
  );

  const currentDate = new Date();
  const fromDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate() - 30
  );

  const [filterDate, setFilterDate] = useState({
    fromDate: fromDate,
    toDate: currentDate,
  });

  const [totalPage, setTotalPage] = useState(0);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [orderBy, setOrderBy] = useState<ISort | null>(null);
  const [loadings, setLoadings] = useState(false);

  const onAccountChange = (e: any) => {
    setUsername(e.target.value);
  };

  const onCurrencyChange = (value: string) => {
    setCurrency([value]);
  };

  const onStatisticalDateChange = (value: any) => {
    setStatisticalDate(value);
  };

  const onChangePage: PaginationProps["onChange"] = (
    pageNumber: number,
    pageSize: number
  ) => {
    setPage(pageNumber);
    setPageSize(pageSize);
  };

  const onClickApplyDate = (event: any, picker: any) => {
    const fromDate = moment(picker.startDate).toDate();
    const toDate = moment(picker.endDate).toDate();
    setFilterDate({
      ...filterDate,
      fromDate,
      toDate,
    });
  };

  const clearDate = () => {
    setFilterDate({
      ...filterDate,
      fromDate: fromDate,
      toDate: currentDate,
    });
  };

  const handleSearch = async () => {
    await getPlayerSummary();
  };

  const getPlayerSummary = async () => {
    const payload = {
      fromDate: filterDate.fromDate.toISOString(),
      toDate: filterDate.toDate.toISOString(),
      username: username ?? username,
      statisticalDate,
      currencies: currency.length > 0 ? [currency] : undefined,
      filterAmount: filterByAmount ?? filterByAmount,
      minNumber: minNumber > 0 ? minNumber : undefined,
      maxNumber: maxNumber > 0 ? maxNumber : undefined,
      page: page,
      take: pageSize,
    };
    try {
      const res = await httpService.post(apis.report.playerSummary, payload);
      setPlayerSummaryData(res.data.result);
      setTotalPage(res.data?.meta?.pageCount);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getPlayerSummary();
  }, [
    filterDate.fromDate,
    filterDate.toDate,
    username,
    statisticalDate,
    currency,
    page,
    pageSize,
    minNumber,
    maxNumber,
  ]);

  const [currencyList, setCurrencyList] = useState<any[]>([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getCurrencyList();
        setCurrencyList(res.data);

        await getPlayerSummary();
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);

  const onChangeMinAmount = (e: any) => {
    setMinNumber(e.target.value);
  };

  const onChangeMaxAmount = (e: any) => {
    setMaxNumber(e.target.value);
  };

  const onClickReset = async () => {
    setFilterDate({
      fromDate: fromDate,
      toDate: currentDate,
    });
    setUsername(undefined);
    setCurrency([]);
    setStatisticalDate(StatisticalDate.LastLogin);
    await getPlayerSummary();
  };

  return (
    <div>
      <Col id="player-summary">
        <div className="filter">
          <div className="filter-header">
            <div className="filter-header__name">
              {t("content.playerSummary.title")}
            </div>
            <div className="filter-header__export">
              <Button>
                <Image
                  width={20}
                  src="/excel_icon.png"
                  style={{ marginBottom: 4, paddingRight: 2 }}
                />
                <CSVLink
                  filename={"Expense_Table.csv"}
                  data={playerSummaryData}
                  className="btn btn-primary"
                >
                  <span> {t("content.playerSummary.export")}</span>
                </CSVLink>
              </Button>
            </div>
          </div>
          <div className="filter-bar">
            <Row gutter={8} className="filter">
              <Col className="date-range-transaction-history">
                <span className="filter__name">
                  {t("content.playerSummary.statisticalDate.title")}
                </span>
                <Select
                  defaultValue={StatisticalDate.LastLogin}
                  style={{ width: 160, marginRight: 10 }}
                  onChange={onStatisticalDateChange}
                >
                  <Option value={StatisticalDate.LastLogin}>
                    {t(
                      "content.playerSummary.statisticalDate.type.lastLoginTime"
                    )}
                  </Option>
                  <Option value={StatisticalDate.RegisterDate}>
                    {t(
                      "content.playerSummary.statisticalDate.type.registerTime"
                    )}
                  </Option>
                  <Option value={StatisticalDate.FirstDeposit}>
                    {t(
                      "content.playerSummary.statisticalDate.type.firstDepositTime"
                    )}
                  </Option>
                  <Option value={StatisticalDate.LastDeposit}>
                    {t(
                      "content.playerSummary.statisticalDate.type.lastDepositTime"
                    )}
                  </Option>
                </Select>
                {/* <Button style={{ marginRight: 6 }}>All</Button> */}
                <CustomDateRangePicker
                  onApply={onClickApplyDate}
                  onCancel={clearDate}
                  containerStyles={{
                    width: "214px",
                    padding: "3px 0px",
                    paddingLeft: "4px",
                    borderRadius: "6px",
                    border: "1px solid #d9d9d9",
                  }}
                  fromDate={filterDate.fromDate}
                  toDate={filterDate.toDate}
                  resultToString
                  opens={"center"}
                  readOnly
                />
              </Col>
              <Col className="date-range-transaction-history">
                <span className="filter__name">
                  {t("content.playerSummary.account.title")}
                  <Tooltip
                    placement="bottom"
                    title={t("content.playerSummary.account.tooltip")}
                    arrow={true}
                  >
                    <QuestionCircleFilled
                      style={{
                        marginLeft: 4,
                        color: "#999",
                        fontSize: 12,
                      }}
                    />
                  </Tooltip>
                </span>
                <Select
                  defaultValue="username"
                  style={{ width: 210 }}
                  onChange={onAccountChange}
                >
                  <Option value="username">
                    {t("content.playerSummary.account.type.username")}
                  </Option>
                  <Option value="accountUnderCompany">
                    {t(
                      "content.playerSummary.account.type.accountUnderCompany"
                    )}
                  </Option>
                  <Option value="agentName">
                    {t("content.playerSummary.account.type.agentName")}
                  </Option>
                  <Option value="registerDomain">
                    {t("content.playerSummary.account.type.registerDomain")}
                  </Option>
                </Select>
              </Col>

              <Col>
                <Input
                  placeholder={t("content.playerSummary.inputSearchCriteria")}
                />
              </Col>

              <Col>
                <div>{t("content.playerSummary.currency")}</div>
                <Select
                  defaultValue={t("content.playerSummary.all")}
                  style={{ width: 120, marginLeft: 10 }}
                  onChange={onCurrencyChange}
                >
                  <Option value="">{t("content.playerSummary.all")}</Option>
                  {currencyList?.map((item, index) => (
                    <Option value={item?.currency} key={index}>
                      {item?.currency}
                    </Option>
                  ))}
                </Select>
              </Col>

              <Col>
                <div style={{ minWidth: 110 }}>
                  {t("content.playerSummary.filterByAmount.title")}
                </div>
                <Select
                  defaultValue=""
                  style={{ width: 160, marginRight: 10 }}
                  onChange={onFilterAmountChange}
                >
                  <Option value={PlayerSummaryAmountFilter.DepositCount}>
                    {t(
                      "content.playerSummary.filterByAmount.type.depositCount"
                    )}
                  </Option>
                  <Option value={PlayerSummaryAmountFilter.DepositAmount}>
                    {t(
                      "content.playerSummary.filterByAmount.type.depositAmount"
                    )}
                  </Option>
                  <Option value={PlayerSummaryAmountFilter.WithdrawCount}>
                    {t(
                      "content.playerSummary.filterByAmount.type.withdrawCount"
                    )}
                  </Option>
                  <Option value={PlayerSummaryAmountFilter.WithdrawAmount}>
                    {t(
                      "content.playerSummary.filterByAmount.type.withdrawAmount"
                    )}
                  </Option>
                  <Option value={PlayerSummaryAmountFilter.CurrentBalance}>
                    {t(
                      "content.playerSummary.filterByAmount.type.currentBalance"
                    )}
                  </Option>
                  <Option value={PlayerSummaryAmountFilter.PromotionBonus}>
                    {t(
                      "content.playerSummary.filterByAmount.type.promotionBonus"
                    )}
                  </Option>
                  <Option value={PlayerSummaryAmountFilter.CompanyWinLost}>
                    {t(
                      "content.playerSummary.filterByAmount.type.companyWinLost"
                    )}
                  </Option>
                </Select>
                <Input
                  type="number"
                  placeholder={t("content.playerSummary.inputMinAmount")}
                  name="minAmount"
                  style={{ width: 140 }}
                  disabled={disableFilterAmount}
                  onChange={onChangeMinAmount}
                />
                <Input
                  type="number"
                  placeholder={t("content.playerSummary.inputMaxAmount")}
                  name="maxAmount"
                  style={{ width: 145, marginLeft: 8 }}
                  disabled={disableFilterAmount}
                  onChange={onChangeMaxAmount}
                />
              </Col>
              <Col>
                <span style={{ paddingRight: 8 }}>
                  {t("content.playerSummary.decimalPoint")}
                </span>
                <Select
                  defaultValue="2"
                  style={{ width: 120 }}
                  onChange={onCurrencyChange}
                >
                  <Option value="2">2</Option>
                  <Option value="3">3</Option>
                  <Option value="4">4</Option>
                  <Option value="5">5</Option>
                  <Option value="6">6</Option>
                </Select>
              </Col>

              <Col>
                <Button
                  className="submit-btn"
                  type="primary"
                  onClick={handleSearch}
                >
                  {loadings && (
                    <tr className="loading" style={{ marginRight: 20 }}>
                      <Loading3QuartersOutlined className="loading-icon-btn" />
                    </tr>
                  )}
                  <span>{t("content.playerSummary.search")}</span>
                </Button>
                <Button style={{ marginLeft: 6 }} onClick={onClickReset}>
                  {t("content.playerSummary.reset")}
                </Button>
              </Col>
            </Row>
          </div>
        </div>

        <div
          className="footer-table"
          style={{ paddingTop: 10, borderTop: "1px solid #dedede" }}
        >
          <Pagination
            defaultCurrent={1}
            total={totalPage * 10}
            showQuickJumper
            onChange={onChangePage}
            style={{ fontSize: 13 }}
          />
          <div>
            <Dropdown
              dropdownRender={() => (
                <div className="footer-table__dropdown">
                  {columnsInDropdown.map((item: any, index: any) => (
                    <div onClick={() => handleCheckColum(item.key)}>
                      <Checkbox
                        style={{ marginRight: 10 }}
                        checked={checkColumn.includes(item.key)}
                      />
                      <div key={index}>{item?.title}</div>
                    </div>
                  ))}
                </div>
              )}
            >
              <a onClick={(e) => e.preventDefault()}>
                <div className="footer-table__bars-icon">
                  <img width={16} src="/bars_icon.svg"></img>
                </div>
              </a>
            </Dropdown>
          </div>
        </div>
        <div id="player-summary-table">
          {loadings && (
            <div className="loading">
              <Loading3QuartersOutlined className="loading-icon" />
            </div>
          )}
          <div id="table-data">
            <Table
              columns={columnsData}
              locale={{ emptyText: t("content.playerSummary.col.noData") }}
              dataSource={playerSummaryData}
              onChange={(pagination, filter, sorter: any) => {
                console.log("===", sorter, orderBy);
                setOrderBy({
                  field: sorter.field,
                  order: sorter.order === "descend" ? "desc" : "asc",
                });
              }}
            />
          </div>
          <div className="footer-table">
            <Pagination
              defaultCurrent={1}
              total={totalPage * 10}
              showQuickJumper
              onChange={onChangePage}
              style={{ fontSize: 13 }}
            />
          </div>
        </div>
      </Col>
    </div>
  );
};

export default PlayerSummary;
