import { Button, Checkbox, Col, Divider, Form, Input, Modal, Radio, Row } from "antd"
import { useLayoutEffect, useState } from "react"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { apis } from "../../../apis"
import { httpService } from "../../../apis/httpService"
import "./ModalEditCurrency.scss"

type FieldType = {
    isModalOpen: boolean
    setIsModalOpen: (value: any) => void
    data: any
}

const ModalEditCurrency = ({ setIsModalOpen, isModalOpen, data }: FieldType) => {
    const [form] = Form.useForm()

    const [checkboxData, setCheckboxData] = useState({
        manualDepositLimitation: false,
        maxManualWithdrawLimit: false,
    })

    const onFinishFailed = (errorInfo: any) => {
        console.log("Failed:", errorInfo)
    }
    const [confirmLoading, setConfirmLoading] = useState(false)

    const handleOk = async () => {
        const updateData = {
            id: data.id,
            ...checkboxData,
            ...form.getFieldsValue(),
        }
        await httpService.patch(apis.company.currency, [updateData])
        setIsModalOpen(false)

        toast.success("Success!")
    }
    const handleCancel = () => {
        setIsModalOpen(false)
    }

    const onChangeCheckBox = (type: string) => {
        if (type === "manualDepositLimitation") {
            setCheckboxData({
                ...checkboxData,
                manualDepositLimitation: !checkboxData.manualDepositLimitation,
            })
        }
        if (type === "maxManualWithdrawLimit") {
            setCheckboxData({
                ...checkboxData,
                maxManualWithdrawLimit: !checkboxData.maxManualWithdrawLimit,
            })
        }
    }

    useLayoutEffect(() => {
        console.log("data", data)
        if (isModalOpen) form.setFieldsValue(data)
    }, [isModalOpen])

    return (
        <div className='modal-update-currency__container'>
            <Modal
                title='Update Currency'
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                confirmLoading={confirmLoading}
                footer={null}
                className='modal-update-currency'
            >
                <div style={{ marginTop: 20 }}>
                    <Form
                        name='basic'
                        labelCol={{ span: 8 }}
                        wrapperCol={{ span: 12 }}
                        style={{ textAlign: "center" }}
                        onFinishFailed={onFinishFailed}
                        form={form}
                    >
                        <Form.Item label='Currency' name='currency'>
                            <Input disabled={true} />
                        </Form.Item>
                        <Form.Item label='Min Deposit' name='minDeposit'>
                            <Input type='number' />
                        </Form.Item>
                        <Form.Item label='Max Deposit' name='maxDeposit'>
                            <Input type='number' />
                        </Form.Item>
                        <Form.Item label='Min Withdraw' name='minWithdraw'>
                            <Input type='number' />
                        </Form.Item>
                        <Form.Item label='Max Withdraw' name='maxWithdraw'>
                            <Input type='number' />
                        </Form.Item>

                        <Form.Item label='Status'>
                            <Radio.Group defaultValue={data?.status ? "Enabled" : "Disabled"}>
                                <Radio value='Enabled'>Enabled</Radio>
                                <Radio value='Disabled'>Disabled</Radio>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item label='Decimal Point for Deposit and Withdraw'>
                            <Radio.Group
                                defaultValue={data?.isDecimalAllow ? "Enabled" : "Disabled"}
                            >
                                <Radio value='Enabled'>Enabled</Radio>
                                <Radio value='Disabled'>Disabled</Radio>
                            </Radio.Group>
                        </Form.Item>
                        <Divider />
                        <Form.Item label='Max Manual Withdrawal' name='maxManualWithdrawLimit'>
                            <Radio.Group
                                style={{ marginRight: 34 }}
                                defaultValue={data?.maxManualWithdrawLimit}
                            >
                                <Row style={{ display: "flex", alignItems: "center" }}>
                                    <Col span={8}>
                                        <Checkbox
                                            onChange={() =>
                                                onChangeCheckBox("maxManualWithdrawLimit")
                                            }
                                            defaultChecked={
                                                !data?.maxManualWithdrawLimit ||
                                                data?.maxManualWithdrawLimit < 0
                                            }
                                        >
                                            No Limit
                                        </Checkbox>
                                    </Col>
                                    <Col span={12}>
                                        <Input
                                            type='number'
                                            name='maxManualWithdrawLimit'
                                            disabled={!checkboxData.maxManualWithdrawLimit}
                                        />
                                    </Col>
                                </Row>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item label='Manual Deposit Limitation' name='manualDepositLimitation'>
                            <Radio.Group
                                style={{ marginRight: 34 }}
                                defaultValue={data?.manualDepositLimitation}
                            >
                                <Row style={{ display: "flex", alignItems: "center" }}>
                                    <Col span={8}>
                                        <Checkbox
                                            onChange={() =>
                                                onChangeCheckBox("manualDepositLimitation")
                                            }
                                            defaultChecked={
                                                !data?.manualDepositLimitation ||
                                                data?.manualDepositLimitation < 0
                                            }
                                        >
                                            No Limit
                                        </Checkbox>
                                    </Col>
                                    <Col span={12}>
                                        <Input
                                            type='number'
                                            name='manualDepositLimitation'
                                            disabled={!checkboxData.manualDepositLimitation}
                                        />
                                    </Col>
                                </Row>
                            </Radio.Group>
                        </Form.Item>

                        <Form.Item wrapperCol={{ offset: 8, span: 8 }} style={{ marginTop: 40 }}>
                            <Button type='primary' htmlType='submit' onClick={handleOk}>
                                Save
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </Modal>
            <ToastContainer autoClose={1500} />
        </div>
    )
}

export default ModalEditCurrency
