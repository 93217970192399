import { useTranslation } from "react-i18next";
import "./GameProvider.scss";

import { Button, Checkbox, CheckboxProps, Col, Row, Select, Switch, Table } from "antd";
import { useEffect, useState } from "react";

import { apis } from "../../../apis";

import { CheckCircleFilled } from "@ant-design/icons";
import { httpService } from "../../../apis/httpService";
import { ModalGameCategoryOrder } from "../../../common/components/modal-game-category-order";
import { ModalSiteDisplayOrder } from "../../../common/components/modal-site-display-order";

const GameProvider = () => {
    const { t } = useTranslation();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isDisplayOrderOpen, setIsDisplayOrderOpen] = useState(false);
    const [dataEdit, setDataEdit] = useState();

    const { Option } = Select;

    const columnInit = [
        {
            title: t("content.gameProvider.col.providerCategory"),
            dataIndex: "gameProviderCategory",
            key: "gameProviderCategory",
        },

        {
            title: t("content.gameProvider.col.gameProvider"),
            dataIndex: "displayName",
            key: "displayName",
            render: (value: any, record: any) => {
                return (
                    <div
                        className="flex"
                        style={{
                            justifyContent: "flex-start",
                            color: "#1f6fde",
                        }}
                    >
                        {/* <Link to={paths.USER_DETAIL.replace(":id", record.userId)}>{value}</Link> */}
                        <p>{value}</p>
                    </div>
                );
            },
        },
        {
            title: t("content.gameProvider.col.status.title"),
            dataIndex: "isEnable",
            key: "isEnable",
            render: (value: any, record: any) => {
                if (value === true) {
                    return <div style={{ color: "#67c23a" }}>{t("content.gameProvider.col.status.type.enabled")}</div>;
                } else {
                    return <div style={{ color: "red" }}>{t("content.gameProvider.col.status.type.disabled")}</div>;
                }
            },
        },
        {
            title: t("content.gameProvider.col.providerStatus"),
            dataIndex: "providerStatus",
            key: "providerStatus",
        },
        {
            title: t("content.gameProvider.col.hot"),
            dataIndex: "isHot",
            key: "isHot",
            render: (value: any, record: any) => {
                if (value === true) {
                    return <CheckCircleFilled style={{ color: "#67c23a", fontSize: 18 }} />;
                }
            },
        },
        {
            title: t("content.gameProvider.col.new"),
            dataIndex: "isNew",
            key: "isNew",
            render: (value: any, record: any) => {
                if (value === true) {
                    return <CheckCircleFilled style={{ color: "#67c23a", fontSize: 18 }} />;
                }
            },
        },
        {
            title: t("content.gameProvider.col.supportCurrency"),
            dataIndex: "currencies",
            key: "currencies",
            render: (value: any, record: any) => {
                return (
                    <div
                        style={{
                            width: 600,
                            whiteSpace: "initial",
                        }}
                    >
                        {value.join(",")}
                    </div>
                );
            },
        },
    ];

    const columnEdit = [
        {
            title: "#",
            dataIndex: "checkbox",
            key: "checkbox",
            render: (value: any, record: any) => {
                return <Checkbox onChange={() => onChangeCheckRow(record)} checked={rowCheck[record.id]}></Checkbox>;
            },
        },
        {
            title: t("content.gameProvider.col.providerCategory"),
            dataIndex: "gameProviderCategory",
            key: "gameProviderCategory",
        },

        {
            title: t("content.gameProvider.col.gameProvider"),
            dataIndex: "displayName",
            key: "displayName",
            render: (value: any, record: any) => {
                return (
                    <div
                        className="flex"
                        style={{
                            justifyContent: "flex-start",
                            color: "#1f6fde",
                        }}
                    >
                        {/* <Link to={paths.USER_DETAIL.replace(":id", record.userId)}>{value}</Link> */}
                        <p>{value}</p>
                    </div>
                );
            },
        },
        {
            title: t("content.gameProvider.col.status.title"),
            dataIndex: "isEnable",
            key: "isEnable",
            render: (value: any, record: any) => {
                if (isEdit) {
                    return (
                        <Switch
                            defaultChecked
                            onChange={(checked: boolean, e) => onChangeSwitch(checked, e, record)}
                            checked={status[record.id]}
                        />
                    );
                } else {
                    if (value) {
                        return (
                            <div style={{ color: "#00c781", textAlign: "center" }}>
                                {t("content.gameProvider.col.status.type.enabled")}
                            </div>
                        );
                    } else {
                        return (
                            <p style={{ textAlign: "center", color: "red" }}>
                                {t("content.gameProvider.col.status.type.disabled")}
                            </p>
                        );
                    }
                }
            },
        },
        {
            title: t("content.gameProvider.col.providerStatus"),
            dataIndex: "providerStatus",
            key: "providerStatus",
        },
        {
            title: t("content.gameProvider.col.hot"),
            dataIndex: "isHot",
            key: "isHot",
            render: (value: any, record: any) => {
                return <Checkbox onChange={() => onCheckHot(record)} checked={hot[record.id]}></Checkbox>;
            },
        },
        {
            title: t("content.gameProvider.col.new"),
            dataIndex: "isNew",
            key: "isNew",
            render: (value: any, record: any) => {
                return <Checkbox onChange={() => onCheckNew(record)} checked={newStatus[record.id]}></Checkbox>;
            },
        },
        {
            title: t("content.gameProvider.col.supportCurrency"),
            dataIndex: "currencies",
            key: "currencies",
            render: (value: any, record: any) => {
                return (
                    <div
                        style={{
                            width: 600,
                            whiteSpace: "initial",
                        }}
                    >
                        {value.join(",")}
                    </div>
                );
            },
        },
    ];

    const [isEdit, setIsEdit] = useState(false);
    const [canSave, setCanSave] = useState(false);
    const [gameProviderList, setGameProviderList] = useState([]);
    const [category, setCategory] = useState([]);
    // const [columns, setColumns] = useState(columnInit)
    const [checkAll, setCheckAll] = useState(false);
    const [rowCheck, setRowCheck] = useState<any>({});
    const [status, setStatus] = useState<any>({});
    const [hot, setHot] = useState<any>({});
    const [newStatus, setNew] = useState<any>({});

    const onChangeCheckRow = (record: any) => {
        setRowCheck((prev: any) => ({ ...prev, [record.id]: !rowCheck[record.id] }));
    };

    const onChangeSwitch = (checked: boolean, e: any, record: any) => {
        setStatus((prev: any) => ({ ...prev, [record.id]: !status[record.id] }));
    };
    const onCheckHot = (record: any) => {
        setHot((prev: any) => ({ ...prev, [record.id]: !hot[record.id] }));
    };
    const onCheckNew = (record: any) => {
        setHot((prev: any) => ({ ...prev, [record.id]: !newStatus[record.id] }));
    };

    const onClickEdit = () => {
        setIsEdit(true);
    };

    const onClickCancel = () => {
        setIsEdit(false);
    };
    const onClickSave = async () => {
        //   if (canSave) {
        //       try {
        //           const res = await httpService.put(apis.webAdmin.adminControlList, {
        //               data: changeData,
        //           })
        //           if (res.data) {
        //               getGameProvider()
        //               setChangeData([])
        //               setIsEdit(false)
        //           }
        //       } catch (error) {
        //           console.log("error", error)
        //       }
        //   }
    };

    const getGameProvider = async () => {
        try {
            const res = await httpService.post(apis.gameAndProvider.list);

            //update res.data later
            const data = res.data || res;

            if (data.length) {
                const cate = data.reduce((acc: any[], cur: any) => {
                    if (!acc.includes(cur.gameProviderCategory)) {
                        acc.push(cur.gameProviderCategory);
                    }
                    return acc;
                }, []);

                setCategory(cate);
            }

            setGameProviderList(data);

            setStatus(
                data?.reduce((acc: any, curr: any) => {
                    if (!acc[curr.id]) {
                        acc[curr.id] = curr.isEnable;
                        return acc;
                    }
                }, {})
            );
            setHot(
                data?.reduce((acc: any, curr: any) => {
                    if (!acc[curr.id]) {
                        acc[curr.id] = curr.isHot;
                        return acc;
                    }
                }, {})
            );
            setNew(
                data?.reduce((acc: any, curr: any) => {
                    if (!acc[curr.id]) {
                        acc[curr.id] = curr.isNew;
                        return acc;
                    }
                }, {})
            );
        } catch (error) {
            console.log("error", error);
        }
    };

    useEffect(() => {
        getGameProvider();
    }, []);

    const onCheckAllChange: CheckboxProps["onChange"] = (e) => {
        const check = e.target.checked;

        setCheckAll(!checkAll);
        setRowCheck(
            gameProviderList?.reduce((acc: any, curr: any) => {
                if (!acc[curr.id]) {
                    acc[curr.id] = check;
                    return acc;
                }
            }, {})
        );
    };

    const onChangeStatus = (type: string) => {
        setStatus(
            gameProviderList?.reduce((acc: any, curr: any) => {
                if (!acc[curr.id]) {
                    acc[curr.id] = type === "enable" ? true : false;
                    return acc;
                }
            }, {})
        );
    };
    const onChangeHot = (type: string) => {
        setHot(
            gameProviderList?.reduce((acc: any, curr: any) => {
                if (!acc[curr.id]) {
                    acc[curr.id] = type === "enable" ? true : false;
                    return acc;
                }
            }, {})
        );
    };
    const onChangeNew = (type: string) => {
        setNew(
            gameProviderList?.reduce((acc: any, curr: any) => {
                if (!acc[curr.id]) {
                    acc[curr.id] = type === "enable" ? true : false;
                    return acc;
                }
            }, {})
        );
    };

    useEffect(() => {
        if (isEdit) {
        }
    }, [isEdit]);

    const onClickGameCategoryOrder = () => {
        setIsModalOpen(true);
    };

    const onClickSiteDisplayOrder = () => {
        setIsDisplayOrderOpen(true);
    };

    return (
        <div>
            <Col id="game-provider-list">
                <div className="filter">
                    <div className="filter-header">
                        <div className="filter-header__name">{t("content.gameProvider.title")}</div>

                        <div className="modify-btn">
                            {!isEdit ? (
                                <div className="modify-btn">
                                    <Button size="small" type="primary" onClick={onClickEdit}>
                                        <span style={{ padding: "0 6px" }}>{t("content.gameProvider.edit")}</span>
                                    </Button>
                                </div>
                            ) : (
                                <div className="modify-btn">
                                    <Button
                                        size="small"
                                        style={{
                                            marginRight: 4,
                                        }}
                                        type="primary"
                                        onClick={onClickCancel}
                                        danger
                                    >
                                        <span style={{ padding: "0 6px" }}>{t("content.gameProvider.cancel")}</span>
                                    </Button>
                                    <Button size="small" type="primary" disabled={!canSave}>
                                        <span style={{ padding: "0 6px" }} onClick={onClickSave}>
                                            {t("content.gameProvider.save")}
                                        </span>
                                    </Button>
                                </div>
                            )}
                        </div>
                    </div>

                    {isEdit && (
                        <div className="filter-bar">
                            <div>
                                <Checkbox onChange={onCheckAllChange} checked={checkAll} style={{ marginRight: 10 }}>
                                    {" "}
                                </Checkbox>

                                <Button
                                    style={{ marginRight: 10 }}
                                    className={checkAll ? "btn" : "disable"}
                                    size="small"
                                    disabled={!checkAll}
                                    onClick={() => onChangeStatus("enable")}
                                >
                                    {t("content.gameProvider.setAllEnabled")}
                                </Button>
                                <Button
                                    style={{ marginRight: 10 }}
                                    className={checkAll ? "btn" : "disable"}
                                    size="small"
                                    disabled={!checkAll}
                                    onClick={() => onChangeHot("enable")}
                                >
                                    {t("content.gameProvider.setAllHot")}
                                </Button>
                                <Button
                                    style={{ marginRight: 10 }}
                                    className={checkAll ? "btn" : "disable"}
                                    size="small"
                                    disabled={!checkAll}
                                    onClick={() => onChangeNew("enable")}
                                >
                                    {t("content.gameProvider.setAllNew")}
                                </Button>
                                <Button
                                    style={{ marginRight: 10 }}
                                    className={checkAll ? "disable-btn" : "disable"}
                                    size="small"
                                    disabled={!checkAll}
                                    onClick={() => onChangeStatus("disable")}
                                >
                                    {t("content.gameProvider.setAllDisabled")}
                                </Button>
                                <Button
                                    style={{ marginRight: 10 }}
                                    className={checkAll ? "disable-btn" : "disable"}
                                    size="small"
                                    disabled={!checkAll}
                                    onClick={() => onChangeHot("disable")}
                                >
                                    {t("content.gameProvider.removeAllHot")}
                                </Button>
                                <Button
                                    style={{ marginRight: 10 }}
                                    className={checkAll ? "disable-btn" : "disable"}
                                    size="small"
                                    disabled={!checkAll}
                                    onClick={() => onChangeNew("disable")}
                                >
                                    {t("content.gameProvider.removeAllNew")}
                                </Button>
                            </div>
                        </div>
                    )}

                    {!isEdit && (
                        <div className="filter-bar">
                            <Row gutter={9}>
                                <Col>
                                    <span className="filter__name">{t("content.gameProvider.providerCategory")}</span>
                                    <Select
                                        defaultValue="all"
                                        style={{ width: 160 }}
                                        // onChange={onTransactionTypeChange}
                                    >
                                        <Option value="all">{t("content.gameProvider.all")}</Option>

                                        {category?.map((cate) => (
                                            <Option value={cate}>{cate}</Option>
                                        ))}
                                    </Select>
                                </Col>
                                <Col>
                                    <span className="filter__name">{t("content.gameProvider.supportCurrency")}</span>
                                    <Select
                                        defaultValue="all"
                                        style={{ width: 120 }}
                                        // onChange={onTransactionTypeChange}
                                    >
                                        <Option value="all">{t("content.gameProvider.all")}</Option>
                                        <Option value="VND">VND</Option>
                                        <Option value="USD">USD</Option>
                                    </Select>
                                </Col>
                                <Col>
                                    <span className="filter__name">{t("content.gameProvider.status.title")}</span>
                                    <Select
                                        defaultValue="all"
                                        style={{ width: 120 }}
                                        // onChange={onCurrencyChange}
                                    >
                                        <Option value="all">{t("content.gameProvider.status.type.all")}</Option>
                                        <Option value="enabled">{t("content.gameProvider.status.type.enabled")}</Option>
                                        <Option value="disabled">
                                            {t("content.gameProvider.status.type.disabled")}
                                        </Option>
                                    </Select>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Button className="btn" size="small" onClick={onClickGameCategoryOrder}>
                                        {t("content.gameProvider.gamesCategoryDisplayOrder")}
                                    </Button>
                                    <Button
                                        className="btn"
                                        size="small"
                                        style={{ marginLeft: 10 }}
                                        onClick={onClickSiteDisplayOrder}
                                    >
                                        {t("content.gameProvider.playerSiteDisplayOrder")}
                                    </Button>
                                </Col>
                            </Row>
                        </div>
                    )}
                </div>

                <div id="betting-record-table">
                    <div id="table-data" style={{ marginTop: 6 }}>
                        <Table
                            columns={isEdit ? columnEdit : columnInit}
                            dataSource={gameProviderList}
                            pagination={false}
                        />
                    </div>
                </div>
            </Col>
            <ModalGameCategoryOrder isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} data={dataEdit} />
            <ModalSiteDisplayOrder
                isModalOpen={isDisplayOrderOpen}
                setIsModalOpen={setIsDisplayOrderOpen}
                data={gameProviderList}
            />
        </div>
    );
};

export default GameProvider;
