import { useTranslation } from "react-i18next";
import "./AdminControls.scss";

import { Button, Checkbox, Col, Input, Switch, Table } from "antd";
import { useEffect, useState } from "react";

import { apis } from "../../../apis";

import { Loading3QuartersOutlined } from "@ant-design/icons";
import { httpService } from "../../../apis/httpService";

const AdminControls = () => {
    const { t } = useTranslation();

    const [isEdit, setIsEdit] = useState(false);
    const [adminControlData, setAdminControlData] = useState([]);
    const [loadings, setLoadings] = useState(false);
    const [stateValue, setStateValue] = useState<any>({});
    const [canSave, setCanSave] = useState(false);

    const [isCheckDailyPlayerWithdrawalCount, setIsCheckDailyPlayerWithdrawalCount] = useState(true);

    const [changeData, setChangeData] = useState<any>([]);

    const onCheckIsCheckDailyPlayerWithdrawalCount = () => {
        setIsCheckDailyPlayerWithdrawalCount(!isCheckDailyPlayerWithdrawalCount);
    };
    const onChangeSwitch = (checked: boolean, e: any, record: any) => {
        record.isEnabled = checked;
        setCanSave(true);
        if (checked) {
            record.value = "Y";
            setStateValue({ ...stateValue, [record.code]: "Enabled" });
        } else {
            record.value = "N";
            setStateValue({ ...stateValue, [record.code]: "Disabled" });
        }

        const changed = changeData?.filter((item: any) => item.id !== record.id);
        if (!changed.length) {
            setChangeData([record]);
        } else {
            setChangeData([...changed, record]);
        }
    };

    const onInputChange = (e: any, record: any) => {
        record.value = e.target.value;
        setCanSave(true);
        setStateValue({ ...stateValue, [record.code]: e.target.value });

        const changed = changeData?.filter((item: any) => item.id !== record.id);
        if (!changed.length) {
            setChangeData([record]);
        } else {
            setChangeData([...changed, record]);
        }
    };

    const columnAdminControls = [
        {
            title: "#",
            dataIndex: "index",
            key: "index",
            render: (text: any, record: any, index: number) => index + 1,
        },
        {
            title: t("content.adminControls.col.setting"),
            dataIndex: "code",
            key: "code",
        },
        {
            title: t("content.adminControls.col.value.title"),
            dataIndex: "value",
            key: "value",
            render: (value: any, record: any) => {
                if (
                    record.code === "ArbitrageCheckStakeRate" ||
                    record.code === "RiskControlCheckingPeriod" ||
                    record.code === "Sv388WhiteListUserName" ||
                    record.code === "WithdrawalLimitRatio"
                ) {
                    if (isEdit) {
                        return (
                            <Input
                                type="number"
                                size="small"
                                style={{ textAlign: "center" }}
                                defaultValue={stateValue?.[record.code]}
                                onChange={(e) => onInputChange(e, record)}
                            />
                        );
                    } else {
                        return <p style={{ textAlign: "center" }}>{value}</p>;
                    }
                }

                if (record.code === "DailyPlayerWithdrawalCount" || record.code === "WlAppDefaultLanguage") {
                    if (isEdit) {
                        return (
                            <div>
                                <div>
                                    <Checkbox
                                        checked={isCheckDailyPlayerWithdrawalCount}
                                        onClick={onCheckIsCheckDailyPlayerWithdrawalCount}
                                    />{" "}
                                    {t("content.adminControls.col.value.type.noLimit")}
                                </div>
                                <Input
                                    type="number"
                                    size="small"
                                    style={{ textAlign: "center", marginTop: 2 }}
                                    disabled={isCheckDailyPlayerWithdrawalCount}
                                    defaultValue={stateValue?.[record.code]}
                                    onChange={(e) => onInputChange(e, record)}
                                ></Input>
                            </div>
                        );
                    } else {
                        return (
                            <p style={{ textAlign: "center" }}>
                                {isCheckDailyPlayerWithdrawalCount
                                    ? t("content.adminControls.col.value.type.noLimit")
                                    : stateValue?.[record.code]}
                            </p>
                        );
                    }
                }

                if (record.code === "GoogleAnalysticKey") {
                    if (isEdit) {
                        return (
                            <Input
                                type="number"
                                size="small"
                                style={{ textAlign: "center" }}
                                placeholder="Ratio"
                                defaultValue={stateValue?.[record.code]}
                                onChange={(e) => onInputChange(e, record)}
                            ></Input>
                        );
                    } else {
                        return <p style={{ textAlign: "center" }}>{stateValue?.[record.code]}</p>;
                    }
                }

                if (
                    record.code === "IsAIChatBotEnabled" ||
                    record.code === "IsAffiliateRequestButtonEnabled" ||
                    record.code === "IsAutoDepositEnable" ||
                    record.code === "IsBankTransferDepositEnabled" ||
                    record.code === "IsBankTransferWithdrawalEnabled" ||
                    record.code === "IsForgetPasswordEnabled" ||
                    record.code === "IsManualCryptocurrencyDepositEnable" ||
                    record.code === "IsNotificationSoundAlertEnable" ||
                    record.code === "IsPaymentDepositApplyWithdrawalLimit" ||
                    record.code === "IsPaymentGatewayDepositEnabled" ||
                    record.code === "IsPaymentGatewayWithdrawalEnabled" ||
                    record.code === "IsPaymentPasswordEnable" ||
                    record.code === "IsRedirectToSportsPage-LightBlueSky" ||
                    record.code === "IsWithdrawalLimitEnabled"
                ) {
                    if (isEdit) {
                        return (
                            <Switch
                                defaultChecked
                                onChange={(checked: boolean, e) => onChangeSwitch(checked, e, record)}
                                checked={stateValue?.[record.code] === "Enabled"}
                            />
                        );
                    } else {
                        if (stateValue?.[record.code] === "Enabled") {
                            return (
                                <div style={{ color: "#00c781", textAlign: "center" }}>{stateValue?.[record.code]}</div>
                            );
                        } else {
                            return <p style={{ textAlign: "center", color: "red" }}>{stateValue?.[record.code]}</p>;
                        }
                    }
                }
            },
        },
        {
            title: t("content.adminControls.col.description"),
            dataIndex: "description",
            key: "description",
            render: (value: any, record: any) => {
                return (
                    <div
                        style={{
                            width: 600,
                            whiteSpace: "initial",
                        }}
                    >
                        {value}
                    </div>
                );
            },
        },
        {
            title: t("content.adminControls.col.modifiedBy"),
            dataIndex: "updatedBy",
            key: "updatedBy",
        },
        {
            title: t("content.adminControls.col.modifiedTime"),
            dataIndex: "updatedAt",
            key: "updatedAt",
        },
    ];

    const getAdminControlsList = async () => {
        try {
            const res = await httpService.get(apis.webAdmin.adminControlList);
            console.log(res);
            if (res?.data) {
                let obj: any = {};
                for (const item of res.data) {
                    if (!obj?.[item.code]) {
                        if (item.possibleValues === "Y/N") {
                            if (item.value === "Y") {
                                obj[item.code] = "Enabled";
                            } else {
                                obj[item.code] = "Disabled";
                            }
                        } else {
                            obj[item.code] = item.value;
                        }
                    }
                }

                setStateValue(obj);
            }

            setAdminControlData(res?.data);
        } catch (error) {
            console.log("error", error);
        }
    };

    useEffect(() => {
        getAdminControlsList();
    }, []);

    const onClickEdit = () => {
        setIsEdit(true);
    };
    const onClickCancel = () => {
        setChangeData([]);
        setIsEdit(false);
    };
    const onClickSave = async () => {
        if (canSave) {
            try {
                const res = await httpService.put(apis.webAdmin.adminControlList, {
                    data: changeData,
                });

                if (res.data) {
                    getAdminControlsList();
                    setChangeData([]);
                    setIsEdit(false);
                }
            } catch (error) {
                console.log("error", error);
            }
        }
    };

    return (
        <div style={{ paddingBottom: 16 }}>
            <Col id="admin-control">
                <div className="filter">
                    <div className="filter-header">
                        <div className="filter-header__name">{t("content.adminControls.title")}</div>

                        <div>
                            {!isEdit ? (
                                <div className="modify-btn">
                                    <Button size="small" type="primary" onClick={onClickEdit}>
                                        <span style={{ padding: "0 6px" }}>{t("content.adminControls.edit")}</span>
                                    </Button>
                                </div>
                            ) : (
                                <div className="modify-btn">
                                    <Button
                                        size="small"
                                        style={{
                                            marginRight: 4,
                                        }}
                                        type="primary"
                                        onClick={onClickCancel}
                                        danger
                                    >
                                        <span style={{ padding: "0 6px" }}>{t("content.adminControls.cancel")}</span>
                                    </Button>
                                    <Button size="small" type="primary" disabled={!canSave}>
                                        <span style={{ padding: "0 6px" }} onClick={onClickSave}>
                                            {t("content.adminControls.save")}
                                        </span>
                                    </Button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div id="admin-controls-table">
                    {loadings && (
                        <div className="loading">
                            <Loading3QuartersOutlined className="loading-icon" />
                        </div>
                    )}
                    <div id="table-data" style={{ marginTop: 1 }}>
                        <Table columns={columnAdminControls} dataSource={adminControlData} pagination={false} />
                    </div>
                </div>
            </Col>
        </div>
    );
};

export default AdminControls;
