import { useTranslation } from "react-i18next";
import "./Transactions.scss";

import { Button, Col, Image, Input, Pagination, PaginationProps, Radio, Row, Select, Space, Table } from "antd";
import { AlignType } from "rc-table/lib/interface";
import { useEffect, useMemo, useState } from "react";

import { CSVLink } from "react-csv";

import moment from "moment";
import { Link } from "react-router-dom";
import { apis } from "../../apis";
import { httpService } from "../../apis/httpService";
import { CustomDateRangePicker } from "../../common/components/date-range-picker";

import { TransactionType } from "../../common/constants/transaction.enum";
import { ISort } from "../../interfaces/Global";
import { paths } from "../../routes/path";

const Transactions = () => {
    const { t } = useTranslation();

    const { Option } = Select;
    const columnsTotal = [
        {
            title: t("content.transactions.transactionList.col.deposit"),
            dataIndex: "deposit",
            key: "deposit",
            sorter: (a: any, b: any) => a.deposit - b.deposit,
        },
        {
            title: t("content.transactions.transactionList.col.withdrawal"),
            dataIndex: "withdrawal",
            key: "withdrawal",
            sorter: (a: any, b: any) => a.withdrawal - b.withdrawal,
        },
    ];
    const dataTotal = [
        {
            total: "VND",
            deposit: 1561188.0,
            withdrawal: 9142118.32,
        },
    ];

    const columnsSource = [
        {
            title: t("content.transactions.transactionList.col.createBy"),
            dataIndex: "createBy",
            key: "createBy",
        },
        {
            title: t("content.transactions.transactionList.col.remark"),
            dataIndex: "remark",
            key: "remark",
        },
    ];
    const dataSource = [
        {
            username: "testkm1",
            date: "2024-01-22 (-4)",
            winLostDate: "2024-02-22 (-4)",
            txn: "11312",
            transactionType: "PaymentGatewayDeposit",
            currency: "VND",
            deposit: 1561188.0,
            withdrawal: 0,
            createBy: "tranquocloi",
            Remark: "",
        },
        {
            username: "testkm1",
            date: "2024-01-22 (-4)",
            winLostDate: "2024-3-22 (-4)",
            txn: "11312",
            transactionType: "PaymentGatewayDeposit",
            currency: "VND",
            deposit: "1,561,188.00",
            withdrawal: "4,500.00",
            createBy: "tranquocloi",
            Remark: "",
        },
        {
            username: "testkm1",
            date: "2024-01-22 (-4)",
            winLostDate: "2024-01-22 (-4)",
            txn: "11312",
            transactionType: "PaymentGatewayDeposit",
            currency: "VND",
            deposit: 0,
            withdrawal: "4,500.00",
            createBy: "tranquocloi",
            Remark: "",
        },
        {
            username: "testkm1",
            date: "2024-01-22 (-4)",
            winLostDate: "2024-01-22 (-4)",
            txn: "11312",
            transactionType: "PaymentGatewayDeposit",
            currency: "VND",
            deposit: "1,561,188.00",
            withdrawal: "4,500.00",
            createBy: "tranquocloi",
            Remark: "",
        },
        {
            username: "testkm1",
            date: "2024-01-22 (-4)",
            winLostDate: "2024-01-22 (-4)",
            txn: "11312",
            transactionType: "PaymentGatewayDeposit",
            currency: "VND",
            deposit: "1,561,188.00",
            withdrawal: 0,
            createBy: "tranquocloi",
            Remark: "",
        },
        {
            username: "testkm1",
            date: "2024-01-22 (-4)",
            winLostDate: "2024-01-22 (-4)",
            txn: "11312",
            transactionType: "PaymentGatewayDeposit",
            currency: "VND",
            deposit: "1,561,188.00",
            withdrawal: 0,
            createBy: "tranquocloi",
            Remark: "",
        },
        {
            username: "testkm1",
            date: "2024-01-22 (-4)",
            winLostDate: "2024-01-22 (-4)",
            txn: "11312",
            transactionType: "PaymentGatewayDeposit",
            currency: "VND",
            deposit: "1,561,188.00",
            withdrawal: "4,500.00",
            createBy: "tranquocloi",
            Remark: "",
        },
        {
            username: "testkm1",
            date: "2024-01-22 (-4)",
            winLostDate: "2024-01-22 (-4)",
            txn: "11312",
            transactionType: "PaymentGatewayDeposit",
            currency: "VND",
            deposit: "1,561,188.00",
            withdrawal: "4,500.00",
            createBy: "tranquocloi",
            Remark: "",
        },
    ];

    function makeColumnsTotal() {
        const columns = [
            {
                title: t("content.transactions.transactionList.col.total"),
                dataIndex: "total",
                key: "total",
                width: "33%",
                render: (value: any, record: any) => {
                    return <div style={{ textAlign: "center", color: "rgb(127, 127, 127)" }}>{value}</div>;
                },
            },
        ];

        for (const col of columnsTotal) {
            if (col.key !== "total") {
                columns.push({
                    title: col.title,
                    key: col.key,
                    dataIndex: col.dataIndex,
                    width: "33%",
                    render: (value: any, record: any) => {
                        return <div className="value-total">{value}</div>;
                    },
                });
            }
        }

        return columns;
    }

    function makeColumnsDataSource() {
        const columns = [
            {
                title: "#",
                dataIndex: "index",
                key: "index",
                render: (text: any, record: any, index: number) => {
                    return <div style={{ textAlign: "center" }}>{index + 1}</div>;
                },
            },
            {
                title: t("content.transactions.transactionList.col.date"),
                dataIndex: "date",
                key: "date",
                sorter: {
                    compare: (a: any, b: any) => a.currency - b.currency,
                },
            },
            {
                title: t("content.transactions.transactionList.col.txnNo"),
                dataIndex: "txn",
                key: "txn",
                align: "center" as AlignType,
            },
            {
                title: t("content.transactions.transactionList.col.transactionType"),
                dataIndex: "transactionType",
                key: "transactionType",
                align: "center" as AlignType,
            },

            {
                title: t("content.transactions.transactionList.col.username"),
                dataIndex: "username",
                key: "username",
                render: (value: any, record: any) => {
                    return (
                        <Link to={paths.USER_DETAIL.replace(":id", record.userId)} style={{ color: "#7f7f7f" }}>
                            {value}
                        </Link>
                    );
                },
            },
            {
                title: t("content.transactions.transactionList.col.currency"),
                dataIndex: "currency",
                key: "currency",
                align: "center" as AlignType,
                sorter: {
                    compare: (a: any, b: any) => a.currency - b.currency,
                    // multiple: 4,
                },
            },
            {
                title: t("content.transactions.transactionList.col.deposit"),
                dataIndex: "deposit",
                key: "deposit",
                sorter: {
                    compare: (a: any, b: any) => a.currency - b.currency,
                    multiple: 3,
                },

                render: (value: any, record: any) => {
                    if (parseInt(value) > 0) {
                        return <div className="col-deposit">{value}</div>;
                    } else {
                        return <div className="col-zero">{value}</div>;
                    }
                },
            },
            {
                title: t("content.transactions.transactionList.col.withdrawal"),
                dataIndex: "withdrawal",
                key: "withdrawal",
                sorter: {
                    compare: (a: any, b: any) => a.currency - b.currency,
                },

                render: (value: any, record: any) => {
                    if (parseInt(value) > 0) {
                        return <div className="col-withdrawal">{value}</div>;
                    } else {
                        return <div className="col-zero">{value}</div>;
                    }
                },
            },
        ];

        for (const col of columnsSource) {
            if (col.key === "createBy") {
                columns.push({
                    title: col.title,
                    key: col.key,
                    dataIndex: col.dataIndex,
                    render: (value: any, record: any) => {
                        return <div style={{ textAlign: "left" }}>{value}</div>;
                    },
                });
            }
            if (col.key === "remark") {
                columns.push({
                    title: col.title,
                    key: col.key,
                    dataIndex: col.dataIndex,
                    render: (value: any, record: any) => {
                        return <div style={{ textAlign: "right" }}>{value}</div>;
                    },
                });
            }
        }

        return columns;
    }

    const columnsTotalMade = useMemo(() => makeColumnsTotal(), [dataTotal]);
    const columnsData = useMemo(() => makeColumnsDataSource(), [dataSource]);

    const [username, setUsername] = useState("");
    const [currency, setCurrency] = useState("");
    const [transactionType, setTransactionType] = useState("");

    //filter columns table data

    //emtydata
    const [emptyText, setEmptyText] = useState(t("content.transactions.transactionList.clickSearchtoSeeInformation"));
    let locale = {
        emptyText,
    };

    const currentDate = new Date();
    const fromDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - 30);

    const [filterDate, setFilterDate] = useState({
        fromDate: fromDate,
        toDate: currentDate,
    });

    const [txnList, setTxnList] = useState([]);
    const [totalPage, setTotalPage] = useState(10);
    const [textSearch, setTextSearch] = useState("");
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [orderBy, setOrderBy] = useState<ISort | null>(null);
    const [colorStatus, setColorStatus] = useState("");
    const [loadings, setLoadings] = useState(false);

    const getTxnList = async () => {
        try {
            const orderStr = orderBy === null ? "" : `&sortKey=${orderBy.field}&sortOrder=${orderBy.order}`;
            const res = await httpService.get(
                `${
                    apis.txn.list
                }/?page=${page}&query=${textSearch}&take=${pageSize}${orderStr}&fromDate=${filterDate.fromDate.toISOString()}&toDate=${filterDate.toDate.toISOString()}&username=${username}&type=${transactionType}&currency=${currency}`
            );

            //apply when have real data
            if (res.data?.result?.length) {
                const result = res.data.result.map((item: any) => {
                    return {
                        date: moment.utc(item.createdAt).add(7, "hours"),
                        txnNo: item.code,
                        transactionType: item.type,
                        username: item.owner.username,
                        currency: item.currency,
                        deposit: item.type === TransactionType.Deposit ? item.amount : 0,
                        withdrawal: item.type === TransactionType.Withdraw ? item.amount : 0,
                        createdBy: item.createdBy,
                        note: item.note,
                        userId: item.owner.id,
                    };
                });

                setTxnList(result);
                setTotalPage(res?.data?.meta.itemCount);
            }
        } catch (error) {
            console.log("error", error);
        }
    };

    useEffect(() => {
        getTxnList();
    }, [
        page,
        textSearch,
        pageSize,
        orderBy,
        username,
        currency,
        transactionType,
        filterDate.fromDate,
        filterDate.toDate,
    ]);

    const onUsernameChange = (e: any) => {
        setUsername(e.target.value);
    };

    const onCurrencyChange = (value: any) => {
        setCurrency(value);
    };

    const onTransactionTypeChange = (value: any) => {
        setTransactionType(value);
    };

    const onChangePage: PaginationProps["onChange"] = (pageNumber: any) => {
        console.log("Page: ", pageNumber);
    };

    const onClickApplyDate = (event: any, picker: any) => {
        const fromDate = moment(picker.startDate).toDate();
        const toDate = moment(picker.endDate).toDate();
        setFilterDate({
            ...filterDate,
            fromDate,
            toDate,
        });
    };

    const clearDate = () => {
        setFilterDate({
            ...filterDate,
            fromDate: fromDate,
            toDate: currentDate,
        });
    };

    const handleSearch = async () => {
        await getTxnList();
    };
    const enterLoading = () => {
        setLoadings(true);

        setTimeout(() => {
            if (dataSource.length === 0) {
                setEmptyText(t("content.transactions.transactionList.col.noData"));
            }
            setLoadings(false);
        }, 3000);
    };

    return (
        <div id="transactions-main">
            <Col id="date-setting">
                <div className="filter-header" style={{ height: "32px" }}>
                    <div className="filter-header__name">{t("content.transactions.dateSetting.title")}</div>

                    <div></div>
                </div>
                <div className="filter-bar">
                    <Row gutter={9} className="filter">
                        <Col className="date-range-transaction-history">
                            <div className="filter__name">{t("content.transactions.dateSetting.statisticalDate")}</div>

                            <CustomDateRangePicker
                                onApply={onClickApplyDate}
                                onCancel={clearDate}
                                containerStyles={{
                                    width: "212px",
                                    padding: "3px 0px",
                                    paddingLeft: "4px",
                                    borderRadius: "6px",
                                    border: "1px solid #d9d9d9",
                                }}
                                fromDate={filterDate.fromDate}
                                toDate={filterDate.toDate}
                                resultToString
                                opens={"center"}
                                readOnly
                            />
                        </Col>

                        <Col>
                            <div className="filter__name">{t("content.transactions.dateSetting.username")}</div>
                            <Input
                                placeholder={t("content.transactions.dateSetting.username")}
                                onChange={onUsernameChange}
                            />
                        </Col>

                        <Col>
                            <div className="filter__name">{t("content.transactions.dateSetting.currency")}</div>
                            <Select
                                defaultValue={t("content.transactions.dateSetting.all")}
                                style={{ width: 120 }}
                                onChange={onCurrencyChange}
                            >
                                <Option value="">{t("content.transactions.dateSetting.all")}</Option>
                                <Option value="tmp">TMP</Option>
                            </Select>
                        </Col>

                        <Col>
                            <div className="filter__name">
                                {t("content.transactions.dateSetting.transactionType.title")}
                            </div>
                            <Select
                                defaultValue={t("content.transactions.dateSetting.transactionType.type.all")}
                                style={{ width: 120 }}
                                onChange={onCurrencyChange}
                            >
                                <Option value="">
                                    {t("content.transactions.dateSetting.transactionType.type.all")}
                                </Option>
                                <Option value="Won">
                                    {t("content.transactions.dateSetting.transactionType.type.won")}
                                </Option>
                                <Option value="Lost">
                                    {t("content.transactions.dateSetting.transactionType.type.lost")}
                                </Option>
                                <Option value="Draw">
                                    {t("content.transactions.dateSetting.transactionType.type.draw")}
                                </Option>
                                <Option value="Running">
                                    {t("content.transactions.dateSetting.transactionType.type.running")}
                                </Option>
                                <Option value="void">
                                    {t("content.transactions.dateSetting.transactionType.type.void")}
                                </Option>
                                <Option value="Bonus">
                                    {t("content.transactions.dateSetting.transactionType.type.bonus")}
                                </Option>
                                {/* <Option value='providerPromotion'> */}
                                <Option value="">
                                    {t("content.transactions.dateSetting.transactionType.type.providerPromotion")}
                                </Option>
                            </Select>
                        </Col>
                        <Col>
                            <div className="filter__name">
                                {t("content.transactions.dateSetting.decimalPointNumbers")}
                            </div>
                            <Radio.Group
                                className="flex align-center"
                                defaultValue={2}
                                // onChange={onChangeDecimalPointNumbers}
                                // value={decimalPointNumbers}
                            >
                                <Radio value={2}>2</Radio>
                                <Radio value={3}>3</Radio>
                                <Radio value={4}>4</Radio>
                                <Radio value={5}>5</Radio>
                                <Radio value={6}>6</Radio>
                            </Radio.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Button className="submit-btn" type="primary" onClick={() => enterLoading()}>
                                {loadings && (
                                    <div className="loading-btn">
                                        <Image
                                            width={16}
                                            src="/loading_icon.svg"
                                            className="loading-icon-btn"
                                            preview={false}
                                        />
                                    </div>
                                )}
                                <div>{t("content.transactions.dateSetting.search")}</div>
                            </Button>
                        </Col>
                    </Row>
                </div>
            </Col>
            <Col id="transactions">
                <Space direction="vertical" size="middle" style={{ width: "100%" }}>
                    <div className="filter-header">
                        <div className="filter-header__name">{t("content.transactions.transactionList.title")}</div>
                        <div className="filter-header__export">
                            <Button>
                                <Image width={20} src="/excel_icon.png" style={{ marginBottom: 4, paddingRight: 2 }} />
                                <CSVLink filename={"Expense_Table.csv"} data={dataSource} className="btn btn-primary">
                                    <span> {t("content.transactions.transactionList.export")}</span>
                                </CSVLink>
                            </Button>
                        </div>
                    </div>

                    <div id="table">
                        <div id="table-total">
                            <Table
                                columns={columnsTotalMade}
                                locale={locale}
                                dataSource={dataTotal}
                                onChange={(pagination, filter, sorter: any) => {
                                    console.log("===", sorter, orderBy);
                                    setOrderBy({
                                        field: sorter.field,
                                        order: sorter.order === "descend" ? "desc" : "asc",
                                    });
                                }}
                            />
                            <div className="footer-table">
                                <Pagination
                                    defaultCurrent={1}
                                    total={totalPage}
                                    showQuickJumper
                                    onChange={onChangePage}
                                    style={{ fontSize: 13 }}
                                />
                            </div>
                        </div>

                        <div id="table-data" style={{ marginTop: 6 }}>
                            <Table
                                columns={columnsData}
                                locale={locale}
                                dataSource={dataSource}
                                onChange={(pagination, filter, sorter: any) => {
                                    console.log("===", sorter, orderBy);
                                    setOrderBy({
                                        field: sorter.field,
                                        order: sorter.order === "descend" ? "desc" : "asc",
                                    });
                                }}
                                // pagination={{
                                //     pageSize,
                                //     total: totalPage,
                                //     onChange: (page, pageSize) => {
                                //         setPage(page);
                                //         setPageSize(pageSize);
                                //     },
                                // }}
                            />
                        </div>
                        <div className="footer-table">
                            <Pagination
                                defaultCurrent={1}
                                total={totalPage}
                                showQuickJumper
                                onChange={onChangePage}
                                style={{ fontSize: 13 }}
                            />
                        </div>
                    </div>
                </Space>
            </Col>
        </div>
    );
};

export default Transactions;
