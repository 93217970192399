import { EditOutlined } from "@ant-design/icons"
import { Button, Switch, Table } from "antd"
import { useEffect, useState } from "react"
import { toast } from "react-toastify"
import { apis } from "../../../apis"
import { httpService } from "../../../apis/httpService"
import { ModalEditCurrency } from "../../../common/components/modal-edit-currency"
import "./Currency.scss"

const Currency = () => {
    const columnLanguage = [
        {
            title: "#",
            dataIndex: "index",
            key: "index",
            render: (text: any, record: any, index: number) => {
                return <div style={{ textAlign: "center" }}>{index + 1}</div>
            },
        },
        {
            title: "Currency",
            dataIndex: "currency",
            key: "currency",
            render: (value: any, record: any) => {
                return <div style={{ textAlign: "center" }}>{value}</div>
            },
        },
        {
            title: "Max Manual Withdraw Limit",
            dataIndex: "maxManualWithdrawLimit",
            key: "maxManualWithdrawLimit",
            render: (value: any, record: any) => {
                return <div style={{ textAlign: "center" }}>{value}</div>
            },
        },

        {
            title: "Manual Deposit Limitation",
            dataIndex: "manualDepositLimitation",
            key: "manualDepositLimitation",
            render: (value: any, record: any) => {
                return <div style={{ textAlign: "center" }}>{value}</div>
            },
        },

        {
            title: "Is Decimal Allow",
            dataIndex: "isDecimalAllow",
            key: "isDecimalAllow",
            render: (isEnable: any, record: any) => {
                if (isEdit) {
                    return (
                        <div style={{ textAlign: "center" }}>
                            <Switch
                                defaultChecked={record.isDecimalAllow}
                                onChange={(checked: boolean, e) =>
                                    onChangeSwitch(checked, e, record, "isDecimalAllow")
                                }
                            />
                        </div>
                    )
                } else {
                    if (isEnable) {
                        return <div style={{ color: "#00c781", textAlign: "center" }}>Enable</div>
                    } else {
                        return <p style={{ textAlign: "center", color: "red" }}>Disable</p>
                    }
                }
            },
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            render: (isEnable: any, record: any) => {
                if (isEdit) {
                    return (
                        <div style={{ textAlign: "center" }}>
                            <Switch
                                defaultChecked={record.status}
                                onChange={(checked: boolean, e) =>
                                    onChangeSwitch(checked, e, record, "status")
                                }
                            />
                        </div>
                    )
                } else {
                    if (isEnable) {
                        return <div style={{ color: "#00c781", textAlign: "center" }}>Enable</div>
                    } else {
                        return <p style={{ textAlign: "center", color: "red" }}>Disable</p>
                    }
                }
            },
        },
        {
            title: "Action",
            dataIndex: "action",
            key: "action",
            render: (value: any, record: any) => {
                return (
                    <div
                        style={{
                            padding: "4px 0px",
                            border: "1px solid #d9d9d9",
                            textAlign: "center",
                            borderRadius: 4,
                            cursor: "pointer",
                        }}
                        onClick={() => onClickEditRecord(record)}
                    >
                        <EditOutlined />
                    </div>
                )
            },
        },
    ]

    const [currencies, setCurrency] = useState([])
    const [isEdit, setIsEdit] = useState(false)
    const [canSave, setCanSave] = useState(false)
    const [dataSave, setDataSave] = useState<any>([])

    const [isModalOpen, setIsModalOpen] = useState(false)
    const [dataEdit, setDataEdit] = useState()
    const showModal = (record?: any) => {
        setDataEdit(record)
        setIsModalOpen(true)
    }

    const getCurrency = async () => {
        try {
            const res = await httpService.get(apis.company.currency)

            if (res?.data) {
                setCurrency(res.data)
            }
        } catch (error) {
            console.log("error", error)
        }
    }

    const onChangeSwitch = (checked: boolean, e: any, record: any, key: string) => {
        setCanSave(true)
        record[key] = checked

        if (dataSave.length) {
            const currentChange = dataSave.find((item: any) => item.currency === record.currency)

            if (currentChange) {
                currentChange[key] = checked
                setDataSave([...dataSave])
            } else {
                setDataSave([...dataSave, record])
            }
        } else {
            setDataSave([record])
        }
    }

    useEffect(() => {
        getCurrency()
    }, [])

    const onClickEdit = () => {
        setIsEdit(true)
    }
    const onClickCancel = () => {
        setIsEdit(false)
    }

    const onClickEditRecord = (record: any) => {
        setDataEdit(record)
        setIsModalOpen(true)
    }

    const onClickSave = async () => {
        console.log("dataSave", dataSave)
        try {
            const res = await httpService.patch(apis.company.currency, dataSave)

            if (res?.data) {
                setIsEdit(false)
                getCurrency()
                toast.success("Success")
            }
        } catch (error) {
            toast.error("Update error")

            console.log("error", error)
        }
    }

    return (
        <div className='currency'>
            <div className='win-lost'>
                <div>
                    <div className='win-lost__header'>
                        <div className='header-name'>Currency</div>

                        <div className='modify-btn'>
                            {!isEdit ? (
                                <div className='modify-btn'>
                                    <Button size='small' type='primary' onClick={onClickEdit}>
                                        <span style={{ padding: "0 6px" }}>Edit</span>
                                    </Button>
                                </div>
                            ) : (
                                <div className='modify-btn'>
                                    <Button
                                        size='small'
                                        style={{
                                            marginRight: 4,
                                        }}
                                        type='primary'
                                        onClick={onClickCancel}
                                        danger
                                    >
                                        <span style={{ padding: "0 6px" }}>Cancel</span>
                                    </Button>
                                    <Button size='small' type='primary' disabled={!canSave}>
                                        <span style={{ padding: "0 6px" }} onClick={onClickSave}>
                                            Save
                                        </span>
                                    </Button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div id='admin-controls-table'>
                    <div id='table-data'>
                        <Table
                            columns={columnLanguage}
                            dataSource={currencies}
                            pagination={false}
                        />
                    </div>
                </div>
            </div>

            <ModalEditCurrency
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                data={dataEdit}
            />
        </div>
    )
}

export default Currency
