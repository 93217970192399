import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { PromotionStatus } from "../constants"
import CustomSelect from "./custom-select"

const PromotionStatusSelect = ({
    value,
    onChange,
}: {
    value: string[]
    onChange: (value: string[]) => void
}) => {
    const { t } = useTranslation(``)
    // Options for Status
    const StatusOptions = useMemo(
        () => [
            {
                label: t("common.state.active"),
                value: PromotionStatus.ACTIVE,
            },
            {
                label: t("common.state.disabled"),
                value: PromotionStatus.DISABLED,
            },
            {
                label: t("common.state.deleted"),
                value: PromotionStatus.DELETED,
            },
            {
                label: t("common.state.activeAndOpen"),
                value: PromotionStatus.ACTIVE,
            },
        ],
        [t]
    )
    return (
        <div className='flex gap-2 items-center '>
            <span>{t("content.promotions.create.form.field.status")}</span>
            <CustomSelect
                className='flex-1'
                withAllOption
                mode='multiple'
                allowClear
                style={{
                    width: 160,
                }}
                options={StatusOptions}
                value={value}
                onChange={(value) => {
                    if (!value.length) {
                        onChange(["*"])
                    } else {
                        onChange(value)
                    }
                }}
                maxTagCount='responsive'
            />
        </div>
    )
}

export default PromotionStatusSelect
