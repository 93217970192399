import { Form } from "antd";
import { Controller, Control } from "react-hook-form";
import { FormItemProps } from "antd";
import ReactQuill from "react-quill";
import { getValidateStatus } from "../../helpers";
import "react-quill/dist/quill.snow.css";

const RichtextEditorField = ({
    name,
    control,
    formItemProps,
}: {
    name: string;
    control: Control<any>;
    formItemProps?: FormItemProps;
}) => {
    const { labelAlign = "left" } = formItemProps || {};
    return (
        <Controller
            name={name}
            control={control}
            render={({ field, fieldState }) => {
                const errorMessage = fieldState.error?.message;
                return (
                    <Form.Item
                        labelAlign={labelAlign}
                        validateStatus={getValidateStatus(fieldState, name)}
                        help={errorMessage}
                        className="wrap-label"
                        {...formItemProps}
                    >
                        {/* <Editor
                            apiKey="zljjpz1hubcrfix46zb6dp5g47a684r9r92crdk4f7cn941v"
                            init={{
                                menubar: "",
                                toolbar1:
                                    "undo redo | bold italic  underline strikethrough | fullscreen preview",
                                toolbar2:
                                    "table charmap emoticons removeformat",
                                toolbar3: "fontfamily fontsize blocks",
                                toolbar4: "outdent indent",
                                toolbar5:
                                    "bullist numlist alignleft aligncenter alignright alignjustify forecolor backcolor code",
                                plugins:
                                    "fullscreen preview emoticons  charmap lists advcode table advtable",
                                ...editorProps.init,
                            }}
                            {...editorProps}
                            onEditorChange={(content) => {
                                field.onChange(content);
                            }}
                            value={field.value}
                            onBlur={field.onBlur}
                        />*/}
                        <ReactQuill
                            theme="snow"
                            value={field.value}
                            onChange={field.onChange}
                            formats={[
                                "header",
                                "bold",
                                "italic",
                                "underline",
                                "strike",
                                "blockquote",
                                "list",
                                "bullet",
                                "indent",
                                "link",
                                "image",
                                "script",
                                "font",
                                "align",
                                "direction",
                                "color",
                                "background",
                                "size",
                            ]}
                            modules={{
                                toolbar: [
                                    ["bold", "italic", "underline", "strike"], // toggled buttons
                                    ["blockquote", "code-block"],

                                    [{ header: 1 }, { header: 2 }], // custom button values
                                    [{ list: "ordered" }, { list: "bullet" }],
                                    [{ script: "sub" }, { script: "super" }], // superscript/subscript
                                    [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
                                    [{ direction: "rtl" }], // text direction

                                    [
                                        {
                                            size: [
                                                "small",
                                                false,
                                                "large",
                                                "huge",
                                            ],
                                        },
                                    ], // custom dropdown
                                    [{ header: [1, 2, 3, 4, 5, 6, false] }],

                                    [{ color: [] }, { background: [] }], // dropdown with defaults from theme
                                    [{ font: [] }],
                                    [{ align: [] }],

                                    ["clean"], // remove formatting button
                                ],
                            }}
                            style={{ height: "200px" }}
                        />
                    </Form.Item>
                );
            }}
        />
    );
};

export default RichtextEditorField;
