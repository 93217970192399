const NotFound: React.FC = () => {
  return (
    <>
      <div className="flex-center" style={{ height: "300px" }}>
        <h1 style={{ fontSize: "30px" }}>404 | Page not found</h1>
      </div>
    </>
  );
};

export default NotFound;
