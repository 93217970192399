import { Button, Card, Col, DatePicker, Form, Input, Row, Select } from "antd";
import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import { apis } from "../../../apis";
import { httpService } from "../../../apis/httpService";
import { paths } from "../../../routes/path";
import "./AddBankAccount.scss";

const { RangePicker } = DatePicker;
const { TextArea } = Input;

const normFile = (e: any) => {
   if (Array.isArray(e)) {
      return e;
   }
   return e?.fileList;
};

const AddBankAccount: React.FC = () => {
   const navigate = useNavigate();

   const [bankList, setBankList] = useState<[{ id: string; name: string }]>();

   const getBankList = async () => {
      const res = await httpService.get(`${apis.bank.bank}/?take=100`);
      setBankList(res?.data?.result);
   };

   useEffect(() => {
      getBankList();
   }, []);

   const onFinish = async (values: any) => {
      try {
         await httpService.post(apis.bank.addBankAccount, values);
         navigate(paths.BANK_LIST_ACCOUNT);
      } catch (error) {
         console.log("error", error);
      }
   };
   const handleChangeSelect = (value: string) => {
      console.log("value", value);
   };
   console.log("bankList", bankList);
   return (
      <Card title='Thêm tài khoản ngân hàng mới'>
         <div className='form'>
            <Form
               onFinish={onFinish}
               // layout='horizontal'
            >
               <Row>
                  <Col span={4}>Ngân hàng:</Col>
                  <Col span={12}>
                     <Form.Item name='bankId'>
                        <Select onChange={handleChangeSelect} placeholder={"Vui lòng chọn"}>
                           {bankList &&
                              bankList?.map((item, index) => {
                                 return (
                                    <Select.Option value={item.id} key={index}>
                                       {item.name}
                                    </Select.Option>
                                 );
                              })}
                        </Select>
                     </Form.Item>
                  </Col>
               </Row>

               <Row>
                  <Col span={4}>Tên người thụ hưởng:</Col>
                  <Col span={12}>
                     <Form.Item
                        name='name'
                        rules={[{ required: true, message: "Vui lòng nhập tên người thụ hưởng!" }]}
                     >
                        <Input placeholder='Nguyễn Văn A' />
                     </Form.Item>
                  </Col>
               </Row>

               <Row>
                  <Col span={4}>Số tài khoản:</Col>
                  <Col span={12}>
                     <Form.Item
                        name='number'
                        rules={[{ required: true, message: "Vui lòng nhập số tài khoản!" }]}
                     >
                        <Input placeholder='8360826521' type='number' />
                     </Form.Item>
                  </Col>
               </Row>

               <Row style={{ marginTop: 30 }}>
                  <Col span={17}>
                     <div className='flex-center'>
                        <Button type='primary' size={"large"} htmlType='submit'>
                           Thêm mới
                        </Button>
                     </div>
                  </Col>
               </Row>
            </Form>
         </div>
      </Card>
   );
};

export default () => <AddBankAccount />;
