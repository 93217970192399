import type { TabsProps } from "antd"
import { Tabs } from "antd"
import { useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { ApplicationFailed, ApplicationSuccessful } from "./containers"

const ApplicationRecord = () => {
    const { t } = useTranslation()
    const [selectedTab, setSelectedTab] = useState("application-successful")
    const items: TabsProps["items"] = useMemo(
        () => [
            {
                key: "application-successful",
                label: t("content.promotions.applicationRecord.tab1"),
                children: (
                    <ApplicationSuccessful isActive={selectedTab === "application-successful"} />
                ),
            },
            {
                key: "application-failed",
                label: t("content.promotions.applicationRecord.tab2"),
                children: <ApplicationFailed isActive={selectedTab === "application-failed"} />,
            },
        ],
        [selectedTab, t]
    )

    return (
        <div className='promotions-create__container'>
            <div id='promotion-list-id' className='production-content'>
                <div className='promotions-container'>
                    <div className='header'>
                        <div className='header__container'>
                            <div className='header__item'>
                                {t("content.promotions.applicationRecord.title")}
                            </div>
                        </div>
                        <div className='flex justify-center items-center bg-[#dee3ec] p-3'>
                            {/* <div>
                                <Button>
                                    <Image
                                        width={20}
                                        src="/excel_icon.png"
                                        style={{
                                            marginBottom: 4,
                                            paddingRight: 2,
                                        }}
                                    />
                                    <CSVLink
                                        filename={"Expense_Table.csv"}
                                        data={[]}
                                        className="btn btn-primary"
                                    >
                                        <span> Export</span>
                                    </CSVLink>
                                </Button>
                            </div> */}
                        </div>
                    </div>
                    <div className='content'>
                        <Tabs defaultActiveKey='1' items={items} onChange={setSelectedTab} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ApplicationRecord
