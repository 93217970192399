import { useTranslation } from "react-i18next"
import {
    ApplicationLimitation,
    AutoRebate,
    CalculateMethod,
    CalculationCriteria,
    DayInWeek,
    Formula,
    MaxApplicationCountResetPeriod,
    PromotionPlayerTag,
    PromotionState,
    PromotionStatus,
    RebateCalculation,
    RebateInterval,
} from "../constants"

const useOptions = () => {
    const { t } = useTranslation()

    const RolloverFormulaOptions = [
        {
            label: t("content.promotions.create.state.formula1"),
            value: Formula.FORMULA1,
        },
        {
            label: t("content.promotions.create.state.formula2"),
            value: Formula.FORMULA2,
        },
        {
            label: t("content.promotions.create.state.formula3"),
            value: Formula.FORMULA3,
        },
    ]
    const APPLICATION_LIMITATION_OPTIONS = [
        {
            label: t("content.promotions.create.state.uniqueLoginIP"),
            value: ApplicationLimitation.UNIQUE_LOGIN_IP,
        },
        {
            label: t("content.promotions.create.state.uniqueDeviceFP"),
            value: ApplicationLimitation.UNIQUE_DEVICE_FP,
        },
        {
            label: t("content.promotions.create.state.uniquePhone"),
            value: ApplicationLimitation.UNIQUE_PHONE,
        },
        {
            label: t("content.promotions.create.state.uniqueEmail"),
            value: ApplicationLimitation.UNIQUE_EMAIL,
        },
        {
            label: t("content.promotions.create.state.uniqueBankAccountNo"),
            value: ApplicationLimitation.UNIQUE_BANK_ACCOUNT_NO,
        },
    ]
    const EligibleWeekdayOptions = [
        {
            label: "MON",
            value: DayInWeek.MON,
        },
        {
            label: "TUE",
            value: DayInWeek.TUE,
        },
        {
            label: "WED",
            value: DayInWeek.WED,
        },
        {
            label: "THU",
            value: DayInWeek.THU,
        },
        {
            label: "PRI",
            value: DayInWeek.PRI,
        },
        {
            label: "SAT",
            value: DayInWeek.SAT,
        },
        {
            label: "SUN",
            value: DayInWeek.SUN,
        },
    ]
    const MaxApplicationCountResetPeriodOptions = [
        {
            label: t("content.promotions.create.state.noReset"),
            value: MaxApplicationCountResetPeriod.NO_RESET,
        },
        {
            label: t("content.promotions.create.state.daily"),
            value: MaxApplicationCountResetPeriod.DAILY,
        },
        {
            label: t("content.promotions.create.state.weekly"),
            value: MaxApplicationCountResetPeriod.WEEKLY,
        },
        {
            label: t("content.promotions.create.state.monthly"),
            value: MaxApplicationCountResetPeriod.MONTHLY,
        },
    ]

    const PromotionPlayerTagOptions = [
        {
            label: "Arbitrage player",
            value: PromotionPlayerTag.ARBITRAGE_PLAYER,
        },
        {
            label: "Risky player",
            value: PromotionPlayerTag.RISKY_PLAYER,
        },
    ]

    const CalculateMethodOptions = [
        {
            label: t("content.promotions.create.state.byCertainAmount"),
            value: CalculateMethod.CERTAIN_AMOUNT,
        },
        {
            label: t("content.promotions.create.state.byPercentage"),
            value: CalculateMethod.PERCENTAGE,
        },
    ]
    const AutoRebateOptions = [
        {
            label: t("content.promotions.create.state.autoApply"),
            value: AutoRebate.AUTO_APPLY,
        },
        {
            label: t("content.promotions.create.state.playerManuallyApply"),
            value: AutoRebate.PLAYER_MANUALLY_APPLY,
        },
    ]
    const CalculateCriteriaOptions = [
        {
            label: t("content.promotions.create.state.firstDepositOfDay"),
            value: CalculationCriteria.FIRST_DEPOSIT_OF_DAY,
        },
        {
            label: t("content.promotions.create.state.latestDepositToThePromotionClaimed"),
            value: CalculationCriteria.LATEST_DEPOSIT_TO_THE_PROMOTION_CLAIMED,
        },
    ]

    const RebateIntervalOptions = [
        {
            label: t("content.promotions.create.state.daily"),
            value: RebateInterval.DAILY,
        },
        {
            label: t("content.promotions.create.state.weekly"),
            value: RebateInterval.WEEKLY,
        },
        {
            label: t("content.promotions.create.state.monthly"),
            value: RebateInterval.MONTHLY,
        },
    ]

    const RebateCalculationOptions = [
        {
            label: t("content.promotions.create.state.mainWallet"),
            value: RebateCalculation.MAIN_WALLET,
        },
        {
            label: t("content.promotions.create.state.promotionWallet"),
            value: RebateCalculation.PROMOTION_WALLET,
        },
        {
            label: t("content.promotions.create.state.ReferralWallet"),
            value: RebateCalculation.REFERRAL_WALLET,
        },
    ]

    const StatusOptions = [
        {
            value: PromotionStatus.ACTIVE,
            label: t("common.state.active"),
        },
        {
            value: PromotionStatus.DISABLED,
            label: t("common.state.disabled"),
        },
        // {
        //     value: PromotionStatus.ACTIVE,
        //     label: t("common.state.activeAndOpen"),
        // },
    ]

    const PromotionStatusLabel = {
        // [PromotionStatus.ACTIVE]: t("common.state.activeAndOpen"),
        [PromotionStatus.ACTIVE]: t("common.state.active"),
        [PromotionStatus.DISABLED]: t("common.state.disabled"),
        [PromotionStatus.DELETED]: t("common.state.deleted"),
    }

    const PromotionStateLabel = {
        [PromotionState.PENDING]: "Pending",
        [PromotionState.APPROVE]: "Approve",
        [PromotionState.REJECTED]: "Rejected",
        [PromotionState.COMPLETED]: "Completed",
        [PromotionState.PROMOTION_TRANSFER]: "Promotion Transfer",
    }

    return {
        RolloverFormulaOptions,
        APPLICATION_LIMITATION_OPTIONS,
        EligibleWeekdayOptions,
        MaxApplicationCountResetPeriodOptions,
        PromotionPlayerTagOptions,
        CalculateMethodOptions,
        AutoRebateOptions,
        CalculateCriteriaOptions,
        RebateIntervalOptions,
        RebateCalculationOptions,
        StatusOptions,
        PromotionStatusLabel,
        PromotionStateLabel,
    }
}

export default useOptions
