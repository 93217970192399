import type { CheckboxProps } from "antd"
import { Checkbox } from "antd"
import { FC } from "react"
import { cn } from "../../../common/components/date-range-picker/DateRangePicker"
import "./styles.scss"

const CustomCheckbox: FC<CheckboxProps> = (props) => {
    return <Checkbox {...props} className={cn(props.className, "custom-checkbox")} />
}

export default CustomCheckbox
