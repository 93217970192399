import { Select } from "antd";
import type { SelectProps } from "antd";
import { useTranslation } from "react-i18next";

type CustomSelectProps = { withAllOption?: boolean } & SelectProps;

const CustomSelect = ({
    options,
    value,
    withAllOption,
    ...props
}: CustomSelectProps) => {
    const { t } = useTranslation();
    const _options = withAllOption
        ? [{ label: t("common.all"), value: "*" }, ...(options ?? [])]
        : options;

    return (
        <Select
            {...props}
            options={_options}
            value={value}
            onSelect={(selectedValue, option) => {
                if (withAllOption) {
                    if (selectedValue === "*") {
                        props.onChange?.(["*"], option);
                    } else {
                        const newValue = value.includes("*")
                            ? [
                                  ...value.filter((v: string) => v !== "*"),
                                  selectedValue,
                              ]
                            : [...value, selectedValue];

                        props.onChange?.(newValue, option);
                    }
                } else {
                    props.onChange?.(selectedValue, option);
                }
            }}
            listHeight={180}
        />
    );
};

export default CustomSelect;
