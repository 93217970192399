import { Form, Input } from "antd";
import { Controller, Control } from "react-hook-form";
import type { FormItemProps, InputProps } from "antd";
import { getValidateStatus } from "../../helpers";

const InputField = ({
    name,
    control,
    formItemProps,
    inputProps,
}: {
    name: string;
    control: Control<any>;
    formItemProps: FormItemProps;
    inputProps?: InputProps;
}) => {
    const { labelAlign = "left" } = formItemProps;
    return (
        <Controller
            name={name}
            control={control}
            render={({ field, fieldState }) => {
                const errorMessage = fieldState.error?.message;

                return (
                    <Form.Item
                        hasFeedback
                        labelAlign={labelAlign}
                        validateStatus={getValidateStatus(fieldState, name)}
                        help={errorMessage}
                        className="wrap-label"
                        {...formItemProps}
                    >
                        <Input {...field} {...inputProps} />
                    </Form.Item>
                );
            }}
        />
    );
};

export default InputField;
