import { Card, Col, Form, Input, Row, Table, TableProps } from "antd";
import React, { useEffect, useState } from "react";

const PermissionGroup: React.FC<any> = () => {
  const [groups, setGroups] = useState<any[]>([]);
  const [isDisableForm, setIsDisableForm] = useState<boolean>(false);

  useEffect(() => {
    setGroups([
      {
        id: 1,
        name: "group1",
        code: "GROUP_1",
        description: "",
        permissions: [],
      },
      {
        id: 2,
        name: "group2",
        code: "GROUP_2",
        description: "",
        permissions: [],
      },
    ]);
  }, []);

  console.log("group -", groups.length);
  const columns = [
    { title: "id", dataIndex: "id", width: 15 },
    { title: "Mã nhóm", dataIndex: "code", width: 20 },
    { title: "Tên nhóm", dataIndex: "name", width: 20 },
    { title: "Chú thích", dataIndex: "description", width: 45 },
  ];

  return groups.length === 0 ? (
    <></>
  ) : (
    <div className='permission-group'>
      <Card title='Permission group'>
        <Table
          className='table-list'
          columns={columns}
          dataSource={groups}
          rowKey='id'
          bordered={true}
          pagination={{
            total: 1000,
            defaultPageSize: 2,
            onChange: (page, pageSize) => {
              console.log(page, pageSize);
            },
          }}
          scroll={{ x: 1300, y: 400 }}
        />
      </Card>
    </div>
  );
};

export default PermissionGroup;
