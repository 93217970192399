import { useTranslation } from "react-i18next";
import { DownOutlined, DragOutlined, LockOutlined, RightOutlined } from "@ant-design/icons";
import { Button, Modal } from "antd";
import { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { ListManager } from "react-beautiful-dnd-grid";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./ModalSiteDisplayOrder.scss";

type FieldType = {
    isModalOpen: boolean;
    setIsModalOpen: (value: any) => void;
    data: any;
};

const ModalSiteDisplayOrder = ({ setIsModalOpen, isModalOpen, data }: FieldType) => {
    const [orderCategory, setOrderCategory] = useState<any[]>([]);
    const [updateData, setUpdateData] = useState<any[]>([]);
    const { t } = useTranslation();
    const handleOk = () => {
        setIsModalOpen(false);

        toast.success("Success!");
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    useEffect(() => {
        if (isModalOpen)
            setOrderCategory(
                data?.reduce((acc: any[], curr: any) => {
                    const children = data?.filter(
                        (item: any) => item.gameProviderCategory === curr.gameProviderCategory
                    );
                    if (acc?.length === 0) {
                        acc.push({
                            id: curr.id,
                            gameProviderCategory: curr.gameProviderCategory,
                            displayName: curr.displayName,
                            children,
                        });
                    } else {
                        const exist = acc.find((item) => item.gameProviderCategory === curr.gameProviderCategory);
                        if (!exist) {
                            acc.push({
                                id: curr.id,
                                gameProviderCategory: curr.gameProviderCategory,
                                displayName: curr.displayName,
                                children,
                            });
                        }
                    }

                    return acc;
                }, [])
            );
    }, [isModalOpen]);

    const handleOnDragEnd = (result: any) => {
        if (!result.destination) return;

        const items = Array.from(orderCategory);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        setOrderCategory(items);
    };

    const [activeDropdown, setActiveDropdown] = useState("");
    const onClickDropdown = (cate: string) => {
        if (activeDropdown === cate) {
            setActiveDropdown("");
        } else {
            setActiveDropdown(cate);
        }
    };

    const reorder = (list: any, startIndex: number, endIndex: number) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);

        return result;
    };

    const reorderList = (sourceIndex: any, destinationIndex: any) => {
        if (destinationIndex === sourceIndex) {
            return;
        }
        const parent = orderCategory.find((item: any) => item.gameProviderCategory === activeDropdown);

        const list = parent.children;
        const listOrdered = reorder(list, sourceIndex, destinationIndex);

        const parentIndex = orderCategory.findIndex((x: any) => x.gameProviderCategory === activeDropdown);
        const newData = {
            ...parent,
            children: listOrdered,
        };
        const copy = [...orderCategory];
        copy.splice(parentIndex, 1, newData);
        setOrderCategory(copy);

        setUpdateData(copy);
    };

    const [canDrag, setCanDrag] = useState(false);
    const checkCanDrag = (type: string) => {
        if (type === "child") {
            setCanDrag(true);
        } else {
            setCanDrag(false);
        }
    };

    return (
        <div>
            <Modal
                title={t("content.gameProvider.playerSiteDisplayOrderModal.title")}
                open={isModalOpen}
                onCancel={handleCancel}
                footer={null}
                className="modal-site-display-order"
            >
                <div style={{ marginTop: 20 }}>
                    <div className="game-list-header">
                        <p className="game-list-header-text">
                            {t("content.gameProvider.playerSiteDisplayOrderModal.notice")}
                        </p>
                        <Button type="primary" size="small" disabled={updateData.length == 0} onClick={handleOk}>
                            {t("content.gameProvider.playerSiteDisplayOrderModal.save")}
                        </Button>
                    </div>
                    <DragDropContext onDragEnd={handleOnDragEnd}>
                        <Droppable droppableId="orderCategory">
                            {(provided) => (
                                <ul className="game-list" {...provided.droppableProps} ref={provided.innerRef}>
                                    {orderCategory.map(({ id, gameProviderCategory, children }: any, index: number) => {
                                        return (
                                            <>
                                                <Draggable
                                                    key={id}
                                                    draggableId={id}
                                                    index={index}
                                                    isDragDisabled={canDrag}
                                                >
                                                    {(provided) => (
                                                        <li
                                                            ref={provided.innerRef}
                                                            {...provided.draggableProps}
                                                            {...provided.dragHandleProps}
                                                        >
                                                            <div>
                                                                <div
                                                                    className="game-list-content"
                                                                    onClick={() =>
                                                                        onClickDropdown(gameProviderCategory)
                                                                    }
                                                                    onMouseEnter={() => checkCanDrag("parent")}
                                                                >
                                                                    <div>
                                                                        {children?.length > 0 && (
                                                                            <>
                                                                                {activeDropdown !==
                                                                                gameProviderCategory ? (
                                                                                    <RightOutlined
                                                                                        style={{
                                                                                            color: "#999",
                                                                                            marginRight: 6,
                                                                                        }}
                                                                                    />
                                                                                ) : (
                                                                                    <DownOutlined
                                                                                        style={{
                                                                                            color: "#999",
                                                                                            marginRight: 6,
                                                                                        }}
                                                                                    />
                                                                                )}
                                                                            </>
                                                                        )}
                                                                        <span>{gameProviderCategory}</span>
                                                                    </div>

                                                                    <DragOutlined style={{ fontSize: 16 }} />
                                                                </div>

                                                                {activeDropdown === gameProviderCategory && (
                                                                    <div
                                                                        onMouseEnter={() => checkCanDrag("child")}
                                                                        className="game-list-children-wrap"
                                                                    >
                                                                        {children.map((item: any, index: number) => {
                                                                            if (item.orderNumber <= 0) {
                                                                                return (
                                                                                    <div
                                                                                        className="game-list-children"
                                                                                        key={index}
                                                                                    >
                                                                                        <div className="children-item-lock">
                                                                                            <LockOutlined
                                                                                                style={{
                                                                                                    fontSize: 16,
                                                                                                    marginRight: 4,
                                                                                                }}
                                                                                            />
                                                                                            <p className="children-item-text-lock">
                                                                                                {item.displayName}
                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                );
                                                                            }
                                                                        })}

                                                                        <ListManager
                                                                            items={children}
                                                                            direction="horizontal"
                                                                            maxItems={1}
                                                                            render={(item) => {
                                                                                if (item.orderNumber > 0) {
                                                                                    return (
                                                                                        <div className="game-list-children">
                                                                                            <div className="children-item">
                                                                                                <DragOutlined
                                                                                                    style={{
                                                                                                        fontSize: 16,
                                                                                                        marginRight: 4,
                                                                                                    }}
                                                                                                />
                                                                                                <p className="children-item-text">
                                                                                                    {item.displayName}
                                                                                                </p>
                                                                                            </div>
                                                                                        </div>
                                                                                    );
                                                                                } else {
                                                                                    return <></>;
                                                                                }
                                                                            }}
                                                                            onDragEnd={reorderList}
                                                                        />
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </li>
                                                    )}
                                                </Draggable>
                                            </>
                                        );
                                    })}
                                    {provided.placeholder}
                                </ul>
                            )}
                        </Droppable>
                    </DragDropContext>
                </div>
            </Modal>
            <ToastContainer autoClose={1500} />
        </div>
    );
};

export default ModalSiteDisplayOrder;
