import "./RevenueStatement.scss";

import {
  Button,
  Checkbox,
  Col,
  Dropdown,
  Image,
  Input,
  Radio,
  Row,
  Select,
  Tooltip,
} from "antd";
import { useEffect, useState } from "react";

import { CSVLink } from "react-csv";
import { useTranslation } from "react-i18next";
import moment from "moment";
import { apis } from "../../../apis";

import {
  DownOutlined,
  Loading3QuartersOutlined,
  QuestionCircleFilled,
  RightOutlined,
} from "@ant-design/icons";
import { httpService } from "../../../apis/httpService";

import { CustomDateRangePicker } from "../../../common/components/date-range-picker";
import { getCurrencyList } from "../../../common/helpers/utils";

const RevenueStatement = () => {
  const { Option } = Select;
  const { t } = useTranslation();
  const columns = [
    {
      title: t("content.revenueStatements.col.currency"),
      dataIndex: "currency",
      key: "currency",
    },
    {
      title: t("content.revenueStatements.col.playerCount"),
      dataIndex: "playerCount",
      key: "playerCount",
    },
    {
      title: t("content.revenueStatements.col.betCount"),
      dataIndex: "betCount",
      key: "betCount",
    },
    {
      title: t("content.revenueStatements.col.turnover"),
      dataIndex: "turnover",
      key: "turnover",
    },

    {
      title: t("content.revenueStatements.col.netTurnover"),
      dataIndex: "netTurnover",
      key: "netTurnover",
    },
    {
      title: t("content.revenueStatements.col.memberWinLost"),
      dataIndex: "memberWinlost",
      key: "memberWinlost",
    },
    {
      title: t("content.revenueStatements.col.memberCommission"),
      dataIndex: "memberCommission",
      key: "memberCommission",
    },
    {
      title: t("content.revenueStatements.col.memberTotal"),
      dataIndex: "memberTotal",
      key: "memberTotal",
    },
    {
      title: t("content.revenueStatements.col.companyWinLost"),
      dataIndex: "companyWinlost",
      key: "companyWinlost",
    },
  ];

  const [username, setUsername] = useState();
  const [currency, setCurrency] = useState();
  const [transactionType, setTransactionType] = useState();
  const [voidBets, setVoidBets] = useState();
  const [decimalPointNumbers, setDecimalPointNumbers] = useState();

  const [showData, setShowData] = useState([0]);
  const [valuesDataGame, setvaluesDataGame] = useState(true);
  const [loadings, setLoadings] = useState(false);
  const [checkColumnCash, setCheckColumnCash] = useState(() =>
    columns.map((item) => item.key)
  );

  const currentDate = new Date();
  const fromDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate() - 30
  );

  const [filterDate, setFilterDate] = useState({
    fromDate: fromDate,
    toDate: currentDate,
  });

  const [textSearch, setTextSearch] = useState("");
  const [revenueData, setRevenueData] = useState([]);
  const [grandTotal, setGrandTotal] = useState<any>();

  const getRevenueStatementList = async () => {
    const payload = {
      fromDate: filterDate.fromDate.toISOString(),
      toDate: filterDate.toDate.toISOString(),
      username: username ? username : undefined,
      transactionType,
      currency,
    };
    try {
      const res = await httpService.post(apis.report.revenueStatement, payload);
      setRevenueData(res.data.revenue);
      setGrandTotal(res.data.total);
    } catch (error) {
      setRevenueData([]);

      console.log("error", error);
    }
  };

  const handleCheckColum = (key: string) => {
    const isChecked = checkColumnCash.includes(key);
    if (isChecked) {
      const newState = checkColumnCash.filter((item) => item !== key);
      setCheckColumnCash(newState);
    } else {
      setCheckColumnCash([...checkColumnCash, key]);
    }
  };

  useEffect(() => {
    if (showData.length !== revenueData.length) {
      const showAll = revenueData.map((item: any) => item?.currency);
      setShowData(showAll);
    } else {
      setShowData([]);
    }
  }, [revenueData]);

  const handleShowOneData = (id: any) => {
    const ischecked = showData.includes(id);
    if (ischecked) {
      setShowData(showData.filter((item) => item !== id));
    } else {
      setShowData((prev) => [...prev, id]);
    }
  };

  const [currencyList, setCurrencyList] = useState<any[]>([]);
  useEffect(() => {
    if (!currencyList?.length) {
      const fetchCurrencyList = async () => {
        try {
          const res = await getCurrencyList();
          setCurrencyList(res.data);
        } catch (error) {
          console.log(error);
        }
      };

      fetchCurrencyList();
    }
  }, []);

  useEffect(() => {
    getRevenueStatementList();
  }, [
    textSearch,
    username,
    currency,
    transactionType,
    filterDate.fromDate,
    filterDate.toDate,
  ]);

  const onUsernameChange = (e: any) => {
    setUsername(e.target.value);
  };

  const onCurrencyChange = (value: any) => {
    if (value) setCurrency(value);
  };

  const onTransactionTypeChange = (value: any) => {
    if (value) setTransactionType(value);
  };

  const onChangeVoidBets = (e: any) => {
    setVoidBets(e.target.value);
  };

  const onChangeDecimalPointNumbers = (e: any) => {
    setDecimalPointNumbers(e.target.value);
  };

  const onClickApplyDate = (event: any, picker: any) => {
    const fromDate = moment(picker.startDate).toDate();
    const toDate = moment(picker.endDate).toDate();
    setFilterDate({
      ...filterDate,
      fromDate,
      toDate,
    });
  };

  const clearDate = () => {
    setFilterDate({
      ...filterDate,
      fromDate: fromDate,
      toDate: currentDate,
    });
  };

  const handleSearch = async () => {
    await getRevenueStatementList();
  };

  return (
    <div>
      <div className="revenue-statement">
        <div className="filter-main">
          <div className="header">
            <div className="header-name">
              {t("content.revenueStatements.title")}
            </div>
            <div className="header-export">
              <Button>
                <Image
                  width={20}
                  src="/excel_icon.png"
                  style={{ marginBottom: 4, paddingRight: 2 }}
                />
                <CSVLink
                  filename={"Expense_Table.csv"}
                  data={revenueData}
                  className="btn btn-primary"
                >
                  <span> {t("content.revenueStatements.export")}</span>
                </CSVLink>
              </Button>
            </div>
          </div>
          <Row className="filter-bar" style={{ marginBottom: 4 }}>
            <Col className="date-range-transaction-history">
              <div>
                {t("content.revenueStatements.statisticalDate")}
                <Tooltip
                  placement="bottom"
                  title={t("content.revenueStatements.dateWinLostTooltip")}
                  arrow={true}
                >
                  <QuestionCircleFilled
                    style={{ marginLeft: 4, color: "#999", fontSize: 12 }}
                  />
                </Tooltip>
              </div>

              <CustomDateRangePicker
                onApply={onClickApplyDate}
                onCancel={clearDate}
                containerStyles={{
                  width: "214px",
                  padding: "3px 0px",
                  paddingLeft: "4px",
                  borderRadius: "6px",
                  border: "1px solid #d9d9d9",
                }}
                fromDate={filterDate.fromDate}
                toDate={filterDate.toDate}
                resultToString
                opens={"center"}
                readOnly
              />
            </Col>

            <Col>
              <div>{t("content.revenueStatements.currency")}</div>
              <Select
                defaultValue={t("content.revenueStatements.all")}
                style={{ width: 120 }}
                onChange={onCurrencyChange}
              >
                <Option value="">{t("content.revenueStatements.all")}</Option>
                {currencyList?.map((item, index) => (
                  <Option value={item?.currency} key={index}>
                    {item?.currency}
                  </Option>
                ))}
              </Select>
            </Col>

            <Col>
              <div>{t("content.revenueStatements.username")}</div>
              <Input
                placeholder={t("content.revenueStatements.username")}
                onChange={onUsernameChange}
              />
            </Col>

            <Col>
              <div>
                {t("content.revenueStatements.includeExcludeVoid.title")}
              </div>
              <Radio.Group
                className="flex align-center"
                defaultValue="ind"
                onChange={onChangeVoidBets}
                value={voidBets}
              >
                <Radio value="ind">
                  {t(
                    "content.revenueStatements.includeExcludeVoid.type.indVoidBets"
                  )}
                </Radio>
                <Radio value="excl">
                  {t(
                    "content.revenueStatements.includeExcludeVoid.type.exclVoidBets"
                  )}
                </Radio>
              </Radio.Group>
            </Col>
            <Col>
              <div>{t("content.revenueStatements.decimalPointNumbers")}</div>
              <Radio.Group
                className="flex align-center"
                defaultValue={2}
                onChange={onChangeDecimalPointNumbers}
                value={decimalPointNumbers}
              >
                <Radio value={2}>2</Radio>
                <Radio value={3}>3</Radio>
                <Radio value={4}>4</Radio>
                <Radio value={5}>5</Radio>
                <Radio value={6}>6</Radio>
              </Radio.Group>
            </Col>
            <Col>
              <Button
                className="submit-btn"
                type="primary"
                onClick={handleSearch}
              >
                {loadings && (
                  <tr className="loading" style={{ marginRight: 20 }}>
                    <Loading3QuartersOutlined className="loading-icon-btn" />
                  </tr>
                )}
                <span>{t("content.revenueStatements.search")}</span>
              </Button>
            </Col>
          </Row>

          <div className="filter-table" style={{ padding: "4px" }}>
            <Dropdown
              dropdownRender={() => (
                <div
                  className="filter-table__dropdown"
                  style={{ textAlign: "left" }}
                >
                  {columns.map((item, index) => (
                    <div
                      onClick={() => handleCheckColum(item.key)}
                      style={{
                        display: "flex",
                        padding: "6px 10px",
                        backgroundColor: "#fff",
                      }}
                    >
                      <Checkbox
                        style={{ marginRight: 10 }}
                        checked={checkColumnCash.includes(item.key)}
                      />
                      <div key={index}>{item?.title}</div>
                    </div>
                  ))}
                </div>
              )}
            >
              <a
                onClick={(e) => e.preventDefault()}
                style={{ marginLeft: "auto" }}
              >
                <div className="bars">
                  <img width={16} src="/bars_icon.svg"></img>
                </div>
              </a>
            </Dropdown>
          </div>
        </div>
        <div className="table-main">
          <table>
            <thead>
              <tr>
                <td style={{ textAlign: "center", width: 200 }}>
                  <div>{t("content.revenueStatements.col.gameProvider")}</div>
                </td>

                {columns.map((item, index) => {
                  const isChecked = checkColumnCash.includes(item.key);
                  if (isChecked) {
                    return <td key={index}>{item?.title}</td>;
                  }
                })}
              </tr>
            </thead>
            {loadings && (
              <tr className="loading">
                <Loading3QuartersOutlined className="loading-icon" />
              </tr>
            )}
            {!valuesDataGame && (
              <div className="no-data">
                {t("content.revenueStatements.clickSearchtoSeeInformation")}
              </div>
            )}

            {revenueData.length > 0 &&
              revenueData.map((items: any, index) => (
                <tbody key={index}>
                  <tr>
                    <td
                      className="name-game"
                      onClick={() => handleShowOneData(items.id)}
                      colSpan={columns.length + 1}
                    >
                      {showData.includes(items.id) ? (
                        <DownOutlined />
                      ) : (
                        <RightOutlined />
                      )}
                      <span>{items.name}</span>
                    </td>
                  </tr>

                  {showData.includes(items.id) &&
                    items.data.map((item: any, index: any) => (
                      <tr>
                        <td style={{ textAlign: "center" }}></td>

                        {columns.map((col: any) => {
                          const isChecked = checkColumnCash.includes(col.key);

                          if (isChecked) {
                            if (
                              col.key === "memberWinlost" ||
                              col.key === "memberCommission" ||
                              col.key === "memberTotal" ||
                              col.key === "CompanyWinlost" ||
                              col.key === "CompanyCommission" ||
                              col.key === "CompanyWinlost"
                            ) {
                              const value = +item[col.key];
                              if (value > 0) {
                                return (
                                  <td>
                                    <span
                                      style={{
                                        color: "#003399",
                                      }}
                                    >
                                      {item[col.key]}
                                    </span>
                                  </td>
                                );
                              } else if (value < 0) {
                                return (
                                  <td>
                                    <span style={{ color: "red" }}>
                                      {item[col.key]}
                                    </span>
                                  </td>
                                );
                              }
                            }

                            return (
                              <td>
                                <span style={{ color: "#7b7b7b" }}>
                                  {item[col.key]}
                                </span>
                              </td>
                            );
                          }
                        })}
                      </tr>
                    ))}
                </tbody>
              ))}

            {grandTotal && (
              <tfoot>
                <tr className="grand-total-table">
                  <td
                    colSpan={1}
                    style={{
                      textAlign: "right",
                      fontWeight: 700,
                    }}
                  >
                    {t("content.revenueStatements.grandTotal")}
                  </td>

                  {columns.map((item, index: number) => {
                    const isChecked = checkColumnCash.includes(item.key);
                    if (isChecked) {
                      if (
                        item.key === "memberWinlost" ||
                        item.key === "memberCommission" ||
                        item.key === "memberTotal" ||
                        item.key === "CompanyWinlost" ||
                        item.key === "CompanyCommission" ||
                        item.key === "CompanyWinlost"
                      ) {
                        const value = +grandTotal[item.key];
                        if (value > 0) {
                          return (
                            <td>
                              <span style={{ color: "#003399" }}>
                                {grandTotal[item.key]}
                              </span>
                            </td>
                          );
                        } else if (value < 0) {
                          return (
                            <td>
                              <span style={{ color: "red" }}>
                                {grandTotal[item.key]}
                              </span>
                            </td>
                          );
                        }
                      }
                      return (
                        <td>
                          <span style={{ color: "#7b7b7b" }}>
                            {grandTotal[item.key]}
                          </span>
                        </td>
                      );
                    }
                  })}
                </tr>
              </tfoot>
            )}
          </table>
        </div>
      </div>
    </div>
  );
};

export default RevenueStatement;
