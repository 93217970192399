import { Form, FormItemProps, Upload, Button } from "antd";
import React, { useRef } from "react";
import { Control, Controller } from "react-hook-form";
import { getValidateStatus } from "../../helpers";
import "../styles.scss";
// import { UploadRef } from "antd/es/upload/Upload";
import { uploadMedia } from "../../services";
import { useTranslation } from "react-i18next";

const { Dragger } = Upload;

const icon =
    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACQAAAAfCAYAAACPvW/2AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAK/SURBVHgBzVfRcdpAEH17AX/EHyEdkApMKrDSgamAgwHiv0AFKBWA/zwxY84VJKkAUoFJBSEd8JWZTMJd9k4CGwESCKTJm+EkpLvV0+7e2xUhAVI2PT5UIEQJWUGbL0rdTe0pIZZMuw+BDnKBHqj7YZdiyHSYTB/G3MCc+0oN5sgAUnZKEL/sS/egdQyhevsHYL6r0d0VcoCsvx+DTLmwcwahzMPDaoGUnEMFydfYU3845uq0HiPzjUevsPcCcTbiMfCWKdZ4fIcMIKIXAk9sxVPoiDxkhEKETJk9IfnU35hpaMJu9QJC5itSgO1XQMV+YA/d5VZ/jnUPUXGw05r5XeWhztuzi8W5xMFk2ldsf+y8a3+CHlnjNiRlPYcIlzxOtxkMk1gFxmUpMEZzfkuFRDLNQEKisidEn++9Umr4cXXpadG1xwsS1diFlc4erTF+y5FstEbx89s9N3cXhPBlo9nfIAShPexHZhxIwhLEl5u97fOd0vtIhAiEcY2QoUscTGZpT/j1erv2bG6JPfc5TdkpLA2sdtChZEIYgmKPVKy6c9J+gC3IKRAm9ZmHI8is4DxCOAZByEhvr1dG70/mRAg9JC623iVRQ84QQUjSxTsLcMiKHv4jCPbOev4YE4qj+Yn8MWMP0Xr+EMK8oQFyh74RmzuIXJ1S958Grn3NC/ws7qkHFLaOXuTunL8Eqlz0JrLVqmBBGbaxeg5RmKrh7cT+I5Z4yYcdBdIoJvZgiSEnOFmVjbaPsLhlDU6FWCkX4SQf+uVrplflTs429jNkAtfIxyLmu+ya29mF52TB2MYtuVdKhu7axEUaQlHIJjdw+i8rurAJfok00Obttj46FaEoHMGFuQp36MUeS2acGm+SJh3XK4Rw4X2hK9Ac3qAvL0eeMsVCsIzczpJsnYTQBkGnXbZgE+ehmCw1Zh/8A6B674OzF1nkAAAAAElFTkSuQmCC";
const iconReupload =
    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAYCAYAAADtaU2/AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAIdSURBVHgBvZbdcdNAFIXPXcvm1SWIDkIFOBUAFWSd4MAbUEGcCnDeMgmDNxVgKrCpwClBdKBXmESXsyvLKLEcJ1LsM7OW9sf69u79kQRbkrU2XtymMJENd9nNxDmX+FuDbcm0ezCdKaQzh7bi0Ni3h8cnfjpa7q7VHkOxB0gX9ZW47xcvS/0YggTZnyEtTa39OIK5nVv7/pcEqGnPwzI1VxBNUVtK8KUregTF/urcebIc6w9GEEkimBdf+YcUmdkvL2iq3Mc3sXPfZlXz9LG+herP54TminiS5kcpyPLNiLyhkZPgY3aWx9s/Oj5Q1REHU1EMx+OLK9SQt5SBdJYH1IdJPppZ/px5I1eiWglbBFisghEaiIE29C4Ebn+Hlum7MIZFVN9DEypFp3agMXJ7Ae7OZ7ysGLCaxxm+8DcJAQc9RR3o4WBM/05943F7P6+kqHBCeT0tjqCJAqDFohHqwR0xl//u+6rFTVliT6IHH3Q0+ITc52nwj7u8fgAaKhPXxxXTedWylv4WPx+vLZmhtKmMikCDkWk5NSqhqISW4Syf+tp3zFoovKV31F3sunsPureofDE2q8vU7VWC10D/71o6S8t9zgcratT36AnQXMLAkfaca1PVR1lZ/Zgiqtl0I/QZVVh8ANTffR0VPo6xY23vC2QzuMmLvwlYzTV2Lb7/CZb+bq0mS1ufTfjyyFqvmJ8TbFUEis48yzP/AVjl3oMLT6EsAAAAAElFTkSuQmCC";

const DraggerImageField = ({
    name,
    control,
    formItemProps = {},
}: {
    name: string;
    control: Control<any>;
    formItemProps?: FormItemProps;
    children?: React.ReactNode;
}) => {
    const { labelAlign = "left" } = formItemProps;
    const uploadRef = useRef<any>(null);
    const { t } = useTranslation();

    return (
        <Controller
            name={name}
            control={control}
            render={({ field, fieldState }) => {
                const errorMessage = fieldState.error?.message;

                return (
                    <Form.Item
                        labelAlign={labelAlign}
                        validateStatus={getValidateStatus(fieldState, name)}
                        help={errorMessage}
                        className="wrap-label"
                        label={t(
                            "content.promotions.create.form.field.bannerUpload"
                        )}
                        tooltip={t(
                            "content.promotions.create.form.tooltip.bannerUpload"
                        )}
                        {...formItemProps}
                    >
                        <div className="image-field__container">
                            <Dragger
                                name="file"
                                multiple={false}
                                beforeUpload={(file) => {
                                    uploadMedia(file)
                                        .then((res: any) => {
                                            field.onChange(res.imageUrl);
                                        })
                                        .catch((err) => {
                                            console.log("err ", err);
                                        });
                                }}
                                showUploadList={false}
                                ref={uploadRef}
                                accept=".png,.jpeg,.jpg"
                            >
                                <div className="cus_upload_style">
                                    {field.value ? (
                                        <img
                                            className="image-preview"
                                            src={
                                                typeof field.value === "string"
                                                    ? field.value
                                                    : URL.createObjectURL(
                                                          field.value
                                                      )
                                            }
                                            alt="dsa"
                                        />
                                    ) : (
                                        <div id="promotion__upload">
                                            <div className="el-upload el-upload--text">
                                                <div className="el-upload-dragger">
                                                    <div className="upload-message">
                                                        <img
                                                            src={icon}
                                                            alt=""
                                                        />
                                                        <div className="upload-message__container">
                                                            <p className="upload-message__instruction">
                                                                {t(
                                                                    "content.promotions.create.form.placeholder.bannerUpload"
                                                                )}
                                                            </p>
                                                            <p
                                                                role="button"
                                                                className="promo-banner-upload-btn"
                                                            >
                                                                <span>
                                                                    {t(
                                                                        "common.browseComputer"
                                                                    )}
                                                                </span>
                                                            </p>
                                                            <p className="upload-message__size">
                                                                {t(
                                                                    "common.promotionImagesize"
                                                                )}
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </Dragger>
                            <div className="cus_reupload_style">
                                <img
                                    src={iconReupload}
                                    className="mb-4"
                                    alt="reupload"
                                />
                                <Button
                                    disabled={!field.value}
                                    type="primary"
                                    onClick={(event) => {
                                        if (uploadRef.current?.upload) {
                                            uploadRef.current.upload.uploader.fileInput.click();
                                        }
                                        event.preventDefault();
                                    }}
                                >
                                    <span>Re-Upload</span>
                                </Button>
                                <div className="text-xs size">
                                    Size 560 * 240 px
                                </div>
                            </div>
                        </div>
                    </Form.Item>
                );
            }}
        />
    );
};

export default DraggerImageField;
