import { yupResolver } from "@hookform/resolvers/yup"
import { Button, Form, Modal, Tabs } from "antd"
import { isEmpty } from "lodash"
import { useEffect, useState } from "react"
import { useFieldArray, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import {
    CheckboxField,
    CheckboxGroupField,
    ConditionalInputField,
    CurrencySelect,
    DateRangePickerField,
    Divider,
    DraggerImageField,
    EligibleGameProvidersSelect,
    FormHeader,
    InputField,
    LanguageDropdown,
    RadioGroupField,
    RichtextEditorField,
    SelectField,
    StatusSelect,
    SwitchField,
} from "../../components"
import {
    CalculateMethod,
    CalculationCriteria,
    Formula,
    MaxApplicationCountResetPeriod,
    PromotionStatus,
    PromotionType,
    applicationDeadlineOptions,
    currentDate,
    fromDate,
} from "../../constants"
import { generateEligibleGameProviders, generateRequestBody } from "../../helpers"
import { useOptions, usePromotion } from "../../hooks"
import { apiCreatePromotion } from "../../services"
import { LanguageOption } from "../../types"
import { dailyDepositBonusSchema } from "../../validation"
import "../styles.scss"

const TabPane = Tabs.TabPane
export const LanguageLabel = () => {
    const { t } = useTranslation()

    return <p className='font-medium mr-4'>{t("common.language")}:</p>
}

const DailyDepositBonus = ({
    isOpen,
    onChangeOpen,
    data,
}: {
    isOpen: boolean
    onChangeOpen: (state: boolean) => void
    data?: any
}) => {
    const [isResetFormEditMode, setIsResetFormEditMode] = useState(false)
    const [activeKey, setActiveKey] = useState("0")
    const [langOptions, setLangOptions] = useState<LanguageOption[]>([])
    const [maxApplicationCountResetPeriodDisabled, setMaxApplicationCountResetPeriodDisabled] =
        useState(true)
    const {
        APPLICATION_LIMITATION_OPTIONS,
        PromotionPlayerTagOptions,
        MaxApplicationCountResetPeriodOptions,
        RolloverFormulaOptions,
        CalculateMethodOptions,
        CalculateCriteriaOptions,
        EligibleWeekdayOptions,
    } = useOptions()
    const { t } = useTranslation()
    const { gameProviderList, languageList } = usePromotion()
    const methods = useForm({
        mode: "all",
        defaultValues: {
            date: {
                startDate: fromDate.toString(),
                endDate: currentDate.toString(),
            },
            totalBudget: -1,
            displayOrder: 0,
            turnOverRollOverTimes: 0,
            maxApplicationCountForThisPromo: -1,
            maxApplicationCountByPlayer: -1,
            maxWithdrawAmount: -1,
            rolloverFormula: Formula.FORMULA1,
            calculationCriteria: CalculationCriteria.FIRST_DEPOSIT_OF_DAY,
            calculateMethod: CalculateMethod.CERTAIN_AMOUNT,
            bonusAmount: 0,
            bonusPercentage: 0,
            minimumDepositRequirement: 0,
            maxBonusAmountEachTime: -1,
            isShowToGuest: true,
            status: PromotionStatus.ACTIVE,
            needPlayerApply: true,
            walletAutoComplete: true,
            autoCompleteAmount: 0,
            eligibleWeekdayToApply: [],
            localizations: [
                {
                    code: "",
                    content: "",
                    image: "",
                    language: "en",
                    remark: "",
                    title: "",
                    tabTitle: t("common.default"),
                },
            ],
        },
        resolver: yupResolver(dailyDepositBonusSchema),
    })
    const { control, watch, handleSubmit, setValue, getValues } = methods
    const [
        walletAutoComplete,
        arbitragePlayerChecking,
        needPlayerApply,
        maxApplicationCountForThisPromo,
        totalBudget,
        calculateMethod,
        maxApplicationCountByPlayer,
    ] = watch([
        "walletAutoComplete",
        "arbitragePlayerChecking",
        "needPlayerApply",
        "maxApplicationCountForThisPromo",
        "totalBudget",
        "calculateMethod",
        "maxApplicationCountByPlayer",
    ])
    const { fields, append, remove } = useFieldArray({
        control,
        name: "localizations",
    })
    const isEdit = !isEmpty(data)

    const handleOk = () => {
        onChangeOpen(false)
        // Reset form
    }

    const handleCancel = () => {
        onChangeOpen(false)
        resetForm()
    }

    const resetForm = () => {
        methods.reset({
            date: {
                startDate: fromDate.toString(),
                endDate: currentDate.toString(),
            },
            totalBudget: -1,
            displayOrder: 0,
            turnOverRollOverTimes: 0,
            maxApplicationCountForThisPromo: -1,
            maxApplicationCountByPlayer: -1,
            maxWithdrawAmount: -1,
            rolloverFormula: Formula.FORMULA1,
            calculationCriteria: CalculationCriteria.FIRST_DEPOSIT_OF_DAY,
            calculateMethod: CalculateMethod.CERTAIN_AMOUNT,
            bonusAmount: 0,
            bonusPercentage: 0,
            minimumDepositRequirement: 0,
            maxBonusAmountEachTime: -1,
            isShowToGuest: true,
            status: PromotionStatus.ACTIVE,
            needPlayerApply: true,
            walletAutoComplete: true,
            autoCompleteAmount: 0,
            eligibleWeekdayToApply: [],
            localizations: [
                {
                    code: "",
                    content: "",
                    image: "",
                    language: "en",
                    remark: "",
                    title: "",
                    tabTitle: t("common.default"),
                },
            ],
        })
    }

    const onSubmit = async (data: any) => {
        const request = generateRequestBody(data, PromotionType.DAILY_DEPOSIT_BONUS)

        try {
            await apiCreatePromotion(request)
            onChangeOpen(false)
            resetForm()
        } catch (error) {
            // Handle error
            console.log("error ", error)
        } finally {
            // Reset form
        }
    }

    const onEdit = (
        targetKey: React.MouseEvent | React.KeyboardEvent | string,
        action: "add" | "remove"
    ) => {
        if (action === "add") {
            append({
                code: "",
                content: "",
                image: "",
                language: "en",
                remark: "",
                title: "",
                tabTitle: "Langua",
            })
        } else {
            const index = fields.findIndex((field) => field.id === targetKey)
            const langKey = getValues(`localizations.${index}.language`)
            const idx = langOptions.findIndex((lang) => lang.key === langKey)
            if (idx !== -1) {
                const newLangOptions = [...langOptions]
                newLangOptions[idx].disabled = false

                setLangOptions(newLangOptions)
            }
            remove(index)
        }
    }

    const onChangeActiveKey = (key: string) => {
        setActiveKey(key)
    }

    useEffect(() => {
        if (
            totalBudget === -1 ||
            maxApplicationCountForThisPromo === -1 ||
            maxApplicationCountByPlayer === -1
        ) {
            setMaxApplicationCountResetPeriodDisabled(true)
            setValue("maxApplicationCountResetPeriod", MaxApplicationCountResetPeriod.NO_RESET)
        } else {
            setMaxApplicationCountResetPeriodDisabled(false)
        }
    }, [totalBudget, maxApplicationCountForThisPromo, maxApplicationCountByPlayer, setValue])

    useEffect(() => {
        if (fields) {
            const lastIndex = fields.length - 1

            setActiveKey(fields[lastIndex].id)
        }
    }, [fields])

    useEffect(() => {
        if (!isEmpty(data) && !isResetFormEditMode) {
            methods.reset({
                ...data,
                date: {
                    startDate: data.startDate,
                    endDate: data.endDate,
                },
                localizations: data.localizations.map((localization: any) => {
                    const lang = langOptions.find(
                        (lang) => lang.key === localization.language
                    )?.label
                    return {
                        ...localization,
                        tabTitle: lang === "en" ? t("common.default") : lang,
                    }
                }),
                applicationLimitation: data.applicationLimitation?.split(",") ?? [],
                notAllowedPlayerTag: data.notAllowedPlayerTag?.split(",") ?? [],
                eligibleGameProviders: generateEligibleGameProviders(
                    data.eligibleGameProviders ?? [],
                    gameProviderList
                ),
            })
            setIsResetFormEditMode(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, methods, langOptions])

    useEffect(() => {
        if (languageList.length > 0) {
            setLangOptions(
                languageList.map((lang: any) => ({
                    label: lang.language,
                    key: lang.iso,
                    disabled: lang.iso === "en",
                }))
            )
        }
    }, [languageList])

    return (
        <Modal
            title={t("content.promotions.create.type.DAILY_DEPOSIT_BONUS.title")}
            open={isOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            centered
            className='modal-promotion-creation'
            footer={null}
        >
            <Form
                name='basic'
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                onFinish={handleSubmit(onSubmit)}
                onFinishFailed={(error) => {
                    console.log("error gi day ,  ", error)
                }}
            >
                <LanguageDropdown
                    items={langOptions.map((lang) => ({
                        ...lang,
                        onClick: () => {
                            setLangOptions((prev) =>
                                prev.map((prevLang) => ({
                                    ...prevLang,
                                    disabled:
                                        prevLang.key === lang.key
                                            ? !prevLang.disabled
                                            : prevLang.disabled,
                                }))
                            )
                            append({
                                code: "",
                                content: "",
                                image: "",
                                language: lang.key,
                                remark: "",
                                title: "",
                                tabTitle: lang.label,
                            })
                        },
                    }))}
                />
                <Tabs
                    type='editable-card'
                    activeKey={activeKey}
                    animated={false}
                    onEdit={onEdit}
                    hideAdd
                    onChange={onChangeActiveKey}
                    tabBarExtraContent={{
                        left: <LanguageLabel />,
                    }}
                >
                    {fields.map((field, index) => {
                        const tabTitle = getValues(`localizations.${index}.tabTitle`)
                        const isDisabled = index !== 0 || isEdit

                        return (
                            <TabPane
                                key={field.id}
                                tabKey={index.toString()}
                                closable={index !== 0}
                                tab={<h4>{tabTitle}</h4>}
                            >
                                <div className='form-promotion__container'>
                                    <div className='general-settings'>
                                        <FormHeader
                                            title={t(
                                                "content.promotions.create.form.field.generalSettings"
                                            )}
                                        />
                                        {/* PROMOTION SETTING SECTION */}
                                        <section id='promotion__setting__section'>
                                            <InputField
                                                name={`localizations.${index}.title`}
                                                control={control}
                                                formItemProps={{
                                                    label: t(
                                                        "content.promotions.create.form.field.promotionTitle"
                                                    ),

                                                    required: true,
                                                }}
                                                inputProps={{
                                                    placeholder: t(
                                                        "content.promotions.create.form.placeholder.promotionTitle"
                                                    ),
                                                }}
                                            />
                                            <InputField
                                                name={`localizations.${index}.code`}
                                                control={control}
                                                formItemProps={{
                                                    label: t(
                                                        "content.promotions.create.form.field.promotionCode"
                                                    ),

                                                    required: true,
                                                }}
                                                inputProps={{
                                                    placeholder: t(
                                                        "content.promotions.create.form.placeholder.promotionCode"
                                                    ),
                                                }}
                                            />
                                            <InputField
                                                name={`localizations.${index}.remark`}
                                                control={control}
                                                formItemProps={{
                                                    label: t(
                                                        "content.promotions.create.form.field.remark"
                                                    ),
                                                }}
                                                inputProps={{
                                                    placeholder: t(
                                                        "content.promotions.create.form.placeholder.remark"
                                                    ),
                                                }}
                                            />
                                        </section>
                                        <Divider dimension='horizontal' className='my-4' />
                                        {/* UPDATE IMAGE SECTION */}
                                        <section id='upload__image__section'>
                                            <DraggerImageField
                                                name={`localizations.${index}.image`}
                                                control={control}
                                                formItemProps={{
                                                    labelCol: {
                                                        span: 24,
                                                    },
                                                    wrapperCol: {
                                                        span: 24,
                                                    },
                                                    required: true,
                                                }}
                                            />
                                        </section>
                                        <Divider dimension='horizontal' className='my-4' />
                                        {/* PROMOTION CONTENT SECTION */}
                                        <section id='promotion__content__section'>
                                            <RichtextEditorField
                                                name={`localizations.${index}.content`}
                                                control={control}
                                                formItemProps={{
                                                    label: t(
                                                        "content.promotions.create.form.field.promotionContent"
                                                    ),
                                                    labelCol: {
                                                        span: 24,
                                                    },
                                                    wrapperCol: {
                                                        span: 24,
                                                    },
                                                    required: true,
                                                }}
                                            />
                                        </section>
                                    </div>
                                    <Divider dimension='horizontal' />
                                    <div className='promotion-settings'>
                                        <FormHeader
                                            title={t(
                                                "content.promotions.create.form.field.promotionSetting"
                                            )}
                                        />
                                        {/* BASIC PROMOTION SECTION */}
                                        <section id='basic-promotion-section'>
                                            {/* {t('content.promotions.create.form.field.basicSettings')} */}
                                            <div className='heading-promotion-setting '>
                                                {t(
                                                    "content.promotions.create.form.field.basicSettings"
                                                )}
                                            </div>
                                            <DateRangePickerField
                                                name='date'
                                                control={control}
                                                formItemProps={{
                                                    label: t(
                                                        "content.promotions.create.form.field.promotionDuration"
                                                    ),
                                                    tooltip: t(
                                                        "content.promotions.create.form.tooltip.promotionDuration"
                                                    ),
                                                    labelCol: {
                                                        span: 24,
                                                    },
                                                    wrapperCol: {
                                                        span: 24,
                                                    },
                                                    required: true,
                                                }}
                                                dateRangePickerProps={{
                                                    disabled: isDisabled,
                                                }}
                                            />
                                            <CurrencySelect
                                                control={control}
                                                isDisabled={isDisabled}
                                            />
                                            <InputField
                                                name='displayOrder'
                                                control={control}
                                                formItemProps={{
                                                    label: t(
                                                        "content.promotions.create.form.field.displayOrder"
                                                    ),
                                                    tooltip: t(
                                                        "content.promotions.create.form.tooltip.displayOrder"
                                                    ),
                                                    required: true,
                                                }}
                                                inputProps={{
                                                    disabled: isDisabled,
                                                }}
                                            />
                                            <SwitchField
                                                name='isShowToGuest'
                                                control={control}
                                                formItemProps={{
                                                    label: t(
                                                        "content.promotions.create.form.field.isShowToGuest"
                                                    ),
                                                    tooltip: t(
                                                        "content.promotions.create.form.tooltip.isShowToGuest"
                                                    ),
                                                }}
                                                switchProps={{
                                                    disabled: index !== 0,
                                                    defaultChecked: true,
                                                }}
                                            />
                                            <StatusSelect
                                                control={control}
                                                isDisabled={isDisabled}
                                            />
                                            <SwitchField
                                                name='includeManualDeposit'
                                                control={control}
                                                formItemProps={{
                                                    label: t(
                                                        "content.promotions.create.form.field.includeManualDeposit"
                                                    ),
                                                    tooltip: t(
                                                        "content.promotions.create.form.tooltip.includeManualDeposit"
                                                    ),
                                                }}
                                                switchProps={{
                                                    disabled: isDisabled,
                                                }}
                                            />
                                            {/* {t('content.promotions.create.form.field.bonusSetting')} */}
                                            <div className='heading-promotion-setting '>
                                                {t(
                                                    "content.promotions.create.form.field.bonusSetting"
                                                )}
                                            </div>
                                            <InputField
                                                name='turnOverRollOverTimes'
                                                control={control}
                                                formItemProps={{
                                                    label: t(
                                                        "content.promotions.create.form.field.turnoverRolloverTimes"
                                                    ),
                                                    required: true,
                                                    tooltip: t(
                                                        "content.promotions.create.form.tooltip.turnoverRolloverTimes"
                                                    ),
                                                }}
                                                inputProps={{
                                                    disabled: isDisabled,
                                                }}
                                            />
                                            <SelectField
                                                name='rolloverFormula'
                                                control={control}
                                                formItemProps={{
                                                    label: t(
                                                        "content.promotions.create.form.field.rolloverFormula"
                                                    ),
                                                    tooltip: t(
                                                        "content.promotions.create.form.tooltip.rolloverFormula"
                                                    ),
                                                }}
                                                selectProps={{
                                                    options: RolloverFormulaOptions,
                                                    disabled: isDisabled,
                                                }}
                                            />
                                            <EligibleGameProvidersSelect
                                                control={control}
                                                isDisabled={isDisabled}
                                            />
                                            {/* <RadioGroupField
                                                name='calculationCriteria'
                                                control={control}
                                                formItemProps={{
                                                    label: t(
                                                        "content.promotions.create.form.field.calculateCriteria"
                                                    ),
                                                    required: true,
                                                    labelCol: { span: 24 },
                                                    wrapperCol: { span: 24 },
                                                }}
                                                radioProps={{
                                                    options: CalculateCriteriaOptions,
                                                    defaultValue:
                                                        CalculationCriteria.FIRST_DEPOSIT_OF_DAY,
                                                    disabled: isDisabled,
                                                }}
                                            /> */}
                                            <RadioGroupField
                                                name='calculateMethod'
                                                control={control}
                                                formItemProps={{
                                                    label: t(
                                                        "content.promotions.create.form.field.calculateMethod"
                                                    ),
                                                    required: true,
                                                }}
                                                radioProps={{
                                                    options: CalculateMethodOptions,
                                                    defaultValue: CalculateMethod.CERTAIN_AMOUNT,
                                                    disabled: isDisabled,
                                                }}
                                            />

                                            {calculateMethod === CalculateMethod.CERTAIN_AMOUNT ? (
                                                <>
                                                    <InputField
                                                        name='bonusAmount'
                                                        control={control}
                                                        formItemProps={{
                                                            label: t(
                                                                "content.promotions.create.form.field.bonusAmount"
                                                            ),
                                                            labelAlign: "left",
                                                            required: true,
                                                        }}
                                                        inputProps={{
                                                            type: "number",
                                                            disabled: isDisabled,
                                                        }}
                                                    />
                                                </>
                                            ) : (
                                                <>
                                                    <InputField
                                                        name='bonusPercentage'
                                                        control={control}
                                                        formItemProps={{
                                                            label: t(
                                                                "content.promotions.create.form.field.bonusPercentage"
                                                            ),
                                                            labelAlign: "left",
                                                            required: true,
                                                        }}
                                                        inputProps={{
                                                            type: "number",
                                                            disabled: isDisabled,
                                                            addonAfter: "%",
                                                        }}
                                                    />
                                                    <ConditionalInputField
                                                        name='maxBonusAmountEachTime'
                                                        control={control}
                                                        formItemProps={{
                                                            label: t(
                                                                "content.promotions.create.form.field.maxBonusAmountEachTime"
                                                            ),
                                                            tooltip: t(
                                                                "content.promotions.create.form.tooltip.maxBonusAmountEachTime"
                                                            ),
                                                        }}
                                                        checkboxProps={{
                                                            defaultChecked: true,
                                                            disabled: isDisabled,
                                                        }}
                                                        inputProps={{
                                                            type: "number",
                                                            disabled: isDisabled,
                                                        }}
                                                    />
                                                </>
                                            )}
                                            <InputField
                                                name='minimumDepositRequirement'
                                                control={control}
                                                formItemProps={{
                                                    label: t(
                                                        "content.promotions.create.form.field.minimumDepositRequirement"
                                                    ),
                                                    labelAlign: "left",
                                                    tooltip: t(
                                                        "content.promotions.create.form.tooltip.minimumDepositRequirement"
                                                    ),
                                                    required: true,
                                                }}
                                                inputProps={{
                                                    type: "number",
                                                    disabled: isDisabled,
                                                }}
                                            />

                                            <Divider dimension='horizontal' />
                                            {/* Process Setting */}
                                            <div className='heading-promotion-setting '>
                                                {t(
                                                    "content.promotions.create.form.field.processSettings"
                                                )}
                                            </div>
                                            <CheckboxField
                                                name='needPlayerApply'
                                                control={control}
                                                formItemProps={{
                                                    label: t(
                                                        "content.promotions.create.form.field.needPlayerApply"
                                                    ),
                                                    tooltip: t(
                                                        "content.promotions.create.form.tooltip.needPlayerApply"
                                                    ),
                                                }}
                                                checkboxProps={{
                                                    disabled: isDisabled,
                                                    defaultChecked: true,
                                                }}
                                            >
                                                Yes
                                            </CheckboxField>
                                            {needPlayerApply && (
                                                <SelectField
                                                    name='applicationDeadline'
                                                    control={control}
                                                    formItemProps={{
                                                        label: t(
                                                            "content.promotions.create.form.field.applicationDeadline"
                                                        ),
                                                        tooltip: t(
                                                            "content.promotions.create.form.tooltip.applicationDeadline"
                                                        ),
                                                    }}
                                                    selectProps={{
                                                        options: applicationDeadlineOptions,
                                                        placeholder: t(
                                                            "content.promotions.create.form.placeholder.applicationDeadline"
                                                        ),
                                                        disabled: isDisabled,
                                                    }}
                                                />
                                            )}
                                            <SwitchField
                                                name='allowMultiplePromotion'
                                                control={control}
                                                formItemProps={{
                                                    label: t(
                                                        "content.promotions.create.form.field.allowMultiplePromotion"
                                                    ),
                                                    tooltip: t(
                                                        "content.promotions.create.form.tooltip.allowMultiplePromotion"
                                                    ),
                                                }}
                                                switchProps={{
                                                    disabled: isDisabled,
                                                }}
                                            />
                                            {/* <CheckboxField
                                                name="walletAutoComplete"
                                                control={control}
                                                formItemProps={{
                                                    label: t(
                                                        "content.promotions.create.form.field.promotionWalletAutoComplete"
                                                    ),
                                                    required: true,
                                                    tooltip: t(
                                                        "content.promotions.create.form.tooltip.promotionWalletAutoComplete"
                                                    ),
                                                }}
                                                checkboxProps={{
                                                    disabled: isDisabled,
                                                    defaultChecked: true,
                                                }}
                                            >
                                                Yes
                                            </CheckboxField>
                                            {walletAutoComplete && (
                                                <InputField
                                                    name="autoCompleteAmount"
                                                    control={control}
                                                    formItemProps={{
                                                        label: t(
                                                            "content.promotions.create.form.field.autoCompleteAmount"
                                                        ),
                                                        required: true,
                                                        tooltip: t(
                                                            "content.promotions.create.form.tooltip.autoCompleteAmount"
                                                        ),
                                                    }}
                                                    inputProps={{
                                                        disabled: isDisabled,
                                                    }}
                                                />
                                            )} */}
                                            <ConditionalInputField
                                                name='maxWithdrawAmount'
                                                control={control}
                                                formItemProps={{
                                                    label: t(
                                                        "content.promotions.create.form.field.maxWithdrawalAmount"
                                                    ),
                                                    tooltip: t(
                                                        "content.promotions.create.form.tooltip.maxWithdrawalAmount"
                                                    ),
                                                    required: true,
                                                }}
                                                checkboxProps={{
                                                    defaultChecked: true,
                                                    disabled: isDisabled,
                                                }}
                                                inputProps={{
                                                    type: "number",
                                                    disabled: isDisabled,
                                                }}
                                            />

                                            <CheckboxGroupField
                                                name='eligibleWeekdayToApply'
                                                control={control}
                                                formItemProps={{
                                                    label: t(
                                                        "content.promotions.create.form.field.eligibleWeekdayToApply"
                                                    ),
                                                    tooltip: t(
                                                        "content.promotions.create.form.tooltip.eligibleWeekdayToApply"
                                                    ),
                                                    labelCol: {
                                                        span: 24,
                                                    },
                                                    wrapperCol: {
                                                        span: 24,
                                                    },
                                                    required: true,
                                                }}
                                                checkboxProps={{
                                                    options: EligibleWeekdayOptions,
                                                    disabled: isDisabled,
                                                }}
                                            />
                                            <Divider dimension='horizontal' />
                                            {/* Risk Control Setting */}
                                            <div className='heading-promotion-setting '>
                                                {t(
                                                    "content.promotions.create.form.field.riskControlSettings"
                                                )}
                                            </div>
                                            <CheckboxGroupField
                                                name='applicationLimitation'
                                                control={control}
                                                formItemProps={{
                                                    label: t(
                                                        "content.promotions.create.form.field.applicationLimitation"
                                                    ),
                                                    tooltip: t(
                                                        "content.promotions.create.form.tooltip.applicationLimitation"
                                                    ),
                                                    labelCol: {
                                                        span: 24,
                                                    },
                                                    wrapperCol: {
                                                        span: 24,
                                                    },
                                                }}
                                                checkboxProps={{
                                                    options: APPLICATION_LIMITATION_OPTIONS,
                                                    disabled: isDisabled,
                                                }}
                                            />
                                            <SelectField
                                                control={control}
                                                name='notAllowedPlayerTag'
                                                formItemProps={{
                                                    label: t(
                                                        "content.promotions.create.form.field.notAllowedPlayerTag"
                                                    ),
                                                    tooltip: t(
                                                        "content.promotions.create.form.tooltip.notAllowedPlayerTag"
                                                    ),
                                                }}
                                                selectProps={{
                                                    options: PromotionPlayerTagOptions,
                                                    placeholder: t(
                                                        "content.promotions.create.form.placeholder.notAllowedPlayerTag"
                                                    ),
                                                    mode: "multiple",
                                                    disabled: isDisabled,
                                                }}
                                            />
                                            {/* <SwitchField
                                                control={control}
                                                name='arbitragePlayerChecking'
                                                formItemProps={{
                                                    label: t(
                                                        "content.promotions.create.form.field.arbitragePlayerChecking"
                                                    ),
                                                }}
                                                switchProps={{
                                                    disabled: isDisabled,
                                                }}
                                            />
                                            {arbitragePlayerChecking && (
                                                <ArbitragePlayerCheckingMessage />
                                            )} */}
                                            {/* <SelectField
                                                name='maxApplicationCountResetPeriod'
                                                control={control}
                                                formItemProps={{
                                                    label: t(
                                                        "content.promotions.create.form.field.maxApplicationCountResetPeriod"
                                                    ),
                                                    tooltip: t(
                                                        "content.promotions.create.form.tooltip.maxApplicationCountResetPeriod"
                                                    ),
                                                }}
                                                selectProps={{
                                                    options: MaxApplicationCountResetPeriodOptions,
                                                    defaultValue:
                                                        MaxApplicationCountResetPeriod.NO_RESET,
                                                    disabled:
                                                        maxApplicationCountResetPeriodDisabled ||
                                                        isDisabled,
                                                }}
                                            /> */}
                                            {/* <ConditionalInputField
                                                name='maxApplicationCountByPlayer'
                                                control={control}
                                                inputProps={{
                                                    type: "number",
                                                    placeholder: t(
                                                        "content.promotions.create.form.placeholder.maxApplicationCountForThisPromotion"
                                                    ),
                                                    min: 0,
                                                    disabled: isDisabled,
                                                }}
                                                formItemProps={{
                                                    label: t(
                                                        "content.promotions.create.form.field.maxApplicationCountByPlayer"
                                                    ),
                                                    tooltip: t(
                                                        "content.promotions.create.form.tooltip.maxApplicationCountByPlayer"
                                                    ),
                                                }}
                                                checkboxProps={{
                                                    defaultChecked: true,
                                                    disabled: isDisabled,
                                                }}
                                            /> */}
                                            <ConditionalInputField
                                                name='totalBudget'
                                                control={control}
                                                inputProps={{
                                                    type: "number",
                                                    placeholder: t(
                                                        "content.promotions.create.form.placeholder.totalBudgetForThisPromotion"
                                                    ),
                                                    min: 0,
                                                    disabled: isDisabled,
                                                }}
                                                formItemProps={{
                                                    label: t(
                                                        "content.promotions.create.form.field.totalBudgetForThisPromotion"
                                                    ),
                                                    tooltip: t(
                                                        "content.promotions.create.form.tooltip.totalBudgetForThisPromotion"
                                                    ),
                                                }}
                                                checkboxProps={{
                                                    defaultChecked: true,
                                                    disabled: isDisabled,
                                                }}
                                            />
                                            <ConditionalInputField
                                                name='maxApplicationCountForThisPromo'
                                                control={control}
                                                inputProps={{
                                                    type: "number",
                                                    placeholder: t(
                                                        "content.promotions.create.form.placeholder.maxApplicationCountForThisPromotion"
                                                    ),
                                                    min: 0,
                                                    disabled: isDisabled,
                                                }}
                                                formItemProps={{
                                                    label: t(
                                                        "content.promotions.create.form.field.maxApplicationCountForThisPromotion"
                                                    ),
                                                    tooltip: t(
                                                        "content.promotions.create.form.tooltip.maxApplicationCountForThisPromotion"
                                                    ),
                                                }}
                                                checkboxProps={{
                                                    defaultChecked: true,
                                                    disabled: isDisabled,
                                                }}
                                            />
                                            <Divider dimension='horizontal' />
                                        </section>
                                    </div>
                                    <div className='cta'>
                                        <Button onClick={handleCancel}>{t("common.cancel")}</Button>
                                        <Button
                                            type='primary'
                                            htmlType='submit'
                                            onClick={(event) => {
                                                event.preventDefault()
                                                methods.handleSubmit(onSubmit)()
                                            }}
                                        >
                                            {t("common.submit")}
                                        </Button>
                                    </div>
                                </div>
                            </TabPane>
                        )
                    })}
                </Tabs>
            </Form>
        </Modal>
    )
}

export default DailyDepositBonus
