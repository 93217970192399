import React from "react"
import { cn } from "../../../common/components/date-range-picker/DateRangePicker"
import "./styles.scss"

const MessageAlert = (
    props: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>
) => {
    return <div {...props} className={cn("message-alert__container", props.className)} />
}

export default MessageAlert
