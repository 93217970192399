import React from "react";
import SelectField from "./form/select-field";
import { PromotionStatus } from "../constants";
import { useTranslation } from "react-i18next";
import { useOptions } from "../hooks";

const StatusSelect = ({
    control,
    isDisabled,
}: {
    control: any;
    isDisabled?: boolean;
}) => {
    const { t } = useTranslation();
    const { StatusOptions } = useOptions();

    return (
        <SelectField
            name="status"
            control={control}
            formItemProps={{
                label: t("content.promotions.create.form.field.status"),
                tooltip: (
                    <div className="whitespace-pre-line">
                        {t("content.promotions.create.form.tooltip.status")}
                    </div>
                ),
            }}
            selectProps={{
                defaultValue: PromotionStatus.ACTIVE,
                options: StatusOptions,
                disabled: isDisabled,
            }}
        />
    );
};

export default StatusSelect;
