import { Button, Form, Input } from "antd"
import { useDispatch } from "react-redux"
import { useNavigate, useSearchParams } from "react-router-dom"
import { paths } from "../../../routes/path"
import { authActions } from "../../../store/slices/auth"

import { useState } from "react"
import "./Login.scss"

type FieldType = {
    username?: string
    password?: string
}

const Login: React.FC<any> = () => {
    const [loading, setLoading] = useState(false)
    const [errorMsg, setErrorMsg] = useState("")
    const navigate = useNavigate()
    const dispatch = useDispatch<any>()
    const [queryParams] = useSearchParams()

    const onFinish = async (values: FieldType) => {
        try {
            setLoading(true)
            await dispatch(authActions.login(values)).unwrap()
            await dispatch(authActions.getProfile()).unwrap()
            setLoading(false)
            navigate(queryParams.get("from") || paths.HOME)
        } catch (error: any) {
            if (error.statusCode === 401) {
                setErrorMsg("Sai tên đăng nhập hoặc mật khẩu!")
            }
            setLoading(false)
        }
    }

    const onFinishFailed = (errorInfo: any) => {
        console.log("Failed:", errorInfo)
    }

    return (
        <div className='flex-center mt-3'>
            <div className='auth-box'>
                <Form
                    name='basic'
                    labelCol={{ span: 24 }}
                    wrapperCol={{ span: 24 }}
                    style={{ maxWidth: 600 }}
                    initialValues={{ remember: true }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete='off'
                >
                    <Form.Item
                        label='Tên đăng nhập'
                        name='username'
                        rules={[{ required: true, message: "Trường bắt buộc" }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label='Mật khẩu'
                        name='password'
                        rules={[{ required: true, message: "Trường bắt buộc!" }]}
                    >
                        <Input.Password />
                    </Form.Item>
                    {errorMsg && <div style={{ color: "red" }}>{errorMsg}</div>}

                    <Form.Item className='mt-2 flex-center'>
                        <Button type='primary' htmlType='submit' loading={loading}>
                            Đăng nhập
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    )
}

export default Login
