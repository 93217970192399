import type { TableProps } from "antd"
import { Button, Input, Pagination, Table, Tooltip } from "antd"
import moment from "moment"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import { CustomDateRangePicker } from "../../../../common/components/date-range-picker"
import { paths } from "../../../../routes/path"
import { AccountInputFilter, PromotionStateSelect, PromotionTypeSelect } from "../../components"
import QuestionSvg from "../../components/icons/question-svg"
import { PromotionState, currentDate } from "../../constants"
import { useOptions } from "../../hooks"
import { getPromotionRecords } from "../../services"

export interface IApplicationRecord {
    id: string
    createdAt: string
    updatedAt: string
    createdBy: string
    updatedBy: string
    deletedAt: string
    deletedBy: string
    state: PromotionState
    bonusAmount: number
    appliedDevice: string
    appliedIp: string
    appliedFP: string
    agency: string
    approvedAmount: number
    minTurnoverToWithdraw: number
    currentTurnover: number
    remark: string
    promotion: {
        id: string
        createdAt: string
        updatedAt: string
        createdBy: string
        updatedBy: string
        deletedAt: string
        deletedBy: string
        title: string
        code: string
        type: string
        remark: string
        image: string
        content: string
        startDate: string
        endDate: string
        currency: string
        displayOrder: number
        isShowToGuest: boolean
        includeManualDeposit: boolean
        status: string
        turnOverRollOverTimes: number
        rolloverFormula: string
        eligibleGameProviders: string
        bonusAmount: number
        calculateMethod: string
        calculationCriteria: string
        bonusPercentage: string
        maxBonusAmountEachTime: string
        minimumDepositRequirement: string
        minimumDepositAmount: string
        needPlayerApply: boolean
        applicationDeadline: number
        allowMultiplePromotion: boolean
        walletAutoComplete: boolean
        autoCompleteAmount: string
        maxWithdrawAmount: number
        eligibleWeekdayToApply: string
        applicationLimitation: string
        notAllowedPlayerTag: string
        arbitragePlayerChecking: boolean
        maxApplicationCountResetPeriod: string
        totalBudget: number
        maxApplicationCountByPlayer: string
        maxApplicationCountForThisPromo: number
        rebateInterval: string
        rebatePercentage: string
        maxRebateAmountEachTime: string
        rebateCalculation: string
        autoRebate: string
        minimumTurnoverRequirement: string
        withdrawalLimitRatio: boolean
        tenantId: string
    }
    user: {
        id: string
        createdAt: string
        updatedAt: string
        createdBy: string
        updatedBy: string
        deletedAt: string
        deletedBy: string
        username: string
        email: string
        code: string
        phone: string
        lastName: string
        firstName: string
        address: string
        isEnable: boolean
        dob: string
        isEmailVerified: boolean
        avatarUrl: string
        type: string
        status: string
        language: string
        currency: string
        tenantId: string
    }
}

const ApplicationSuccessful = ({ isActive }: { isActive: boolean }) => {
    const [isLoading, setIsLoading] = useState(false)
    const [filterMeta, setFilterMeta] = useState({
        take: 25,
        totalItem: 0,
        startDate: currentDate,
        endDate: currentDate,
        promotionType: ["*"],
        status: [PromotionState.COMPLETED as string],
        sortKey: "username",
        username: "",
        code: "",
        applyIp: "",
        applyFP: "",
    })
    const { t } = useTranslation()
    const { PromotionStateLabel } = useOptions()
    const [data, setData] = useState<IApplicationRecord[]>([])
    const fixedColumns: TableProps<IApplicationRecord>["columns"] = useMemo(
        () => [
            {
                title: "#",
                width: 60,
                render: (_, __, index) => {
                    return <span>{index + 1}</span>
                },
            },
            {
                title: (
                    <div className='mx-auto w-fit'>
                        <p className='w-fit'>{t("content.promotions.management.username")}</p>
                        <p className='w-fit'>{t("content.promotions.management.agentName")}</p>
                    </div>
                ),
                width: 150,
                render: (_, record) => {
                    return (
                        <div>
                            <Link to={paths.USER_DETAIL.replace(":id", record.user.username)}>
                                <span className='underline'>{record.user.username}</span>
                            </Link>
                            <p>{record.agency ?? "--"}</p>
                        </div>
                    )
                },
            },
            {
                title: (
                    <div className='mx-auto w-fit'>
                        <p className='w-fit'>
                            {t("content.promotions.create.form.field.promotionTitle")}
                        </p>
                        <p className='w-fit'>
                            {t("content.promotions.create.form.field.promotionCode")}
                        </p>
                    </div>
                ),
                width: 250,
                render: (_, record) => {
                    return (
                        <div>
                            <p>{record.promotion.title}</p>
                            <p>{record.promotion.code}</p>
                        </div>
                    )
                },
            },
            {
                title: t("content.promotions.management.requestDate"),
                dataIndex: "createdAt",
                width: 210,
                render: (createdAt: string) => {
                    return <p>{moment(createdAt).format("YYYY-MM-DD HH:mm:ss")}</p>
                },
            },
            {
                title: (
                    <div className='flex flex-col items-end'>
                        <div className='flex items-center gap-1'>
                            <span>{t("content.promotions.management.approvedAmount")}</span>
                            <Tooltip
                                placement='left'
                                title={t(
                                    "content.promotions.management.tooltip.approvedAmountRecord"
                                )}
                            >
                                <div className='flex items-center justify-center'>
                                    <QuestionSvg />
                                </div>
                            </Tooltip>
                        </div>
                        <div className='flex items-center gap-1'>
                            <span>{t("content.promotions.management.minTurnoverToWithdraw")}</span>
                            <Tooltip
                                placement='left'
                                title={t(
                                    "content.promotions.management.tooltip.minTurnoverToWithdraw"
                                )}
                            >
                                <div className='flex items-center justify-center'>
                                    <QuestionSvg />
                                </div>
                            </Tooltip>
                        </div>
                        <div className='flex items-center gap-1'>
                            <span>{t("content.promotions.management.currentTurnover")}</span>
                            <Tooltip
                                placement='left'
                                title={t("content.promotions.management.tooltip.currentTurnover")}
                            >
                                <div className='flex items-center justify-center'>
                                    <QuestionSvg />
                                </div>
                            </Tooltip>
                        </div>
                    </div>
                ),
                width: 220,
                render: (_, record) => {
                    return (
                        <div className='text-right'>
                            <p>{record.approvedAmount}</p>
                            <p>{record.minTurnoverToWithdraw}</p>
                            <p>{record.currentTurnover}</p>
                        </div>
                    )
                },
            },
            {
                title: (
                    <div className='mx-auto w-fit'>
                        <p className='w-fit'>{t("content.promotions.management.applyDevice")}</p>
                        <p className='w-fit'>{t("content.promotions.management.applyIP")}</p>
                        <p className='w-fit'>{t("content.promotions.management.applyFP")}</p>
                    </div>
                ),
                width: 150,
                render: (_, record) => {
                    return (
                        <div>
                            <p>{record.appliedDevice ?? "--"}</p>
                            <p>{record.appliedIp ?? "--"}</p>
                            <p>{record.appliedFP ?? "--"}</p>
                        </div>
                    )
                },
            },
            {
                title: "Status",
                width: 115,
                render: (_, record: any) => {
                    return (
                        <div>
                            <p className='font-bold text-[#00c781]'>
                                {PromotionStateLabel[record.state as PromotionState]}
                            </p>
                        </div>
                    )
                },
            },
            {
                title: t("content.promotions.create.form.field.remark"),
                width: 250,
                render: (_, record) => {
                    return (
                        <div>
                            <p>{record.remark}</p>
                        </div>
                    )
                },
            },
            {
                title: (
                    <div className='mx-auto w-fit'>
                        <div className='w-fit'>
                            {t("content.promotions.create.form.field.modifiedBy")}
                        </div>
                        <div className='w-fit'>
                            {t("content.promotions.create.form.field.modifiedTime")}
                        </div>
                    </div>
                ),
                render: (_, record) => {
                    return (
                        <div>
                            <p>
                                {record.updatedBy || record.createdBy || record.promotion.createdBy}
                            </p>
                            <p>
                                {moment(record.updatedAt || record.createdAt).format(
                                    "YYYY-MM-DD HH:mm:ss"
                                )}
                            </p>
                        </div>
                    )
                },
                width: 160,
            },
        ],
        [t]
    )
    const mergedColumns = useMemo<typeof fixedColumns>(() => {
        return fixedColumns.map((col) => ({ ...col, onCell: undefined }))
    }, [fixedColumns])

    const handleSearchPromotionRecord = useCallback(
        async (meta?: { page: number; take: number }) => {
            const requestParams: {
                code?: string
                promotionState?: string
                type?: string
                applyIp?: string
                applyFP?: string
                sortKey?: string
                username?: string
                agentName?: string
                requestTimeTo?: string
                requestTimeFrom?: string
                take: number
                page: number
            } = {
                take: meta?.take ?? 25,
                page: meta?.page ?? 1,
                sortKey: filterMeta.sortKey,
                requestTimeFrom: moment(filterMeta.startDate).format(),
                requestTimeTo: moment(filterMeta.endDate).format(),
                code: filterMeta.code,
                applyIp: filterMeta.applyIp,
                applyFP: filterMeta.applyFP,
            }

            if (filterMeta.sortKey === "username") {
                requestParams.username = filterMeta.username
            } else {
                requestParams.agentName = filterMeta.username
            }
            if (filterMeta.promotionType.length > 0 && !filterMeta.promotionType.includes("*")) {
                requestParams.type = filterMeta.promotionType.join(",")
            }
            if (filterMeta.status.length > 0 && !filterMeta.status.includes("*")) {
                requestParams.promotionState = filterMeta.status.join(",")
            }

            setIsLoading(true)
            try {
                const res = await getPromotionRecords(requestParams)
                setData(res.data.result)
            } catch (error) {
            } finally {
                setIsLoading(false)
            }
        },
        [filterMeta]
    )

    useEffect(() => {
        if (isActive) {
            handleSearchPromotionRecord()
        } else {
            setData([])
        }
    }, [isActive])

    return (
        <div>
            <div className='flex gap-2 justify-end'>
                <AccountInputFilter
                    sortKey={filterMeta.sortKey}
                    username={filterMeta.username}
                    onChange={(value) => {
                        setFilterMeta((current) => ({
                            ...current,
                            sortKey: value.sortKey,
                            username: value.username,
                        }))
                    }}
                />
                <Button
                    type='primary'
                    htmlType='button'
                    onClick={() => {
                        handleSearchPromotionRecord()
                    }}
                >
                    {t("common.search")}
                </Button>
            </div>
            <div className='flex my-3 mx-2 justify-end'>
                <div className='flex gap-4 overflow-x-scroll'>
                    {/* Requested Date */}
                    <div className='flex gap-2 items-center '>
                        <div className='flex items-center gap-1'>
                            <div className='w-[115px]'>
                                {t("content.promotions.management.requestedDate")}
                            </div>
                            <Tooltip
                                placement='left'
                                title={t("content.promotions.management.tooltip.requestedDate")}
                            >
                                <div className='flex items-center justify-center'>
                                    <QuestionSvg />
                                </div>
                            </Tooltip>
                        </div>
                        <div className='w-[250px] flex-1'>
                            <CustomDateRangePicker
                                onApply={(event: any, picker: any) => {
                                    const newStartDate = moment(picker.startDate).toDate()
                                    const newEndDate = moment(picker.endDate).toDate()

                                    setFilterMeta((current) => ({
                                        ...current,
                                        startDate: newStartDate,
                                        endDate: newEndDate,
                                    }))
                                }}
                                onCancel={() => {}}
                                containerStyles={{
                                    width: "214px",
                                    padding: "3px 0px",
                                    paddingLeft: "4px",
                                    borderRadius: "6px",
                                    border: "1px solid #d9d9d9",
                                }}
                                fromDate={filterMeta.startDate}
                                toDate={filterMeta.endDate}
                                resultToString
                                opens={"center"}
                                readOnly
                            />
                        </div>
                    </div>
                    {/* Promotion Type */}
                    <PromotionTypeSelect
                        value={filterMeta.promotionType}
                        onChange={(value) => {
                            setFilterMeta({
                                ...filterMeta,
                                promotionType: value,
                            })
                        }}
                    />
                    {/* {t("content.promotions.create.form.field.promotionCode")} */}
                    <div className='flex gap-2 items-center '>
                        <span>{t("content.promotions.create.form.field.promotionCode")}</span>
                        <Input
                            placeholder={t("content.promotions.management.codePlaceholder")}
                            className='flex-1 w-[180px]'
                            onChange={(event) => {
                                setFilterMeta((current) => ({
                                    ...current,
                                    code: event.target.value,
                                }))
                            }}
                        />
                    </div>
                    {/* Promotion Status */}
                    <PromotionStateSelect
                        value={filterMeta.status}
                        onChange={(value) => {
                            setFilterMeta({
                                ...filterMeta,
                                status: value,
                            })
                        }}
                    />
                    {/* Apply IP */}
                    <div className='flex gap-2 items-center '>
                        <span>{t("content.promotions.management.applyIP")}</span>
                        <Input
                            placeholder='Search...'
                            className='flex-1 w-[180px]'
                            onChange={(event) => {
                                setFilterMeta((current) => ({
                                    ...current,
                                    applyIp: event.target.value,
                                }))
                            }}
                        />
                    </div>
                    {/* Apply FP */}
                    <div className='flex gap-2 items-center '>
                        <span>{t("content.promotions.management.applyFP")}</span>
                        <Input
                            placeholder='Search...'
                            className='flex-1 w-[180px]'
                            onChange={(event) => {
                                setFilterMeta((current) => ({
                                    ...current,
                                    applyFP: event.target.value,
                                }))
                            }}
                        />
                    </div>
                </div>
            </div>
            <Table
                id='table-data'
                bordered
                columns={mergedColumns}
                scroll={{ x: 2000, y: 400 }}
                rowKey='id'
                dataSource={data}
                pagination={false}
                loading={isLoading}
            />
            <div className='mt-4'>
                <Pagination
                    defaultCurrent={1}
                    total={filterMeta.totalItem}
                    showQuickJumper
                    showSizeChanger
                    defaultPageSize={25}
                    pageSizeOptions={["25", "50", "100"]}
                    onChange={(page) => {
                        setFilterMeta((current) => ({
                            ...current,
                            page,
                        }))
                        handleSearchPromotionRecord({
                            take: filterMeta.take,
                            page,
                        })
                    }}
                    onShowSizeChange={(currentPage, size) => {
                        setFilterMeta((current) => ({
                            ...current,
                            take: size,
                            page: currentPage,
                        }))
                        handleSearchPromotionRecord({
                            page: currentPage,
                            take: size,
                        })
                    }}
                    style={{ fontSize: 13 }}
                />
            </div>
        </div>
    )
}

export default ApplicationSuccessful
