import { Button, Tabs, TabsProps } from "antd"
import { useEffect, useMemo, useState } from "react"
import { toast } from "react-toastify"
import { apis } from "../../../apis"
import { httpService } from "../../../apis/httpService"
import "./PaymentGatewayBankSetting.scss"
import HTPay from "./gateway/htpay/HTPay"
import PayPro from "./gateway/paypro/PayPro"

type FieldType = {
    currentPassword?: string
    password?: string
    confirmPassword?: string
}

const PaymentGatewayBankSetting = () => {
    const items: TabsProps["items"] = useMemo(
        () => [
            {
                key: "1",
                label: "PayPro",
                children: <PayPro />,
            },
            {
                key: "2",
                label: "HTPay",
                children: <HTPay />,
            },
        ],
        []
    )

    const [languages, setLanguages] = useState([])
    const [isEdit, setIsEdit] = useState(false)
    const [canSave, setCanSave] = useState(false)
    const [dataSave, setDataSave] = useState<any>([])

    const onChangeSwitch = (checked: boolean, e: any, record: any) => {
        setCanSave(true)
        record.status = checked
        setDataSave([...dataSave, record])
    }

    const getLanguages = async () => {
        try {
            const res = await httpService.get(apis.company.language)

            if (res?.data) {
                setLanguages(res.data)
            }
        } catch (error) {
            console.log("error", error)
        }
    }

    useEffect(() => {
        getLanguages()
    }, [])

    const onClickEdit = () => {
        setIsEdit(true)
    }
    const onClickCancel = () => {
        setIsEdit(false)
    }

    const onClickSave = async () => {
        try {
            const res = await httpService.patch(apis.company.language, dataSave)

            if (res?.data) {
                setIsEdit(false)
                getLanguages()
                toast.success("Success")
            }
        } catch (error) {
            toast.error("Update error")

            console.log("error", error)
        }
    }

    return (
        <div className='payment-gateway'>
            <div className='win-lost'>
                <div>
                    <div className='win-lost__header'>
                        <div className='header-name'>Payment Gateway Bank Setting</div>

                        <div className='modify-btn'>
                            {!isEdit ? (
                                <div className='modify-btn'>
                                    <Button size='small' type='primary' onClick={onClickEdit}>
                                        <span style={{ padding: "0 6px" }}>Edit</span>
                                    </Button>
                                </div>
                            ) : (
                                <div className='modify-btn'>
                                    <Button
                                        size='small'
                                        style={{
                                            marginRight: 4,
                                        }}
                                        type='primary'
                                        onClick={onClickCancel}
                                        danger
                                    >
                                        <span style={{ padding: "0 6px" }}>Cancel</span>
                                    </Button>
                                    <Button size='small' type='primary' disabled={!canSave}>
                                        <span style={{ padding: "0 6px" }} onClick={onClickSave}>
                                            Save
                                        </span>
                                    </Button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                <div className='content-tab'>
                    <Tabs
                        defaultActiveKey='1'
                        items={items}
                        onChange={(key) => {
                            console.log("tab key", key)
                        }}
                    />
                </div>
            </div>
        </div>
    )
}

export default PaymentGatewayBankSetting
