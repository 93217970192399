export enum TransactionType {
  Deposit = 'Deposit',
  Withdraw = 'Withdraw',
  // Manual = 'manual',
  SeamlessGame = 'SeamlessGame',
  ManualDeposit = 'ManualDeposit',
  ManualWithdraw = 'ManualWithdraw',
  ManualBonus = 'ManualBonus',
  ManualRebate = 'ManualRebate',
  ManualBalanceAddition = 'BalanceAddition',
  ManualBalanceDeduction = 'BalanceDeduction',
  GameSettled = 'GameSettled',
  GameDeduct = 'GameDeduct',
  GameBonus = 'GameBonus',
  GameCancel = 'GameCancel',
  GameRollback = 'GameRollback',
  GameStake = 'GameStake',
  PromotionRebate = 'PromotionRebate',
  PromotionBonus = 'PromotionBonus',
  ReferralBonus = 'ReferralBonus',
}

export enum TransactionState {
  Waiting = 'waiting',
  Verified = 'verified',
  Approved = 'approved',
  Rejected = 'rejected',
  Cancelled = 'cancelled',
  PaymentProcessing = 'paymentProcessing',
  PaymentError = 'paymentError',
}

export enum TransactionStatus {
  Succeed = 'succeed',
  Failed = 'failed',
}
