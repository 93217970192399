import { Button, Card, Checkbox, Col, Form, Radio, Row } from "antd";

export interface ITrackingProps {}

const TrackingSetting: React.FC<ITrackingProps> = () => {
  return (
    <>
      <Card
        title="Cài đặt theo dõi hoạt động"
        extra={<a href="#">Hoạt động thành viên</a>}
      >
        <Form
          labelCol={{ span: 4 }}
          wrapperCol={{ span: 14 }}
          layout="horizontal"
        >
          <Form.Item name="size" wrapperCol={{ span: 24 }}>
            <Checkbox.Group>
              <Row>
                <Col span={8}>
                  <Checkbox value="A" style={{ lineHeight: "32px" }}>
                    Đăng ký / Đăng nhập
                  </Checkbox>
                </Col>
                <Col span={8}>
                  <Checkbox value="B" style={{ lineHeight: "32px" }}>
                    Thay đổi mật khẩu
                  </Checkbox>
                </Col>
                <Col span={8}>
                  <Checkbox value="C" style={{ lineHeight: "32px" }}>
                    Nạp rút tiền
                  </Checkbox>
                </Col>
                <Col span={8}>
                  <Checkbox value="D" style={{ lineHeight: "32px" }}>
                    Nạp tiền lần đầu
                  </Checkbox>
                </Col>
                <Col span={8}>
                  <Checkbox value="E" style={{ lineHeight: "32px" }}>
                    Cập nhật thông tin tài khoản
                  </Checkbox>
                </Col>
                <Col span={8}>
                  <Checkbox value="F" style={{ lineHeight: "32px" }}>
                    Cập nhật thông tin ngân hàng
                  </Checkbox>
                </Col>
              </Row>
            </Checkbox.Group>
          </Form.Item>
          <div className="flex-center mt-2">
            <Button type="primary">Lưu thay đổi</Button>
          </div>
        </Form>
      </Card>
    </>
  );
};

export default TrackingSetting;
