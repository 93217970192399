import { ExclamationCircleFilled } from "@ant-design/icons";
import { Button, Form, Input } from "antd";
import { useState } from "react";
import { toast } from "react-toastify";
import { apis } from "../../../apis";
import { httpService } from "../../../apis/httpService";
import { useTranslation } from "react-i18next";
import "./ChangePassword.scss";

type FieldType = {
  currentPassword?: string;
  password?: string;
  confirmPassword?: string;
};

const ChangePassword = () => {
  const { t } = useTranslation();
  const [error, setError] = useState("");
  const onFinish = async (values: any) => {
    if (values.confirmPassword !== values.password) {
      setError(t("content.security.changePassword.confirmPasswordMatch"));
    }
    try {
      const payload = {
        currentPassword: values.currentPassword,
        newPassword: values.password,
        newConfirmPassword: values.confirmPassword,
      };
      await httpService.post(apis.security.changePassword, payload);
      toast.success(t("content.security.changePassword.changePasswordSuccess"));
    } catch (error) {
      toast.error(t("content.security.changePassword.changePasswordError"));
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    toast.error(errorInfo);
  };

  return (
    <div className="change-password">
      <div className="win-lost">
        <div>
          <div className="win-lost__header">
            <div className="header-name">
              {t("content.security.changePassword.title")}
            </div>
          </div>

          <div className="remark-content" style={{ padding: "26px 40px" }}>
            <p>
              <span style={{ width: 20 }}>
                <ExclamationCircleFilled />
              </span>
              <span className="remark-title">
                {t("content.security.changePassword.remark")}
              </span>
            </p>
            <li className="remark-text">
              {t("content.security.changePassword.remarkText1")}
            </li>
            <li className="remark-text">
              {t("content.security.changePassword.remarkText2")}
            </li>
          </div>
        </div>
        <div
          className="table-main"
          style={{ textAlign: "center", padding: "54px 0" }}
        >
          <Form
            name="basic"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 8 }}
            style={{ textAlign: "center" }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item<FieldType>
              label={t("content.security.changePassword.currentPassword")}
              name="currentPassword"
              rules={[
                {
                  required: true,
                  message: t(
                    "content.security.changePassword.currentPasswordMsg"
                  ),
                },
              ]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item<FieldType>
              label={t("content.security.changePassword.password")}
              name="password"
              rules={[
                {
                  required: true,
                  message: t("content.security.changePassword.passwordMsg"),
                },
              ]}
            >
              <Input.Password />
            </Form.Item>
            <Form.Item<FieldType>
              label={t("content.security.changePassword.confirmPassword")}
              name="confirmPassword"
              rules={[
                {
                  required: true,
                  message: t(
                    "content.security.changePassword.confirmPasswordMsg"
                  ),
                },
              ]}
            >
              <Input.Password />
            </Form.Item>
            {error && <div style={{ color: "red" }}>{error}</div>}

            <Form.Item
              wrapperCol={{ offset: 8, span: 8 }}
              style={{ marginTop: 40 }}
            >
              <Button style={{ marginRight: 8 }}>
                {t("content.security.changePassword.reset")}
              </Button>
              <Button type="primary" htmlType="submit">
                {t("content.security.changePassword.submit")}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
