import i18next from "i18next"
import { useEffect } from "react"
import { initReactI18next, useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { Router } from "./Router"
import { RoundSpinner } from "./common/components/round-spinner"
import { useCredential } from "./hooks/useCredential"
import { IStoreState } from "./interfaces/Store"
import translationEN from "./translation/en/translationEN.json"
import translationVI from "./translation/vi/translationVI.json"

i18next.use(initReactI18next).init({
    resources: {
        en: {
            translation: translationEN,
        },
        vi: {
            translation: translationVI,
        },
    },
    fallbackLng: "en",

    interpolation: {
        escapeValue: false,
    },
})

const App = () => {
    const { i18n } = useTranslation()
    const lang = useSelector((state: any) => state.language)

    useEffect(() => {
        i18n.changeLanguage(lang.language)
    }, [lang.language, i18n])

    useCredential()
    const appStates = useSelector((state: IStoreState) => state.auth)

    if (appStates.loading) {
        return <RoundSpinner />
    }

    return (
        <>
            <Router profile={appStates.profile}></Router>
        </>
    )
}

export default App
