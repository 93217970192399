import {
    CheckCircleFilled,
    CloseCircleFilled,
    EllipsisOutlined,
    ReloadOutlined,
} from "@ant-design/icons"
import {
    Button,
    Checkbox,
    Dropdown,
    Image,
    Input,
    Pagination,
    Table,
    TableProps,
    Tabs,
    TabsProps,
    Tag,
    Tooltip,
} from "antd"
import moment from "moment"
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { CSVLink } from "react-csv"
import { useTranslation } from "react-i18next"
import { CustomDateRangePicker } from "../../../common/components/date-range-picker"
import { sleep } from "../../../common/helpers/utils"
import { AccountInputFilter, PromotionTypeSelect } from "../components"
import CustomSelect from "../components/custom-select"
import QuestionSvg from "../components/icons/question-svg"
import { PromotionState, currentDate } from "../constants"
import { approvePromotion, getPromotionVerify } from "../services"
import "./styles.scss"

const { TextArea } = Input
enum TimeOption {
    PROCESSING_TIME = "PROCESSING_TIME",
    REQUEST_TIME = "REQUEST_TIME",
}

const ApplicationVerify = () => {
    const [isLoading, setIsLoading] = useState(false)
    const [data, setData] = useState([
        {
            id: 1,
            appliedDevice: "Mobile",
            remark: "This is a test remark",
            suggestedAmount: 100,
            currency: "VND",
            approvedAmount: 120,
            description: "lorem ipsum",
        },
    ])
    const [filterMeta, setFilterMeta] = useState({
        take: 25,
        totalItem: 0,
        startDate: currentDate,
        endDate: currentDate,
        promotionType: ["*"],
        promotionState: PromotionState.PENDING,
        status: ["*"],
        sortKey: "username",
        username: "",
        code: "",
        applyIp: "",
    })
    const [promotionTransferFilter, setPromotionTransferFilter] = useState({
        type: TimeOption.PROCESSING_TIME,
        startDate: currentDate,
        endDate: currentDate,
        username: "",
    })
    const [isAutoRefresh, setIsAutoRefresh] = useState(false)
    const { t } = useTranslation()
    const timerRef = useRef<NodeJS.Timeout | null>(null)

    const items = useMemo<TabsProps["items"]>(
        () => [
            {
                key: PromotionState.PENDING,
                label: (
                    <div className='flex items-center gap-1'>
                        <span>{t("content.promotions.create.state.pending")}</span>
                        <Tooltip
                            placement='left'
                            title={t("content.promotions.management.tooltip.pending")}
                        >
                            <div className='flex items-center justify-center'>
                                <QuestionSvg />
                            </div>
                        </Tooltip>
                    </div>
                ),
            },
            {
                key: PromotionState.APPROVE,
                label: (
                    <div className='flex items-center gap-1'>
                        <span>{t("content.promotions.create.state.approve")}</span>
                        <Tooltip
                            placement='left'
                            title={t("content.promotions.management.tooltip.approve")}
                        >
                            <div className='flex items-center justify-center'>
                                <QuestionSvg />
                            </div>
                        </Tooltip>
                    </div>
                ),
            },
            {
                key: PromotionState.REJECTED,
                label: (
                    <div className='flex items-center gap-1'>
                        <span>{t("content.promotions.create.state.rejected")}</span>
                        <Tooltip
                            placement='left'
                            title={t("content.promotions.management.tooltip.rejected")}
                        >
                            <div className='flex items-center justify-center'>
                                <QuestionSvg />
                            </div>
                        </Tooltip>
                    </div>
                ),
            },
            {
                key: PromotionState.COMPLETED,
                label: (
                    <div className='flex items-center gap-1'>
                        <span>{t("content.promotions.create.state.completed")}</span>
                        <Tooltip
                            placement='left'
                            title={t("content.promotions.management.tooltip.completed")}
                        >
                            <div className='flex items-center justify-center'>
                                <QuestionSvg />
                            </div>
                        </Tooltip>
                    </div>
                ),
            },
            {
                key: PromotionState.PROMOTION_TRANSFER,
                label: (
                    <div className='flex items-center gap-1'>
                        <span>{t("content.promotions.create.state.promotionTransfer")}</span>
                        <Tooltip
                            placement='left'
                            title={t("content.promotions.management.tooltip.promotionTransfer")}
                        >
                            <div className='flex items-center justify-center'>
                                <QuestionSvg />
                            </div>
                        </Tooltip>
                    </div>
                ),
            },
        ],
        []
    )

    const handleChangeData = useCallback(
        (event: React.ChangeEvent<any>, record: any, field: string) => {
            const index = data.findIndex((r) => r.id === record.id)
            const newRecord = {
                ...record,
                isDataChanged: true,
                [field]: event.target.value,
            }
            const newData = [...data]
            newData[index] = newRecord

            setData(newData)
        },
        [data]
    )

    const handleApiUpdatePromotion = useCallback(async (record?: any) => {
        setIsLoading(true)
        try {
            await sleep(1000)
            approvePromotion({
                id: record?.id,
                state: record?.state,
                approvedAmount: record?.approvedAmount,
                minTurnoverToWithdraw: record?.minTurnoverToWithdraw,
                remark: record?.remark,
            })
        } catch (error) {
            console.error(error)
        } finally {
            setIsLoading(false)
        }
    }, [])

    const fixedColumns: TableProps["columns"] = useMemo(
        () => [
            {
                title: (
                    <div className='mx-auto w-fit'>
                        <p className='w-fit'>{t("content.promotions.management.username")}</p>
                        <p className='w-fit'>{t("content.promotions.management.agentName")}</p>
                    </div>
                ),
                width: 200,
            },
            {
                title: (
                    <div className='mx-auto w-fit'>
                        <p className='w-fit'>
                            {t("content.promotions.create.form.field.promotionTitle")}
                        </p>
                        <p className='w-fit'>
                            {t("content.promotions.create.form.field.promotionCode")}
                        </p>
                    </div>
                ),
                width: 300,
            },
            {
                title: t("content.promotions.create.form.field.promotionType"),
                width: 300,
            },
            {
                title: t("content.promotions.management.requestDate"),
                width: 210,
            },
            {
                title: (
                    <div className='flex flex-col items-end'>
                        <div className='flex items-center gap-1'>
                            <span>{t("content.promotions.management.suggestedAmount")}</span>
                            <Tooltip
                                placement='left'
                                title={t("content.promotions.management.tooltip.suggestedAmount")}
                            >
                                <div className='flex items-center justify-center'>
                                    <QuestionSvg />
                                </div>
                            </Tooltip>
                        </div>
                        <div className='flex items-center gap-1'>
                            <span>{t("content.promotions.management.approvedAmount")}</span>
                            <Tooltip
                                placement='left'
                                title={t("content.promotions.management.tooltip.approvedAmount")}
                            >
                                <div className='flex items-center justify-center'>
                                    <QuestionSvg />
                                </div>
                            </Tooltip>
                        </div>
                    </div>
                ),
                width: 190,
                render: (_, record) => {
                    return (
                        <>
                            {filterMeta.promotionState === PromotionState.PENDING && (
                                <div className='flex gap-2 flex-col items-end'>
                                    <p>
                                        {record.currency} {record.suggestedAmount}
                                    </p>
                                    <Input
                                        className='text-right text-[12px] py-1'
                                        size='small'
                                        value={record.approvedAmount}
                                        type='number'
                                        onChange={(event) => {
                                            handleChangeData(event, record, "approvedAmount")
                                        }}
                                    />
                                </div>
                            )}
                        </>
                    )
                },
            },
            {
                title: (
                    <div className='flex items-center gap-1'>
                        <span>{t("content.promotions.management.minTurnoverToWithdraw")}</span>
                        <Tooltip
                            placement='left'
                            title={t("content.promotions.management.tooltip.minTurnoverToWithdraw")}
                        >
                            <div className='flex items-center justify-center'>
                                <QuestionSvg />
                            </div>
                        </Tooltip>
                    </div>
                ),
                width: 160,
            },
            {
                title: (
                    <div className='mx-auto w-fit'>
                        <p className='w-fit'>{t("content.promotions.management.applyDevice")}</p>
                        <p className='w-fit'>{t("content.promotions.management.applyIP")}</p>
                    </div>
                ),
                width: 220,
                render: (_, record) => {
                    return (
                        <div>
                            <p>{record.appliedDevice ?? "--"}</p>
                            <p>{record.appliedIp ?? "--"}</p>
                        </div>
                    )
                },
            },
            {
                title: t("content.promotions.create.form.field.promotionCode"),
                width: 400,
                render: (_, record) => {
                    return (
                        <TextArea
                            rows={2}
                            value={record.remark}
                            onChange={(event) => {
                                handleChangeData(event, record, "remark")
                            }}
                            placeholder={t("content.promotions.management.remarkPlaceholder")}
                        />
                    )
                },
            },
            {
                title: t("content.promotions.management.action"),
                align: "center",
                fixed: "right",
                render: (_, record) => {
                    return (
                        <>
                            {filterMeta.promotionState === PromotionState.PENDING && (
                                <div className='flex gap-2 justify-center'>
                                    <Button
                                        type='primary'
                                        htmlType='button'
                                        size='small'
                                        disabled={!record.isDataChanged}
                                        icon={<CheckCircleFilled style={{ width: 12 }} />}
                                        className='bg-[#67c23a] hover:bg-[#95d475]'
                                        onClick={() =>
                                            handleApiUpdatePromotion({
                                                id: record.id,
                                                approvedAmount: record.approvedAmount,
                                                remark: record.remark,
                                                state: PromotionState.APPROVE,
                                            })
                                        }
                                    >
                                        {t("common.approve")}
                                    </Button>
                                    <Button
                                        type='primary'
                                        danger
                                        htmlType='button'
                                        size='small'
                                        disabled={!record.isDataChanged}
                                        icon={<CloseCircleFilled style={{ width: 12 }} />}
                                        onClick={() => {
                                            handleApiUpdatePromotion({
                                                id: record.id,
                                                remark: record.remark,
                                                state: PromotionState.REJECTED,
                                            })
                                        }}
                                    >
                                        {t("common.reject")}
                                    </Button>
                                </div>
                            )}
                            {filterMeta.promotionState === PromotionState.APPROVE && (
                                <div>
                                    <Button
                                        type='primary'
                                        htmlType='button'
                                        size='small'
                                        disabled={!record.isDataChanged}
                                        icon={<CheckCircleFilled style={{ width: 12 }} />}
                                        className='bg-[#67c23a] hover:bg-[#95d475]'
                                        onClick={() => {
                                            handleApiUpdatePromotion({
                                                id: record.id,
                                                remark: record.remark,
                                            })
                                        }}
                                    >
                                        {t("common.complete")}
                                    </Button>
                                </div>
                            )}
                            {filterMeta.promotionState === PromotionState.COMPLETED && (
                                <div>
                                    <Button
                                        type='primary'
                                        htmlType='button'
                                        size='small'
                                        disabled={!record.isDataChanged}
                                        icon={<CheckCircleFilled style={{ width: 12 }} />}
                                        className='bg-[#67c23a] hover:bg-[#95d475]'
                                        onClick={() => {
                                            handleApiUpdatePromotion({
                                                id: record.id,
                                                remark: record.remark,
                                            })
                                        }}
                                    >
                                        {t("common.save")}
                                    </Button>
                                </div>
                            )}
                        </>
                    )
                },
                width: 270,
            },
        ],
        [handleChangeData, handleApiUpdatePromotion, filterMeta.promotionState, t]
    )
    const mergedColumns = useMemo<typeof fixedColumns>(() => {
        return fixedColumns.map((col) => ({ ...col, onCell: undefined }))
    }, [fixedColumns])
    const dropdownItems = [
        {
            key: "approve-all",
            label: "Approve all waiting applications",
        },
        {
            key: "reject-all",
            label: "Reject all waiting applications",
        },
    ]

    const handleSearchApplicationReview = useCallback(
        async (meta?: { page: number; take: number }) => {
            const { page, take } = meta || { page: 1, take: 10 }
            const requestParams: {
                take: number
                page: number
                promotionType?: string
                requestTimeFrom: string
                requestTimeTo: string
                promotionCode: string
                promotionState: PromotionState
                username?: string
                agentName?: string
                applyIp?: string
            } = {
                take,
                page,
                requestTimeFrom: moment(filterMeta.startDate).format(),
                requestTimeTo: moment(filterMeta.endDate).format(),
                promotionCode: filterMeta.code,
                promotionState: filterMeta.promotionState,
                applyIp: filterMeta.applyIp,
            }

            if (filterMeta.promotionType.length > 0 && filterMeta.promotionType[0] !== "*") {
                requestParams.promotionType = filterMeta.promotionType.join(",")
            }

            if (filterMeta.sortKey === "username") {
                requestParams.username = filterMeta.username
            } else {
                requestParams.agentName = filterMeta.username
            }

            setIsLoading(true)
            try {
                const res = await getPromotionVerify(requestParams)
                setData(res.data.result)
                setFilterMeta((current) => ({
                    ...current,
                    totalItem: res.data.meta.itemCount,
                }))
            } catch (error) {
            } finally {
                setIsLoading(false)
            }
        },
        [filterMeta]
    )

    const handleChangeTabs = (activeKey: string) => {
        setFilterMeta((currentState) => ({
            ...currentState,
            promotionState: activeKey as PromotionState,
        }))
        // call api here
        handleSearchApplicationReview()
    }

    useEffect(() => {
        if (isAutoRefresh) {
            timerRef.current = setInterval(() => {
                handleSearchApplicationReview()
            }, 5000)
        } else {
            if (timerRef.current) {
                clearInterval(timerRef.current)
            }
        }
        return () => {
            if (timerRef.current) {
                clearInterval(timerRef.current)
            }
        }
    }, [isAutoRefresh, handleSearchApplicationReview])

    const TimeOptions = [
        {
            label: "Processing time",
            value: TimeOption.PROCESSING_TIME,
        },
        {
            label: t("content.promotions.management.requestDate"),
            value: TimeOption.REQUEST_TIME,
        },
    ]

    useEffect(() => {
        handleSearchApplicationReview()
    }, [])

    return (
        <div className='promotions-create__container'>
            <div id='promotion-list-id' className='production-content'>
                <div className='promotions-container'>
                    <div className='header'>
                        <div className='header__container'>
                            <div className='header__item'>
                                {t("content.promotions.management.applicationReview")}
                                <Tooltip
                                    placement='right'
                                    title={`Reviewing player's promotion`}
                                    className='ml-2'
                                >
                                    <div className='flex items-center justify-center'>
                                        <QuestionSvg />
                                    </div>
                                </Tooltip>
                            </div>
                        </div>
                        <div className='flex justify-center items-center bg-[#dee3ec] p-3'>
                            <div>
                                <Button>
                                    <Image
                                        width={20}
                                        src='/excel_icon.png'
                                        style={{
                                            marginBottom: 4,
                                            paddingRight: 2,
                                        }}
                                    />
                                    <CSVLink
                                        filename={t(
                                            "content.promotions.management.applicationReview"
                                        )}
                                        data={data}
                                        className='btn btn-primary'
                                    >
                                        <span> Export</span>
                                    </CSVLink>
                                </Button>
                            </div>
                        </div>
                    </div>
                    <div className='content'>
                        <div className='relative flex items-center justify-between border-b-2 border-solid border-[#2a437c] mb-5'>
                            <Tabs
                                rootClassName='promotion-verify-tabs'
                                defaultActiveKey='1'
                                items={items}
                                onChange={handleChangeTabs}
                                className='border-b-4 border-indigo-500'
                                indicator={{
                                    size: 0,
                                }}
                            />
                            <div className='absolute bottom-2 right-0 flex gap-2 items-center'>
                                <Checkbox
                                    onChange={(e) => {
                                        setIsAutoRefresh(e.target.checked)
                                    }}
                                    checked={isAutoRefresh}
                                >
                                    {t("content.promotions.management.autoRefresh")}
                                </Checkbox>
                                <Button
                                    type='primary'
                                    htmlType='button'
                                    icon={<ReloadOutlined />}
                                    onClick={() => {
                                        handleSearchApplicationReview()
                                    }}
                                >
                                    {t("content.promotions.management.refresh")}
                                </Button>
                                {filterMeta.promotionState === PromotionState.PENDING && (
                                    <Dropdown
                                        menu={{
                                            items: dropdownItems,
                                            onClick: (event) => {
                                                if (event.key === "approve-all") {
                                                    handleApiUpdatePromotion({
                                                        state: PromotionState.APPROVE,
                                                    })
                                                } else if (event.key === "reject-all") {
                                                    handleApiUpdatePromotion({
                                                        state: PromotionState.REJECTED,
                                                    })
                                                }
                                            },
                                        }}
                                        placement='bottomLeft'
                                        arrow
                                    >
                                        <div className='w-6 h-6 rounded-md border-2 border-solid border-gray-400 flex items-center justify-center'>
                                            <EllipsisOutlined />
                                        </div>
                                    </Dropdown>
                                )}
                            </div>
                        </div>
                        {filterMeta.promotionState !== PromotionState.PROMOTION_TRANSFER && (
                            <>
                                <div className='flex gap-2'>
                                    <AccountInputFilter
                                        sortKey={filterMeta.sortKey}
                                        username={filterMeta.username}
                                        onChange={(value) => {
                                            setFilterMeta((current) => ({
                                                ...current,
                                                sortKey: value.sortKey,
                                                username: value.username,
                                            }))
                                        }}
                                    />
                                    {/* Requested Date */}
                                    <div className='flex gap-2 items-center '>
                                        <div className='flex items-center gap-1'>
                                            <div className='w-[115px]'>
                                                {t("content.promotions.management.requestedDate")}
                                            </div>
                                            <Tooltip
                                                placement='left'
                                                title={t(
                                                    "content.promotions.management.tooltip.requestedDate"
                                                )}
                                            >
                                                <div className='flex items-center justify-center'>
                                                    <QuestionSvg />
                                                </div>
                                            </Tooltip>
                                        </div>
                                        <div className='w-[250px] flex-1'>
                                            <CustomDateRangePicker
                                                onApply={(event: any, picker: any) => {
                                                    const newStartDate = moment(
                                                        picker.startDate
                                                    ).toDate()
                                                    const newEndDate = moment(
                                                        picker.endDate
                                                    ).toDate()

                                                    setFilterMeta((current) => ({
                                                        ...current,
                                                        startDate: newStartDate,
                                                        endDate: newEndDate,
                                                    }))
                                                }}
                                                onCancel={() => {}}
                                                containerStyles={{
                                                    width: "214px",
                                                    padding: "3px 0px",
                                                    paddingLeft: "4px",
                                                    borderRadius: "6px",
                                                    border: "1px solid #d9d9d9",
                                                }}
                                                fromDate={filterMeta.startDate}
                                                toDate={filterMeta.endDate}
                                                resultToString
                                                opens={"center"}
                                                readOnly
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='flex my-3 mx-2'>
                                    <div className='flex gap-4 overflow-x-scroll'>
                                        {/* Promotion Type */}
                                        <PromotionTypeSelect
                                            value={filterMeta.promotionType}
                                            onChange={(value) => {
                                                setFilterMeta({
                                                    ...filterMeta,
                                                    promotionType: value,
                                                })
                                            }}
                                        />
                                        {/* {t("content.promotions.create.form.field.promotionCode")} */}
                                        <div className='flex gap-2 items-center '>
                                            <span>
                                                {t(
                                                    "content.promotions.create.form.field.promotionCode"
                                                )}
                                            </span>
                                            <Input
                                                placeholder={t(
                                                    "content.promotions.management.codePlaceholder"
                                                )}
                                                className='flex-1 w-[180px]'
                                                onChange={(event) => {
                                                    setFilterMeta((current) => ({
                                                        ...current,
                                                        code: event.target.value,
                                                    }))
                                                }}
                                            />
                                        </div>
                                        {/* Apply IP */}
                                        <div className='flex gap-2 items-center '>
                                            <span>
                                                {t("content.promotions.management.applyIP")}
                                            </span>
                                            <Input
                                                placeholder={t(
                                                    "content.promotions.management.ipPlaceholder"
                                                )}
                                                className='flex-1 w-[180px]'
                                                onChange={(event) => {
                                                    setFilterMeta((current) => ({
                                                        ...current,
                                                        applyIp: event.target.value,
                                                    }))
                                                }}
                                            />
                                        </div>
                                        <Button
                                            type='primary'
                                            htmlType='button'
                                            onClick={() => {
                                                handleSearchApplicationReview()
                                            }}
                                        >
                                            {t("common.search")}
                                        </Button>
                                    </div>
                                </div>
                            </>
                        )}
                        {/* TABLE */}
                        {filterMeta.promotionState !== PromotionState.PROMOTION_TRANSFER && (
                            <>
                                <Table
                                    id='table-data'
                                    bordered
                                    columns={mergedColumns}
                                    scroll={{ x: 2000, y: 400 }}
                                    rowKey='id'
                                    dataSource={data}
                                    pagination={false}
                                    expandable={{
                                        expandedRowRender: (record) => (
                                            <div className='flex w-full bg-white'>
                                                <div className='w-1/3 p-4'>
                                                    <ExpandUserInfo title='Player Info' />
                                                </div>
                                                <div className='w-1/3 p-4 border-x-2 border-[#e5e7eb] border-solid'>
                                                    <ExpandUserInfo title="Transaction's Summary" />
                                                </div>
                                                <div className='w-1/2 p-4'>
                                                    <div className='font-bold text-[#303133]'>
                                                        Multi-accounts Checking
                                                    </div>
                                                    <div>
                                                        <Tag className='cursor-pointer'>
                                                            Register IP: 0
                                                        </Tag>
                                                    </div>
                                                </div>
                                            </div>
                                        ),
                                    }}
                                    loading={isLoading}
                                />
                                <div className='mt-4'>
                                    <Pagination
                                        defaultCurrent={1}
                                        total={filterMeta.totalItem}
                                        showQuickJumper
                                        showSizeChanger
                                        defaultPageSize={25}
                                        pageSizeOptions={["25", "50", "100"]}
                                        onChange={(page) => {
                                            setFilterMeta((current) => ({
                                                ...current,
                                                page,
                                            }))
                                            handleSearchApplicationReview({
                                                take: filterMeta.take,
                                                page,
                                            })
                                        }}
                                        onShowSizeChange={(currentPage, size) => {
                                            setFilterMeta((current) => ({
                                                ...current,
                                                take: size,
                                                page: currentPage,
                                            }))
                                            handleSearchApplicationReview({
                                                page: currentPage,
                                                take: size,
                                            })
                                        }}
                                        style={{ fontSize: 13 }}
                                    />
                                </div>
                            </>
                        )}

                        {filterMeta.promotionState === PromotionState.PROMOTION_TRANSFER && (
                            <div className='flex gap-2 justify-end'>
                                <CustomSelect
                                    style={{
                                        width: 220,
                                    }}
                                    options={TimeOptions}
                                    value={promotionTransferFilter.type}
                                    onChange={(value) => {
                                        setPromotionTransferFilter((current) => ({
                                            ...current,
                                            type: value,
                                        }))
                                    }}
                                />
                                {/* Requested Date */}
                                <div className='flex gap-2 items-center '>
                                    <div className='w-[250px] flex-1'>
                                        <CustomDateRangePicker
                                            onApply={(event: any, picker: any) => {
                                                const newStartDate = moment(
                                                    picker.startDate
                                                ).toDate()
                                                const newEndDate = moment(picker.endDate).toDate()

                                                setPromotionTransferFilter((current) => ({
                                                    ...current,
                                                    startDate: newStartDate,
                                                    endDate: newEndDate,
                                                }))
                                            }}
                                            onCancel={() => {}}
                                            containerStyles={{
                                                width: "214px",
                                                padding: "3px 0px",
                                                paddingLeft: "4px",
                                                borderRadius: "6px",
                                                border: "1px solid #d9d9d9",
                                            }}
                                            fromDate={promotionTransferFilter.startDate}
                                            toDate={promotionTransferFilter.endDate}
                                            resultToString
                                            opens={"center"}
                                            readOnly
                                        />
                                    </div>
                                </div>
                                {/* Username */}
                                <div className='flex gap-2 items-center '>
                                    <span>Username</span>
                                    <Input
                                        placeholder='Select the username ...'
                                        className='flex-1 w-[180px]'
                                        onChange={(event) => {
                                            setPromotionTransferFilter((current) => ({
                                                ...current,
                                                username: event.target.value,
                                            }))
                                        }}
                                    />
                                </div>
                                <Button
                                    type='primary'
                                    htmlType='button'
                                    onClick={() => {
                                        // handleSearchApplicationReview();
                                    }}
                                >
                                    {t("common.search")}
                                </Button>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    )
}

const ExpandUserInfo = ({ title }: { title: string }) => {
    return (
        <>
            <div className='font-bold text-[#303133]'>{title}</div>
            <div className='flex justify-between'>
                <div>Register Time</div>
                <div>2023-08-23 05:04:19 AM (-4)</div>
            </div>
        </>
    )
}

export default ApplicationVerify
