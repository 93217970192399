import { Button, Input, Table, TableProps } from "antd"
import moment from "moment"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { CustomDateRangePicker } from "../../../../common/components/date-range-picker"
import { PromotionState, currentDate } from "../../constants"
import { getPromotionRecords } from "../../services"
import { IApplicationRecord } from "./successful"

const ApplicationFailed = ({ isActive }: { isActive: boolean }) => {
    const [isLoading, setIsLoading] = useState(false)
    const [data, setData] = useState<IApplicationRecord[]>([])
    const [filterMeta, setFilterMeta] = useState({
        totalItem: 0,
        startDate: currentDate,
        endDate: currentDate,
        username: "",
        errorID: "",
    })
    const { t } = useTranslation()
    const fixedColumns: TableProps<IApplicationRecord>["columns"] = useMemo(
        () => [
            {
                title: t("content.promotions.management.errorID"),
                width: 160,
                dataIndex: "id",
            },
            {
                title: t("content.promotions.management.requestDate"),
                width: 250,
                dataIndex: "createdAt",
                render: (createdAt) => {
                    return <p>{moment(createdAt).format("YYYY-MM-DD HH:mm:ss")}</p>
                },
            },
            {
                title: t("content.promotions.management.username"),
                width: 250,
                dataIndex: "user",
                render: (_, record) => {
                    return <p>{record.user.username}</p>
                },
            },
            {
                title: t("content.promotions.management.actionType"),
                width: 150,
                render: () => {
                    return <p>{t("content.promotions.management.apply")}</p>
                },
            },
            {
                title: (
                    <div className='mx-auto w-fit'>
                        <p className='w-fit'>
                            {t("content.promotions.create.form.field.promotionTitle")}
                        </p>
                        <p className='w-fit'>
                            {t("content.promotions.create.form.field.promotionCode")}
                        </p>
                    </div>
                ),
                width: 250,
                render: (_, record) => {
                    return (
                        <div>
                            <p>{record.promotion.title}</p>
                            <p>{record.promotion.code}</p>
                        </div>
                    )
                },
            },
            {
                title: t("content.promotions.management.failReason"),
                render: (_, record) => {
                    return <p>{record.remark}</p>
                },
            },
        ],
        [t]
    )
    const mergedColumns = useMemo<typeof fixedColumns>(() => {
        return fixedColumns.map((col) => ({ ...col, onCell: undefined }))
    }, [fixedColumns])

    const handleSearchPromotionRecord = useCallback(
        async (meta?: { page: number; take: number }) => {
            const requestParams: {
                code?: string
                type?: string
                applyIp?: string
                applyFP?: string
                username?: string
                requestTimeTo?: string
                requestTimeFrom?: string
                take: number
                page: number
                promotionState: PromotionState
                id?: string
            } = {
                take: meta?.take ?? 25,
                page: meta?.page ?? 1,
                promotionState: PromotionState.REJECTED,
                username: filterMeta.username,
                id: filterMeta.errorID,
                requestTimeFrom: moment(filterMeta.startDate).format(),
                requestTimeTo: moment(filterMeta.endDate).format(),
            }

            setIsLoading(true)
            try {
                const res = await getPromotionRecords(requestParams)
                setData(res.data.result)
            } catch (error) {
            } finally {
                setIsLoading(false)
            }
        },
        [filterMeta]
    )

    useEffect(() => {
        if (isActive) {
            handleSearchPromotionRecord()
        } else {
            setData([])
        }
    }, [isActive])

    return (
        <div>
            <div className='flex gap-4 my-3 mx-2 overflow-x-scroll'>
                {/* Error ID */}
                <div className='flex gap-2 items-center '>
                    <span>{t("content.promotions.management.errorID")}</span>
                    <Input
                        placeholder={t("content.promotions.management.codePlaceholder")}
                        className='flex-1 w-[180px]'
                        onChange={(event) => {
                            setFilterMeta((current) => ({
                                ...current,
                                errorID: event.target.value,
                            }))
                        }}
                    />
                </div>
                {/* Username */}
                <div className='flex gap-2 items-center '>
                    <span>{t("content.promotions.management.username")}</span>
                    <Input
                        placeholder={t("content.promotions.management.usernamePlaceholder")}
                        className='flex-1 w-[180px]'
                        onChange={(event) => {
                            setFilterMeta((current) => ({
                                ...current,
                                username: event.target.value,
                            }))
                        }}
                    />
                </div>
                {/* Requested Date */}
                <div className='flex gap-2 items-center '>
                    <div className="w-[115px]">{t("content.promotions.management.requestedDate")}</div>
                    <CustomDateRangePicker
                        onApply={(event: any, picker: any) => {
                            const newStartDate = moment(picker.startDate).toDate()
                            const newEndDate = moment(picker.endDate).toDate()

                            setFilterMeta((current) => ({
                                ...current,
                                startDate: newStartDate,
                                endDate: newEndDate,
                            }))
                        }}
                        onCancel={() => {}}
                        containerStyles={{
                            width: "214px",
                            padding: "3px 0px",
                            paddingLeft: "4px",
                            borderRadius: "6px",
                            border: "1px solid #d9d9d9",
                        }}
                        fromDate={filterMeta.startDate}
                        toDate={filterMeta.endDate}
                        resultToString
                        opens={"center"}
                        readOnly
                    />
                </div>
                <Button
                    type='primary'
                    htmlType='button'
                    onClick={() => {
                        handleSearchPromotionRecord()
                    }}
                >
                    {t("common.submit")}
                </Button>
            </div>
            <Table
                loading={isLoading}
                id='table-data'
                bordered
                columns={mergedColumns}
                scroll={{ x: 2000, y: 400 }}
                rowKey='id'
                dataSource={data}
                pagination={false}
            />
        </div>
    )
}

export default ApplicationFailed
