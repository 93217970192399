import { Tooltip, Table, Button, Pagination } from "antd";
import type { TableProps } from "antd";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import QuestionSvg from "../components/icons/question-svg";
import EditSvg from "../components/icons/edit-svg";
import { useTranslation } from "react-i18next";
import { getPromotionList } from "../services";
import i18next from "i18next";
import { PromotionStatus, PromotionType } from "../constants";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { paths } from "../../../routes/path";
import {
    DailyDepositBonus,
    DepositBonus,
    FirstDepositBonus,
    RebateOnDepositAndWithdrawal,
    RebateOnLoseOnly,
    RebateOnTurnover,
    RebateOnWinLost,
    RegisterBonus,
} from "../create/containers";
import CustomSelect from "../components/custom-select";
import { useOptions, usePromotion } from "../hooks";
import "./styles.scss";
import { LanguageOption } from "../types";

export interface RecordType {
    id: string;
    title: string;
    code: string;
    type: string;
    currency: string;
    status: string;
    turnOverRollOverTimes: number;
    bonusPercentage: string;
    bonusAmount: string;
    totalBudget: number;
    startDate: string;
    endDate: string;
    displayOrder: number;
    createdBy: string;
    createdAt: string;
    updatedBy: string;
    updatedAt: string;
}

const PromotionList = () => {
    const [data, setData] = useState<RecordType[]>([]);
    const [isLoading, setIsLoading] = useState(false);
    const [filterMeta, setFilterMeta] = useState({
        currency: ["*"],
        language: ["*"],
        status: ["*"],
        totalItem: 500,
        take: 20,
        page: 1,
    });
    const [langOptions, setLangOptions] = useState<LanguageOption[]>([]);
    const [firstDepositBonusData, setFirstDepositBonusData] = useState({});
    const [registerBonusData, setRegisterBonusData] = useState({});
    const [dailyDepositBonusData, setDailyDepositBonusData] = useState({});
    const [depositBonusData, setDepositBonusData] = useState({});
    const [rebateOnTurnoverData, setRebateOnTurnoverData] = useState({});
    const [rebateOnLoseOnlyData, setRebateOnLoseOnlyData] = useState({});
    const [rebateOnWinLostData, setRebateOnWinLostData] = useState({});
    const [rebateOnDepositWithdrawalData, setRebateOnDepositWithdrawalData] =
        useState({});
    const [isRegisterBonusOpen, setIsRegisterBonusOpen] = useState(false);
    const [isFirstDepositBonusOpen, setIsFirstDepositBonusOpen] =
        useState(false);
    const [isDailyDepositBonusOpen, setIsDailyDepositBonusOpen] =
        useState(false);
    const [isDepositBonusOpen, setIsDepositBonusOpen] = useState(false);
    const [isRebateOnTurnoverOpen, setIsRebateOnTurnoverOpen] = useState(false);
    const [isRebateOnLoseOnlyOpen, setIsRebateOnLoseOnlyOpen] = useState(false);
    const [isRebateOnWinLostOpen, setIsRebateOnWinLostOpen] = useState(false);
    const [
        isRebateOnDepositWithdrawalOpen,
        setIsRebateOnDepositWithdrawalOpen,
    ] = useState(false);
    const { currencyList, languageList } = usePromotion();
    const { PromotionStatusLabel, StatusOptions } = useOptions();
    const { t } = useTranslation();

    const handleEdit = useCallback(
        (record: RecordType) => (event: React.MouseEvent) => {
            switch (record.type) {
                case PromotionType.REGISTER_BONUS:
                    setIsRegisterBonusOpen(true);
                    setRegisterBonusData(record);
                    break;
                case PromotionType.FIRST_DEPOSIT_BONUS:
                    setIsFirstDepositBonusOpen(true);
                    setFirstDepositBonusData(record);
                    break;
                case PromotionType.DAILY_DEPOSIT_BONUS:
                    setIsDailyDepositBonusOpen(true);
                    setDailyDepositBonusData(record);
                    break;
                case PromotionType.DEPOSIT_BONUS:
                    setIsDepositBonusOpen(true);
                    setDepositBonusData(record);
                    break;
                case PromotionType.REBASE_ON_TURNOVER:
                    setIsRebateOnTurnoverOpen(true);
                    setRebateOnTurnoverData(record);
                    break;
                case PromotionType.REBASE_ON_TURNOVER_LOSE_ONLY:
                    setIsRebateOnLoseOnlyOpen(true);
                    setRebateOnLoseOnlyData(record);
                    break;
                case PromotionType.REBASE_ON_WIN_LOST:
                    setIsRebateOnWinLostOpen(true);
                    setRebateOnWinLostData(record);
                    break;
                case PromotionType.REBASE_ON_DEPOSIT_AND_WITHDRAW:
                    setIsRebateOnDepositWithdrawalOpen(true);
                    setRebateOnDepositWithdrawalData(record);
                    break;
                default:
                    break;
            }
        },
        []
    );
    const fixedColumns: TableProps<RecordType>["columns"] = useMemo(
        () => [
            {
                title: "#",
                width: 60,
                render: (_, __, index) => {
                    return <span>{index + 1}</span>;
                },
            },
            {
                title: (
                    <div>
                        <div>
                            {t(
                                "content.promotions.create.form.field.promotionTitle"
                            )}
                        </div>
                        <div>
                            {t(
                                "content.promotions.create.form.field.promotionCode"
                            )}
                        </div>
                    </div>
                ),
                width: 400,
                render: (_, record) => {
                    return (
                        <div>
                            <p>{record.title}</p>
                            <p>{record.code}</p>
                        </div>
                    );
                },
            },
            {
                title: t("content.promotions.create.form.field.promotionType"),
                dataIndex: "type",
                width: 240,
                render: (promotionType) => (
                    <span className="capitalize">
                        {i18next.t(
                            `content.promotions.create.type.${promotionType}.title`
                        )}
                    </span>
                ),
            },
            {
                title: t("content.promotions.create.form.field.currency"),
                dataIndex: "currency",
                width: 100,
                render: (currency) => (
                    <span className="font-bold text-[#596d86]">{currency}</span>
                ),
                align: "center",
            },
            {
                title: (
                    <div className="flex items-center gap-1">
                        <span>
                            {t("content.promotions.create.form.field.status")}
                        </span>
                        <Tooltip
                            placement="left"
                            title={t(
                                "content.promotions.management.tooltip.status"
                            )}
                        >
                            <div className="flex items-center justify-center">
                                <QuestionSvg />
                            </div>
                        </Tooltip>
                    </div>
                ),
                dataIndex: "status",
                render: (status: PromotionStatus) => (
                    <span className="font-bold text-[#00c781]">
                        {PromotionStatusLabel[status]}
                    </span>
                ),
                width: 140,
                align: "center",
            },
            {
                title: (
                    <div className="flex flex-col items-end">
                        <div className="flex items-center gap-1">
                            <span>
                                {t(
                                    "content.promotions.create.form.field.rollover"
                                )}
                            </span>
                            <Tooltip
                                placement="left"
                                title={t(
                                    "content.promotions.management.tooltip.rollover"
                                )}
                            >
                                <div className="flex items-center justify-center">
                                    <QuestionSvg />
                                </div>
                            </Tooltip>
                        </div>
                        <div className="flex items-center gap-1">
                            <span>
                                {t(
                                    "content.promotions.create.form.field.percentage"
                                )}
                            </span>
                            <Tooltip
                                placement="left"
                                title={t(
                                    "content.promotions.management.tooltip.percentage"
                                )}
                            >
                                <div className="flex items-center justify-center">
                                    <QuestionSvg />
                                </div>
                            </Tooltip>
                        </div>
                    </div>
                ),
                render: (_, record) => {
                    return (
                        <div>
                            <p>{record.turnOverRollOverTimes ?? 0} times</p>
                            <p>
                                {record.bonusPercentage
                                    ? `${record.bonusPercentage}%`
                                    : "-"}
                            </p>
                        </div>
                    );
                },
                align: "right",
                width: 140,
            },
            {
                title: (
                    <div className="flex flex-col items-end">
                        <div className="flex items-center gap-1">
                            <span>
                                {t(
                                    "content.promotions.create.form.field.bonusAmount"
                                )}
                            </span>
                            <Tooltip
                                placement="left"
                                title={t(
                                    "content.promotions.management.tooltip.bonusAmount"
                                )}
                            >
                                <div className="flex items-center justify-center">
                                    <QuestionSvg />
                                </div>
                            </Tooltip>
                        </div>
                        <div className="flex items-center gap-1">
                            <span>
                                {t(
                                    "content.promotions.create.form.field.maxBonusBudget"
                                )}
                            </span>
                            <Tooltip
                                placement="left"
                                title={t(
                                    "content.promotions.management.tooltip.maxBonusBudget"
                                )}
                            >
                                <div className="flex items-center justify-center">
                                    <QuestionSvg />
                                </div>
                            </Tooltip>
                        </div>
                    </div>
                ),
                render: (_, record) => {
                    return (
                        <div>
                            <p>{record.bonusAmount || "-"}</p>
                            <p>
                                {record.totalBudget === -1
                                    ? t(
                                          "content.promotions.create.state.noLimit"
                                      )
                                    : record.totalBudget}
                            </p>
                        </div>
                    );
                },
                align: "right",
                width: 190,
            },
            {
                title: (
                    <div>
                        <div>
                            {t(
                                "content.promotions.create.form.field.startDate"
                            )}
                        </div>
                        <div>
                            {t("content.promotions.create.form.field.endDate")}
                        </div>
                    </div>
                ),
                render: (_, record) => {
                    return (
                        <div>
                            <p>
                                {moment(record.startDate).format("YYYY-MM-DD")}
                            </p>
                            <p>{moment(record.endDate).format("YYYY-MM-DD")}</p>
                        </div>
                    );
                },
                align: "center",
                width: 160,
            },
            {
                title: t("content.promotions.create.form.field.displayOrder"),
                dataIndex: "displayOrder",
                align: "center",
                width: 80,
            },
            {
                title: (
                    <div>
                        <div className="">
                            {t(
                                "content.promotions.create.form.field.modifiedBy"
                            )}
                        </div>
                        <div className="">
                            {t(
                                "content.promotions.create.form.field.modifiedTime"
                            )}
                        </div>
                    </div>
                ),
                render: (_, record) => {
                    return (
                        <div>
                            <p>{record.updatedBy || record.createdBy}</p>
                            <p>
                                {moment(
                                    record.updatedAt || record.createdAt
                                ).format("YYYY-MM-DD")}
                            </p>
                        </div>
                    );
                },
                width: 160,
            },
            {
                title: (
                    <div className="flex items-center justify-center gap-1">
                        <span>
                            {t("content.promotions.create.form.field.action")}
                        </span>
                        <Tooltip
                            placement="left"
                            title={t(
                                "content.promotions.management.tooltip.action"
                            )}
                        >
                            <div className="flex items-center justify-center">
                                <QuestionSvg />
                            </div>
                        </Tooltip>
                    </div>
                ),
                align: "center",
                fixed: "right",
                render: (_, record) => (
                    <div onClick={handleEdit(record)}>
                        <EditSvg className="hover:text-[#409eff] cursor-pointer" />
                    </div>
                ),
                width: 110,
            },
        ],
        [handleEdit, t]
    );
    const mergedColumns = useMemo<typeof fixedColumns>(() => {
        return fixedColumns.map((col) => ({ ...col, onCell: undefined }));
    }, [fixedColumns]);
    const tblRef: Parameters<typeof Table>[0]["ref"] = useRef(null);
    const navigate = useNavigate();

    const navigateToCreate = () => {
        navigate(paths.PROMOTION.CREATE);
    };

    const handleSearchPromotionList = async (meta?: {
        page: number;
        take: number;
    }) => {
        const requestParams: {
            currency?: string;
            status?: string;
            language?: string;
            take: number;
            page: number;
        } = {
            take: meta?.take ?? 25,
            page: meta?.page ?? 1,
        };
        if (
            filterMeta.currency.length > 0 &&
            !filterMeta.currency.includes("*")
        ) {
            requestParams.currency = filterMeta.currency.join(",");
        }
        if (filterMeta.status.length > 0 && !filterMeta.status.includes("*")) {
            requestParams.status = filterMeta.status.join(",");
        }
        if (
            filterMeta.language.length > 0 &&
            !filterMeta.language.includes("*")
        ) {
            requestParams.language = filterMeta.language.join(",");
        }
        setIsLoading(true);
        try {
            const res = await getPromotionList(requestParams);
            setFilterMeta((current) => ({
                ...current,
                totalItem: res.data.meta.itemCount,
            }));
            setData(res.data.result);
        } catch (error) {
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        const fetchPromotionList = async () => {
            setIsLoading(true);
            try {
                const res = await getPromotionList();
                setData(res.data.result);
                setFilterMeta((current) => ({
                    ...current,
                    totalItem: res.data.meta.itemCount,
                }));
            } catch (error) {
            } finally {
                setIsLoading(false);
            }
        };
        fetchPromotionList();
    }, []);

    useEffect(() => {
        if (languageList.length > 0) {
            setLangOptions(
                languageList.map((lang: any) => ({
                    label: lang.language,
                    key: lang.iso,
                    disabled: lang.iso === "en",
                }))
            );
        }
    }, [languageList]);

    return (
        <div className="promotions-create__container">
            <div id="promotion-list-id" className="production-content">
                <div className="promotions-container">
                    <div className="header">
                        <div className="header__container">
                            <div className="header__item">
                                {t("content.promotions.list.title")}
                            </div>
                        </div>
                        <div className="flex justify-center items-center bg-[#dee3ec] p-3">
                            <Button
                                size="small"
                                type="primary"
                                onClick={navigateToCreate}
                            >
                                {t("common.addNew")}
                            </Button>
                        </div>
                    </div>
                    <div className="flex justify-end gap-4 mt-3 mx-2">
                        {/* CURRENCY FILTER */}
                        <div className="flex gap-2 items-center w-56">
                            <span>{t("common.currency")}</span>
                            <CustomSelect
                                className="flex-1"
                                withAllOption
                                mode="multiple"
                                allowClear
                                style={{ width: "100%" }}
                                options={currencyList.map((item: any) => ({
                                    label: item.currency,
                                    value: item.currency,
                                }))}
                                value={filterMeta.currency}
                                onChange={(value) => {
                                    if (!value.length) {
                                        setFilterMeta({
                                            ...filterMeta,
                                            currency: ["*"],
                                        });
                                    } else {
                                        setFilterMeta({
                                            ...filterMeta,
                                            currency: value,
                                        });
                                    }
                                }}
                                maxTagCount="responsive"
                            />
                        </div>
                        {/* STATUS FILTER */}
                        <div className="flex gap-2 items-center w-56">
                            <span>{t("common.status")}</span>
                            <CustomSelect
                                className="flex-1"
                                withAllOption
                                mode="multiple"
                                allowClear
                                style={{ width: "100%" }}
                                options={StatusOptions}
                                value={filterMeta.status}
                                onChange={(value) => {
                                    if (!value.length) {
                                        setFilterMeta({
                                            ...filterMeta,
                                            status: ["*"],
                                        });
                                    } else {
                                        setFilterMeta({
                                            ...filterMeta,
                                            status: value,
                                        });
                                    }
                                }}
                                maxTagCount="responsive"
                            />
                        </div>
                        {/* LANGUAGE FILTER */}
                        <div className="flex gap-2 items-center w-56">
                            <span>{t("common.language")}</span>
                            <CustomSelect
                                className="flex-1"
                                withAllOption
                                mode="multiple"
                                allowClear
                                style={{ width: "100%" }}
                                options={langOptions.map((item) => ({
                                    label: item.label,
                                    value: item.key,
                                }))}
                                value={filterMeta.language}
                                onChange={(value) => {
                                    if (!value.length) {
                                        setFilterMeta({
                                            ...filterMeta,
                                            language: ["*"],
                                        });
                                    } else {
                                        setFilterMeta({
                                            ...filterMeta,
                                            language: value,
                                        });
                                    }
                                }}
                                maxTagCount="responsive"
                            />
                        </div>
                        <Button
                            type="primary"
                            htmlType="button"
                            onClick={() => {
                                handleSearchPromotionList();
                            }}
                        >
                            {t("common.search")}
                        </Button>
                    </div>
                    <div className="content">
                        <Table
                            loading={isLoading}
                            id="table-data"
                            bordered
                            columns={mergedColumns}
                            scroll={{ x: 2000, y: 400 }}
                            rowKey="id"
                            dataSource={data}
                            pagination={false}
                            ref={tblRef}
                        />
                        <div className="mt-4">
                            <Pagination
                                defaultCurrent={1}
                                total={filterMeta.totalItem}
                                showQuickJumper
                                showSizeChanger
                                defaultPageSize={25}
                                pageSizeOptions={["25", "50", "100"]}
                                onChange={(page) => {
                                    setFilterMeta((current) => ({
                                        ...current,
                                        page,
                                    }));
                                    handleSearchPromotionList({
                                        take: filterMeta.take,
                                        page,
                                    });
                                }}
                                onShowSizeChange={(currentPage, size) => {
                                    setFilterMeta((current) => ({
                                        ...current,
                                        take: size,
                                        page: currentPage,
                                    }));
                                    handleSearchPromotionList({
                                        page: currentPage,
                                        take: size,
                                    });
                                }}
                                style={{ fontSize: 13 }}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <FirstDepositBonus
                isOpen={isFirstDepositBonusOpen}
                data={firstDepositBonusData}
                onChangeOpen={setIsFirstDepositBonusOpen}
            />
            <RegisterBonus
                isOpen={isRegisterBonusOpen}
                data={registerBonusData}
                onChangeOpen={setIsRegisterBonusOpen}
            />
            <DailyDepositBonus
                isOpen={isDailyDepositBonusOpen}
                data={dailyDepositBonusData}
                onChangeOpen={setIsDailyDepositBonusOpen}
            />
            <DepositBonus
                isOpen={isDepositBonusOpen}
                data={depositBonusData}
                onChangeOpen={setIsDepositBonusOpen}
            />
            <RebateOnTurnover
                isOpen={isRebateOnTurnoverOpen}
                data={rebateOnTurnoverData}
                onChangeOpen={setIsRebateOnTurnoverOpen}
            />
            <RebateOnLoseOnly
                isOpen={isRebateOnLoseOnlyOpen}
                data={rebateOnLoseOnlyData}
                onChangeOpen={setIsRebateOnLoseOnlyOpen}
            />
            <RebateOnWinLost
                isOpen={isRebateOnWinLostOpen}
                data={rebateOnWinLostData}
                onChangeOpen={setIsRebateOnWinLostOpen}
            />
            <RebateOnDepositAndWithdrawal
                isOpen={isRebateOnDepositWithdrawalOpen}
                data={rebateOnDepositWithdrawalData}
                onChangeOpen={setIsRebateOnDepositWithdrawalOpen}
            />
        </div>
    );
};

export default PromotionList;
