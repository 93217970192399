export enum Permission {
    Dashboard = "Dashboard",
    Report = "Report",
    Security = "Security",
    SubAccount = "SubAccount",
    Transaction = "Transaction",
    GameAndProvider = "GameAndProvider",
    WebAdmin = "WebAdmin",
    Company = "Company",
    PaymentGateway = "PaymentGateway",
    Promotion = "Promotion",
}
