import axios, { AxiosInstance, AxiosRequestConfig, AxiosStatic, Method } from "axios"
import { apis } from "."
import { ACCESS_TOKEN, REFRESH_TOKEN } from "../common/constants/local-storage-keys"
import { getEnvironment } from "../common/helpers/utils"
import { IDataRefreshToken, IDataResponse } from "../interfaces/Global"

declare module "axios" {
    export interface AxiosInstance {
        request<T = any>(config: AxiosRequestConfig): Promise<T>
        get<T = any>(url: string, config?: AxiosRequestConfig): Promise<T>
        delete<T = any>(url: string, config?: AxiosRequestConfig): Promise<T>
        head<T = any>(url: string, config?: AxiosRequestConfig): Promise<T>
        post<T = any>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T>
        put<T = any>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T>
        patch<T = any>(url: string, data?: any, config?: AxiosRequestConfig): Promise<T>
    }
}

export class HttpService {
    private readonly axios: AxiosInstance
    public accessToken: string | undefined = ""
    public refreshToken: string | undefined = ""

    constructor(axios: AxiosStatic, baseUrl?: string) {
        this.axios = axios.create({
            baseURL: baseUrl || process.env.REACT_APP_BE_URL || "http://api.789lava.com",
        })

        this.axios.interceptors.response.use(
            (res) => {
                return res.data
            },
            (error) => {
                return Promise.reject(error)
            }
        )
    }

    setToken(token: string) {
        this.accessToken = token
        this.axios.defaults.headers.common.Authorization = `Bearer ${token}`
        this.axios.defaults.headers.common["Content-Type"] = "application/json"

        if (getEnvironment() === "browser") {
            window.localStorage.setItem(ACCESS_TOKEN, token)
        }
    }

    setRefreshToken(token: string) {
        this.refreshToken = token

        if (getEnvironment() === "browser") {
            window.localStorage.setItem(REFRESH_TOKEN, token)
        }
    }

    async requestRefreshToken(token: string): Promise<IDataRefreshToken | null> {
        try {
            const res = await this.get(apis.auth.refreshToken, {
                refresh: token || this.refreshToken,
            })

            this.setToken(res?.data.accessToken)
            this.setRefreshToken(res?.data.refreshToken)

            return res?.data
        } catch (error) {
            return null
        }
    }

    request<T = any>(method: Method, url: string, data: any) {
        return this.axios.request<T>({
            method: method,
            url: url,
            data: data,
        })
    }

    get<T = any>(url: string, headers?: Record<any, any>, params?: Record<any, any>): Promise<T> {
        return this.axios.get<T>(url, {
            headers,
            params,
        })
    }

    post<T = any>(url: string, data?: any, config?: any): Promise<IDataResponse<T>> {
        return this.axios.post<IDataResponse<T>>(url, data, config)
    }

    put<T = any>(url: string, data: any): Promise<IDataResponse<T>> {
        return this.axios.put<IDataResponse<T>>(url, data)
    }

    patch<T = any>(url: string, data?: any): Promise<IDataResponse<T>> {
        return this.axios.patch<IDataResponse<T>>(url, data)
    }

    delete<T = any>(url: string, config?: AxiosRequestConfig): Promise<IDataResponse<T>> {
        return this.axios.delete(url, config)
    }
}

export const httpService = new HttpService(axios)
export const httpFileService = new HttpService(
    axios,
    process.env.REACT_APP_BE_FILE_URL || "http://acv.file.789lava.com"
)
