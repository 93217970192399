import { Form, Select } from "antd";
import { Controller, Control } from "react-hook-form";
import type { FormItemProps, SelectProps } from "antd";
import { getValidateStatus } from "../../helpers";

const SelectField = ({
    name,
    control,
    formItemProps,
    selectProps,
}: {
    name: string;
    control: Control<any>;
    formItemProps?: FormItemProps;
    selectProps?: SelectProps;
    children?: React.ReactNode;
}) => {
    const { labelAlign = "left" } = formItemProps || {};
    return (
        <Controller
            name={name}
            control={control}
            render={({ field, fieldState }) => {
                const errorMessage = fieldState.error?.message;
                return (
                    <Form.Item
                        labelAlign={labelAlign}
                        validateStatus={getValidateStatus(fieldState, name)}
                        help={errorMessage}
                        className="wrap-label"
                        {...formItemProps}
                    >
                        <Select
                            {...selectProps}
                            {...field}
                            disabled={selectProps?.disabled}
                        />
                    </Form.Item>
                );
            }}
        />
    );
};

export default SelectField;
