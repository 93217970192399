import React, { useEffect, useLayoutEffect, useMemo } from "react"
import {
    Route,
    Routes,
    createSearchParams,
    useLocation,
    useNavigate,
    useSearchParams,
} from "react-router-dom"
import { LightLayout } from "./common/components/layouts/light"
import { IProfile } from "./interfaces/User"
import { IRouteItem, routes } from "./routes"
import { paths } from "./routes/path"

export interface IRouterProps {
    profile?: IProfile
}
export interface IWrapperProps {
    profile?: IProfile
    route: IRouteItem
    children: React.ReactNode
}

const Wrapper: React.FC<IWrapperProps> = ({ children, profile, route }) => {
    const navigate = useNavigate()
    const location = useLocation()
    const [queryParams] = useSearchParams()

    useLayoutEffect(() => {
        // should be go out auth page when user have login
        if (!route.auth && route.path === location.pathname && profile) {
            navigate(queryParams.get("from") || paths.HOME)
        }
        // should be go to auth page when user not yet login
        if (route.auth && route.path === location.pathname && !profile) {
            navigate({
                pathname: paths.LOGIN,
                search: createSearchParams({
                    from: location.pathname,
                }).toString(),
            })
        }
    }, [profile, navigate, location, queryParams, route])

    if (route.auth && !profile) {
        return <></>
    }

    return <>{children}</>
}

export const Router: React.FC<IRouterProps> = ({ profile }) => {
    const location = useLocation()
    const Layout = useMemo(() => {
        const layout = routes.find((r) => r.path === location.pathname)
        if (layout) {
            return layout.layout
        }
        return LightLayout
    }, [location.pathname])

    const navigate = useNavigate()

    useEffect(() => {
        if (!profile) {
            navigate("/auth/login")
        }
    }, [])

    return (
        <>
            <Layout profile={profile}>
                <Routes>
                    {routes.map((route) => {
                        return (
                            <Route
                                key={route.path}
                                path={route.path}
                                Component={() => (
                                    <Wrapper key={route.path} route={route} profile={profile}>
                                        <route.component />
                                    </Wrapper>
                                )}
                            />
                        )
                    })}
                </Routes>
            </Layout>
        </>
    )
}
