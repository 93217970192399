import { Form } from "antd";
import { Controller, Control } from "react-hook-form";
import type { CheckboxOptionType, FormItemProps, RadioGroupProps } from "antd";
import { Radio, Space } from "antd";
import { getValidateStatus } from "../../helpers";

const RadioGroupField = ({
    name,
    control,
    formItemProps,
    radioProps,
}: {
    name: string;
    control: Control<any>;
    formItemProps?: FormItemProps;
    radioProps: RadioGroupProps & { direction?: "vertical" | "horizontal" };
}) => {
    const { labelAlign = "left" } = formItemProps || {};
    const { direction } = radioProps || {};
    return (
        <Controller
            name={name}
            control={control}
            render={({ field, fieldState }) => {
                const errorMessage = fieldState.error?.message;
                return (
                    <Form.Item
                        labelAlign={labelAlign}
                        validateStatus={getValidateStatus(fieldState, name)}
                        help={errorMessage}
                        className="wrap-label"
                        {...formItemProps}
                    >
                        {direction === "vertical" ? (
                            <Radio.Group
                                onChange={field.onChange}
                                value={field.value}
                                disabled={radioProps?.disabled}
                            >
                                <Space direction={direction}>
                                    {(
                                        radioProps.options as CheckboxOptionType[]
                                    )?.map((item: CheckboxOptionType) => (
                                        <Radio
                                            key={item.value as string}
                                            value={item.value}
                                        >
                                            {item.label}
                                        </Radio>
                                    ))}
                                </Space>
                            </Radio.Group>
                        ) : (
                            <Radio.Group
                                onChange={(e) => {
                                    field.onChange(e);
                                    radioProps?.onChange?.(e);
                                }}
                                value={field.value}
                                disabled={radioProps.disabled}
                                options={radioProps.options}
                            />
                        )}
                    </Form.Item>
                );
            }}
        />
    );
};

export default RadioGroupField;
