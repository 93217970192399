import { useTranslation } from "react-i18next";
import "./BettingRecord.scss";

import {
    Button,
    Checkbox,
    Col,
    Dropdown,
    Image,
    Input,
    Pagination,
    PaginationProps,
    Radio,
    Row,
    Select,
    Space,
    Table,
} from "antd";
import { AlignType } from "rc-table/lib/interface";
import { useEffect, useMemo, useState } from "react";

import { CSVLink } from "react-csv";

import moment from "moment";
import { Link } from "react-router-dom";
import { apis } from "../../../apis";

import { Loading3QuartersOutlined } from "@ant-design/icons";
import { httpService } from "../../../apis/httpService";
import { ISort } from "../../../interfaces/Global";
import { paths } from "../../../routes/path";

import { CustomDateRangePicker } from "../../../common/components/date-range-picker";
import { getCurrencyList, getGameProviders } from "../../../common/helpers/utils";
const BettingRecord = () => {
    const { t } = useTranslation();

    const { Option } = Select;
    const columnsTotal = [
        {
            title: t("content.bettingRecord.col.betCount"),
            dataIndex: "betCount",
            key: "betCount",
        },
        {
            title: t("content.bettingRecord.col.turnover"),
            dataIndex: "turnover",
            key: "turnover",
        },

        {
            title: t("content.bettingRecord.col.netTurnover"),
            dataIndex: "netTurnover",
            key: "netTurnover",
        },

        {
            title: t("content.bettingRecord.col.memberWinLost"),
            dataIndex: "memberWinlost",
            key: "memberWinlost",
        },
        {
            title: t("content.bettingRecord.col.memberCommission"),
            dataIndex: "memberCommission",
            key: "memberCommission",
        },
        {
            title: t("content.bettingRecord.col.memberTotal"),
            dataIndex: "memberTotal",
            key: "memberTotal",
        },
        {
            title: t("content.bettingRecord.col.companyWinLost"),
            dataIndex: "companyWinlost",
            key: "companyWinlost",
        },
    ];

    const columnsSource = [
        {
            title: t("content.bettingRecord.col.turnover"),
            dataIndex: "turnover",
            key: "turnover",
        },

        {
            title: t("content.bettingRecord.col.netTurnover"),
            dataIndex: "netTurnover",
            key: "netTurnover",
        },

        {
            title: t("content.bettingRecord.col.memberWinLost"),
            dataIndex: "memberWinlost",
            key: "memberWinlost",
        },
        {
            title: t("content.bettingRecord.col.memberCommission"),
            dataIndex: "memberCommission",
            key: "memberCommission",
        },
        {
            title: t("content.bettingRecord.col.memberTotal"),
            dataIndex: "memberTotal",
            key: "memberTotal",
        },
        {
            title: t("content.bettingRecord.col.companyWinLost"),
            dataIndex: "companyWinlost",
            key: "companyWinlost",
        },
        {
            title: t("content.bettingRecord.col.status"),
            dataIndex: "status",
            key: "status",
        },
    ];

    function makeColumnsTotal() {
        const columns = [
            {
                title: t("content.bettingRecord.col.currency"),
                dataIndex: "currency",
                key: "currency",
                render: (value: any, record: any) => {
                    return <div>{value}</div>;
                },
            },
        ];

        for (const col of columnsTotal) {
            if (col.key !== "total") {
                columns.push({
                    title: col.title,
                    key: col.key,
                    dataIndex: col.dataIndex,
                    render: (value: any, record: any) => {
                        if (parseInt(value) < 0) {
                            return <div style={{ color: "red", alignItems: "right" }}>{value}</div>;
                        } else if (parseInt(value) > 0) {
                            return <div style={{ color: "blue", alignItems: "right" }}>{value}</div>;
                        } else {
                            return <div style={{ color: "#7b7b7b", alignItems: "right" }}>{value}</div>;
                        }
                    },
                });
            }
        }

        return columns;
    }

    function makeColumnsDataSource() {
        const columns = [
            {
                title: "#",
                dataIndex: "index",
                key: "index",
                render: (text: any, record: any, index: number) => index + 1,
            },
            {
                title: t("content.bettingRecord.col.username"),
                dataIndex: "username",
                key: "username",
                render: (value: any, record: any) => {
                    return (
                        <div
                            className="flex"
                            style={{
                                textDecoration: "underline",
                                justifyContent: "flex-start",
                                color: "blue",
                            }}
                        >
                            <Link to={paths.USER_DETAIL.replace(":id", value)}>{value}</Link>
                        </div>
                    );
                },
            },
            {
                title: t("content.bettingRecord.col.winlostDate"),
                dataIndex: "winlostDate",
                key: "winlostDate",
                align: "left" as AlignType,
            },
            {
                title: t("content.bettingRecord.col.orderTime"),
                dataIndex: "orderTime",
                key: "orderTime",
            },
            {
                title: t("content.bettingRecord.col.gameProvider"),
                dataIndex: "gameProvider",
                key: "gameProvider",
            },
            {
                title: t("content.bettingRecord.col.gameName"),
                dataIndex: "gameName",
                key: "gameName",
            },
            {
                title: t("content.bettingRecord.col.refNo"),
                dataIndex: "refNo",
                key: "refNo",
                render: (value: string, record: any) => {
                    return (
                        <div
                            className="flex"
                            style={{
                                textDecoration: "underline",
                                justifyContent: "flex-start",
                                color: "blue",
                                cursor: "pointer",
                            }}
                            onClick={() => onClickRefNo(value)}
                        >
                            <p>{value}</p>
                        </div>
                    );
                },
            },
            {
                title: t("content.bettingRecord.col.currency"),
                dataIndex: "currency",
                key: "currency",
            },
        ];

        for (const col of columnsSource) {
            if (
                col.key !== "index" &&
                col.key !== "username" &&
                col.key !== "winlostDate" &&
                col.key !== "orderTime" &&
                col.key !== "gameProvider" &&
                col.key !== "gameName" &&
                col.key !== "refNo" &&
                col.key !== "currency" &&
                col.key !== "status"
            ) {
                columns.push({
                    title: col.title,
                    key: col.key,
                    dataIndex: col.dataIndex,
                    render: (value: any, record: any) => {
                        if (parseInt(value) < 0) {
                            return <div style={{ color: "red", alignItems: "right" }}>{value}</div>;
                        } else if (parseInt(value) > 0) {
                            return <div style={{ color: "blue", alignItems: "right" }}>{value}</div>;
                        } else {
                            return <div style={{ color: "#7b7b7b", alignItems: "right" }}>{value}</div>;
                        }
                    },
                });
            }
            if (col.key === "status") {
                columns.push({
                    title: col.title,
                    key: col.key,
                    dataIndex: col.dataIndex,
                    render: (value: any, record: any) => {
                        if (value == "Won") {
                            return <div style={{ color: "#67c23a" }}>{value}</div>;
                        } else if (value == "Lost") {
                            return <div style={{ color: "red" }}>{value}</div>;
                        } else {
                            return <div style={{ color: "#7b7b7b" }}>{value}</div>;
                        }
                    },
                });
            }
        }

        return columns;
    }

    //emtydata
    const [emptyText, setEmptyText] = useState(t("content.bettingRecord.col.noData"));
    let locale = {
        emptyText,
    };

    const currentDate = new Date();
    const fromDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - 30);

    const [filterDate, setFilterDate] = useState({
        fromDate: fromDate,
        toDate: currentDate,
    });

    const [totalPage, setTotalPage] = useState(500);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [orderBy, setOrderBy] = useState<ISort | null>();
    const [loadings, setLoadings] = useState(false);
    const [gameProvider, setGameProvider] = useState<string>();
    const [status, setStatus] = useState();
    const [refNo, setRefNo] = useState();
    const [data, setData] = useState([]);
    const [total, setTotal] = useState<any>();
    const [currencyList, setCurrencyList] = useState<any[]>([]);
    const [gameProvidersList, setGameProvidersList] = useState<any[]>([]);
    const [categories, setCategories] = useState<any[]>([]);

    const columnsTotalMade = useMemo(() => makeColumnsTotal(), [total]);
    const columnsSourceMade = useMemo(() => makeColumnsDataSource(), [data]);

    const [username, setUsername] = useState(null);
    const [currency, setCurrency] = useState(null);
    const [transactionType, setTransactionType] = useState<string>();
    const [checkColumn, setCheckColumn] = useState(() => columnsSourceMade.map((item) => item.key));
    //filter columns table data
    const columnsData = columnsSourceMade.filter((i) => checkColumn.includes(i.key));
    const columnsInDropdown = useMemo(() => columnsSourceMade.filter((i) => i.key !== "index"), []);

    const onClickRefNo = async (value: string) => {
        try {
            const res = await httpService.get(`${apis.gameAndProvider.betDetail}?refNo=${value}`);
            if (res.data) {
                window.open(res.data.url, "_blank");
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handleCheckColum = (key: any) => {
        const isChecked = checkColumn.includes(key);
        if (isChecked) {
            const newState = checkColumn.filter((item) => item !== key);
            setCheckColumn(newState);
        } else {
            setCheckColumn([...checkColumn, key]);
        }
    };

    const getWinLostList = async () => {
        const payload = {
            fromDate: filterDate.fromDate.toISOString(),
            toDate: filterDate.toDate.toISOString(),
            username: username ?? username,
            transactionType: transactionType ?? transactionType,
            currency: currency ?? currency,
            gameProvider: gameProvider ?? gameProvider,
            status: status ?? status,
            refNo: refNo ?? refNo,
            category: transactionType ?? transactionType,
            page,
            take: pageSize,
        };
        try {
            const res = await httpService.post(apis.report.bettingRecord, payload);

            setData(res.data.histories);
            setTotal(res.data.total);
            if (res.data?.meta?.pageCount) setTotalPage(res.data?.meta?.pageCount);
        } catch (error) {
            setData([]);
            console.log("error", error);
        }
    };

    useEffect(() => {
        if (!currencyList?.length) {
            const fetchData = async () => {
                try {
                    const [currency, category, provider] = await Promise.all([
                        getCurrencyList(),
                        httpService.get(apis.gameAndProvider.category),
                        getGameProviders(),
                    ]);

                    setCurrencyList(currency.data);
                    setCategories(category.data);
                    setGameProvidersList(provider.data);
                } catch (error) {
                    console.log(error);
                }
            };

            fetchData();
        }
    }, []);

    useEffect(() => {
        getWinLostList();
    }, [page, pageSize, orderBy, username, currency, transactionType, filterDate.fromDate, filterDate.toDate]);

    const onUsernameChange = (e: any) => {
        setUsername(e.target.value);
    };
    const onRefNoChange = (e: any) => {
        setRefNo(e.target.value);
    };

    const onCurrencyChange = (value: any) => {
        setCurrency(value);
    };

    const onChangeStatus = (value: any) => {
        setStatus(value);
    };

    const onTransactionTypeChange = (value: string) => {
        setTransactionType(value);
    };

    const onGameProvidersChange = (value: string) => {
        setGameProvider(value);
    };

    const onChangePage: PaginationProps["onChange"] = (pageNumber: number, pageSize: number) => {
        setPage(pageNumber);
        setPageSize(pageSize);
    };

    const onClickApplyDate = (event: any, picker: any) => {
        const fromDate = moment(picker.startDate).toDate();
        const toDate = moment(picker.endDate).toDate();
        setFilterDate({
            ...filterDate,
            fromDate,
            toDate,
        });
    };

    const clearDate = () => {
        setFilterDate({
            ...filterDate,
            fromDate: fromDate,
            toDate: currentDate,
        });
    };

    const handleSearch = async () => {
        await getWinLostList();
    };

    return (
        <div>
            <Col id="betting-record">
                <Space direction="vertical" size="middle" style={{ width: "100%" }}>
                    <div className="filter">
                        <div className="filter-header">
                            <div className="filter-header__name">{t("content.bettingRecord.title")}</div>
                            <div className="filter-header__export">
                                <Button>
                                    <Image
                                        width={20}
                                        src="/excel_icon.png"
                                        style={{ marginBottom: 4, paddingRight: 2 }}
                                    />
                                    <CSVLink filename={"Expense_Table.csv"} data={data} className="btn btn-primary">
                                        <span> {t("content.bettingRecord.export")}</span>
                                    </CSVLink>
                                </Button>
                            </div>
                        </div>
                        <div className="filter-bar">
                            <Row gutter={9} className="filter">
                                <Col className="date-range-transaction-history">
                                    <span className="filter__name">
                                        {t("content.bettingRecord.statisticalDate.title")}
                                    </span>
                                    {/* <Select
                                        defaultValue='winlostdate'
                                        style={{ width: 160, marginRight: 10 }}
                                        onChange={onTransactionTypeChange}
                                    >
                                        <Option value='winlostdate'>Win/Lost Date</Option>
                                        <Option value='odertime'>Oder Time</Option>
                                    </Select> */}
                                    <CustomDateRangePicker
                                        onApply={onClickApplyDate}
                                        onCancel={clearDate}
                                        containerStyles={{
                                            width: "214px",
                                            padding: "3px 0px",
                                            paddingLeft: "4px",
                                            borderRadius: "6px",
                                            border: "1px solid #d9d9d9",
                                        }}
                                        fromDate={filterDate.fromDate}
                                        toDate={filterDate.toDate}
                                        resultToString
                                        opens={"center"}
                                        readOnly
                                    />
                                </Col>
                                <Col>
                                    <span className="filter__name">
                                        {t("content.bettingRecord.gameProvider.title")}
                                    </span>
                                    <Select
                                        defaultValue={t("content.bettingRecord.gameProvider.allType")}
                                        style={{ width: 120 }}
                                        onChange={onTransactionTypeChange}
                                    >
                                        <Option value="">{t("content.bettingRecord.gameProvider.allType")}</Option>
                                        {categories?.map((item, index) => (
                                            <Option value={item.gameProviderCategory} key={index}>
                                                {item.gameProviderCategory}
                                            </Option>
                                        ))}
                                    </Select>
                                    <Select
                                        defaultValue={t("content.bettingRecord.gameProvider.allProvider")}
                                        style={{ width: 140, marginLeft: 8 }}
                                        onChange={onGameProvidersChange}
                                    >
                                        <Option value="">{t("content.bettingRecord.gameProvider.allProvider")}</Option>
                                        {gameProvidersList?.map((item, index) => (
                                            <Option value={item.gameProviderName} key={index}>
                                                {item.displayName}
                                            </Option>
                                        ))}
                                    </Select>
                                </Col>
                                <Col>
                                    <span className="filter__name">{t("content.bettingRecord.status.title")}</span>
                                    <Select
                                        defaultValue={t("content.bettingRecord.all")}
                                        style={{ width: 120 }}
                                        onChange={onChangeStatus}
                                    >
                                        <Option value="">{t("content.bettingRecord.all")}</Option>
                                        <Option value="Won">{t("content.bettingRecord.status.won")}</Option>
                                        <Option value="Lost">{t("content.bettingRecord.status.lose")}</Option>
                                        <Option value="Draw">{t("content.bettingRecord.status.draw")}</Option>
                                        <Option value="Running">{t("content.bettingRecord.status.running")}</Option>
                                        <Option value="void">{t("content.bettingRecord.status.void")}</Option>
                                        <Option value="Bonus">{t("content.bettingRecord.status.bonus")}</Option>
                                        {/* <Option value='providerPromotion'> */}
                                        <Option value="">{t("content.bettingRecord.status.providerPromotion")}</Option>
                                    </Select>
                                </Col>

                                <Col>
                                    <span className="filter__name">{t("content.bettingRecord.username")}</span>
                                    <Input
                                        placeholder={t("content.bettingRecord.username")}
                                        onChange={onUsernameChange}
                                    />
                                </Col>
                                <Col>
                                    <span className="filter__name">{t("content.bettingRecord.refNo")}</span>
                                    <Input onChange={onRefNoChange} />
                                </Col>

                                <Col>
                                    <span className="filter__name">{t("content.bettingRecord.currency")}</span>
                                    <Select
                                        defaultValue={t("content.bettingRecord.all")}
                                        style={{ width: 120 }}
                                        onChange={onCurrencyChange}
                                    >
                                        <Option value="">{t("content.report.bettingRecord.all")}</Option>
                                        {currencyList?.map((item, index) => (
                                            <Option value={item?.currency} key={index}>
                                                {item?.currency}
                                            </Option>
                                        ))}
                                    </Select>
                                </Col>
                                <Col>
                                    <span className="filter__name">
                                        {t("content.bettingRecord.decimalPointNumbers")}
                                    </span>
                                    <Radio.Group
                                        className="flex align-center"
                                        defaultValue={2}
                                        // onChange={onChangeDecimalPointNumbers}
                                        // value={decimalPointNumbers}
                                    >
                                        <Radio value={2}>2</Radio>
                                        <Radio value={3}>3</Radio>
                                        <Radio value={4}>4</Radio>
                                        <Radio value={5}>5</Radio>
                                        <Radio value={6}>6</Radio>
                                    </Radio.Group>
                                </Col>
                                <Col>
                                    <Button className="submit-btn" type="primary" onClick={handleSearch}>
                                        {loadings && (
                                            <tr className="loading" style={{ marginRight: 20 }}>
                                                <Loading3QuartersOutlined className="loading-icon-btn" />
                                            </tr>
                                        )}
                                        <span>{t("content.bettingRecord.search")}</span>
                                    </Button>
                                </Col>
                            </Row>
                        </div>
                    </div>
                    <div id="betting-record-table">
                        {loadings && <div className="layout-table"></div>}

                        <div id="table-total">
                            <Table
                                columns={columnsTotalMade}
                                locale={locale}
                                dataSource={total}
                                onChange={(pagination, filter, sorter: any) => {
                                    console.log("===", sorter, orderBy);
                                    setOrderBy({
                                        field: sorter.field,
                                        order: sorter.order === "descend" ? "desc" : "asc",
                                    });
                                }}
                                // pagination={{
                                //     pageSize,
                                //     total: totalPage,
                                //     onChange: (page, pageSize) => {
                                //         setPage(page);
                                //         setPageSize(pageSize);
                                //     },
                                // }}
                            />
                            <div className="footer-table" style={{ marginTop: 2 }}>
                                <Pagination
                                    defaultCurrent={1}
                                    total={totalPage * 10}
                                    showQuickJumper
                                    onChange={onChangePage}
                                    style={{ fontSize: 13 }}
                                />
                                <div style={{ paddingTop: 30 }}>
                                    <Dropdown
                                        dropdownRender={() => (
                                            <div className="footer-table__dropdown">
                                                {columnsInDropdown.map((item: any, index: any) => (
                                                    <div onClick={() => handleCheckColum(item.key)}>
                                                        <Checkbox
                                                            style={{ marginRight: 10 }}
                                                            checked={checkColumn.includes(item.key)}
                                                        />
                                                        <div key={index}>{item?.title}</div>
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    >
                                        <a onClick={(e) => e.preventDefault()}>
                                            <div className="footer-table__bars-icon">
                                                <img width={16} src="/bars_icon.svg"></img>
                                            </div>
                                        </a>
                                    </Dropdown>
                                </div>
                            </div>
                        </div>
                        {loadings && (
                            <div className="loading">
                                <Loading3QuartersOutlined className="loading-icon" />
                            </div>
                        )}
                        <div id="table-data" style={{ marginTop: 6 }}>
                            <Table
                                columns={columnsData}
                                locale={locale}
                                dataSource={data}
                                onChange={(pagination, filter, sorter: any) => {
                                    console.log("===", sorter, orderBy);
                                    setOrderBy({
                                        field: sorter.field,
                                        order: sorter.order === "descend" ? "desc" : "asc",
                                    });
                                }}
                            />
                        </div>
                        <div className="footer-table">
                            <Pagination
                                defaultCurrent={1}
                                total={totalPage * 10}
                                showQuickJumper
                                onChange={onChangePage}
                                style={{ fontSize: 13 }}
                            />
                        </div>
                    </div>
                </Space>
            </Col>
        </div>
    );
};

export default BettingRecord;
