import { Select } from "antd"
import moment from "moment"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { apis } from "../../apis"
import { httpService } from "../../apis/httpService"
import { TransactionTable } from "../../common/components/txn-table"

import { TransactionType } from "../../common/constants/transaction.enum"
import { ISort } from "../../interfaces/Global"

const Deposit = () => {
    const { t } = useTranslation()
    const { Option } = Select
    const data = [
        {
            key: "1",
            txnNo: "13722",
            username: "testkm123",
            requestTime: "2023-12-13 20:36:15 (+7)",
            beforeBalance: "1,031.00",
            afterBalance: "1,041.00",
            currency: "TMP",
            amount: "10.00",
            remark: "",
            modifiedBy: "VIL488test",
        },
        {
            key: "2",
            txnNo: "13722",
            username: "testkm123",
            requestTime: "2023-12-13 20:36:15 (+7)",
            beforeBalance: "1,031.00",
            afterBalance: "1,041.00",
            currency: "TMP",
            amount: "10.00",
            remark: "",
            modifiedBy: "VIL488test",
        },
        {
            key: "3",
            txnNo: "13722",
            username: "testkm123",
            requestTime: "2023-12-13 20:36:15 (+7)",
            beforeBalance: "1,031.00",
            afterBalance: "1,041.00",
            currency: "TMP",
            amount: "10.00",
            remark: "",
            modifiedBy: "VIL488test",
        },
    ]

    const [txnList, setTxnList] = useState([])
    const [totalPage, setTotalPage] = useState(10)
    const [textSearch, setTextSearch] = useState("")
    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(10)
    const [orderBy, setOrderBy] = useState<ISort | null>(null)
    const [stateTxn, setStateTxn] = useState("waiting")

    const onChangeTab = (state: string) => {
        console.log("state", state)
        setStateTxn(state)
    }

    const getTxnList = async () => {
        try {
            const orderStr =
                orderBy === null ? "" : `&sortKey=${orderBy.field}&sortOrder=${orderBy.order}`
            const res = await httpService.get(
                `${apis.txn.list}/?page=${page}&query=${textSearch}&take=${pageSize}${orderStr}&type=${TransactionType.Deposit}&state=${stateTxn}`
            )

            //apply when have real data
            if (res.data?.result?.length) {
                const result = res.data.result.map((item: any) => {
                    return {
                        date: moment.utc(item.createdAt).add(7, "hours"),
                        txnNo: item.code,
                        transactionType: item.type,
                        username: item.owner.username,
                        currency: item.currency,
                        deposit: item.type === TransactionType.Deposit ? item.amount : 0,
                        withdrawal: item.type === TransactionType.Withdraw ? item.amount : 0,
                        createdBy: item.createdBy,
                        note: item.note,
                        userId: item.owner.id,
                    }
                })

                setTxnList(result)
                setTotalPage(res?.data?.meta.itemCount)
            }
        } catch (error) {
            console.log("error", error)
        }
    }

    useEffect(() => {
        getTxnList()
    }, [page, textSearch, pageSize, orderBy])

    return <TransactionTable cardTitle={"Deposit"} data={data} onChangeTab={onChangeTab} />
}

export default Deposit
