import React from "react";
import "./styles.scss";
import { useTranslation } from "react-i18next";

type PromotionTypeCardType = {
	title: string;
	description: string;
	onClick?: (event: React.MouseEvent) => void;
};

const PromotionTypeCard = ({
	title,
	description,
	onClick,
}: PromotionTypeCardType) => {
	const { t } = useTranslation();
	return (
		<div className="promotion-type-card" onClick={onClick}>
			<div className="promotion-type-card__content">
				<span className="promotion-type-card__title">{title}</span>
				<div className="promotion-type-card__divider" />
				<p className="promotion-type-card__description">
					{description}
				</p>
			</div>
			<div className="promotion-type-card__btn">
				<svg
					className="svg-inline--fa fa-plus icon w-4"
					aria-hidden="true"
					focusable="false"
					data-prefix="fas"
					data-icon="plus"
					role="img"
					xmlns="http://www.w3.org/2000/svg"
					viewBox="0 0 448 512"
				>
					<path
						className=""
						fill="currentColor"
						d="M432 256c0 17.69-14.33 32.01-32 32.01H256v144c0 17.69-14.33 31.99-32 31.99s-32-14.3-32-31.99v-144H48c-17.67 0-32-14.32-32-32.01s14.33-31.99 32-31.99H192v-144c0-17.69 14.33-32.01 32-32.01s32 14.32 32 32.01v144h144C417.7 224 432 238.3 432 256z"
					></path>
				</svg>
				<span className="text-white">{t("common.addNew")}</span>
			</div>
		</div>
	);
};

export default PromotionTypeCard;
