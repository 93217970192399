import { Select } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TransactionTable } from '../../common/components/txn-table';

const Withdraw = () => {
    const { t } = useTranslation();
    const { Option } = Select;
    const data = [
        {
            key: '1',
            txnNo: '13722',
            username: 'testkm123',
            requestTime: '2023-12-13 20:36:15 (+7)',
            beforeBalance: '1,031.00',
            afterBalance: '1,041.00',
            currency: 'TMP',
            amount: '10.00',
            remark: '',
            modifiedBy: 'VIL488test',
        },
        {
            key: '2',
            txnNo: '13722',
            username: 'testkm123',
            requestTime: '2023-12-13 20:36:15 (+7)',
            beforeBalance: '1,031.00',
            afterBalance: '1,041.00',
            currency: 'TMP',
            amount: '10.00',
            remark: '',
            modifiedBy: 'VIL488test',
        },
        {
            key: '3',
            txnNo: '13722',
            username: 'testkm123',
            requestTime: '2023-12-13 20:36:15 (+7)',
            beforeBalance: '1,031.00',
            afterBalance: '1,041.00',
            currency: 'TMP',
            amount: '10.00',
            remark: '',
            modifiedBy: 'VIL488test',
        },
        {
            key: '11',
            txnNo: '13722',
            username: 'testkm123',
            requestTime: '2023-12-13 20:36:15 (+7)',
            beforeBalance: '1,031.00',
            afterBalance: '1,041.00',
            referenceNumber: '1,031.00',
            currency: 'TMP',
            amount: '10.00',
            remark: '',
            modifiedBy: 'VIL488test',
        },
        {
            key: '13',
            txnNo: '13722',
            username: 'testkm123',
            requestTime: '2023-12-13 20:36:15 (+7)',
            beforeBalance: '1,031.00',
            afterBalance: '1,041.00',
            currency: 'TMP',
            amount: '10.00',
            remark: '',
            modifiedBy: 'VIL488test',
        },
        {
            key: '13',
            txnNo: '13722',
            username: 'testkm123',
            requestTime: '2023-12-13 20:36:15 (+7)',
            beforeBalance: '1,031.00',
            afterBalance: '1,041.00',
            currency: 'TMP',
            amount: '10.00',
            remark: '',
            modifiedBy: 'VIL488test',
        },
        {
            key: '13',
            txnNo: '13722',
            username: 'testkm123',
            requestTime: '2023-12-13 20:36:15 (+7)',
            beforeBalance: '1,031.00',
            afterBalance: '1,041.00',
            currency: 'TMP',
            amount: '10.00',
            remark: '',
            modifiedBy: 'VIL488test',
        },
        {
            key: '13',
            txnNo: '13722',
            username: 'testkm123',
            requestTime: '2023-12-13 20:36:15 (+7)',
            beforeBalance: '1,031.00',
            afterBalance: '1,041.00',
            currency: 'TMP',
            amount: '10.00',
            remark: '',
            modifiedBy: 'VIL488test',
        },
        {
            key: '114',
            txnNo: '13722',
            username: 'testkm123',
            requestTime: '2023-12-13 20:36:15 (+7)',
            beforeBalance: '1,031.00',
            afterBalance: '1,041.00',
            currency: 'TMP',
            amount: '10.00',
            remark: '',
            modifiedBy: 'VIL488test',
        },
        {
            key: '113',
            txnNo: '13722',
            username: 'testkm123',
            requestTime: '2023-12-13 20:36:15 (+7)',
            beforeBalance: '1,031.00',
            afterBalance: '1,041.00',
            currency: 'TMP',
            amount: '10.00',
            remark: '',
            modifiedBy: 'VIL488test',
        },
        {
            key: '14113',
            txnNo: '13722',
            username: 'testkm123',
            requestTime: '2023-12-13 20:36:15 (+7)',
            beforeBalance: '1,031.00',
            afterBalance: '1,041.00',
            currency: 'TMP',
            amount: '10.00',
            remark: '',
            modifiedBy: 'VIL488test',
        },
        {
            key: '141',
            txnNo: '13722',
            username: 'testkm123',
            requestTime: '2023-12-13 20:36:15 (+7)',
            beforeBalance: '1,031.00',
            referenceNumber: '1,031.00',
            afterBalance: '1,041.00',
            currency: 'TMP',
            amount: '10.00',
            remark: '',
            modifiedBy: 'VIL488test',
        },
        {
            key: '141',
            txnNo: '13722',
            username: 'testkm123',
            requestTime: '2023-12-13 20:36:15 (+7)',
            beforeBalance: '1,031.00',
            referenceNumber: '1,031.00',
            afterBalance: '1,041.00',
            currency: 'TMP',
            amount: '10.00',
            remark: '',
            modifiedBy: 'VIL488test',
        },
        {
            key: '11541',
            txnNo: '1372âcsdas22',
            username: 'testkm123',
            requestTime: '2023-12-13 20:36:15 (+7)',
            beforeBalance: '1,031.00',
            afterBalance: '1,041.00',
            currency: 'TMP',
            amount: '10.00',
            remark: '',
            modifiedBy: 'VIL488test',
        },
        // ... more data
    ];
    const [stateTxn, setStateTxn] = useState('waiting');

    const onChangeTab = (state: string) => {
        setStateTxn(state);
    };
    return <TransactionTable cardTitle={'Withdraw'} data={data} onChangeTab={onChangeTab} />;
};

export default Withdraw;
