import { Form, FormItemProps, CheckboxProps, InputProps, Input } from "antd";
import React from "react";
import { Control, Controller } from "react-hook-form";
import CustomCheckbox from "../checkbox";
import { getValidateStatus } from "../../helpers";
import { t } from "i18next";

const ConditionalInputField = ({
    name,
    control,
    formItemProps = {},
    checkboxProps = {},
    inputProps = {},
}: {
    name: string;
    control: Control<any>;
    formItemProps?: FormItemProps;
    checkboxProps?: CheckboxProps;
    inputProps?: InputProps;
    children?: React.ReactNode;
}) => {
    const { labelAlign = "left" } = formItemProps;

    return (
        <Controller
            name={name}
            control={control}
            render={({ field, fieldState }) => {
                const errorMessage = fieldState.error?.message;

                return (
                    <Form.Item
                        labelAlign={labelAlign}
                        validateStatus={getValidateStatus(fieldState, name)}
                        help={errorMessage}
                        className="wrap-label"
                        {...formItemProps}
                    >
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "0.5rem",
                            }}
                        >
                            <CustomCheckbox
                                {...checkboxProps}
                                onChange={(event) => {
                                    field.onChange(
                                        event.target.checked ? -1 : 0
                                    );
                                    checkboxProps.onChange?.(event);
                                }}
                            >
                                {t("content.promotions.create.state.noLimit")}
                            </CustomCheckbox>
                            <Input
                                {...field}
                                value={field.value === -1 ? "" : field.value}
                                {...inputProps}
                                disabled={
                                    field.value === -1 || inputProps.disabled
                                }
                                style={{ flex: 1, ...inputProps.style }}
                            />
                        </div>
                    </Form.Item>
                );
            }}
        />
    );
};

export default ConditionalInputField;
