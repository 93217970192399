import { Popover } from "antd";
import { cn } from "../../../common/components/date-range-picker/DateRangePicker";

type Option = {
    value: string;
    label: string;
};

const withTooltipMultiCascader = (Component: any) => (props: any) => {
    const toRenderItems = (item: [string, string]) => {
        const gameProvider = props.options.find((option: Option) => {
            return option.value === item[0];
        });
        const label = gameProvider.children.find(
            (child: Option) => child.value === item[1]
        )?.label;

        return (
            <MultiValue
                onClick={() => {
                    if (props.disabled) return;
                    props.onChange(
                        props.value.filter(
                            (selected: [string, string]) =>
                                selected[1] !== item[1]
                        )
                    );
                }}
                disabled={props.disabled}
                label={label}
            />
        );
    };

    return (
        <Popover
            content={
                <div className="flex gap-2 flex-wrap max-w-[400px] max-h-[200px] overflow-auto">
                    {props.value?.map(toRenderItems)}
                </div>
            }
            trigger="hover"
        >
            <Component {...props} />
        </Popover>
    );
};

const MultiValue = ({
    label,
    onClick,
    disabled,
}: {
    label: string;
    onClick: (event: React.MouseEvent) => void;
    disabled?: boolean;
}) => {
    return (
        <div
            className={cn(
                "flex gap-2 border-solid border-[1px] border-transparent px-2 py-[2px] rounded w-fit",
                {
                    "opacity-50 bg-[#00000040] cursor-not-allowed": disabled,
                    "bg-[#0000000f]": !disabled,
                }
            )}
        >
            <div>{label}</div>
            <div
                onClick={onClick}
                className={!disabled ? "cursor-pointer" : "cursor-not-allowed"}
            >
                <svg
                    fill-rule="evenodd"
                    viewBox="64 64 896 896"
                    focusable="false"
                    data-icon="close"
                    width="10px"
                    height="10px"
                    fill="currentColor"
                    aria-hidden="true"
                >
                    <path d="M799.86 166.31c.02 0 .04.02.08.06l57.69 57.7c.04.03.05.05.06.08a.12.12 0 010 .06c0 .03-.02.05-.06.09L569.93 512l287.7 287.7c.04.04.05.06.06.09a.12.12 0 010 .07c0 .02-.02.04-.06.08l-57.7 57.69c-.03.04-.05.05-.07.06a.12.12 0 01-.07 0c-.03 0-.05-.02-.09-.06L512 569.93l-287.7 287.7c-.04.04-.06.05-.09.06a.12.12 0 01-.07 0c-.02 0-.04-.02-.08-.06l-57.69-57.7c-.04-.03-.05-.05-.06-.07a.12.12 0 010-.07c0-.03.02-.05.06-.09L454.07 512l-287.7-287.7c-.04-.04-.05-.06-.06-.09a.12.12 0 010-.07c0-.02.02-.04.06-.08l57.7-57.69c.03-.04.05-.05.07-.06a.12.12 0 01.07 0c.03 0 .05.02.09.06L512 454.07l287.7-287.7c.04-.04.06-.05.09-.06a.12.12 0 01.07 0z"></path>
                </svg>
            </div>
        </div>
    );
};

export default withTooltipMultiCascader;
