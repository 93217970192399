import type { DatePickerProps } from "antd"
import { Card, DatePicker, Image, Switch, Table, message } from "antd"
import type { ColumnsType } from "antd/es/table"
import dayjs from "dayjs"
import customParseFormat from "dayjs/plugin/customParseFormat"
import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"

import { apis } from "../../../apis"
import { httpService } from "../../../apis/httpService"
import { ISort } from "../../../interfaces/Global"
import { paths } from "../../../routes/path"
import "./List.scss"
import { UserListFilter } from "./components/fitler"

dayjs.extend(customParseFormat)

const { RangePicker } = DatePicker

const dateFormat = "YYYY/MM/DD"
const weekFormat = "MM/DD"
const monthFormat = "YYYY/MM"
const dateFormatList = ["DD/MM/YYYY", "DD/MM/YY", "DD-MM-YYYY", "DD-MM-YY"]

const customFormat: DatePickerProps["format"] = (value) =>
    `custom format: ${value.format(dateFormat)}`

const customWeekStartEndFormat: DatePickerProps["format"] = (value) =>
    `${dayjs(value).startOf("week").format(weekFormat)} ~ ${dayjs(value)
        .endOf("week")
        .format(weekFormat)}`

interface DataType {
    id: string
    key: React.Key
    name: string
    age: number
    address: string
    username: string
    role: string
    phone: string
    status: string
    avatarUrl: string
}

export interface IListProps {}

const initialValues = {
    dateType: 1,
    group: 0,
    key: "phone",
}

const List: React.FC<IListProps> = () => {
    const [userList, setUserList] = useState([])
    const [totalPage, setTotalPage] = useState(10)
    const [textSearch, setTextSearch] = useState("")
    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(10)
    const [orderBy, setOrderBy] = useState<ISort | null>(null)
    const [userRole, setUserRole] = useState<Number | string>(0)
    const getListMember = async () => {
        try {
            const orderStr =
                orderBy === null ? "" : `&sortKey=${orderBy.field}&sortOrder=${orderBy.order}`
            const _userRole = userRole === 0 ? "" : `&userRole=${userRole}`
            const res = await httpService.get(
                `${apis.user.list}/?page=${page}&query=${textSearch}&take=${pageSize}${orderStr}${_userRole}`
            )
            setUserList(res?.data?.result)
            setTotalPage(res?.data?.meta.itemCount)
        } catch (error) {
            console.log("error", error)
        }
    }

    const [messageApi, contextHolder] = message.useMessage()
    const onIsEnableChange = async (id: string, isEnable: boolean) => {
        try {
            if (!isEnable) {
                const res = await httpService.delete(apis.user.detail.replace(":id", id))
                console.log(res)
            } else {
                const res = await httpService.patch(apis.user.active.replace(":id", id))
                console.log("active,", res)
            }
            messageApi.open({
                type: "success",
                content: `Đã ${isEnable ? "" : "huỷ"} kích hoạt người dùng thành công`,
            })
            getListMember()
        } catch (error: any) {
            console.error(error?.response?.data?.messageCode)
            messageApi.open({
                type: "error",
                content: error?.response?.data?.messageCode?.replace(/_/g, " ") || "Error",
            })
        }
    }

    useEffect(() => {
        getListMember()
    }, [page, textSearch, pageSize, orderBy, userRole])

    let timeoutSearchText: NodeJS.Timeout | null = null

    const handleSearch = (e: any) => {
        if (timeoutSearchText !== null) {
            clearTimeout(timeoutSearchText)
        }
        timeoutSearchText = setTimeout(() => setTextSearch(e.target.value), 500)
    }

    const columns: ColumnsType<DataType> = [
        {
            title: "Tài khoản",
            dataIndex: "username",
            fixed: "left",
            width: 200,
            render: (value, record: DataType) => {
                return (
                    <div className='flex align-center'>
                        <div style={{ marginRight: 15, width: 32 }}>
                            <Image width={32} src={record.avatarUrl} />
                        </div>
                        <Link className='bold' to={paths.USER_DETAIL.replace(":id", record.id)}>
                            {value}
                        </Link>{" "}
                        <br />
                        <span style={{ color: "#666" }}>{record.name}</span>
                    </div>
                )
            },
        },

        { title: "Vai trò", dataIndex: "role" },
        { title: "Điện thoại", dataIndex: "phone" },
        { title: "Họ", dataIndex: "firstName" },
        { title: "Tên", dataIndex: "lastName" },
        { title: "Số dư", dataIndex: "balance", sorter: true },
        { title: "vai trò", dataIndex: "role" },
        { title: "Số điện thoại", dataIndex: "phone" },
        { title: "Trạng thái", dataIndex: "status" },
        //  { title: "Xác minh email", dataIndex: "isEmailVerified", key: "7" },
        {
            title: <div className='flex-center'>Kích hoạt</div>,
            key: "action",
            render(_, record: any) {
                return (
                    <div className='flex-center'>
                        <Switch
                            checked={record.isEnable}
                            onClick={() => onIsEnableChange(record.id, !record.isEnable)}
                        />
                    </div>
                )
            },
        },
    ]

    return (
        <>
            {contextHolder}
            <div className='list'>
                <div className='filter'>
                    <UserListFilter
                        initialValues={initialValues}
                        dateFormat={dateFormat}
                        handleSearch={handleSearch}
                        onUserRoleChange={(role: string) => setUserRole(role)}
                    ></UserListFilter>
                </div>
                <Card title='Danh sách thành viên' className='card-table'>
                    <Table
                        className='table-list'
                        columns={columns}
                        dataSource={userList}
                        onChange={(pagination, filter, sorter: any) => {
                            console.log("===", sorter, orderBy)
                            setOrderBy({
                                field: sorter.field,
                                order: sorter.order === "descend" ? "desc" : "asc",
                            })
                        }}
                        pagination={{
                            pageSize,
                            total: totalPage,
                            onChange: (page, pageSize) => {
                                setPage(page)
                                setPageSize(pageSize)
                            },
                        }}
                        rowKey='id'
                        scroll={{ x: 1300, y: 400 }}
                        expandable={{
                            expandedRowRender: (record) => (
                                <p style={{ margin: 0 }}>Địa chỉ: {record.address}</p>
                            ),
                            rowExpandable: (record) => record.name !== "Not Expandable",
                        }}
                    />
                </Card>
            </div>
        </>
    )
}

export default List
