import { Form } from "antd";
import { Controller, Control } from "react-hook-form";
import type { FormItemProps, CheckboxProps } from "antd";
import CustomCheckbox from "../checkbox";
import { getValidateStatus } from "../../helpers";

const CheckboxField = ({
    name,
    control,
    formItemProps,
    checkboxProps,
    children,
}: {
    name: string;
    control: Control<any>;
    formItemProps?: FormItemProps;
    checkboxProps?: CheckboxProps;
    children?: React.ReactNode;
}) => {
    const { labelAlign = "left" } = formItemProps || {};
    return (
        <Controller
            name={name}
            control={control}
            render={({ field, fieldState }) => {
                const errorMessage = fieldState.error?.message;
                return (
                    <Form.Item
                        labelAlign={labelAlign}
                        validateStatus={getValidateStatus(fieldState, name)}
                        help={errorMessage}
                        className="wrap-label"
                        {...formItemProps}
                    >
                        <CustomCheckbox {...field} {...checkboxProps}>
                            {children}
                        </CustomCheckbox>
                    </Form.Item>
                );
            }}
        />
    );
};

export default CheckboxField;
