import {
    Button,
    Col,
    DatePicker,
    Image,
    Input,
    PaginationProps,
    Row,
    Select,
    Space,
    Table,
    Tabs,
} from "antd"
import { useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"

import moment from "moment"

import { Link } from "react-router-dom"

import { Loading3QuartersOutlined } from "@ant-design/icons"
import TabPane from "antd/es/tabs/TabPane"
import { apis } from "../../../../apis"
import { httpService } from "../../../../apis/httpService"
import { CustomDateRangePicker } from "../../../../common/components/date-range-picker"

import { TransactionType } from "../../../../common/constants/transaction.enum"
import { ISort } from "../../../../interfaces/Global"
import { paths } from "../../../../routes/path"
import "./ManualPaymentTransaction.scss"

const ManualPaymentTransaction = () => {
    const { t } = useTranslation()

    const { RangePicker } = DatePicker
    const { Option } = Select

    const dataTotal = [
        {
            currency: "VND",
            deposit: 8441826.0,
            withdrawal: 9142118.0032,
        },
        {
            currency: "SGD",
            deposit: 1561188.0,
            withdrawal: 352799.0094,
        },
    ]
    const dataSource = [
        {
            account: "testkmsd1",
            date: "2023-12-13 20:32:38 (+7)",
            providers: "PrincePay",
            refNo: "20062_218228",
            transactionType: "Payment Deposit",

            currency: "VND",
            amount: "200000",

            requestTime: "2024-03-18 08:26:01 AM (-4)",
            status: "pending",
            modifiedBy: "cskh2",
            modifiedTime: "2024-03-16 10:01:11 PM (-4)",
        },
        {
            account: "testkmsd1",
            date: "2023-12-13 20:32:38 (+7)",
            providers: "PrincePay",
            refNo: "20062_218228",
            transactionType: "Payment Deposit",

            currency: "VND",
            amount: "200000",

            requestTime: "2024-03-18 08:26:01 AM (-4)",
            status: "success",
            modifiedBy: "cskh2",
            modifiedTime: "2024-03-16 10:01:11 PM (-4)",
        },
        {
            account: "testkmsd1",
            date: "2023-12-13 20:32:38 (+7)",
            providers: "PrincePay",
            refNo: "20062_218228",
            transactionType: "Payment Deposit",

            currency: "VND",
            amount: "200000",

            requestTime: "2024-03-18 08:26:01 AM (-4)",
            status: "reject",
            modifiedBy: "cskh2",
            modifiedTime: "2024-03-16 10:01:11 PM (-4)",
        },
        {
            account: "testkmsd1",
            date: "2023-12-13 20:32:38 (+7)",
            providers: "PrincePay",
            refNo: "20062_218228",
            transactionType: "Payment Deposit",

            currency: "VND",
            amount: "200000",

            requestTime: "2024-03-18 08:26:01 AM (-4)",
            status: "success",
            modifiedBy: "cskh2",
            modifiedTime: "2024-03-16 10:01:11 PM (-4)",
        },
        {
            account: "testkmsd1",
            date: "2023-12-13 20:32:38 (+7)",
            providers: "PrincePay",
            refNo: "20062_218228",
            transactionType: "Payment Deposit",

            currency: "VND",
            amount: "200000",

            requestTime: "2024-03-18 08:26:01 AM (-4)",
            status: "success",
            modifiedBy: "cskh2",
            modifiedTime: "2024-03-16 10:01:11 PM (-4)",
        },
        {
            account: "testkmsd1",
            date: "2023-12-13 20:32:38 (+7)",
            providers: "PrincePay",
            refNo: "20062_218228",
            transactionType: "Payment Deposit",

            currency: "VND",
            amount: "200000",

            requestTime: "2024-03-18 08:26:01 AM (-4)",
            status: "reject",
            modifiedBy: "cskh2",
            modifiedTime: "2024-03-16 10:01:11 PM (-4)",
        },
        {
            account: "testkmsd1",
            date: "2023-12-13 20:32:38 (+7)",
            providers: "PrincePay",
            refNo: "20062_218228",
            transactionType: "Payment Deposit",

            currency: "VND",
            amount: "200000",

            requestTime: "2024-03-18 08:26:01 AM (-4)",
            status: "pending",
            modifiedBy: "cskh2",
            modifiedTime: "2024-03-16 10:01:11 PM (-4)",
        },
        {
            account: "testkmsd1",
            date: "2023-12-13 20:32:38 (+7)",
            providers: "PrincePay",
            refNo: "20062_218228",
            transactionType: "Payment Deposit",

            currency: "VND",
            amount: "200000",

            requestTime: "2024-03-18 08:26:01 AM (-4)",
            status: "pending",
            modifiedBy: "cskh2",
            modifiedTime: "2024-03-16 10:01:11 PM (-4)",
        },
        {
            account: "testkmsd1",
            date: "2023-12-13 20:32:38 (+7)",
            providers: "PrincePay",
            refNo: "20062_218228",
            transactionType: "Payment Deposit",

            currency: "VND",
            amount: "200000",

            requestTime: "2024-03-18 08:26:01 AM (-4)",
            status: "reject",
            modifiedBy: "cskh2",
            modifiedTime: "2024-03-16 10:01:11 PM (-4)",
        },
        {
            account: "testkmsd1",
            date: "2023-12-13 20:32:38 (+7)",
            providers: "PrincePay",
            refNo: "20062_218228",
            transactionType: "Payment Deposit",

            currency: "VND",
            amount: "200000",

            requestTime: "2024-03-18 08:26:01 AM (-4)",
            status: "success",
            modifiedBy: "cskh2",
            modifiedTime: "2024-03-16 10:01:11 PM (-4)",
        },
    ]

    const tabs = [
        {
            key: "deposit",
            label: "Deposit",
        },
        {
            key: "withdrawal",
            label: "Withdrawal",
        },
    ]

    const columnsSource = [
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
        },
        {
            title: "Transaction Operation",
            dataIndex: "transactionOperation",
            key: "transactionOperation",
            render: (text: any, record: any) => (
                <Select disabled placeholder='Select' style={{ width: "100%" }}></Select>
            ),
        },
    ]
    function makeColumnsDataSource() {
        const columns = [
            {
                title: "#",
                dataIndex: "index",
                key: "index",

                render: (text: any, record: any, index: number) => {
                    return (
                        <div style={{ textAlign: "center", padding: "8px 16px" }}>{index + 1}</div>
                    )
                },
            },

            {
                title: "Account",
                dataIndex: "account",
                key: "account",
                render: (value: any, record: any) => {
                    return (
                        <Link
                            to={paths.USER_DETAIL.replace(":id", record.userId)}
                            style={{ color: "#7f7f7f" }}
                        >
                            {value}
                        </Link>
                    )
                },
            },

            {
                title: (
                    <div style={{ display: "block" }}>
                        <div>Providers</div>
                        <div>Ref No</div>
                    </div>
                ),
                dataIndex: "providers",
                key: "providers",
                render: (text: any, record: any) => (
                    <div style={{ display: "block", textAlign: "center" }}>
                        <div>{record.providers}</div>
                        <div>{record.refNo}</div>
                    </div>
                ),
            },
            {
                title: "Transaction Type",
                dataIndex: "transactionType",
                key: "transactionType",
            },

            {
                title: "Request Time",
                dataIndex: "requestTime",
                key: "requestTime",
            },
            {
                title: (
                    <div style={{ display: "block" }}>
                        <div>Currency</div>
                        <div>Amount</div>
                    </div>
                ),
                dataIndex: "currency",
                key: "currency",

                render: (text: any, record: any) => (
                    <div style={{ display: "block", textAlign: "center" }}>
                        <div>{record.currency}</div>
                        <div>{record.amount}</div>
                    </div>
                ),
            },

            {
                title: (
                    <div style={{ display: "block" }}>
                        <div>Modified By</div>
                        <div>Modified Time</div>
                    </div>
                ),
                dataIndex: "modified",
                key: "modified",

                render: (text: any, record: any) => (
                    <div style={{ display: "block" }}>
                        <div>{record.modifiedBy}</div>
                        <div>{record.modifiedTime}</div>
                    </div>
                ),
            },
        ]

        for (const col of columnsSource) {
            if (col.key === "status") {
                columns.splice(6, 0, {
                    title: col.title,
                    key: col.key,
                    dataIndex: col.dataIndex,
                    render: (value: any, record: any) => {
                        if (value === "success") {
                            return (
                                <div className='col-status success-color'>
                                    <Image
                                        width={16}
                                        className='col-status__icon'
                                        src='/success_icon.svg'
                                        preview={false}
                                    />
                                    <div className='col-status__name'>{value}</div>
                                </div>
                            )
                        } else if (value === "reject") {
                            return (
                                <div className='col-status error-color'>
                                    <Image
                                        width={14}
                                        className='col-status__icon'
                                        src='/reject_icon.svg'
                                        preview={false}
                                    />
                                    <div className='col-status__name'>{value}</div>
                                </div>
                            )
                        } else {
                            return (
                                <div className='col-status zero-color'>
                                    <Image
                                        width={14}
                                        className='col-status__icon'
                                        src='/pending_icon.svg'
                                        preview={false}
                                    />
                                    <div className='col-status__name'>{value}</div>
                                </div>
                            )
                        }
                    },
                })
            }
            if (col.key === "transactionOperation") {
                columns.splice(7, 0, {
                    title: col.title,
                    key: col.key,
                    dataIndex: col.dataIndex,
                    render: (value: any, record: any) => {
                        if (record.status === "pending") {
                            return (
                                <Select placeholder='Select' style={{ width: "100%" }}>
                                    <Option value='deposit' style={{ color: "rgb(103, 194, 58)" }}>
                                        <Image width={16} src='/success_icon.svg' preview={false} />
                                        <span style={{ paddingLeft: "6px" }}>
                                            Deposit To Player
                                        </span>
                                    </Option>

                                    <Option value='rejected' style={{ color: "rgb(255, 64, 64)" }}>
                                        <Image width={14} src='/reject_icon.svg' preview={false} />

                                        <span style={{ paddingLeft: "6px" }}>Rejected</span>
                                    </Option>
                                </Select>
                            )
                        } else {
                            return (
                                <Select
                                    disabled
                                    placeholder='Select'
                                    style={{ width: "100%" }}
                                ></Select>
                            )
                        }
                    },
                })
            }
        }

        return columns
    }

    const columnsData = useMemo(() => makeColumnsDataSource(), [dataSource])

    const [username, setUsername] = useState("")
    const [currency, setCurrency] = useState("")
    const [transactionType, setTransactionType] = useState("")

    const [loadings, setLoadings] = useState(false)

    //emtydata
    const [emptyText, setEmptyText] = useState("Click Search to see information")
    let locale = {
        emptyText,
    }

    const currentDate = new Date()
    const fromDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate() - 30
    )

    const [filterDate, setFilterDate] = useState({
        fromDate: fromDate,
        toDate: currentDate,
    })

    const [txnList, setTxnList] = useState([])
    const [totalPage, setTotalPage] = useState(10)
    const [textSearch, setTextSearch] = useState("")
    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(10)
    const [orderBy, setOrderBy] = useState<ISort | null>(null)

    const getTxnList = async () => {
        try {
            const orderStr =
                orderBy === null ? "" : `&sortKey=${orderBy.field}&sortOrder=${orderBy.order}`
            const res = await httpService.get(
                `${
                    apis.txn.list
                }/?page=${page}&query=${textSearch}&take=${pageSize}${orderStr}&fromDate=${filterDate.fromDate.toISOString()}&toDate=${filterDate.toDate.toISOString()}&username=${username}&type=${transactionType}&currency=${currency}`
            )

            //apply when have real data
            if (res.data?.result?.length) {
                const result = res.data.result.map((item: any) => {
                    return {
                        date: moment.utc(item.createdAt).add(7, "hours"),
                        txnNo: item.code,
                        transactionType: item.type,
                        username: item.owner.username,
                        currency: item.currency,
                        deposit: item.type === TransactionType.Deposit ? item.amount : 0,
                        withdrawal: item.type === TransactionType.Withdraw ? item.amount : 0,
                        createdBy: item.createdBy,
                        note: item.note,
                        userId: item.owner.id,
                    }
                })

                setTxnList(result)
                setTotalPage(res?.data?.meta.itemCount)
            }
        } catch (error) {
            console.log("error", error)
        }
    }

    useEffect(() => {
        getTxnList()
    }, [
        page,
        textSearch,
        pageSize,
        orderBy,
        username,
        currency,
        transactionType,
        filterDate.fromDate,
        filterDate.toDate,
    ])

    const onUsernameChange = (e: any) => {
        setUsername(e.target.value)
    }

    const onCurrencyChange = (value: any) => {
        setCurrency(value)
    }

    const onTransactionTypeChange = (value: any) => {
        setTransactionType(value)
    }

    const onChangeTab = (value: any) => {}

    const onClickApplyDate = (event: any, picker: any) => {
        const fromDate = moment(picker.startDate).toDate()
        const toDate = moment(picker.endDate).toDate()
        setFilterDate({
            ...filterDate,
            fromDate,
            toDate,
        })
    }

    const clearDate = () => {
        setFilterDate({
            ...filterDate,
            fromDate: fromDate,
            toDate: currentDate,
        })
    }

    const handleSearch = async () => {
        await getTxnList()
    }

    const enterLoading = () => {
        setLoadings(true)

        setTimeout(() => {
            if (dataSource.length === 0) {
                setEmptyText("No Data")
            }
            setLoadings(false)
        }, 3000)
    }
    const onChangePage: PaginationProps["onChange"] = (pageNumber: any) => {
        console.log("Page: ", pageNumber)
    }

    return (
        <div id='manul-payment-transaction'>
            <Col id='transactions'>
                <Space direction='vertical' size='middle' style={{ width: "100%" }}>
                    <Row className='filter-header'>
                        <div className='filter-header__name'>Manual Payment Transaction</div>
                        <div></div>
                    </Row>
                    <Tabs defaultActiveKey='1' onChange={(value) => onChangeTab(value)}>
                        {tabs.map((item, index) => {
                            return (
                                <TabPane tab={item.label} key={index}>
                                    <Row className='filter-bar'>
                                        <Row gutter={9} className='filter'>
                                            <Col className='date-range-transaction-history'>
                                                <div className='filter__name'>Date</div>
                                                <CustomDateRangePicker
                                                    onApply={onClickApplyDate}
                                                    onCancel={clearDate}
                                                    containerStyles={{
                                                        width: "214px",
                                                        padding: "3px 0px",
                                                        paddingLeft: "4px",
                                                        borderRadius: "6px",
                                                        border: "1px solid #d9d9d9",
                                                    }}
                                                    fromDate={filterDate.fromDate}
                                                    toDate={filterDate.toDate}
                                                    resultToString
                                                    opens={"center"}
                                                    readOnly
                                                />
                                            </Col>

                                            <Col>
                                                <div className='filter__name'>Username</div>
                                                <Input
                                                    placeholder='Username'
                                                    onChange={onUsernameChange}
                                                />
                                            </Col>

                                            <Col className='col-submit'>
                                                <Button
                                                    className='submit-btn'
                                                    type='primary'
                                                    onClick={() => enterLoading()}
                                                >
                                                    {loadings && (
                                                        <div className='loading-btn'>
                                                            <Image
                                                                width={16}
                                                                src='/loading_icon.svg'
                                                                className='loading-icon-btn'
                                                                preview={false}
                                                            />
                                                        </div>
                                                    )}
                                                    Submit
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Row>
                                    <Row id='table'>
                                        <div id='table-data'>
                                            <Table
                                                columns={columnsData}
                                                locale={locale}
                                                dataSource={dataSource}
                                                onChange={(pagination, filter, sorter: any) => {
                                                    console.log("===", sorter, orderBy)
                                                    setOrderBy({
                                                        field: sorter.field,
                                                        order:
                                                            sorter.order === "descend"
                                                                ? "desc"
                                                                : "asc",
                                                    })
                                                }}
                                                // pagination={{
                                                //     pageSize,
                                                //     total: totalPage,
                                                //     onChange: (page, pageSize) => {
                                                //         setPage(page);
                                                //         setPageSize(pageSize);
                                                //     },
                                                // }}
                                            />
                                        </div>

                                        {loadings && (
                                            <tr className='loading-mask-table'>
                                                <div className='loading-icon-table'>
                                                    <Loading3QuartersOutlined className='loading-img-table' />
                                                </div>
                                            </tr>
                                        )}
                                    </Row>
                                </TabPane>
                            )
                        })}
                    </Tabs>
                </Space>
            </Col>
        </div>
    )
}

export default ManualPaymentTransaction
