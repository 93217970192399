import { apis } from "../../apis"
import { httpFileService, httpService } from "../../apis/httpService"
import { PromotionState } from "./constants"

export const apiCreatePromotion = async (data: any) => {
    return httpService.post(apis.promotion.create, data)
}
export const apiUpdatePromotion = async (id: string, data: any) => {
    return httpService.patch(`${apis.promotion.create}/${id}`, data)
}

export const apiUploadImage = async (data: any) => {
    return httpService.post(apis.media.imageUpload, data)
}

export const getGameProviderList = async () => {
    return httpService.post(apis.gameAndProvider.list, {})
}

export const getPromotionList = (params?: {
    page?: number
    currency?: string
    status?: string
    language?: string
    take?: number
}) => {
    return httpService.get(apis.promotion.create, {}, params ?? {})
}

export const getPromotionRecords = (params?: {
    page?: number
    currency?: string
    status?: string
    language?: string
    take?: number
}) => {
    return httpService.get(apis.promotion.promotionRecord, {}, params ?? {})
}

export const getPromotionStatistics = (params?: {
    page?: number
    currency?: string
    status?: string
    language?: string
    take?: number
}) => {
    return httpService.get(apis.promotion.promotionStatistics, {}, params ?? {})
}

export const approvePromotion = (body: {
    id?: string
    state: string
    approvedAmount: number
    minTurnoverToWithdraw: number
    remark: string
}) => {
    return httpService.patch(`${apis.promotion.userPromotion}/${body.id ?? ""}`, body)
}

export const getPromotionVerify = (params?: {
    page?: number
    take?: number
    promotionState: PromotionState
    requestTimeFrom: string
    requestTimeTo: string
    promotionType?: string
    promotionCode?: string
    applyIp?: string
    agentName?: string
    username?: string
}) => {
    console.log("params ", params)
    return httpService.get(apis.promotion.promotionVerify, {}, params ?? {})
}

export const uploadMedia = async (data: any) => {
    const now = new Date()
    const formData = new FormData()
    formData.append("image", data)
    formData.append("fileName", `${data.name}-${now.getTime()}`)

    return httpFileService.post(apis.media.imageUpload, formData, {
        headers: { "Content-Type": "multipart/form-data" },
    })
}

export const getLanguageList = async () => {
    return httpService.get(apis.public.languages)
}
