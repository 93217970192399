import { useTranslation } from "react-i18next";
import "./styles.scss";
import { PromotionTypeCard } from "../components";
import { usePromotion } from "../hooks";
import { useState } from "react";
import {
    DailyDepositBonus,
    FirstDepositBonus,
    RegisterBonus,
    DepositBonus,
    RebateOnTurnover,
    RebateOnLoseOnly,
    RebateOnWinLost,
    RebateOnDepositAndWithdrawal,
} from "./containers";
import { PromotionType } from "../constants";
/** Component */

const CreatePromotion = () => {
    const [isRegisterBonusOpen, setIsRegisterBonusOpen] = useState(false);
    const [isFirstDepositBonusOpen, setIsFirstDepositBonusOpen] =
        useState(false);
    const [isDailyDepositBonusOpen, setIsDailyDepositBonusOpen] =
        useState(false);
    const [isDepositBonusOpen, setIsDepositBonusOpen] = useState(false);
    const [isRebateOnTurnoverOpen, setIsRebateOnTurnoverOpen] = useState(false);
    const [isRebateOnLoseOnlyOpen, setIsRebateOnLoseOnlyOpen] = useState(false);
    const [isRebateOnWinLostOpen, setIsRebateOnWinLostOpen] = useState(false);
    const [
        isRebateOnDepositWithdrawalOpen,
        setIsRebateOnDepositWithdrawalOpen,
    ] = useState(false);
    const { t } = useTranslation();
    const { PROMOTION_TYPE_LIST_1, PROMOTION_TYPE_LIST_2 } = usePromotion();

    const handleShowModal = (type: PromotionType) => {
        switch (type) {
            case PromotionType.REGISTER_BONUS:
                setIsRegisterBonusOpen(true);
                break;
            case PromotionType.FIRST_DEPOSIT_BONUS:
                setIsFirstDepositBonusOpen(true);
                break;
            case PromotionType.DAILY_DEPOSIT_BONUS:
                setIsDailyDepositBonusOpen(true);
                break;
            case PromotionType.DEPOSIT_BONUS:
                setIsDepositBonusOpen(true);
                break;
            case PromotionType.REBASE_ON_TURNOVER:
                setIsRebateOnTurnoverOpen(true);
                break;
            case PromotionType.REBASE_ON_TURNOVER_LOSE_ONLY:
                setIsRebateOnLoseOnlyOpen(true);
                break;
            case PromotionType.REBASE_ON_WIN_LOST:
                setIsRebateOnWinLostOpen(true);
                break;
            case PromotionType.REBASE_ON_DEPOSIT_AND_WITHDRAW:
                setIsRebateOnDepositWithdrawalOpen(true);
                break;
            default:
        }
    };

    return (
        <>
            <div className="promotions-create__container mb-4">
                <div className="production-content">
                    <div className="promotions-container">
                        <div className="header">
                            <div className="header__container">
                                <div className="header__item">
                                    {t("content.promotions.create.title.bonus")}
                                </div>
                            </div>
                        </div>
                        <div className="content">
                            <RemarkBonus />
                            <div className="promotion-type-list">
                                {PROMOTION_TYPE_LIST_1.map((item) => (
                                    <PromotionTypeCard
                                        onClick={() => handleShowModal(item.id)}
                                        key={item.id}
                                        {...item}
                                    />
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="promotions-create__container">
                <div className="production-content">
                    <div className="promotions-container">
                        <div className="header">
                            <div className="header__container">
                                <div className="header__item">
                                    {t(
                                        "content.promotions.create.title.rebate"
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="content">
                            <RemarkRebate />
                            <div className="promotion-type-list">
                                {PROMOTION_TYPE_LIST_2.map((item) => (
                                    <PromotionTypeCard
                                        onClick={() => handleShowModal(item.id)}
                                        {...item}
                                    />
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <RegisterBonus
                isOpen={isRegisterBonusOpen}
                onChangeOpen={setIsRegisterBonusOpen}
            />
            <FirstDepositBonus
                isOpen={isFirstDepositBonusOpen}
                onChangeOpen={setIsFirstDepositBonusOpen}
            />
            <DailyDepositBonus
                isOpen={isDailyDepositBonusOpen}
                onChangeOpen={setIsDailyDepositBonusOpen}
            />
            <DepositBonus
                isOpen={isDepositBonusOpen}
                onChangeOpen={setIsDepositBonusOpen}
            />
            <RebateOnTurnover
                isOpen={isRebateOnTurnoverOpen}
                onChangeOpen={setIsRebateOnTurnoverOpen}
            />
            <RebateOnLoseOnly
                isOpen={isRebateOnLoseOnlyOpen}
                onChangeOpen={setIsRebateOnLoseOnlyOpen}
            />
            <RebateOnWinLost
                isOpen={isRebateOnWinLostOpen}
                onChangeOpen={setIsRebateOnWinLostOpen}
            />
            <RebateOnDepositAndWithdrawal
                isOpen={isRebateOnDepositWithdrawalOpen}
                onChangeOpen={setIsRebateOnDepositWithdrawalOpen}
            />
        </>
    );
};

const RemarkBonus = () => {
    const { t } = useTranslation();
    return (
        <div className="notification__container bonus-remark">
            <div className="notification__title">
                <i className="el-icon">
                    <svg
                        viewBox="0 0 1024 1024"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fill="currentColor"
                            d="M512 64a448 448 0 1 1 0 896 448 448 0 0 1 0-896zm0 192a58.432 58.432 0 0 0-58.24 63.744l23.36 256.384a35.072 35.072 0 0 0 69.76 0l23.296-256.384A58.432 58.432 0 0 0 512 256zm0 512a51.2 51.2 0 1 0 0-102.4 51.2 51.2 0 0 0 0 102.4z"
                        ></path>
                    </svg>
                </i>
                <h3>{t("common.remark")}</h3>
            </div>
            <ul className="pl-4 ml-3 list-disc list-outside">
                <li
                    dangerouslySetInnerHTML={{
                        __html: t("content.promotions.create.remark.bonus.li1"),
                    }}
                />
                <li>{t("content.promotions.create.remark.bonus.li2")}</li>
                <li>{t("content.promotions.create.remark.bonus.li3")}</li>
                <li>{t("content.promotions.create.remark.bonus.li4")}</li>
                <li>{t("content.promotions.create.remark.bonus.li5")}</li>
            </ul>
        </div>
    );
};

const RemarkRebate = () => {
    const { t } = useTranslation();
    return (
        <div className="notification__container rebate-remark">
            <div className="notification__title">
                <i className="el-icon">
                    <svg
                        viewBox="0 0 1024 1024"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            fill="currentColor"
                            d="M512 64a448 448 0 1 1 0 896 448 448 0 0 1 0-896zm0 192a58.432 58.432 0 0 0-58.24 63.744l23.36 256.384a35.072 35.072 0 0 0 69.76 0l23.296-256.384A58.432 58.432 0 0 0 512 256zm0 512a51.2 51.2 0 1 0 0-102.4 51.2 51.2 0 0 0 0 102.4z"
                        ></path>
                    </svg>
                </i>
                <h3>{t("common.remark")}</h3>
            </div>
            <ul className="pl-4 ml-3 list-disc list-outside">
                <li
                    dangerouslySetInnerHTML={{
                        __html: t(
                            "content.promotions.create.remark.rebate.li1"
                        ),
                    }}
                />
                <li>{t("content.promotions.create.remark.rebate.li2")}</li>
                <li>{t("content.promotions.create.remark.rebate.li3")}</li>
            </ul>
        </div>
    );
};

export default CreatePromotion;
