import moment from "moment"
import { ControllerFieldState } from "react-hook-form"
import { PromotionType } from "./constants"

export const getValidateStatus = (fieldState: ControllerFieldState, name: string) => {
    if (fieldState.isTouched || fieldState.invalid) {
        return fieldState.invalid ? "error" : "success"
    }
    return ""
}

export const generateRequestBody = (data: any, type: PromotionType) => {
    const request = {
        ...data,
        type,
        applicationLimitation: data.applicationLimitation?.join(","),
        notAllowedPlayerTag: data.notAllowedPlayerTag?.join(","),
        rebateCalculation: data.rebateCalculation?.join(","),
        eligibleWeekdayToApply: data.eligibleWeekdayToApply?.join(","),
        eligibleGameProviders: data.eligibleGameProviders
            .map((gameProvider: string[]) => gameProvider[1])
            .join(","),
        image: data.localizations[0].image,
        content: data.localizations[0].content,
        title: data.localizations[0].title,
        remark: data.localizations[0].remark,
        code: data.localizations[0].code,
        startDate: moment(data.date.startDate).format(),
        endDate: moment(data.date.endDate).format(),
    }

    return request
}

export const generateEligibleGameProviders = (
    selectedGameProviders: string,
    gameProviderList: any[]
) => {
    const result: [string, string][] = selectedGameProviders.split(",").map((gameProvider) => {
        let gameProviderCategory = ""
        let providerId = ""

        gameProviderList.forEach((provider) => {
            provider.children.forEach((child: any) => {
                if (child.value === gameProvider) {
                    gameProviderCategory = provider.label
                    providerId = child.value
                }
            })
        })
        return [gameProviderCategory, providerId]
    })

    return result
}
