export enum TxnState {
    Waiting = "waiting",
    Verified = "verified",
    Approved = "approved",
    Completed = "completed",
    Rejected = "rejected",
    Cancelled = "cancelled",
    PaymentProcessing = "paymentProcessing",
    PaymentError = "paymentError",
}
