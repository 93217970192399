import { useSelector } from "react-redux";
import SelectField from "./form/select-field";
import { useTranslation } from "react-i18next";

const CurrencySelect = ({
    name = "currency",
    control,
    isDisabled,
}: {
    name?: string;
    control: any;
    isDisabled?: boolean;
}) => {
    const { currencyList } = useSelector((state: any) => state.promotion);
    const { t } = useTranslation();

    return (
        <SelectField
            name={name}
            control={control}
            formItemProps={{
                label: t("content.promotions.create.form.field.currency"),
                required: true,
                tooltip: t("content.promotions.create.form.tooltip.currency"),
            }}
            selectProps={{
                options: currencyList.map((c: any) => ({
                    value: c.currency,
                    label: c.currency,
                })),
                disabled: isDisabled,
            }}
        />
    );
};

export default CurrencySelect;
