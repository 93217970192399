import { Button, Form, Input, Modal, Select } from "antd";
import { Option } from "antd/es/mentions";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { apis } from "../../../apis";
import { httpService } from "../../../apis/httpService";
import "./ModalCreateNewTheme.scss";

type FieldType = {
    isModalOpen: boolean;
    setIsModalOpen: (value: any) => void;
};

const ModalCreateNewTheme = ({ setIsModalOpen, isModalOpen }: FieldType) => {
    const [form] = Form.useForm();
    const { t } = useTranslation();
    const onFinish = async (values: any) => {
        try {
            const newTheme = await httpService.post(apis.webAdmin.themes, {
                title: values.title,
                baseFrom: activeTheme?.id,
            });
            if (newTheme?.data) {
                toast.success("Create success!");
                form.resetFields();
                setIsModalOpen(false);
            }
        } catch (error) {
            console.log("error", error);
            toast.error("Create failed!");
        }
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log("Failed:", errorInfo);
    };
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [themes, setThemes] = useState([]);
    const [activeTheme, setActiveTheme] = useState<any>({});

    const onChangeTheme = (value: any, option: any) => {
        setActiveTheme({ value, id: option.key });
    };

    const getThemes = async () => {
        const res = await httpService.get(apis.webAdmin.themes);
        if (!activeTheme?.id) {
            setActiveTheme({ value: res.data[0].code, id: res.data[0].id });
        }
        setThemes(res.data);
    };
    useEffect(() => {
        getThemes();
    }, []);

    const handleOk = async (values: any) => {};
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    return (
        <div className="modal-create-new-theme__container">
            <Modal
                title={t("content.customizeTheme.createThemeModal.title")}
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                confirmLoading={confirmLoading}
                footer={null}
                className="modal-create-new-theme"
            >
                <div style={{ marginTop: 20 }}>
                    <Form
                        name="basic"
                        labelCol={{ span: 8 }}
                        wrapperCol={{ span: 12 }}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        style={{ textAlign: "center" }}
                        form={form}
                    >
                        <Form.Item label={t("content.customizeTheme.createThemeModal.themeName")} name="title">
                            <Input />
                        </Form.Item>
                        <Form.Item
                            label={t("content.customizeTheme.createThemeModal.copyFromTheme")}
                            name="baseFrom"
                            style={{ marginTop: 15 }}
                        >
                            <Select
                                defaultValue="default"
                                style={{ textAlign: "left" }}
                                onChange={(value, option) => onChangeTheme(value, option)}
                            >
                                {themes?.map((item: any) => {
                                    return (
                                        <Option value={item.code} key={item.id}>
                                            {item.title}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>

                        <Form.Item wrapperCol={{ offset: 8, span: 8 }} style={{ marginTop: 40 }}>
                            <Button type="primary" htmlType="submit" onClick={handleOk}>
                                {t("content.customizeTheme.createThemeModal.create")}
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </Modal>
            <ToastContainer autoClose={1500} />
        </div>
    );
};

export default ModalCreateNewTheme;
