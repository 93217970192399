import { Button, Dropdown } from "antd";
import "./styles.scss";
import { useTranslation } from "react-i18next";

const LanguageDropdown = ({ items }: { items: any }) => {
    const { t } = useTranslation();

    return (
        <Dropdown
            menu={{
                items,
            }}
            trigger={["click"]}
        >
            <div className="btn-add-language" style={{ width: 200 }}>
                <Button className="lang-button__container">
                    <i className="el-icon">
                        <svg
                            viewBox="0 0 1024 1024"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                fill="currentColor"
                                d="M512 64a448 448 0 1 1 0 896 448 448 0 0 1 0-896zm-38.4 409.6H326.4a38.4 38.4 0 1 0 0 76.8h147.2v147.2a38.4 38.4 0 0 0 76.8 0V550.4h147.2a38.4 38.4 0 0 0 0-76.8H550.4V326.4a38.4 38.4 0 1 0-76.8 0v147.2z"
                                data-darkreader-inline-fill=""
                            />
                        </svg>
                    </i>
                    {t("common.addNew")}
                </Button>
            </div>
        </Dropdown>
    );
};

export default LanguageDropdown;
