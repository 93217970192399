import { CalendarOutlined } from "@ant-design/icons"
import { Button } from "antd"
import i18next from "i18next"
import moment from "moment"
import React, { useEffect, useRef, useState } from "react"
import DateRangePicker from "react-bootstrap-daterangepicker"
import { useTranslation } from "react-i18next"
import "./DateRangePicker.sass"

export const cn = (...classNames: any) => {
    let classNameList = ""

    for (const className of classNames) {
        if (!className) continue
        if (typeof className === "string") {
            classNameList += " " + className
        }
        if (typeof className === "object") {
            const tClasses = Object.keys(className)

            for (const tClass of tClasses) {
                if (tClass && className[tClass]) {
                    classNameList += " " + tClass
                }
            }
        }
    }

    return classNameList
}

interface IDateRangePickerProps {
    fromDate: any
    toDate: any
    resultToString: boolean // time will be set from 00:00:00 to 23:59:59
    onApply: (e: any, picker: any) => void
    onCancel: (e: any, picker: any) => void
    containerStyles: any
    readOnly: boolean
    opens: "right" | "left" | "center"
    drops: "down" | "up" | "auto"
    maxDate: string // MM/DD/YYYY
    minDate: string // MM/DD/YYYY
    maxYear: string // YYYY
    minYear: string // YYYY
    maxSpan: {
        days: number
    }
    dateLimit: {
        days: number
    }
    timePicker: boolean
    timePickerDay: boolean
    timePicker24Hour: boolean
    timePickerSeconds: boolean
    singleDatePicker: boolean
    timePickerIncrement: number
    disabled: boolean
    input: any
    className: string
    containerClass: string
    children: React.ReactNode | ((value: any) => React.ReactNode)
    locale: any
    isRightIcon: boolean
    description: React.ReactNode | React.ReactElement | string
}

const CustomDateRangePicker = (props: any) => {
    const { t } = useTranslation()
    const refDateRangePicker = useRef(null)
    const {
        children,
        fromDate,
        toDate,
        resultToString,
        containerStyles,
        onCancel,
        readOnly,
        onApply,
        disabled,
        className,
        containerClass,
        isRightIcon,
        description,
        ...rest
    } = props
    /**
     * moment object
     */
    const [stValues, setStValues] = useState({
        fromDate,
        toDate,
    })

    useEffect(() => {
        if (resultToString) {
            setStValues({
                fromDate: fromDate ? moment(fromDate) : fromDate,
                toDate: toDate ? moment(toDate) : toDate,
            })
        } else {
            setStValues({
                fromDate: fromDate,
                toDate: toDate,
            })
        }
    }, [fromDate, toDate])

    // useEffect(() => {
    //     refineUI({ type: "show" })
    // })

    // useEffect(() => {
    //     const scriptTag = document.createElement("script")

    //     scriptTag.innerHTML = `
    //         function refineDateRangePicker() {
    //             $('.hourselect').each(function () {
    //                    $(this).attr('size', 1)
    //                        .attr('onfocus', 'this.size=5;')
    //                        .attr('onblur', 'this.size=1;')
    //                        .attr('onchange', "this.size=1; this.blur(); setTimeout(() => refineDateRangePicker(), 200);")
    //             })
    //             $('.minuteselect').each(function () {
    //                    $(this).attr('size', 1)
    //                        .attr('onfocus', 'this.size=5;')
    //                        .attr('onblur', 'this.size=1;')
    //                        .attr('onchange', "this.size=1; this.blur(); setTimeout(() => refineDateRangePicker(), 200);")
    //             })
    //             $('.calendar-table td').each(function () {
    //                 $(this).attr('onmousedown', "setTimeout(() => refineDateRangePicker(), 200);")
    //             })
    //         }
    //     `
    //     document.body.appendChild(scriptTag)
    // }, [])

    useEffect(() => {
        const customRangeElements = document.querySelectorAll('[data-range-key="Custom Range"]')

        if (customRangeElements) {
            customRangeElements.forEach(
                (customRangeElement) => (customRangeElement.textContent = t("calendar.custom"))
            )
        }
    }, [])

    // const refineUI = (event: any) => {
    //     if (event.type === "show") {
    //         $(".hourselect").each(function () {
    //             $(".hourselect")
    //                 .attr("size", 1)
    //                 .attr("onfocus", "this.size=5;")
    //                 .attr("onblur", "this.size=1;")
    //                 .attr(
    //                     "onchange",
    //                     "this.size=1; this.blur(); setTimeout(() => refineDateRangePicker(), 200);"
    //                 )
    //         })
    //         $(".minuteselect").each(function () {
    //             $(".minuteselect")
    //                 .attr("size", 1)
    //                 .attr("onfocus", "this.size=5;")
    //                 .attr("onblur", "this.size=1;")
    //         })
    //         $(".calendar-table td").each(function () {
    //             $(".calendar-table td").attr(
    //                 "onmousedown",
    //                 "setTimeout(() => refineDateRangePicker(), 200);"
    //             )
    //         })
    //     }
    // }
    const getTimePattern = () => {
        let pattern = "DD-MM-YYYY"

        if (props.timePicker) {
            if (props.timePicker24Hour) {
                pattern = "HH:mm DD-MM-YYYY"
            } else {
                pattern = "hh:mmA DD-MM-YYYY"
            }
        }

        return pattern
    }
    const renderTimePickerText = () => {
        const pattern = getTimePattern()

        if (stValues.fromDate && stValues.toDate) {
            return (
                moment(stValues.fromDate).format(pattern) +
                "   -   " +
                moment(stValues.toDate).format(pattern)
            )
        }
        if (stValues.fromDate && !stValues.toDate) {
            return stValues.fromDate.format(pattern)
        }
        if (props.timePickerDay) {
            return "dd/mm/yyyy"
        }

        return i18next.t("calendar.all")
    }
    const onPreApply = (event: any, picker: any) => {
        if (resultToString) {
            const fromDate = moment
                .utc(
                    picker.startDate.set({
                        hour: 0,
                        minute: 0,
                        second: 0,
                        millisecond: 999,
                    })
                )
                .format()
            const endDate = moment
                .utc(
                    picker.endDate.set({
                        hour: 23,
                        minute: 59,
                        second: 59,
                        millisecond: 999,
                    })
                )
                .format()

            const _fromDate = moment(picker.fromDate).toDate()
            const _endDate = moment(picker.endDate).toDate()

            onApply &&
                onApply(event, {
                    ...picker,
                    startDate: fromDate,
                    endDate: endDate,
                })

            return
        }
        if (onApply) {
            onApply(event, picker)
        }
    }
    const onPreCancel = (event: any, picker: any) => {
        if (onCancel) {
            onCancel(event, picker)
        }
    }
    const getContainerStyles = () => {
        let style = {}

        if (readOnly) {
            style = {
                cursor: "pointer",
            }
        }

        return {
            display: "inline-block",
            position: "relative",
            border: "1px solid #E2E8F0",
            borderRadius: ".5rem",
            ...containerStyles,
            ...style,
        }
    }
    const renderChildren = () => {
        if (typeof children === "function") {
            return children(stValues)
        }

        return children
    }

    const renderInput = () => {
        return (
            <input
                id='children-daterpicker-input'
                type='text'
                value={renderTimePickerText()}
                className={cn(`form-control ${className}`, {
                    "pr-0": Boolean(description),
                    "px-1": !description,
                })}
                style={{
                    ...(readOnly
                        ? {
                              userSelect: "none",
                              pointerEvents: "none",
                          }
                        : {}),
                    width: "100%",
                    border: "unset",
                }}
            />
        )
    }

    const renderDescription = () => {
        if (!description) return null

        return <div className={"description"}>{description}</div>
    }

    const renderChildrenCalender = () => {
        return (
            <section>
                {/* {renderDescription()} */}
                <div
                    className={cn("box-time", {
                        "flex-row-reverse": isRightIcon,
                    })}
                    // style={{
                    //     minWidth: "260px",
                    // }}
                >
                    <CalendarOutlined />
                    {/* <div
                        className={cn("d-flex flex-wrap align-items-center")}
                        style={{
                            width: "calc(100% - 2px)",
                        }}
                    > */}
                    {renderInput()}
                    {/* </div> */}
                </div>
            </section>
        )
    }

    const predefinedRanges = {
        [t("calendar.today")]: [moment(), moment()],
        [t("calendar.yesterday")]: [moment().subtract(1, "days"), moment().subtract(1, "days")],
        [t("calendar.thisWeek")]: [
            moment().startOf("week").add(1, "d"),
            moment().endOf("week").add(1, "d"),
        ],
        [t("calendar.lastWeek")]: [
            moment().subtract(1, "week").startOf("week").add(1, "d"),
            moment().subtract(1, "week").endOf("week").add(1, "d"),
        ],
        [t("calendar.thisMonth")]: [moment().startOf("month"), moment().endOf("month")],
        [t("calendar.lastMonth")]: [
            moment().subtract(1, "month").startOf("month"),
            moment().subtract(1, "month").endOf("month"),
        ],
        // [t("calendar.thisYear")]: [moment().startOf("year"), moment().endOf("year")],
        // [t("calendar.lastYear")]: [
        //     moment().subtract(1, "year").startOf("year"),
        //     moment().subtract(1, "year").endOf("year"),
        // ],
    }

    return (
        <section>
            <DateRangePicker
                {...rest}
                ref={refDateRangePicker}
                containerStyles={getContainerStyles()}
                containerClass={cn(
                    "date-ranger-picker",
                    {
                        "gs-atm--disable": disabled,
                        // default: _.isEmpty(description),
                    },
                    containerClass
                )}
                onApply={onPreApply}
                onCancel={onPreCancel}
                // onEvent={refineUI}
                startDate={stValues.fromDate}
                endDate={stValues.toDate}
                showDropdowns
                ranges={predefinedRanges}
                alwaysShowCalendars
                locale={{
                    applyLabel: <Button>Apply</Button>,
                    cancelLabel: "Cancel",
                    ...rest.locale,
                }}
            >
                {children ? renderChildren() : renderChildrenCalender()}
            </DateRangePicker>
        </section>
    )
}

export default CustomDateRangePicker
