import { PlusOutlined, UserAddOutlined } from "@ant-design/icons";
import { Checkbox, Col, Collapse, Row } from "antd";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { apis } from "../../../apis";
import { httpService } from "../../../apis/httpService";
import ModalCreateSubAccount from "../../../common/components/modal-create-sub-account/ModalCreateSubAccount";
import { ModalNewGroupList } from "../../../common/components/modal-new-groupList";
import { capitalizeFirstLetter } from "../../../common/helpers/utils";
import "./Manage.scss";

const CRUD = ["create", "read", "update", "delete"];

const myRole = {
    dashboard: {
        code: "m.dashboard",
        title: "Dashboard",
        submenu: {
            operationalDashboard: {
                code: "s.operational-dashboard",
                title: "Operational dashboard",
                abilities: {
                    getDashboardStatistical: {
                        action: "read",
                        subject: "dashboard-statistical",
                    },
                    deleteDashboardStatistical: {
                        action: "delete",
                        subject: "dashboard-statistical",
                    },
                },
            },
        },
    },
    member: {
        code: "m.member",
        title: "Member",
        submenu: {
            accountList: {
                code: "s.account-list",
                title: "Account list",
                abilities: {
                    getAccounts: { action: "Read", subject: "accounts" },
                    exportAccounts: { action: "export", subject: "accounts" },
                },
            },
            accountBalance: {
                code: "s.account-balance",
                title: "Account balance",
                abilities: {
                    getAccountBalance: { action: "Read", subject: "balance" },
                    getAccounts: { action: "Read", subject: "accounts" },
                },
            },
            tagSettings: {
                code: "s.tag-settings",
                title: "Tag settings",
                abilities: {
                    getTags: { action: "Read", subject: "tag" },
                    createTags: { action: "create", subject: "tag" },
                    updateTags: { action: "update", subject: "tag" },
                },
            },
            streamerAccount: {
                code: "s.streamer-account",
                title: "Streamer account",
                abilities: {
                    getStreamAccount: { action: "Read", subject: "stream-accounts" },
                    getAccounts: { action: "Read", subject: "accounts" },
                },
            },
        },
    },
    report: {
        code: "m.report",
        title: "Report",
        submenu: {
            winLost: {
                code: "s.win-lost",
                title: "Win/Lost",
                abilities: {
                    getAccounts: { action: "Read", subject: "accounts" },
                    getWinLostReport: { action: "Read", subject: "win-lost-report" },
                    exportWinLostReport: {
                        action: "export",
                        subject: "win-lost-report",
                    },
                },
            },
            revenueStatements: {
                code: "s.revenue-statements",
                title: "Revenue statements",
                abilities: {
                    getAccounts: { action: "Read", subject: "accounts" },
                    getRevenueStatementsReport: {
                        action: "Read",
                        subject: "revenue-statements-report",
                    },
                    exportRevenueStatementsReport: {
                        action: "export",
                        subject: "revenue-statements-report",
                    },
                },
            },
            bettingRecord: {
                code: "s.betting-record",
                title: "Betting record",
                abilities: {
                    getAccounts: { action: "Read", subject: "accounts" },
                    getBettingRecordReport: {
                        action: "Read",
                        subject: "betting-record-report",
                    },
                    exportBettingRecordReport: {
                        action: "export",
                        subject: "betting-record-report",
                    },
                },
            },
            sportsBettingRecord: {
                code: "s.sports-betting-record",
                title: "Sports betting record",
                abilities: {
                    getAccounts: { action: "Read", subject: "accounts" },
                    getSportsBettingRecordReport: {
                        action: "Read",
                        subject: "sports-betting-record-report",
                    },
                    exportSportsBettingRecordReport: {
                        action: "export",
                        subject: "sports-betting-record-report",
                    },
                },
            },
            playerWinLost: {
                code: "s.player-win-lost",
                title: "Player win/lost",
                abilities: {
                    getAccounts: { action: "Read", subject: "accounts" },
                    playerWinLostReport: {
                        action: "Read",
                        subject: "player-win-lost-report",
                    },
                    exportPlayerWinLostReport: {
                        action: "export",
                        subject: "player-win-lost-report",
                    },
                },
            },
            playerSummary: {
                code: "s.player-summary",
                title: "Player summary",
                abilities: {
                    getAccounts: { action: "Read", subject: "accounts" },
                    playerSummaryReport: {
                        action: "Read",
                        subject: "player-summary-report",
                    },
                    exportPlayerSummaryReport: {
                        action: "export",
                        subject: "player-summary-report",
                    },
                },
            },
            dailyReport: {
                code: "s.daily-report",
                title: "Daily report",
                abilities: {
                    getAccounts: { action: "Read", subject: "accounts" },
                    getDailyReport: { action: "Read", subject: "daily-report" },
                    exportDailyReport: { action: "export", subject: "daily-report" },
                },
            },
        },
    },
    transaction: {
        code: "m.transaction",
        title: "Transaction",
        submenu: {
            depositVerify: {
                code: "s.deposit-verify",
                title: "Deposit verify",
                abilities: {
                    getDepositVerify: { action: "Read", subject: "deposit-verify" },
                    submitDepositVerify: {
                        action: "submit",
                        subject: "deposit-verify",
                    },
                },
            },
            withdrawalVerify: {
                code: "s.withdrawal-verify",
                title: "Withdrawal verify",
                abilities: {
                    getWithdrawalVerify: {
                        action: "Read",
                        subject: "withdrawal-verify",
                    },
                    submitWithdrawalVerify: {
                        action: "submit",
                        subject: "withdrawal-verify",
                    },
                },
            },
            transactionRecord: {
                code: "s.transaction-record",
                title: "Transaction record",
                abilities: {
                    getTransactionRecord: {
                        action: "Read",
                        subject: "transaction-record",
                    },
                    exportTransactionRecord: {
                        action: "export",
                        subject: "transaction-record",
                    },
                },
            },
            balanceHistory: {
                code: "s.balance-history",
                title: "Balance history",
                abilities: {
                    getBalanceHistory: { action: "Read", subject: "balance-history" },
                },
            },
            companyBank: {
                code: "s.company-bank",
                title: "Company bank",
                abilities: {
                    getCompanyBank: { action: "Read", subject: "company-bank" },
                    createCompanyBank: {
                        action: "create",
                        subject: "company-bank",
                    },
                    updateCompanyBank: { action: "update", subject: "company-bank" },
                },
            },
            playerBank: {
                code: "s.player-bank",
                title: "Player bank",
                abilities: {
                    getPlayerBank: { action: "Read", subject: "player-bank" },
                    createPlayerBank: { action: "create", subject: "player-bank" },
                    updatePlayerBank: { action: "update", subject: "player-bank" },
                },
            },
            playerBankGroup: {
                code: "s.player-bank-group",
                title: "Player bank group",
                abilities: {
                    getPlayerBankGroup: {
                        action: "Read",
                        subject: "player-bank-group",
                    },
                    deletePlayerBankGroup: {
                        action: "delete",
                        subject: "player-bank-group",
                    },
                },
            },
            manualPaymentTransaction: {
                code: "s.manual-payment-transaction",
                title: "Manual payment transaction",
                abilities: {
                    getManualDepositTransaction: {
                        action: "Read",
                        subject: "manual-deposit-transaction",
                    },

                    submitManualWithdrawalTransaction: {
                        action: "submit",
                        subject: "manual-withdrawal-transaction",
                    },
                },
            },
        },
    },
};

const permissionListBEMockup = {
    dashboard: {
        code: "m.dashboard",
        title: "Dashboard",
        submenu: {
            operationalDashboard: {
                code: "s.operational-dashboard",
                title: "Operational dashboard",
                abilities: {
                    getDashboardStatistical: {
                        action: "read",
                        subject: "dashboard-statistical",
                    },
                    deleteDashboardStatistical: {
                        action: "delete",
                        subject: "dashboard-statistical",
                    },
                },
            },
        },
    },
    member: {
        code: "m.member",
        title: "Member",
        submenu: {
            accountList: {
                code: "s.account-list",
                title: "Account list",
                abilities: {
                    getAccounts: { action: "Read", subject: "accounts" },
                    exportAccounts: { action: "export", subject: "accounts" },
                },
            },
            accountBalance: {
                code: "s.account-balance",
                title: "Account balance",
                abilities: {
                    getAccountBalance: { action: "Read", subject: "balance" },
                    getAccounts: { action: "Read", subject: "accounts" },
                },
            },
            tagSettings: {
                code: "s.tag-settings",
                title: "Tag settings",
                abilities: {
                    getTags: { action: "Read", subject: "tag" },
                    createTags: { action: "create", subject: "tag" },
                    updateTags: { action: "update", subject: "tag" },
                },
            },
            streamerAccount: {
                code: "s.streamer-account",
                title: "Streamer account",
                abilities: {
                    getStreamAccount: { action: "Read", subject: "stream-accounts" },
                    getAccounts: { action: "Read", subject: "accounts" },
                },
            },
        },
    },
    report: {
        code: "m.report",
        title: "Report",
        submenu: {
            winLost: {
                code: "s.win-lost",
                title: "Win/Lost",
                abilities: {
                    getAccounts: { action: "Read", subject: "accounts" },
                    getWinLostReport: { action: "Read", subject: "win-lost-report" },
                    exportWinLostReport: {
                        action: "export",
                        subject: "win-lost-report",
                    },
                },
            },
            revenueStatements: {
                code: "s.revenue-statements",
                title: "Revenue statements",
                abilities: {
                    getAccounts: { action: "Read", subject: "accounts" },
                    getRevenueStatementsReport: {
                        action: "Read",
                        subject: "revenue-statements-report",
                    },
                    exportRevenueStatementsReport: {
                        action: "export",
                        subject: "revenue-statements-report",
                    },
                },
            },
            bettingRecord: {
                code: "s.betting-record",
                title: "Betting record",
                abilities: {
                    getAccounts: { action: "Read", subject: "accounts" },
                    getBettingRecordReport: {
                        action: "Read",
                        subject: "betting-record-report",
                    },
                    exportBettingRecordReport: {
                        action: "export",
                        subject: "betting-record-report",
                    },
                },
            },
            sportsBettingRecord: {
                code: "s.sports-betting-record",
                title: "Sports betting record",
                abilities: {
                    getAccounts: { action: "Read", subject: "accounts" },
                    getSportsBettingRecordReport: {
                        action: "Read",
                        subject: "sports-betting-record-report",
                    },
                    exportSportsBettingRecordReport: {
                        action: "export",
                        subject: "sports-betting-record-report",
                    },
                },
            },
            playerWinLost: {
                code: "s.player-win-lost",
                title: "Player win/lost",
                abilities: {
                    getAccounts: { action: "Read", subject: "accounts" },
                    playerWinLostReport: {
                        action: "Read",
                        subject: "player-win-lost-report",
                    },
                    exportPlayerWinLostReport: {
                        action: "export",
                        subject: "player-win-lost-report",
                    },
                },
            },
            playerSummary: {
                code: "s.player-summary",
                title: "Player summary",
                abilities: {
                    getAccounts: { action: "Read", subject: "accounts" },
                    playerSummaryReport: {
                        action: "Read",
                        subject: "player-summary-report",
                    },
                    exportPlayerSummaryReport: {
                        action: "export",
                        subject: "player-summary-report",
                    },
                },
            },
            dailyReport: {
                code: "s.daily-report",
                title: "Daily report",
                abilities: {
                    getAccounts: { action: "Read", subject: "accounts" },
                    getDailyReport: { action: "Read", subject: "daily-report" },
                    exportDailyReport: { action: "export", subject: "daily-report" },
                },
            },
        },
    },
    transaction: {
        code: "m.transaction",
        title: "Transaction",
        submenu: {
            depositVerify: {
                code: "s.deposit-verify",
                title: "Deposit verify",
                abilities: {
                    getDepositVerify: { action: "Read", subject: "deposit-verify" },
                    submitDepositVerify: {
                        action: "submit",
                        subject: "deposit-verify",
                    },
                },
            },
            withdrawalVerify: {
                code: "s.withdrawal-verify",
                title: "Withdrawal verify",
                abilities: {
                    getWithdrawalVerify: {
                        action: "Read",
                        subject: "withdrawal-verify",
                    },
                    submitWithdrawalVerify: {
                        action: "submit",
                        subject: "withdrawal-verify",
                    },
                },
            },
            transactionRecord: {
                code: "s.transaction-record",
                title: "Transaction record",
                abilities: {
                    getTransactionRecord: {
                        action: "Read",
                        subject: "transaction-record",
                    },
                    exportTransactionRecord: {
                        action: "export",
                        subject: "transaction-record",
                    },
                },
            },
            balanceHistory: {
                code: "s.balance-history",
                title: "Balance history",
                abilities: {
                    getBalanceHistory: { action: "Read", subject: "balance-history" },
                },
            },
            companyBank: {
                code: "s.company-bank",
                title: "Company bank",
                abilities: {
                    getCompanyBank: { action: "Read", subject: "company-bank" },
                    createCompanyBank: {
                        action: "create",
                        subject: "company-bank",
                    },
                    updateCompanyBank: { action: "update", subject: "company-bank" },
                },
            },
            playerBank: {
                code: "s.player-bank",
                title: "Player bank",
                abilities: {
                    getPlayerBank: { action: "Read", subject: "player-bank" },
                    createPlayerBank: { action: "create", subject: "player-bank" },
                    updatePlayerBank: { action: "update", subject: "player-bank" },
                },
            },
            playerBankGroup: {
                code: "s.player-bank-group",
                title: "Player bank group",
                abilities: {
                    getPlayerBankGroup: {
                        action: "Read",
                        subject: "player-bank-group",
                    },
                    createPlayerBankGroup: {
                        action: "create",
                        subject: "player-bank-group",
                    },
                    updatePlayerBankGroup: {
                        action: "update",
                        subject: "player-bank-group",
                    },
                    deletePlayerBankGroup: {
                        action: "delete",
                        subject: "player-bank-group",
                    },
                },
            },
            manualPaymentTransaction: {
                code: "s.manual-payment-transaction",
                title: "Manual payment transaction",
                abilities: {
                    getManualDepositTransaction: {
                        action: "Read",
                        subject: "manual-deposit-transaction",
                    },
                    submitManualDepositTransaction: {
                        action: "submit",
                        subject: "manual-deposit-transaction",
                    },
                    getManualWithdrawalTransaction: {
                        action: "Read",
                        subject: "manual-withdrawal-transaction",
                    },
                    submitManualWithdrawalTransaction: {
                        action: "submit",
                        subject: "manual-withdrawal-transaction",
                    },
                },
            },
        },
    },
};

export const Manage = () => {
    const { t } = useTranslation();
    const user = useSelector((state: any) => state.auth.profile);
    // console.log("user", user);

    const [activeGroupId, setActiveGroupId] = useState();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalCreateOpen, setIsModalCreateOpen] = useState(false);
    const showModalCreate = (record?: any) => {
        //  setDataEdit(record)
        setIsModalCreateOpen(true);
    };
    const handleItemClick = (index: any) => {
        setActiveGroupId(index);
    };
    const onClickNewGroupList = () => {
        setIsModalOpen(true);
    };

    const [permissionGroups, setPermissionGroups] = useState<any[]>([]);

    const getPermissionGroups = async () => {
        try {
            const res = await httpService.get(`${apis.permission.groups}/?page=${1}&take=${100}`);
            if (res.data?.result?.length) {
                setPermissionGroups(res.data.result);
            }
        } catch (error) {
            console.log("error", error);
        }
    };

    const [appPermissionsFromBE, setAppPermissionsFromBE] = useState<any>();
    const getMyPermissionRoles = async () => {
        try {
            const res = await httpService.get(`${apis.permission.menu}/?roleId=${activeGroupId}`);

            // setAppPermissionsFromBE(res?.data);
            setAppPermissionsFromBE(permissionListBEMockup); //mockup
        } catch (error) {
            console.log("error", error);
        }
    };

    const [listPermissionShow, setListPermissionShow] = useState([]);

    const makeUiCheckBox = (permissionTransformed) => {
        if (permissionTransformed) {
            const res = Object.entries(permissionTransformed)?.map(([key, item]: any, index) => {
                return {
                    key: index,
                    label: (
                        <>
                            <Row style={{ display: "flex" }}>
                                <Col span={12}>{item.title}</Col>
                                <Col span={12} style={{ display: "flex", gap: 30 }}>
                                    {CRUD.map((crud, index) => {
                                        return (
                                            <Checkbox
                                                style={{ fontSize: "12px" }}
                                                defaultChecked={item[crud] || false}
                                                checked={item[crud]}
                                                onChange={(e) =>
                                                    handleChangeCheckboxGroup(item, e.target.checked, crud)
                                                }
                                                key={index}
                                            >
                                                {capitalizeFirstLetter(crud)}
                                            </Checkbox>
                                        );
                                    })}
                                </Col>
                            </Row>
                        </>
                    ),
                    children: (
                        <>
                            {Object.entries(item.submenu)?.map(([subKey, subItem]: any, indexChild) => {
                                return (
                                    <>
                                        <Row style={{ display: "flex" }} key={indexChild}>
                                            <Col span={12} style={{ fontSize: "12px", paddingLeft: "24px" }}>
                                                {subItem.title}
                                            </Col>
                                            <Col span={12} className="permission-child ">
                                                {CRUD.map((crud, indexItem) => {
                                                    let checked = false;
                                                    let name = "";
                                                    let isAvailable = false;
                                                    Object.entries(subItem.abilities)?.find(([key, ab]: any) => {
                                                        if (ab.action.toLowerCase() === crud) {
                                                            checked = ab.checked;
                                                            name = key;
                                                            isAvailable = true;
                                                        }
                                                    });
                                                    return (
                                                        <Checkbox
                                                            style={{ fontSize: "12px" }}
                                                            defaultChecked={checked || false}
                                                            checked={checked}
                                                            name={name}
                                                            disabled={!isAvailable}
                                                            onChange={(e) => {
                                                                handleChangeCheckboxItem(
                                                                    subItem,
                                                                    e.target.checked,
                                                                    crud,
                                                                    e.target.name
                                                                );
                                                            }}
                                                            key={indexItem}
                                                        >
                                                            {capitalizeFirstLetter(crud)}
                                                        </Checkbox>
                                                    );
                                                })}
                                            </Col>
                                        </Row>
                                    </>
                                );
                            })}
                        </>
                    ),
                };
            });
            setListPermissionShow(res);
        }
    };

    function transformPermissions(listPermission, currentUserPermission) {
        if (!listPermission) return;
        const result = JSON.parse(JSON.stringify(listPermission));

        function processObject(listObj, userObj) {
            for (const key in listObj) {
                if (typeof listObj[key] === "object" && !Array.isArray(listObj[key])) {
                    // Add create, read, update, delete if not an abilities listObject
                    if (listObj[key].submenu) {
                        listObj[key].create = false;
                        listObj[key].read = false;
                        listObj[key].update = false;
                        listObj[key].delete = false;
                    }
                }
                if (typeof listObj[key] !== "object" || listObj[key] === null) {
                    continue;
                }

                if (key === "abilities") {
                    for (const ability in listObj[key]) {
                        listObj[key][ability].checked = !!userObj && !!userObj[key] && !!userObj[key][ability];
                    }
                } else {
                    processObject(listObj[key], userObj && userObj[key]);
                }
            }
        }

        processObject(result, currentUserPermission);
        return result;
    }

    function checkPermissions(permissions) {
        function processSubmenu(submenu) {
            let actions = {
                read: { all: true, exists: false },
                create: { all: true, exists: false },
                update: { all: true, exists: false },
                delete: { all: true, exists: false },
            };

            for (const item in submenu) {
                if (submenu[item].abilities) {
                    for (const ability in submenu[item].abilities) {
                        const action = submenu[item].abilities[ability].action.toLowerCase();
                        if (actions[action]) {
                            actions[action].exists = true;
                            if (!submenu[item].abilities[ability].checked) {
                                actions[action].all = false;
                            }
                        }
                    }
                }
            }

            return {
                read: actions.read.exists && actions.read.all,
                create: actions.create.exists && actions.create.all,
                update: actions.update.exists && actions.update.all,
                delete: actions.delete.exists && actions.delete.all,
            };
        }

        for (const key in permissions) {
            if (permissions[key].submenu) {
                const results = processSubmenu(permissions[key].submenu);
                permissions[key].read = results.read;
                permissions[key].create = results.create;
                permissions[key].update = results.update;
                permissions[key].delete = results.delete;
            }
        }

        return permissions;
    }

    const [permissionTransformed, setPermissionTransformed] = useState();
    useEffect(() => {
        const transformedPermissions = transformPermissions(appPermissionsFromBE, myRole); //myRole will be user.menu

        const checkedPermissions = checkPermissions(transformedPermissions);
        setPermissionTransformed(checkedPermissions);

        makeUiCheckBox(checkedPermissions);
    }, [appPermissionsFromBE, user?.menu]);

    useEffect(() => {
        makeUiCheckBox(permissionTransformed);
    }, [permissionTransformed]);

    useEffect(() => {
        getMyPermissionRoles();
    }, [activeGroupId]);

    useEffect(() => {
        if (!activeGroupId && permissionGroups.length) {
            setActiveGroupId(permissionGroups[0].id);
            getMyPermissionRoles();
        }
    }, [permissionGroups]);

    useEffect(() => {
        getPermissionGroups();
    }, [isModalOpen]);

    const handleChangeCheckboxItem = (record: any, checked: boolean, action: string, keyTarget: string) => {
        const newPermissionGroups = _.cloneDeep(permissionTransformed) as any;

        // Loop through all permissions
        for (const moduleKey in newPermissionGroups) {
            const module = newPermissionGroups[moduleKey];

            if (module.submenu) {
                for (const submenuKey in module.submenu) {
                    const submenu = module.submenu[submenuKey];

                    // Check if the submenu code matches the record code
                    if (submenu.code === record.code) {
                        if (!checked) {
                            module[action] = checked;
                        }
                        submenu.abilities[keyTarget].checked = checked;

                        break;
                    }
                }
            }
        }
        setPermissionTransformed(newPermissionGroups);
    };

    const handleChangeCheckboxGroup = (record: any, checked: boolean, action: string) => {
        const newPermissionGroups = _.cloneDeep(permissionTransformed);
        function updatePermissions(newPermissionGroups, updatedPermission) {
            // Loop through the main menu of permissionBe
            for (let menuKey in newPermissionGroups) {
                let menu = newPermissionGroups[menuKey];

                if (menu.code === updatedPermission.code) {
                    // Update the main actions (create, read, update, delete)
                    menu[action] = checked;

                    // Loop through the submenu
                    for (let submenuKey in updatedPermission.submenu) {
                        let updatedSubmenu = updatedPermission.submenu[submenuKey];

                        // Find the corresponding submenu in permissionBe
                        if (menu.submenu && menu.submenu[submenuKey]) {
                            let submenu = menu.submenu[submenuKey];

                            // Loop through abilities
                            for (let abilityKey in updatedSubmenu.abilities) {
                                if (
                                    submenu.abilities &&
                                    submenu.abilities[abilityKey] &&
                                    submenu.abilities[abilityKey].action.toLowerCase() === action
                                ) {
                                    submenu.abilities[abilityKey].checked = checked;
                                }
                            }
                        }
                    }
                }
            }
        }
        updatePermissions(newPermissionGroups, record);
        setPermissionTransformed(newPermissionGroups);
    };

    const handleUpdatePermissions = () => {
        console.log("permissionTransformed", permissionTransformed);
    };

    return (
        <>
            <div>
                <div id="manage">
                    <div id="group-list">
                        <div className="header">
                            <div className="header-name">
                                <p>{t("content.resource.manage.groupList")}</p>
                            </div>
                            <div className="header-btn">
                                <button className="btn-new" onClick={onClickNewGroupList}>
                                    <PlusOutlined style={{ marginRight: "5px" }} />
                                    {t("content.resource.manage.new")}
                                </button>
                            </div>
                        </div>
                        <div className="body">
                            <div className="container">
                                <div className="list">
                                    {permissionGroups &&
                                        permissionGroups.map((i: any) => (
                                            <div
                                                key={i.id}
                                                className={`item ${activeGroupId === i.id ? "active" : ""}`}
                                                onClick={() => handleItemClick(i.id)}
                                            >
                                                <div className="item-content">
                                                    <div onClick={showModalCreate}>
                                                        <UserAddOutlined style={{ marginRight: "5px" }} />
                                                    </div>
                                                    <div>{i.name}</div>
                                                </div>
                                            </div>
                                        ))}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div id="available-permission">
                        <div className="header">
                            <div className="header-name">{t("content.resource.manage.activeResources")}</div>
                            <div className="header-btn" onClick={handleUpdatePermissions}>
                                <button className="btn-update_permission">
                                    {t("content.resource.manage.updatePermission")}
                                </button>
                            </div>
                        </div>
                        <div className="body">
                            <div className="container">
                                <div className="list-permission">
                                    <Collapse defaultActiveKey={["1"]} ghost items={listPermissionShow} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <ModalNewGroupList isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
                </div>

                <ModalCreateSubAccount isModalOpen={isModalCreateOpen} setIsModalOpen={setIsModalCreateOpen} />
            </div>
        </>
    );
};
