import { ExclamationCircleFilled } from "@ant-design/icons"
import { Button, Switch, Table } from "antd"
import { useEffect, useState } from "react"
import { toast } from "react-toastify"
import { apis } from "../../../apis"
import { httpService } from "../../../apis/httpService"
import "./Language.scss"

type FieldType = {
    currentPassword?: string
    password?: string
    confirmPassword?: string
}

const Language = () => {
    const columnLanguage = [
        {
            title: "#",
            dataIndex: "index",
            key: "index",
            render: (text: any, record: any, index: number) => {
                return <div style={{ textAlign: "center" }}>{index + 1}</div>
            },
        },
        {
            title: "ISO",
            dataIndex: "iso",
            key: "iso",
            render: (value: any, record: any) => {
                return <div style={{ textAlign: "center" }}>{value}</div>
            },
        },
        {
            title: "Language",
            dataIndex: "language",
            key: "language",
            render: (value: any, record: any) => {
                return <div style={{ textAlign: "center" }}>{value}</div>
            },
        },
        {
            title: "Status",
            dataIndex: "status",
            key: "status",
            render: (isEnable: any, record: any) => {
                if (isEdit) {
                    return (
                        <div style={{ textAlign: "center" }}>
                            <Switch
                                defaultChecked={record.status}
                                onChange={(checked: boolean, e) =>
                                    onChangeSwitch(checked, e, record)
                                }
                                // checked={record.status}
                            />
                        </div>
                    )
                } else {
                    if (isEnable) {
                        return <div style={{ color: "#00c781", textAlign: "center" }}>Enable</div>
                    } else {
                        return <p style={{ textAlign: "center", color: "red" }}>Disable</p>
                    }
                }
            },
        },
        {
            title: "Modified By",
            dataIndex: "updatedBy",
            key: "updatedBy",
            render: (value: any, record: any) => {
                return <div style={{ textAlign: "center" }}>{value}</div>
            },
        },
        {
            title: "Modified Time",
            dataIndex: "updatedAt",
            key: "updatedAt",
            render: (value: any, record: any) => {
                return <div style={{ textAlign: "center" }}>{value}</div>
            },
        },
    ]

    const [languages, setLanguages] = useState([])
    const [isEdit, setIsEdit] = useState(false)
    const [canSave, setCanSave] = useState(false)
    const [dataSave, setDataSave] = useState<any>([])

    const onChangeSwitch = (checked: boolean, e: any, record: any) => {
        setCanSave(true)
        record.status = checked
        setDataSave([...dataSave, record])
    }

    const getLanguages = async () => {
        try {
            const res = await httpService.get(apis.company.language)

            if (res?.data) {
                setLanguages(res.data)
            }
        } catch (error) {
            console.log("error", error)
        }
    }

    useEffect(() => {
        getLanguages()
    }, [])

    const onClickEdit = () => {
        setIsEdit(true)
    }
    const onClickCancel = () => {
        setIsEdit(false)
    }

    const onClickSave = async () => {
        try {
            const res = await httpService.patch(apis.company.language, dataSave)

            if (res?.data) {
                setIsEdit(false)
                getLanguages()
                toast.success("Success")
            }
        } catch (error) {
            toast.error("Update error")

            console.log("error", error)
        }
    }

    return (
        <div className='language'>
            <div className='win-lost'>
                <div>
                    <div className='win-lost__header'>
                        <div className='header-name'>Language List</div>

                        <div className='modify-btn'>
                            {!isEdit ? (
                                <div className='modify-btn'>
                                    <Button size='small' type='primary' onClick={onClickEdit}>
                                        <span style={{ padding: "0 6px" }}>Edit</span>
                                    </Button>
                                </div>
                            ) : (
                                <div className='modify-btn'>
                                    <Button
                                        size='small'
                                        style={{
                                            marginRight: 4,
                                        }}
                                        type='primary'
                                        onClick={onClickCancel}
                                        danger
                                    >
                                        <span style={{ padding: "0 6px" }}>Cancel</span>
                                    </Button>
                                    <Button size='small' type='primary' disabled={!canSave}>
                                        <span style={{ padding: "0 6px" }} onClick={onClickSave}>
                                            Save
                                        </span>
                                    </Button>
                                </div>
                            )}
                        </div>
                    </div>

                    <div className='remark-content' style={{ padding: "26px 40px" }}>
                        <p>
                            <span style={{ width: 20 }}>
                                <ExclamationCircleFilled />
                            </span>
                            <span className='remark-title'>Remark</span>
                        </p>
                        <li className='remark-text'>
                            Enable/Disable the language that allow users to choose on the website.
                        </li>
                        <li className='remark-text'>
                            If a language disabled. 12.1 Customize Themes & 12.3 Customize Company
                            Flow will not show that language as an option.
                        </li>
                    </div>
                </div>
                <div id='admin-controls-table'>
                    <div id='table-data'>
                        <Table columns={columnLanguage} dataSource={languages} pagination={false} />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Language
