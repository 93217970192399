import { ExclamationCircleFilled } from "@ant-design/icons";
import { Button, Form, Input } from "antd";
import { useState } from "react";
import { toast } from "react-toastify";
import { apis } from "../../../apis";
import { httpService } from "../../../apis/httpService";
import { useTranslation } from "react-i18next";
import "./ChangeLoginName.scss";

type FieldType = {
  newLoginName: string;
};

const ChangeLoginName = () => {
  const { t } = useTranslation();
  const [error, setError] = useState("");
  const onFinish = async (values: any) => {
    if (values.newLoginName?.length < 6 || values.newLoginName?.length > 15) {
      setError(t("content.security.changeLoginName.newLoginNameError"));
    }
    try {
      const res = await httpService.put(apis.security.changeLoginName, values);
      if (res.data) {
        toast.success(
          t("content.security.changeLoginName.newLoginNameSuccess")
        );
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  const onFinishFailed = (errorInfo: any) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <div className="change-login-name">
      <div className="win-lost">
        <div>
          <div className="win-lost__header">
            <div className="header-name">
              {t("content.security.changeLoginName.title")}
            </div>
          </div>

          <div className="remark-content" style={{ padding: "26px 40px" }}>
            <p>
              <span style={{ width: 20 }}>
                <ExclamationCircleFilled />
              </span>
              <span className="remark-title">
                {t("content.security.changeLoginName.remark")}
              </span>
            </p>
            <li className="remark-text">
              {t("content.security.changeLoginName.remarkText1")}
            </li>
            <li className="remark-text">
              {t("content.security.changeLoginName.remarkText2")}
            </li>
            <li className="remark-text">
              {t("content.security.changeLoginName.remarkText3")}
            </li>
          </div>
        </div>
        <div
          className="table-main"
          style={{ textAlign: "center", padding: "54px 0" }}
        >
          <Form
            name="basic"
            labelCol={{ span: 8 }}
            wrapperCol={{ span: 8 }}
            style={{ textAlign: "center" }}
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <Form.Item<FieldType>
              label={t("content.security.changeLoginName.newLoginName")}
              name="newLoginName"
              rules={[
                {
                  required: true,
                  message: t(
                    "content.security.changeLoginName.newLoginNameMsg"
                  ),
                },
              ]}
            >
              <Input />
            </Form.Item>

            {error && <div style={{ color: "red" }}>{error}</div>}

            <Form.Item
              wrapperCol={{ offset: 8, span: 8 }}
              style={{ marginTop: 40 }}
            >
              <Button style={{ marginRight: 8 }}>
                {t("content.security.changeLoginName.reset")}
              </Button>
              <Button type="primary" htmlType="submit">
                {t("content.security.changeLoginName.submit")}
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default ChangeLoginName;
