import "./DailyReport.scss";

import {
  Button,
  Checkbox,
  Col,
  Dropdown,
  Image,
  Pagination,
  PaginationProps,
  Popover,
  Row,
  Select,
  Tooltip,
} from "antd";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CSVLink } from "react-csv";

import moment from "moment";
import { apis } from "../../../apis";

import { Loading3QuartersOutlined } from "@ant-design/icons";
import { httpService } from "../../../apis/httpService";

import { CustomDateRangePicker } from "../../../common/components/date-range-picker";
import { getCurrencyList } from "../../../common/helpers/utils";
import QuestionSvg from "../../promotions/components/icons/question-svg";

const DailyReport = () => {
  const { Option } = Select;
  const { t } = useTranslation();
  const columns = [
    {
      title: t("content.dailyReport.col.date"),
      dataIndex: "date",
      key: "date",
    },
    {
      title: t("content.dailyReport.col.newRegister"),
      dataIndex: "newRegister",
      key: "newRegister",
    },
    {
      title: t("content.dailyReport.col.firstDepositAccount"),
      dataIndex: "firstDepositAccount",
      key: "firstDepositAccount",
    },
    {
      title: t("content.dailyReport.col.depositAccount"),
      dataIndex: "depositAccount",
      key: "depositAccount",
    },
    {
      title: t("content.dailyReport.col.depositCount"),
      dataIndex: "depositCount",
      key: "depositCount",
    },
    {
      title: t("content.dailyReport.col.depositAmount"),
      dataIndex: "depositAmount",
      key: "depositAmount",
    },
    {
      title: t("content.dailyReport.col.withdrawalAccount"),
      dataIndex: "withdrawalAccount",
      key: "withdrawalAccount",
    },
    {
      title: t("content.dailyReport.col.withdrawalCount"),
      dataIndex: "withdrawalCount",
      key: "withdrawalCount",
    },
    {
      title: t("content.dailyReport.col.withdrawalAmount"),
      dataIndex: "withdrawalAmount",
      key: "withdrawalAmount",
    },

    {
      title: t("content.dailyReport.col.netTurnover"),
      dataIndex: "netTurnover",
      key: "netTurnover",
    },
    {
      title: t("content.dailyReport.col.memberWinLost"),
      dataIndex: "memberWinLost",
      key: "memberWinLost",
    },
    {
      title: t("content.dailyReport.col.commission"),
      dataIndex: "commission",
      key: "commission",
    },
    {
      title: t("content.dailyReport.col.promotionRebate"),
      dataIndex: "promotionRebate",
      key: "promotionRebate",
    },
    {
      title: t("content.dailyReport.col.promotionBonus"),
      dataIndex: "promotionBonus",
      key: "promotionBonus",
    },
    {
      title: t("content.dailyReport.col.referralBonus"),
      dataIndex: "referralBonus",
      key: "referralBonus",
    },
    {
      title: t("content.dailyReport.col.manualBonus"),
      dataIndex: "manualBonus",
      key: "manualBonus",
    },
    {
      title: t("content.dailyReport.col.manualRebate"),
      dataIndex: "manualRebate",
      key: "manualRebate",
    },
    {
      title: t("content.dailyReport.col.companyWinLost"),
      dataIndex: "companyWinLost",
      key: "companyWinLost",
    },
  ];

  const contentStatisticalChildren = (
    <div>{t("content.dailyReport.contentStatisticalChildren")}</div>
  );
  const tabs = ["Credit", "Cash"];

  const [totalPage, setTotalPage] = useState(500);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [currency, setCurrency] = useState("VND");
  const [loadings, setLoadings] = useState(false);
  const [checkColumnCash, setCheckColumnCash] = useState(() =>
    columns.map((item) => item.key)
  );
  const [dataDaily, setDataDaily] = useState<any[]>([]);
  const [total, setTotal] = useState();

  const handleCheckColum = (key: string) => {
    const isChecked = checkColumnCash.includes(key);
    if (isChecked) {
      const newState = checkColumnCash.filter((item) => item !== key);
      setCheckColumnCash(newState);
    } else {
      setCheckColumnCash([...checkColumnCash, key]);
    }
  };

  const currentDate = new Date();
  const fromDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate() - 30
  );

  const [filterDate, setFilterDate] = useState({
    fromDate: fromDate,
    toDate: currentDate,
  });

  const getDailyReportList = async () => {
    const payload = {
      fromDate: filterDate.fromDate.toISOString(),
      toDate: filterDate.toDate.toISOString(),
      currency,
      page,
      take: pageSize,
    };
    try {
      const res = await httpService.post(apis.report.daily, payload);
      setDataDaily(res.data?.dailyReports);
      setTotal(res.data.total);
      setTotalPage(res.data?.meta?.pageCount);
    } catch (error) {
      setDataDaily([]);
      console.log("error", error);
    }
  };

  useEffect(() => {
    getDailyReportList();
  }, [currency, filterDate.fromDate, filterDate.toDate]);

  const onCurrencyChange = (value: any) => {
    setCurrency(value);
  };

  const onClickApplyDate = (event: any, picker: any) => {
    const fromDate = moment(picker.startDate).toDate();
    const toDate = moment(picker.endDate).toDate();
    setFilterDate({
      ...filterDate,
      fromDate,
      toDate,
    });
  };

  const onChangePage: PaginationProps["onChange"] = (
    pageNumber: number,
    pageSize: number
  ) => {
    setPage(pageNumber);
    setPageSize(pageSize);
  };

  const clearDate = () => {
    setFilterDate({
      ...filterDate,
      fromDate: fromDate,
      toDate: currentDate,
    });
  };

  const [currencyList, setCurrencyList] = useState<any[]>([]);
  useEffect(() => {
    if (!currencyList?.length) {
      const fetchCurrencyList = async () => {
        try {
          const res = await getCurrencyList();
          setCurrencyList(res.data);
        } catch (error) {
          console.log(error);
        }
      };

      fetchCurrencyList();
    }
  }, []);

  const renderTooltip = (key: string) => {
    let tooltipTile;
    let label;

    if (key === "depositAmount") {
      /* {t(
         "content.promotions.management.approvedAmount"
              )} */

      label = t("content.dailyReport.col.depositAmount");
      tooltipTile = t("content.dailyReport.depositAmountFormula");
    }

    if (key === "withdrawalAmount") {
      label = t("content.dailyReport.col.withdrawalAmount");
      tooltipTile = t("content.dailyReport.withdrawalAmountFormula");
    }

    if (key === "companyWinLost") {
      label = t("content.dailyReport.col.companyWinLost");
      tooltipTile = t("content.dailyReport.companyWinLostFormula");
    }

    return (
      <td
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <span style={{ marginRight: 2 }}>{label}</span>
        <Tooltip placement="right" title={tooltipTile}>
          <span>
            <QuestionSvg />
          </span>
        </Tooltip>
      </td>
    );
  };

  return (
    <div>
      <div id="daily-report">
        <div className="filter-main">
          <div className="header">
            <div className="header-name">{t("content.dailyReport.title")}</div>
            <div className="header-export">
              <Button>
                <Image
                  width={20}
                  src="/excel_icon.png"
                  style={{ marginBottom: 4, paddingRight: 2 }}
                />
                <CSVLink
                  filename={"Expense_Table.csv"}
                  data={dataDaily}
                  className="btn btn-primary"
                >
                  <span> {t("content.dailyReport.export")}</span>
                </CSVLink>
              </Button>
            </div>
          </div>
          <Row className="filter-bar">
            <Col className="date-range-transaction-history">
              <div>{t("content.dailyReport.statisticalDate")}</div>
              <Popover content={contentStatisticalChildren}>
                <Image
                  preview={false}
                  width={16}
                  src="/question_mark_icon.svg"
                />
              </Popover>
              <CustomDateRangePicker
                onApply={onClickApplyDate}
                onCancel={clearDate}
                containerStyles={{
                  width: "214px",
                  padding: "3px 0px",
                  paddingLeft: "4px",
                  borderRadius: "6px",
                  border: "1px solid #d9d9d9",
                }}
                fromDate={filterDate.fromDate}
                toDate={filterDate.toDate}
                resultToString
                opens={"center"}
                readOnly
              />
            </Col>

            <Col>
              <div>{t("content.dailyReport.currency")}</div>
              <Select
                defaultValue="VND"
                style={{ width: 120 }}
                onChange={onCurrencyChange}
              >
                {currencyList?.map((item, index) => (
                  <Option value={item?.currency} key={index}>
                    {item?.currency}
                  </Option>
                ))}
              </Select>
            </Col>

            <Col>
              <Button
                className="submit-btn"
                type="primary"
                onClick={getDailyReportList}
              >
                {loadings && (
                  <tr className="loading" style={{ marginRight: 20 }}>
                    <Loading3QuartersOutlined
                      className="loading-icon"
                      style={{
                        marginRight: "20px",
                        width: "10px",
                        color: "white",
                      }}
                    />
                  </tr>
                )}
                <span>{t("content.dailyReport.submit")}</span>
              </Button>
              {/* <Button style={{ marginLeft: 10 }}>
                                <span>Reset</span>
                            </Button> */}
            </Col>
          </Row>

          <div className="filter-table" style={{ padding: "4px" }}>
            <Pagination
              onChange={onChangePage}
              defaultCurrent={1}
              total={totalPage * 10}
              showQuickJumper
              style={{ fontSize: 13 }}
            />
            <Dropdown
              dropdownRender={() => (
                <div className="filter-table__dropdown">
                  {columns.map((item: any, index: any) => (
                    <div
                      onClick={() => handleCheckColum(item.key)}
                      style={{
                        display: "flex",
                        padding: "6px 10px",
                        backgroundColor: "#fff",
                      }}
                    >
                      <Checkbox
                        style={{ marginRight: 10 }}
                        checked={checkColumnCash.includes(item.key)}
                      />
                      <div key={index}>{item?.title}</div>
                    </div>
                  ))}
                </div>
              )}
            >
              <a onClick={(e) => e.preventDefault()}>
                <div className="bars">
                  <img width={16} src="/bars_icon.svg"></img>
                </div>
              </a>
            </Dropdown>
          </div>
        </div>

        <div className="table-main">
          {loadings && <div className="layout-table"></div>}
          <table>
            <thead>
              <tr>
                {columns.map((item, index) => {
                  const isChecked = checkColumnCash.includes(item.key);
                  if (isChecked) {
                    if (
                      item.key === "depositAmount" ||
                      item.key === "withdrawalAmount" ||
                      item.key === "companyWinLost"
                    ) {
                      return renderTooltip(item.key);
                    }
                    return <td key={index}>{item?.title}</td>;
                  }
                })}
              </tr>
            </thead>
            {loadings && (
              <tr className="loading">
                <Loading3QuartersOutlined className="loading-icon" />
              </tr>
            )}

            {!dataDaily?.length && (
              <div className="no-data">
                <p style={{ color: "#888" }}>
                  {t("content.dailyReport.col.noData")}
                </p>
              </div>
            )}

            {dataDaily?.length > 0 && (
              <tbody>
                <tr className="grand-total-table">
                  <td
                    style={{
                      textAlign: "center",
                      fontWeight: 500,
                    }}
                  >
                    {t("content.dailyReport.col.grandTotal")}
                  </td>

                  {total &&
                    columns.map((i) => {
                      if (i.key === "date") return;
                      const isChecked = checkColumnCash.includes(i.key);
                      if (isChecked) {
                        if (
                          i.key === "date" ||
                          i.key === "newRegister" ||
                          i.key === "firstDepositAccount" ||
                          i.key === "depositAccount" ||
                          i.key === "depositCount" ||
                          i.key === "withdrawalAccount" ||
                          i.key === "withdrawalCount"
                        ) {
                          return (
                            <td style={{ textAlign: "center" }}>
                              <span>{total[i.key]}</span>
                            </td>
                          );
                        }
                        return (
                          <td
                            style={{
                              textAlign: "right",
                            }}
                          >
                            <span>{total[i.key]}</span>
                          </td>
                        );
                      }
                    })}
                </tr>

                {dataDaily?.map((items: any, index) => (
                  <tr>
                    {columns.map((col: any) => {
                      const isChecked = checkColumnCash.includes(col.key);

                      if (isChecked) {
                        if (
                          col.key === "date" ||
                          col.key === "newRegister" ||
                          col.key === "firstDepositAccount" ||
                          col.key === "depositAccount" ||
                          col.key === "depositCount" ||
                          col.key === "withdrawalAccount" ||
                          col.key === "withdrawalCount"
                        ) {
                          return (
                            <td style={{ textAlign: "center" }} key={index}>
                              <span>{items[col.key]}</span>
                            </td>
                          );
                        }
                        if (col.key === "betCount" || col.key === "currency") {
                          return (
                            <td style={{ textAlign: "center" }} key={index}>
                              <span>{items[col.key]}</span>
                            </td>
                          );
                        }

                        return (
                          <td
                            style={{
                              textAlign: "right",
                            }}
                            key={index}
                          >
                            <span>{items[col.key]}</span>
                          </td>
                        );
                      }
                    })}
                  </tr>
                ))}
              </tbody>
            )}
            {loadings && (
              <tr className="loading-mask-table">
                <div className="loading-icon-table">
                  <Loading3QuartersOutlined className="loading-icon" />
                </div>
              </tr>
            )}
          </table>
        </div>

        <div className="footer-table footer-table__bottom ">
          <Pagination
            onChange={onChangePage}
            defaultCurrent={1}
            total={totalPage * 10}
            showQuickJumper
            style={{ fontSize: 13 }}
          />
        </div>
      </div>
    </div>
  );
};

export default DailyReport;
