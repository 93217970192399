import { Card, Col, DatePicker, Form, Input, Radio, Row, Select } from "antd";
import dayjs from "dayjs";
import { ChangeEventHandler } from "react";
import { UserRole } from "../../constant/UserRole";

export interface IUserListFilterProps {
  initialValues: any;
  dateFormat: any;
  handleSearch: ChangeEventHandler<HTMLInputElement>;
  onUserRoleChange: Function;
}

const UserListFilter: React.FC<IUserListFilterProps> = (
  props: IUserListFilterProps
) => {
  const { initialValues, dateFormat, handleSearch, onUserRoleChange } = props;

  const { RangePicker } = DatePicker;

  return (
    <Card title='Lọc'>
      <Form
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        initialValues={initialValues}
        layout='horizontal'
      >
        <Row>
          <Col span={6}>
            <Form.Item label='Ngày thống kê' name='dateType'>
              <Select>
                <Select.Option value={0}>Tất cả</Select.Option>
                <Select.Option value={1}>Ngày đăng nhập</Select.Option>
                <Select.Option value={2}>Ngày đăng ký</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <div className='col-inner'>
              <Form.Item label={<span></span>} wrapperCol={{ span: 24 }}>
                <RangePicker
                  defaultValue={[
                    dayjs("2023/09/01", dateFormat),
                    dayjs("2023/10/01", dateFormat),
                  ]}
                  format={dateFormat}
                />
              </Form.Item>
            </div>
          </Col>
        </Row>
        <Row>
          <Col span={6}>
            <Form.Item label='Loại người chơi' name='ty' initialValue={"a"}>
              <Radio.Group>
                <Radio value='a'>Online</Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
          <Col span={9}>
            <div className='col-inner'>
              <Form.Item label='Group' name='group'>
                <Select
                  onChange={(value: string | Number) => {
                    onUserRoleChange(value);
                  }}
                >
                  <Select.Option value={0}>Tất cả</Select.Option>
                  {Object.values(UserRole).map(
                    (value: string, index: number) => (
                      <Select.Option value={value} key={index + 1}>
                        {value}
                      </Select.Option>
                    )
                  )}
                </Select>
              </Form.Item>
            </div>
          </Col>
          <Col span={9}>
            <div className='col-inner'>
              <Form.Item label={<span></span>} name='term'>
                <Input placeholder='Từ khoá' onChange={handleSearch} />
              </Form.Item>
            </div>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default UserListFilter;
