import {
    Button,
    Col,
    DatePicker,
    Image,
    Input,
    PaginationProps,
    Row,
    Select,
    Space,
    Table,
} from "antd"
import { useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"

import moment from "moment"

import { Link } from "react-router-dom"

import { Loading3QuartersOutlined } from "@ant-design/icons"
import { apis } from "../../../../apis"
import { httpService } from "../../../../apis/httpService"
import { CustomDateRangePicker } from "../../../../common/components/date-range-picker"

import { TransactionType } from "../../../../common/constants/transaction.enum"
import { ISort } from "../../../../interfaces/Global"
import { paths } from "../../../../routes/path"
import "./PaymentTransaction.scss"

const PaymentTransaction = () => {
    const { t } = useTranslation()

    const { RangePicker } = DatePicker
    const { Option } = Select

    const dataTotal = [
        {
            currency: "VND",
            deposit: 8441826.0,
            withdrawal: 9142118.0032,
        },
        {
            currency: "SGD",
            deposit: 1561188.0,
            withdrawal: 352799.0094,
        },
    ]
    const dataSource = [
        {
            account: "testkmsd1",
            date: "2023-12-13 20:32:38 (+7)",
            providers: "PrincePay",
            refNo: "20062_218228",
            transactionType: "Payment Deposit",
            createBy: "Payment Transaction",
            currency: "VND",
            amount: "200000",

            Remark: "",
            transactionStatus: "PaymentPaid",
            modifiedBy: "cskh2",
            modifiedTime: "2024-03-16 10:01:11 PM (-4)",
        },
        {
            account: "testkmsd1",
            date: "2023-12-13 20:32:38 (+7)",
            providers: "PrincePay",
            refNo: "20062_218228",
            transactionType: "Payment Deposit",
            createBy: "Payment Transaction",
            currency: "VND",
            amount: "200000",

            Remark: "",
            transactionStatus: "PaymentPaid",
            modifiedBy: "cskh2",
            modifiedTime: "2024-03-16 10:01:11 PM (-4)",
        },
        {
            account: "testkmsd1",
            date: "2023-12-13 20:32:38 (+7)",
            providers: "PrincePay",
            refNo: "20062_218228",
            transactionType: "Payment Deposit",
            createBy: "Payment Transaction",
            currency: "VND",
            amount: "200000",

            Remark: "",
            transactionStatus: "PaymentPaid",
            modifiedBy: "cskh2",
            modifiedTime: "2024-03-16 10:01:11 PM (-4)",
        },
        {
            account: "testkmsd1",
            date: "2023-12-13 20:32:38 (+7)",
            providers: "PrincePay",
            refNo: "20062_218228",
            transactionType: "Payment Deposit",
            createBy: "Payment Transaction",
            currency: "VND",
            amount: "200000",

            Remark: "",
            transactionStatus: "PaymentPaid",
            modifiedBy: "cskh2",
            modifiedTime: "2024-03-16 10:01:11 PM (-4)",
        },
        {
            account: "testkmsd1",
            date: "2023-12-13 20:32:38 (+7)",
            providers: "PrincePay",
            refNo: "20062_218228",
            transactionType: "Payment Deposit",
            createBy: "Payment Transaction",
            currency: "VND",
            amount: "200000",

            Remark: "",
            transactionStatus: "PaymentPaid",
            modifiedBy: "cskh2",
            modifiedTime: "2024-03-16 10:01:11 PM (-4)",
        },
        {
            account: "testkmsd1",
            date: "2023-12-13 20:32:38 (+7)",
            providers: "PrincePay",
            refNo: "20062_218228",
            transactionType: "Payment Deposit",
            createBy: "Payment Transaction",
            currency: "VND",
            amount: "200000",

            Remark: "",
            transactionStatus: "PaymentPaid",
            modifiedBy: "cskh2",
            modifiedTime: "2024-03-16 10:01:11 PM (-4)",
        },
        {
            account: "testkmsd1",
            date: "2023-12-13 20:32:38 (+7)",
            providers: "PrincePay",
            refNo: "20062_218228",
            transactionType: "Payment Deposit",
            createBy: "Payment Transaction",
            currency: "VND",
            amount: "200000",

            Remark: "",
            transactionStatus: "PaymentPaid",
            modifiedBy: "cskh2",
            modifiedTime: "2024-03-16 10:01:11 PM (-4)",
        },
        {
            account: "testkmsd1",
            date: "2023-12-13 20:32:38 (+7)",
            providers: "PrincePay",
            refNo: "20062_218228",
            transactionType: "Payment Deposit",
            createBy: "Payment Transaction",
            currency: "VND",
            amount: "200000",

            Remark: "",
            transactionStatus: "PaymentPaid",
            modifiedBy: "cskh2",
            modifiedTime: "2024-03-16 10:01:11 PM (-4)",
        },
        {
            account: "testkmsd1",
            date: "2023-12-13 20:32:38 (+7)",
            providers: "PrincePay",
            refNo: "20062_218228",
            transactionType: "Payment Deposit",
            createBy: "Payment Transaction",
            currency: "VND",
            amount: "200000",

            Remark: "",
            transactionStatus: "PaymentPaid",
            modifiedBy: "cskh2",
            modifiedTime: "2024-03-16 10:01:11 PM (-4)",
        },
        {
            account: "testkmsd1",
            date: "2023-12-13 20:32:38 (+7)",
            providers: "PrincePay",
            refNo: "20062_218228",
            transactionType: "Payment Deposit",
            createBy: "Payment Transaction",
            currency: "VND",
            amount: "200000",

            Remark: "",
            transactionStatus: "PaymentPaid",
            modifiedBy: "cskh2",
            modifiedTime: "2024-03-16 10:01:11 PM (-4)",
        },
    ]
    const columnsTotal = [
        {
            title: "Total Deposit",
            dataIndex: "deposit",
            key: "deposit",
            sorter: (a: any, b: any) => a.deposit - b.deposit,
        },
        {
            title: "Total Withdrawal",
            dataIndex: "withdrawal",
            key: "withdrawal",
            sorter: (a: any, b: any) => a.withdrawal - b.withdrawal,
        },
    ]

    function makeColumnsTotal() {
        const columns = [
            {
                title: "Currency",
                dataIndex: "currency",
                key: "currency",
                width: "33%",
                render: (value: any, record: any) => {
                    return (
                        <div style={{ textAlign: "center", color: "rgb(127, 127, 127)" }}>
                            {value}
                        </div>
                    )
                },
            },
        ]

        for (const col of columnsTotal) {
            if (col.key === "deposit") {
                columns.push({
                    title: col.title,
                    key: col.key,
                    dataIndex: col.dataIndex,
                    width: "33%",
                    render: (value: any, record: any) => {
                        return <div className='primary-color'>{value}</div>
                    },
                })
            } else {
                columns.push({
                    title: col.title,
                    key: col.key,
                    dataIndex: col.dataIndex,
                    width: "33%",
                    render: (value: any, record: any) => {
                        return <div className='error-color'>{value}</div>
                    },
                })
            }
        }

        return columns
    }

    const columnsSource = [
        {
            title: (
                <div style={{ display: "block" }}>
                    <div>Currency</div>
                    <div>Amount</div>
                </div>
            ),
            dataIndex: "currency",
            key: "currency",
        },
    ]
    function makeColumnsDataSource() {
        const columns = [
            {
                title: "#",
                dataIndex: "index",
                key: "index",

                render: (text: any, record: any, index: number) => {
                    return (
                        <div style={{ textAlign: "center", padding: "8px 16px" }}>{index + 1}</div>
                    )
                },
            },
            {
                title: "Date",
                dataIndex: "date",
                key: "date",
            },
            {
                title: (
                    <div style={{ display: "block" }}>
                        <div>Providers</div>
                        <div>Ref No</div>
                    </div>
                ),
                dataIndex: "providers",
                key: "providers",
                render: (text: any, record: any) => (
                    <div style={{ display: "block", textAlign: "center" }}>
                        <div>{record.providers}</div>
                        <div>{record.refNo}</div>
                    </div>
                ),
            },
            {
                title: "Transaction Type",
                dataIndex: "transactionType",
                key: "transactionType",
            },

            {
                title: "Account",
                dataIndex: "account",
                key: "account",
                render: (value: any, record: any) => {
                    return (
                        <Link
                            to={paths.USER_DETAIL.replace(":id", record.userId)}
                            style={{ color: "#7f7f7f" }}
                        >
                            {value}
                        </Link>
                    )
                },
            },

            {
                title: "Create By",
                dataIndex: "createBy",
                key: "createBy",
            },
            {
                title: "Remark",
                dataIndex: "remark",
                key: "remark",
            },
            {
                title: "Transaction Status",
                dataIndex: "transactionStatus",
                key: "transactionStatus",
            },
            {
                title: (
                    <div style={{ display: "block" }}>
                        <div>Modified By</div>
                        <div>Modified Time</div>
                    </div>
                ),
                dataIndex: "modified",
                key: "modified",

                render: (text: any, record: any) => (
                    <div style={{ display: "block" }}>
                        <div>{record.modifiedBy}</div>
                        <div>{record.modifiedTime}</div>
                    </div>
                ),
            },
        ]

        for (const col of columnsSource) {
            if (col.key === "currency") {
                columns.splice(5, 0, {
                    title: col.title,
                    key: col.key,
                    dataIndex: col.dataIndex,
                    render: (value: any, record: any) => {
                        if (record.amount > 0) {
                            return (
                                <div style={{ display: "block", textAlign: "center" }}>
                                    <div>{record.currency}</div>
                                    <div className='primary-color'>{record.amount}</div>
                                </div>
                            )
                        } else {
                            return (
                                <div style={{ display: "block", textAlign: "center" }}>
                                    <div>{record.currency}</div>
                                    <div className='error-color'>{record.amount}</div>
                                </div>
                            )
                        }
                    },
                })
            }
        }

        return columns
    }

    const columnsTotalMade = useMemo(() => makeColumnsTotal(), [dataTotal])

    const columnsData = useMemo(() => makeColumnsDataSource(), [dataSource])

    const [username, setUsername] = useState("")
    const [currency, setCurrency] = useState("")
    const [transactionType, setTransactionType] = useState("")

    const [loadings, setLoadings] = useState(false)

    //emtydata
    const [emptyText, setEmptyText] = useState("Click Search to see information")
    let locale = {
        emptyText,
    }

    const currentDate = new Date()
    const fromDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate() - 30
    )

    const [filterDate, setFilterDate] = useState({
        fromDate: fromDate,
        toDate: currentDate,
    })

    const [txnList, setTxnList] = useState([])
    const [totalPage, setTotalPage] = useState(10)
    const [textSearch, setTextSearch] = useState("")
    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(10)
    const [orderBy, setOrderBy] = useState<ISort | null>(null)

    const getTxnList = async () => {
        try {
            const orderStr =
                orderBy === null ? "" : `&sortKey=${orderBy.field}&sortOrder=${orderBy.order}`
            const res = await httpService.get(
                `${
                    apis.txn.list
                }/?page=${page}&query=${textSearch}&take=${pageSize}${orderStr}&fromDate=${filterDate.fromDate.toISOString()}&toDate=${filterDate.toDate.toISOString()}&username=${username}&type=${transactionType}&currency=${currency}`
            )

            //apply when have real data
            if (res.data?.result?.length) {
                const result = res.data.result.map((item: any) => {
                    return {
                        date: moment.utc(item.createdAt).add(7, "hours"),
                        txnNo: item.code,
                        transactionType: item.type,
                        username: item.owner.username,
                        currency: item.currency,
                        deposit: item.type === TransactionType.Deposit ? item.amount : 0,
                        withdrawal: item.type === TransactionType.Withdraw ? item.amount : 0,
                        createdBy: item.createdBy,
                        note: item.note,
                        userId: item.owner.id,
                    }
                })

                setTxnList(result)
                setTotalPage(res?.data?.meta.itemCount)
            }
        } catch (error) {
            console.log("error", error)
        }
    }

    useEffect(() => {
        getTxnList()
    }, [
        page,
        textSearch,
        pageSize,
        orderBy,
        username,
        currency,
        transactionType,
        filterDate.fromDate,
        filterDate.toDate,
    ])

    const onUsernameChange = (e: any) => {
        setUsername(e.target.value)
    }

    const onCurrencyChange = (value: any) => {
        setCurrency(value)
    }

    const onTransactionTypeChange = (value: any) => {
        setTransactionType(value)
    }

    const onClickApplyDate = (event: any, picker: any) => {
        const fromDate = moment(picker.startDate).toDate()
        const toDate = moment(picker.endDate).toDate()
        setFilterDate({
            ...filterDate,
            fromDate,
            toDate,
        })
    }

    const clearDate = () => {
        setFilterDate({
            ...filterDate,
            fromDate: fromDate,
            toDate: currentDate,
        })
    }

    const handleSearch = async () => {
        await getTxnList()
    }

    const enterLoading = () => {
        setLoadings(true)

        setTimeout(() => {
            if (dataSource.length === 0) {
                setEmptyText("No Data")
            }
            setLoadings(false)
        }, 3000)
    }
    const onChangePage: PaginationProps["onChange"] = (pageNumber: any) => {
        console.log("Page: ", pageNumber)
    }

    return (
        <div id='payment-transaction'>
            <Col id='filter'>
                <div className='filter-header' style={{ height: "32px" }}>
                    <div className='filter-header__name'>Date Setting</div>
                    <div></div>
                </div>
                <div className='filter-bar'>
                    <Row gutter={9} className='filter'>
                        <Col className='date-range-transaction-history'>
                            <div className='filter__name'>Date</div>

                            <CustomDateRangePicker
                                onApply={onClickApplyDate}
                                onCancel={clearDate}
                                containerStyles={{
                                    width: "214px",
                                    padding: "3px 0px",
                                    paddingLeft: "4px",
                                    borderRadius: "6px",
                                    border: "1px solid #d9d9d9",
                                }}
                                fromDate={filterDate.fromDate}
                                toDate={filterDate.toDate}
                                resultToString
                                opens={"center"}
                                readOnly
                            />
                        </Col>

                        <Col>
                            <div className='filter__name'>Username</div>
                            <Input placeholder='Username' onChange={onUsernameChange} />
                        </Col>

                        <Col>
                            <div className='filter__name'>Currency</div>
                            <Select
                                defaultValue='All'
                                style={{ width: 120 }}
                                onChange={onCurrencyChange}
                            >
                                <Option value=''>All</Option>
                                <Option value='tmp'>AED</Option>
                                <Option value='tmp'>AUD</Option>
                                <Option value='tmp'>BDT</Option>
                                <Option value='tmp'>BRL</Option>
                                <Option value='tmp'>CAD</Option>
                                <Option value='tmp'>CHF</Option>
                                <Option value='tmp'>CNY</Option>
                                <Option value='tmp'>EUR</Option>
                                <Option value='tmp'>GBP</Option>
                                <Option value='tmp'>HKD</Option>
                                <Option value='tmp'>IDR</Option>
                                <Option value='tmp'>INR</Option>
                                <Option value='JPY'>JPY</Option>
                                <Option value='KRW'>KRW</Option>
                                <Option value='LAK'>LAK</Option>
                                <Option value='MMK'>MMK</Option>
                                <Option value='MXN'>MXN</Option>
                                <Option value='MYR'>MYR</Option>
                                <Option value='NOK'>NOK</Option>
                                <Option value='NPR'>NPR</Option>
                                <Option value='NZD'>NZD</Option>
                                <Option value='PHP'>PHP</Option>
                                <Option value='RUB'>RUB</Option>
                                <Option value='SEK'>SEK</Option>
                                <Option value='THB'>THB</Option>
                                <Option value='UCC'>UCC</Option>
                                <Option value='USD'>USD</Option>
                                <Option value='VND'>VND</Option>
                                <Option value='ZAR'>ZAR</Option>
                            </Select>
                        </Col>
                        <Col>
                            <div className='filter__name'>Status</div>
                            <Select
                                defaultValue='All'
                                style={{ width: 120 }}
                                onChange={onCurrencyChange}
                            >
                                <Option value=''>All</Option>
                                <Option value='paymentStarted'>PaymentStarted</Option>
                                <Option value='paymentTransferSuccess'>
                                    PaymentTransferSuccess
                                </Option>
                                <Option value='paymentRejected'>PaymentRejected</Option>
                                <Option value='paymentPaid'>PaymentPaid</Option>
                                <Option value='paymentSuccessDepositToPlayer'>
                                    PaymentSuccessDepositToPlayer
                                </Option>
                                <Option value='paymentError'>PaymentError</Option>
                                <Option value='paymentRejectedByCompany'>
                                    PaymentRejectedByCompany
                                </Option>
                                <Option value='paymentInternalTransactionError'>
                                    PaymentInternalTransactionError
                                </Option>
                                <Option value='paymentSuccessHoldOnCreditFromPlayer'>
                                    PaymentSuccessHoldOnCreditFromPlayer
                                </Option>
                                <Option value='paymentSuccessRollBackCreditToPlayer'>
                                    PaymentSuccessRollBackCreditToPlayer
                                </Option>
                                <Option value='paymentAdminVerified'>PaymentAdminVerified</Option>
                                <Option value='paymentAdminApproved'>PaymentAdminApproved</Option>
                                <Option value='paymentAdminRejected'>PaymentAdminRejected</Option>
                            </Select>
                        </Col>
                        <Col>
                            <div className='filter__name'>Providers</div>
                            <Select
                                defaultValue='All'
                                style={{ width: 120 }}
                                onChange={onCurrencyChange}
                            >
                                <Option value=''>All</Option>
                                <Option value='payPro'>PayPro</Option>
                                <Option value='hTPay'>HTPay</Option>
                                <Option value='princePay'>PrincePay</Option>
                            </Select>
                        </Col>
                        <Col>
                            <div className='filter__name'>Transaction Type</div>
                            <Select
                                defaultValue='All'
                                style={{ width: 120 }}
                                onChange={onCurrencyChange}
                            >
                                <Option value=''>All</Option>
                                <Option value='gatewayDeposit'>PaymentGatewayDeposit</Option>
                                <Option value='gatewayWithdrawal'>PaymentGatewayWithdrawal</Option>
                            </Select>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Button
                                className='submit-btn'
                                type='primary'
                                onClick={() => enterLoading()}
                            >
                                {loadings && (
                                    <div className='loading-btn'>
                                        <Image
                                            width={16}
                                            src='/loading_icon.svg'
                                            className='loading-icon-btn'
                                            preview={false}
                                        />
                                    </div>
                                )}
                                Submit
                            </Button>
                        </Col>
                    </Row>
                </div>
            </Col>
            <Col id='transactions'>
                <Space direction='vertical' size='middle' style={{ width: "100%" }}>
                    <div className='filter-header'>
                        <div className='filter-header__name'>Transaction List</div>
                        <div></div>
                    </div>

                    <div id='table'>
                        <div id='table-total'>
                            <Table
                                columns={columnsTotalMade}
                                locale={locale}
                                dataSource={dataTotal}
                                onChange={(pagination, filter, sorter: any) => {
                                    console.log("===", sorter, orderBy)
                                    setOrderBy({
                                        field: sorter.field,
                                        order: sorter.order === "descend" ? "desc" : "asc",
                                    })
                                }}
                            />
                        </div>
                        <div id='table-data'>
                            <Table
                                columns={columnsData}
                                locale={locale}
                                dataSource={dataSource}
                                onChange={(pagination, filter, sorter: any) => {
                                    console.log("===", sorter, orderBy)
                                    setOrderBy({
                                        field: sorter.field,
                                        order: sorter.order === "descend" ? "desc" : "asc",
                                    })
                                }}
                                // pagination={{
                                //     pageSize,
                                //     total: totalPage,
                                //     onChange: (page, pageSize) => {
                                //         setPage(page);
                                //         setPageSize(pageSize);
                                //     },
                                // }}
                            />
                        </div>

                        {loadings && (
                            <tr className='loading-mask-table'>
                                <div className='loading-icon-table'>
                                    <Loading3QuartersOutlined className='loading-img-table' />
                                </div>
                            </tr>
                        )}
                    </div>
                </Space>
            </Col>
        </div>
    )
}

export default PaymentTransaction
