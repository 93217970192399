import { useTranslation } from "react-i18next";
import "./CustomizeThemes.scss";

import { Button, Checkbox, Col, ColorPicker, Input, Popover, Row, Select, Spin, Table, Tabs } from "antd";
import { useEffect, useState } from "react";
import { apis } from "../../../apis";

import { DeleteOutlined } from "@ant-design/icons";
import { Option } from "antd/es/mentions";
import TabPane from "antd/es/tabs/TabPane";
import { isArray } from "lodash";
import { toast } from "react-toastify";
import { httpService } from "../../../apis/httpService";
import { ModalConfirm } from "../../../common/components/modal-confirm";
import { ModalCopyThemeFromOtherDomain } from "../../../common/components/modal-copy-theme-from-other-domain";
import { ModalCopyThemeToOtherDomain } from "../../../common/components/modal-copy-theme-to-other-domain";
import { ModalCreateNewTheme } from "../../../common/components/modal-create-new-theme";
import { CustomizeTheType } from "../../../common/constants/customize -theme";
import { uploadMedia } from "../../promotions/services";
import { ICreateNewHtmlId } from "./interface/ICreateNewHtmlId";

const CustomizeThemes = () => {
    const { t } = useTranslation();

    const columns = [
        {
            title: "#",
            dataIndex: "index",
            key: "index",
            render: (text: any, record: any, index: number) => {
                if (!record || (!isEdit && record?.htmlId === "initial")) {
                    return;
                }
                if (isEdit) {
                    if (record.htmlId === "initial") {
                        return <Button icon="+" shape="circle" />;
                    }
                } else {
                    return (
                        <div className="column-edit" style={{ textAlign: "center" }}>
                            {index + 1}
                        </div>
                    );
                }
                return (
                    <div className="column-edit" style={{ textAlign: "center" }}>
                        {index + 1}
                    </div>
                );
            },
        },
        {
            title: t("content.customizeTheme.col.guideLine.title"),
            dataIndex: "guildLine",
            key: "guildLine",
            render: (text: any, record: any, index: number) => {
                if (isEdit && record.htmlId === "initial") {
                    return (
                        <Row>
                            *{t("content.customizeTheme.col.guideLine.noted")}
                            {createNewData?.guildLine && (
                                <div className="image-edit-wrapper">
                                    <img src={createNewData?.guildLine} alt="" className="cover-img" />
                                </div>
                            )}
                            <input
                                type="file"
                                style={{ marginBottom: 10, marginTop: 10 }}
                                onChange={(event) => {
                                    if (event.target.files?.length) {
                                        handleCreateImg(event.target.files[0], "guildLine");
                                    }
                                }}
                            />
                        </Row>
                    );
                }
                return (
                    <div className="column-edit" style={{ textAlign: "center", paddingTop: 10 }}>
                        {/* using this row for center image */}
                        <Row>
                            <Col>
                                <div style={{ textAlign: "center", paddingTop: 10 }}> {` `}</div>
                            </Col>
                        </Row>
                        <Popover placement="bottom" content={<img src={record?.guildLine} alt="" width={300} />}>
                            <div style={{ minWidth: 20 }}>
                                <img src={record?.guildLine} alt="" width={60} />
                            </div>
                        </Popover>
                    </div>
                );
            },
        },

        {
            title: t("content.customizeTheme.col.hidden"),
            dataIndex: "hidden",
            key: "hidden",
            render: (text: any, record: any, index: number) => {
                if (!record || (!isEdit && record?.htmlId === "initial")) {
                    return;
                }
                if (isEdit) {
                    return (
                        <div style={{ minWidth: 50, textAlign: "center" }}>
                            <Checkbox
                                disabled={!isEdit}
                                onChange={(e) => handleChangeCheckboxEdit(e.target.checked, record)}
                            />
                        </div>
                    );
                }
                return (
                    <div style={{ minWidth: 50, textAlign: "center" }}>
                        <Checkbox
                            disabled={!isEdit}
                            onChange={(e) => handleChangeCheckboxCreate(e.target.checked)}
                            checked={createNewData?.hidden}
                        />
                    </div>
                );
            },
        },
        {
            title: t("content.customizeTheme.col.htmlId"),
            dataIndex: "htmlId",
            key: "htmlId",
            render: (text: any, record: any, index: number) => {
                if (!isEdit && record?.htmlId === "initial") {
                    return;
                }
                if (isEdit) {
                    if (text === "initial") {
                        return (
                            <Input
                                status={error ? "error" : undefined}
                                onChange={(e) => handleChangeInputCreate(e.target.value, record, "htmlId")}
                                value={createNewData?.htmlId}
                            ></Input>
                        );
                    }
                    return <div className="column-table">{text}</div>;
                } else {
                    return <div>{text}</div>;
                }
            },
        },
        {
            title: t("content.customizeTheme.col.image.title"),
            dataIndex: "image",
            key: "image",
            render: (value: any, record: any, index: number) => {
                // if ((!isEdit && record?.htmlId === "initial") || !record?.hasImage) {
                if (!isEdit && record?.htmlId === "initial") {
                    return <div style={{ minWidth: 100 }}></div>;
                }

                if (isEdit) {
                    if (record?.htmlId === "initial") {
                        return (
                            <div>
                                <Row gutter={10}>
                                    <Col span={12} style={{ display: "flex", alignItems: "center" }}>
                                        <p style={{ marginRight: 10 }}>
                                            {t("content.customizeTheme.col.image.width")}:
                                        </p>{" "}
                                        <Input
                                            type="number"
                                            status={error ? "error" : undefined}
                                            onChange={(e) => handleChangeInputCreate(e.target.value, record, "width")}
                                            value={createNewData?.imageInfo?.width}
                                        />
                                    </Col>

                                    <Col span={12} style={{ display: "flex", alignItems: "center" }}>
                                        <p style={{ marginRight: 10 }}>
                                            {t("content.customizeTheme.col.image.height")}:
                                        </p>{" "}
                                        <Input
                                            status={error ? "error" : undefined}
                                            type="number"
                                            onChange={(e) => handleChangeInputCreate(e.target.value, record, "height")}
                                            value={createNewData?.imageInfo?.height}
                                        />
                                    </Col>
                                </Row>

                                {createNewData?.image && (
                                    <div className="image-edit-wrapper">
                                        <img src={createNewData?.image} alt="" className="cover-img" />
                                    </div>
                                )}

                                <Row>
                                    <input
                                        type="file"
                                        style={{ marginBottom: 10, marginTop: 20, marginLeft: 50 }}
                                        disabled={
                                            !createNewData?.htmlId ||
                                            !createNewData?.imageInfo?.width ||
                                            !createNewData?.imageInfo?.height
                                                ? true
                                                : false
                                        }
                                        onChange={(event) => {
                                            if (event.target.files?.length) {
                                                handleCreateImg(event.target.files[0], "image");
                                            }
                                        }}
                                    />
                                </Row>
                            </div>
                        );
                    }

                    return (
                        <div className="image-edit-wrapper" style={{ minWidth: 100 }}>
                            {(record.imageInfo?.width || record.imageInfo?.height) && (
                                <div className="width-image">
                                    {t("content.customizeTheme.col.image.width")}: {`${record?.imageInfo?.width}`} |{" "}
                                    {t("content.customizeTheme.col.image.height")}: {`${record?.imageInfo?.height}`} |
                                    {t("content.customizeTheme.col.image.fileType")}: jpg,png,jpeg
                                </div>
                            )}

                            {record.image && (
                                <div className="image-edit-wrapper">
                                    <img src={record.image} alt="" className="cover-img" />
                                </div>
                            )}

                            <input
                                type="file"
                                style={{ margin: "10px 0px 10px 40px" }}
                                onChange={(event) => {
                                    if (event.target.files?.length) {
                                        uploadMedia(event.target.files[0])
                                            .then((res: any) => {
                                                handleEditImg(record, res.imageUrl);
                                            })
                                            .catch((err) => {});
                                    }
                                }}
                            />
                        </div>
                    );
                } else {
                    return (
                        <div>
                            {(record.imageInfo?.width || record.imageInfo?.height) && (
                                <div
                                    style={{
                                        textAlign: "center",
                                        color: "#2a437c",
                                    }}
                                >
                                    {t("content.customizeTheme.col.image.width")}: {`${record?.imageInfo?.width || ""}`}{" "}
                                    | {t("content.customizeTheme.col.image.height")}:{" "}
                                    {`${record?.imageInfo?.height || ""}`}
                                </div>
                            )}
                            <div
                                className="column-edit"
                                style={{ textAlign: "center", marginTop: 10, cursor: "pointer" }}
                            >
                                <Popover placement="bottom" content={<img src={record?.image} alt="" width={300} />}>
                                    <img src={record?.image} alt="" width={150} />
                                </Popover>
                            </div>
                        </div>
                    );
                }
            },
        },
        {
            title: t("content.customizeTheme.col.hyperLink"),
            dataIndex: "hyperLink",
            key: "hyperLink",
            render: (text: any, record: any, index: number) => {
                if (!isEdit && record?.htmlId === "initial") {
                    return;
                }
                if (isEdit) {
                    if (text === "initial") {
                        return (
                            <Input
                                onChange={(e) => handleChangeInputCreate(e.target.value, record, "hyperLink")}
                                value={createNewData?.hyperLink}
                            ></Input>
                        );
                    }
                    return (
                        <div className="input-edit">
                            <Input
                                defaultValue={text}
                                onChange={(e) => onChangeInputEdit(e.target.value, "hyperLink", record)}
                                value={record.hyperLink}
                            ></Input>
                        </div>
                    );
                } else {
                    return <div className="column-table">{text}</div>;
                }
            },
        },
        {
            title: t("content.customizeTheme.col.value"),
            dataIndex: "extraData",
            key: "extraData",
            render: (value: any, record: any, index: number) => {
                if (!isEdit && record?.htmlId == "initial") {
                    return;
                }
                if (isEdit) {
                    if (value === "initial") {
                        return (
                            <Input
                                onChange={(e) => handleChangeInputCreate(e.target.value, record, "extraData")}
                                value={createNewData?.extraData}
                            ></Input>
                        );
                    }
                    if (record.htmlId === "theme_color") {
                        if (isArray(value)) {
                            return (
                                <div>
                                    <Row style={{ minWidth: 200 }}>
                                        <Col span={14}>
                                            <p>Text color:</p>
                                        </Col>
                                        <Col span={10}>
                                            <ColorPicker
                                                defaultValue={`${value[0].textColor}`}
                                                onChange={(e) => onChangeColor(e.toHex(), "textColor")}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={14}>
                                            <span>Main color:</span>
                                        </Col>
                                        <Col span={10}>
                                            <ColorPicker
                                                defaultValue={`${value[0].mainColor}`}
                                                onChange={(e) => onChangeColor(e.toHex(), "mainColor")}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={14}>
                                            <span>Main color soft:</span>
                                        </Col>
                                        <Col span={10}>
                                            <ColorPicker
                                                defaultValue={`${value[0].mainColorSoft}`}
                                                onChange={(e) => onChangeColor(e.toHex(), "mainColorSoft")}
                                            />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col span={14}>
                                            <span>Main color soft 2:</span>
                                        </Col>
                                        <Col span={10}>
                                            <ColorPicker
                                                defaultValue={`${value[0].mainColorSoft2}`}
                                                onChange={(e) => onChangeColor(e.toHex(), "mainColorSoft2")}
                                            />
                                        </Col>
                                    </Row>
                                </div>
                            );
                        }
                    } else {
                        return (
                            <div className="input-edit">
                                <Input
                                    defaultValue={value}
                                    onChange={(e) => onChangeInputEdit(e.target.value, "extraData", record)}
                                    value={record.extraData}
                                ></Input>
                            </div>
                        );
                    }
                } else {
                    if (record?.htmlId === "theme_color") {
                        return (
                            <div>
                                <Row style={{ minWidth: 200 }}>
                                    <Col span={14}>
                                        <p>Text color:</p>
                                    </Col>
                                    <Col span={10}>
                                        <p
                                            style={{
                                                width: 26,
                                                height: 26,
                                                borderRadius: 4,
                                                backgroundColor: `${value[0].textColor}`,
                                            }}
                                        ></p>
                                    </Col>
                                </Row>
                                <Row style={{ padding: "6px 0" }}>
                                    <Col span={14}>
                                        <span>Main color:</span>
                                    </Col>
                                    <Col span={10}>
                                        <p
                                            style={{
                                                width: 26,
                                                height: 26,
                                                borderRadius: 4,
                                                backgroundColor: `${value[0].mainColor}`,
                                            }}
                                        ></p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col span={14}>
                                        <span>Main color soft:</span>
                                    </Col>
                                    <Col span={10}>
                                        <p
                                            style={{
                                                width: 26,
                                                height: 26,
                                                borderRadius: 4,
                                                backgroundColor: `${value[0].mainColorSoft}`,
                                            }}
                                        ></p>
                                    </Col>
                                </Row>
                                <Row style={{ padding: "6px 0" }}>
                                    <Col span={14}>
                                        <span>Main color soft 2:</span>
                                    </Col>
                                    <Col span={10}>
                                        <p
                                            style={{
                                                width: 26,
                                                height: 26,
                                                borderRadius: 4,
                                                backgroundColor: `${value[0].mainColorSoft2}`,
                                            }}
                                        ></p>
                                    </Col>
                                </Row>
                            </div>
                        );
                    } else {
                        return <div className="column-table">{value}</div>;
                    }
                }
            },
        },
        {
            title: t("content.customizeTheme.col.resourceKey"),
            dataIndex: "resourceKey",
            key: "resourceKey",
            render: (text: any, record: any, index: number) => {
                if (!isEdit && record?.htmlId == "initial") {
                    return;
                }
                if (isEdit) {
                    if (text === "initial") {
                        return (
                            <Input
                                onChange={(e) => handleChangeInputCreate(e.target.value, record, "resourceKey")}
                                value={createNewData?.resourceKey}
                            ></Input>
                        );
                    }
                    return (
                        <div className="input-edit">
                            <Input
                                defaultValue={text}
                                onChange={(e) => onChangeInputEdit(e.target.value, "resourceKey", record)}
                                value={record.resourceKey}
                            ></Input>
                        </div>
                    );
                } else {
                    return <div className="column-table">{text}</div>;
                }
            },
        },
        {
            title: t("content.customizeTheme.col.action"),
            dataIndex: "action",
            key: "action",
            render: (text: any, record: any, index: number) => {
                if (!isEdit && record?.htmlId == "initial") {
                    return <div style={{ width: 70, textAlign: "center", cursor: "pointer" }} aria-disabled></div>;
                }
                if (isEdit) {
                    if (text !== "initial" && record?.htmlId !== "theme_color") {
                        return (
                            <div
                                style={{ width: 70, textAlign: "center", cursor: "pointer" }}
                                onClick={() => handleDelete(record)}
                            >
                                <DeleteOutlined />
                            </div>
                        );
                    }
                }
            },
        },
    ];

    const [isEdit, setIsEdit] = useState(false);
    const [canSave, setCanSave] = useState(false);

    const [editData, setEditData] = useState<any>([]);

    const [tab, setTab] = useState<any>("globalSetting");

    const [tabData, setTabData] = useState<any>([]);

    const [themes, setThemes] = useState<any>([]);
    const [domains, setDomains] = useState<any[]>([]);
    const [languages, setLanguages] = useState([]);
    const [activeTheme, setActiveTheme] = useState<any>({});
    const [activeDomain, setActiveDomain] = useState("");
    const [activeLanguage, setActiveLanguage] = useState("en");
    const [activePlatform, setPlatformActive] = useState("mobile");
    const [themeData, setThemeData] = useState<any>([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isModalCopyThemeFromOtherDomain, setIsModalCopyThemeFromOtherDomain] = useState(false);
    const [isModalCopyThemeToOtherDomain, setIsModalCopyThemeToOtherDomain] = useState(false);
    const [isModalConfirmOpen, setIsModalConfirmOpen] = useState(false);
    const [tabTitle, setTabTitle] = useState("");
    const [createNewData, setCreateNewData] = useState<ICreateNewHtmlId | null>();

    const [error, setError] = useState(false);

    const [deleteRecord, setDeleteRecord] = useState<any>([]);
    const handleDelete = (record: any) => {
        const newData = dataTable.filter((i: any) => i.htmlId !== record.htmlId);
        setDataTable(newData);

        if (record.htmlId !== "theme_color") setDeleteRecord([...deleteRecord, record]);
        setCanSave(true);
    };

    const handleCreateImg = (img: any, key: string) => {
        // if (!error) {
        uploadMedia(img)
            .then((res: any) => {
                createImg(res.imageUrl, key);
            })
            .catch((err) => {});
        // }
    };

    useEffect(() => {
        if (!createNewData?.htmlId && (createNewData?.imageInfo?.width || createNewData?.imageInfo?.height)) {
            setError(true);
        }
    }, [createNewData]);

    const createImg = (url: string, key: string) => {
        setCreateNewData({ ...createNewData, [key]: url });
    };

    const handleEditImg = (record: any, url: string) => {
        const key = dataTable.find((i: any) => i.htmlId === record.htmlId);
        key.image = url;
        setDataTable([...dataTable]);

        const existContent = editData.find((i: any) => i.htmlId === record.htmlId);
        const othersContent = editData.filter((i: any) => i.htmlId !== record.htmlId);

        if (existContent) {
            setEditData([
                ...othersContent,
                {
                    ...existContent,
                    image: url,
                },
            ]);
        } else {
            setEditData([
                ...othersContent,
                {
                    ...key,
                    image: url,
                },
            ]);
        }
        setCanSave(true);
    };

    const onChangeColor = (value: string, type: string) => {
        setCanSave(true);

        const colors = tabData.find((item: any) => item.htmlId === "theme_color");
        const colorsData = colors.extraData[0];
        const colorChanged = { ...colorsData, [type]: `#${value}` };
        colors.extraData = [colorChanged];

        const newTabData = tabData.filter((item: any) => item.htmlId !== "theme_color" && item.htmlId !== "initial");

        setEditData([...newTabData, colors]);
    };

    const [pendingTab, setPendingTab] = useState("");
    const onChangeTab = (value: string) => {
        if (createNewData?.htmlId || canSave) {
            setIsModalConfirmOpen(true);

            setPendingTab(value);
            return;
        }

        setTab(value);
    };
    const confirmTabChange = () => {
        setIsModalConfirmOpen(false);
        setCanSave(false);

        setCreateNewData(null);
        setEditData([]);
        changeTab(pendingTab);
    };

    const changeTab = (value: string) => {
        const tableTitle = themeData.content?.find((item: any) => item.code === value);
        setTabTitle(tableTitle?.title);
        setTab(value);
    };

    const getThemes = async () => {
        const res = await httpService.get(apis.webAdmin.themes);
        setThemes(res.data);
        if (!activeTheme?.id) {
            setActiveTheme({
                id: res?.data[0]?.id,
                title: res?.data[0]?.title,
            });
        }
    };
    const getThemeDetail = async () => {
        if (activeTheme?.id) {
            const res = await httpService.get(`${apis.webAdmin.themes}/${activeTheme.id}`);
            if (res.data?.platform?.length) {
                const data = res.data.platform.find(
                    (item: any) => item.language === activeLanguage && item.platform === activePlatform
                );
                setThemeData(data);
            }
        }
    };
    const getDomains = async () => {
        const res = await httpService.get(apis.webAdmin.domainThemes);
        if (res?.data && !activeDomain) {
            setActiveDomain(res?.data[0]?.domain);
        }
        setDomains(res?.data);
    };
    const getLanguages = async () => {
        const res = await httpService.get(apis.public.languages);
        setLanguages(res?.data);
    };

    useEffect(() => {
        Promise.all([getThemes(), getDomains(), getLanguages()]);
    }, []);

    useEffect(() => {
        getThemes();
    }, [isModalOpen]);

    useEffect(() => {
        if (activeTheme?.id && activeDomain && activeLanguage && activePlatform) {
            getThemeDetail();
        }
    }, [activeTheme.id, activeLanguage, activePlatform, activeDomain]);

    const [dataTable, setDataTable] = useState<any>([]);
    const [activeBanner, setActiveBanner] = useState("homepage");
    const [dataSelect, setDataSelect] = useState<any>({});

    useEffect(() => {
        const tabData = themeData?.content?.find((item: any) => item.code === tab);
        setTabTitle(tabData?.title);
        setTabData(tabData?.value);

        if (tabData?.value?.length) {
            if (tabData.code === "bannerUpload") {
                const activeTabBanner = tabData?.value?.find((item: any) => item.category === activeBanner);
                setDataSelect(activeTabBanner);

                if (activeTabBanner?.categoryData?.length) {
                    setDataTable([
                        ...activeTabBanner?.categoryData,
                        {
                            guildLine: "initial",
                            hidden: false,
                            hasImage: true,
                            htmlId: "initial",
                            image: "initial",
                            hyperLink: "initial",
                            extraData: "initial",
                            resourceKey: "initial",
                        },
                    ]);
                } else {
                    setDataTable([
                        {
                            guildLine: "initial",
                            hidden: false,
                            hasImage: true,
                            htmlId: "initial",
                            image: "initial",
                            hyperLink: "initial",
                            extraData: "initial",
                            resourceKey: "initial",
                        },
                    ]);
                }
            } else {
                setDataTable([
                    ...tabData?.value,
                    {
                        guildLine: "initial",
                        hidden: false,
                        hasImage: true,
                        htmlId: "initial",
                        image: "initial",
                        hyperLink: "initial",
                        extraData: "initial",
                        resourceKey: "initial",
                    },
                ]);
            }
        } else {
            setDataTable([
                {
                    guildLine: "initial",
                    hidden: false,
                    hasImage: true,
                    htmlId: "initial",
                    image: "initial",
                    hyperLink: "initial",
                    extraData: "initial",
                    resourceKey: "initial",
                },
            ]);
        }
        if (!activeDomain && domains?.length) {
            setActiveDomain(domains[0].domain);
        }
    }, [tab, themeData, domains, activeBanner]);

    const onClickEdit = () => {
        setIsEdit(true);
    };
    const onClickCancel = () => {
        setCreateNewData(null);
        setEditData([]);
        setIsEdit(false);
    };
    const onClickCreateTheme = () => {
        setIsModalOpen(true);
    };
    const onClickSave = async () => {
        if (canSave) {
            try {
                const otherTabData = themeData.content.filter((i: any) => i.code !== tab);
                let content = themeData.content;
                if (editData?.length) {
                    if (createNewData) {
                        if (tab === "bannerUpload") {
                            const otherCategory = tabData.filter((i: any) => i.category !== activeBanner);

                            const existData = editData.filter((i: any) => i.htmlId !== createNewData.htmlId);
                            const saveData = [...existData, createNewData];
                            content = [
                                ...otherTabData,
                                {
                                    code: tab,
                                    title: tabTitle,
                                    value: [...otherCategory, { category: activeBanner, categoryData: saveData }],
                                },
                            ];
                        } else {
                            content = [
                                ...otherTabData,
                                { code: tab, title: tabTitle, value: [...editData, createNewData] },
                            ];
                        }
                    } else {
                        if (tab === "bannerUpload") {
                            const otherCategory = tabData.filter((i: any) => i.category !== activeBanner);

                            const saveData = [...editData];
                            content = [
                                ...otherTabData,
                                {
                                    code: tab,
                                    title: tabTitle,
                                    value: [...otherCategory, { category: activeBanner, categoryData: saveData }],
                                },
                            ];
                        } else {
                            content = [...otherTabData, { code: tab, title: tabTitle, value: [...editData] }];
                        }
                    }
                } else if (createNewData) {
                    if (tab === "bannerUpload") {
                        const otherCategory = tabData.filter((i: any) => i.category !== activeBanner);

                        content = [
                            ...otherTabData,
                            {
                                code: tab,
                                title: tabTitle,
                                value: [
                                    ...otherCategory,
                                    {
                                        category: activeBanner,
                                        categoryData: [...(dataSelect?.categoryData || []), createNewData],
                                    },
                                ],
                            },
                        ];
                    } else {
                        content = [...otherTabData, { code: tab, title: tabTitle, value: [...tabData, createNewData] }];
                    }
                }

                if (deleteRecord?.length) {
                    const currentContent = content.find((i: any) => i.code === tab);

                    if (tab === "bannerUpload") {
                        const otherCategory = tabData.filter((i: any) => i.category !== activeBanner);

                        const currentTab = currentContent.value.find((i: any) => i.category === activeBanner);
                        const dataDeleted = currentTab.categoryData.filter((i: any) => {
                            const isDelete = deleteRecord.find((j: any) => j.htmlId === i.htmlId);

                            if (!isDelete) {
                                return i;
                            }
                        });

                        content = [
                            ...otherTabData,
                            {
                                code: tab,
                                title: tabTitle,
                                value: [...otherCategory, { category: activeBanner, categoryData: dataDeleted }],
                            },
                        ];
                    } else {
                        const remainingData = currentContent.value.filter((i: any) => {
                            const isDelete = deleteRecord.find((j: any) => j.htmlId === i.htmlId);

                            if (!isDelete) {
                                return i;
                            }
                        });

                        content = [
                            ...otherTabData,
                            {
                                code: tab,
                                title: tabTitle,
                                value: [...remainingData],
                            },
                        ];
                    }
                }

                const res = await httpService.put(`${apis.webAdmin.themes}/${activeTheme.id}`, {
                    domain: activeDomain,
                    language: activeLanguage,
                    platform: activePlatform,
                    content,
                });

                if (res.data) {
                    setEditData([]);
                    setIsEdit(false);
                    setCreateNewData(null);
                    await getThemeDetail();
                    toast.success("Update success!");
                }
            } catch (error) {
                console.log("error", error);
                toast.error("Update failed!");
            }
        }
    };

    const onChangeTheme = (value: any, option: any) => {
        setActiveTheme({ value, id: option.key });
    };
    const onDomainChange = (value: any) => {
        setActiveDomain(value);
    };
    const onChangeLanguage = (value: any) => {
        setActiveLanguage(value);
    };
    const onChangePlatform = (value: any) => {
        setPlatformActive(value);
    };

    const onClickCreateThemeFromOtherDomain = () => {
        setIsModalCopyThemeFromOtherDomain(true);
    };

    const onClickCreateThemeToOtherDomain = () => {
        setIsModalCopyThemeToOtherDomain(true);
    };

    ///---Edit
    const onChangeInputEdit = (value: any, type: string, record: any) => {
        setCanSave(true);

        setEditData((prev: any) => {
            if (tab === "bannerUpload") {
                const otherEditData = editData?.filter((item: any) => item.htmlId !== record.htmlId);

                const data = dataSelect.categoryData.find((item: any) => item.htmlId === record.htmlId);
                const tabDataExist = dataSelect.categoryData.filter((item: any) => {
                    const dataUpdate = otherEditData?.find((e: any) => e.htmlId === item.htmlId);
                    if (dataUpdate) {
                        return dataUpdate;
                    }
                    if (item.htmlId !== record.htmlId && item.htmlId !== "initial") {
                        return item;
                    }
                });

                if (type === "hyperLink" || type === "extraData" || type === "resourceKey") {
                    data[type] = value;
                }

                return [...tabDataExist, data];
            } else {
                const exist = tabData.find((item: any) => item.htmlId === record.htmlId);
                const tabDataExist = tabData.filter(
                    (item: any) => item.htmlId !== record.htmlId && item.htmlId !== "initial"
                );

                if (type === "hyperLink" || type === "extraData" || type === "resourceKey") {
                    exist[type] = value;
                }

                return [...(tabDataExist || []), exist];
            }
        });
    };

    const handleChangeCheckboxEdit = (checked: boolean, record: any) => {
        const data = dataTable.find((i: any) => i.htmlId === record.htmlId);
        data.hidden = checked;
        setDataTable([...dataTable]);

        if (tab === "bannerUpload") {
            const existContent = editData.find((i: any) => i.htmlId === record.htmlId);
            const currentTab = tabData.find((i: any) => i.category === activeBanner);
            const othersContent = currentTab?.categoryData?.filter((i: any) => i.htmlId !== record.htmlId);
            if (existContent) {
                setEditData([
                    ...othersContent,
                    {
                        ...existContent,
                        hidden: checked,
                    },
                ]);
            } else {
                setEditData([
                    ...othersContent,
                    {
                        ...data,
                        hidden: checked,
                    },
                ]);
            }
        } else {
            const existContent = editData.find((i: any) => i.htmlId === record.htmlId);
            const othersContent = tabData.filter((i: any) => i.htmlId !== record.htmlId);

            if (existContent) {
                setEditData([
                    ...othersContent,
                    {
                        ...existContent,
                        hidden: checked,
                    },
                ]);
            } else {
                setEditData([
                    ...othersContent,
                    {
                        ...data,
                        hidden: checked,
                    },
                ]);
            }
        }

        setCanSave(true);
    };
    ///End Edit
    const handleChangeInputCreate = (value: any, record: any, type: string) => {
        setCanSave(true);
        if (type === "width" || type === "height") {
            setCreateNewData((prev: any) => {
                return {
                    ...prev,
                    imageInfo: {
                        ...prev?.imageInfo,
                        [type]: value,
                    },
                };
            });
        } else {
            setCreateNewData((prev: any) => {
                return {
                    ...prev,
                    [type]: value,
                };
            });
        }
    };
    const handleChangeCheckboxCreate = (checked: boolean) => {
        setCreateNewData((prev: any) => {
            return {
                ...prev,
                hidden: checked,
            };
        });
    };
    const onChangeBannerType = (value: string) => {
        if (canSave) {
            setIsModalConfirmOpen(true);

            setPendingTab(value);
            return;
        }
        if (tab === "bannerUpload") {
            setActiveBanner(value);
        }
    };
    return (
        <div style={{ paddingBottom: 16 }}>
            <Col id="theme-setting" style={{ paddingBottom: 16 }}>
                <div className="filter">
                    <div className="filter-header">
                        <div className="filter-header__name">{t("content.customizeTheme.setting.title")}</div>

                        <div>
                            <div className="modify-btn">
                                <Button className="btn" size="small" onClick={onClickCreateThemeFromOtherDomain}>
                                    {t("content.customizeTheme.setting.copyThemeFromOtherDomain")}
                                </Button>
                                <Button className="btn" size="small" onClick={onClickCreateThemeToOtherDomain}>
                                    {t("content.customizeTheme.setting.copyThemeToOtherDomain")}
                                </Button>
                            </div>
                        </div>
                    </div>
                    <Row className="filter-bar">
                        <Col>
                            <div>{t("content.customizeTheme.setting.domain")}</div>
                            <Select style={{ minWidth: 170 }} onChange={onDomainChange} value={activeDomain}>
                                {domains?.map((item: any) => {
                                    return (
                                        <Option value={item.domain} key={item.domain}>
                                            {item.domain}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </Col>
                        <Col>
                            <div>{t("content.customizeTheme.setting.language")}</div>
                            <Select defaultValue="en" style={{ minWidth: 140 }} onChange={onChangeLanguage}>
                                {languages?.map((item: any) => {
                                    return (
                                        <Option value={item.iso} key={item.iso}>
                                            {item.languageLocalName}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </Col>
                        <Col>
                            <div>{t("content.customizeTheme.setting.platform")}</div>
                            <Select defaultValue="mobile" style={{ width: 100 }} onChange={onChangePlatform}>
                                <Option value="desktop">Desktop</Option>
                                <Option value="mobile">Mobile</Option>
                            </Select>
                        </Col>
                    </Row>
                    {/* <Row className='active-theme-setting'>
                        <Col>
                            Active Desktop Theme: <span className='active-theme'>SBO_Desktop</span>
                         </Col>
                        <Col>
                            Active Mobile Theme: <span className='active-theme'>SBO_Mobile</span>
                        </Col>
                    </Row> */}
                </div>
            </Col>
            <Col className="customize-theme">
                <div className="filter">
                    <div className="filter-header">
                        <div className="filter-header__name">{t("content.customizeTheme.title")}</div>

                        <div className="modify-btn">
                            <Select
                                defaultValue="default"
                                size="small"
                                style={{ width: 150 }}
                                onChange={(value, option) => onChangeTheme(value, option)}
                            >
                                {themes?.map((item: any) => {
                                    return (
                                        <Option value={item.code} key={item.id}>
                                            {item.title}
                                        </Option>
                                    );
                                })}
                            </Select>
                            {!isEdit ? (
                                <div className="modify-btn">
                                    <Button size="small" type="primary" onClick={onClickEdit}>
                                        <span style={{ padding: "0 6px" }}>{t("content.customizeTheme.edit")}</span>
                                    </Button>
                                </div>
                            ) : (
                                <div className="modify-btn">
                                    <Button
                                        size="small"
                                        style={{
                                            marginRight: 4,
                                        }}
                                        type="primary"
                                        onClick={onClickCancel}
                                        danger
                                    >
                                        <span style={{ padding: "0 6px" }}>{t("content.customizeTheme.cancel")}</span>
                                    </Button>
                                    <Button size="small" type="primary" disabled={!canSave}>
                                        <span style={{ padding: "0 6px" }} onClick={onClickSave}>
                                            {t("content.customizeTheme.save")}
                                        </span>
                                    </Button>
                                </div>
                            )}
                            <Button size="small" type="primary" onClick={onClickCreateTheme}>
                                <span style={{ padding: "0 6px" }}>{t("content.customizeTheme.createTheme")}</span>
                            </Button>
                        </div>
                    </div>
                    <Row className="tab-bar">
                        <Col>
                            <Tabs defaultActiveKey="1" onChange={(value) => onChangeTab(value)}>
                                <TabPane
                                    tab={t("content.customizeTheme.globalSetting")}
                                    key={CustomizeTheType.GLOBAL_SETTING}
                                ></TabPane>
                                <TabPane
                                    tab={
                                        <>
                                            {t("content.customizeTheme.bannerUpload")}{" "}
                                            <Select
                                                defaultValue="homepage"
                                                style={{ width: 130, paddingLeft: 8 }}
                                                onChange={onChangeBannerType}
                                            >
                                                <Option value="homepage">Homepage</Option>
                                                <Option value="liveCasino">Live Casino</Option>
                                                <Option value="game">Game</Option>
                                                <Option value="fish">Fish</Option>
                                                <Option value="sport">Sport</Option>
                                                <Option value="casino">Casino</Option>
                                                <Option value="cookFighting">CookFighting</Option>
                                                <Option value="lottery">Lottery</Option>
                                                <Option value="promotion">Promotion</Option>
                                                <Option value="vibClub">Vip Club</Option>
                                                <Option value="agency">Agency</Option>
                                            </Select>
                                        </>
                                    }
                                    key={CustomizeTheType.BANNER_UPLOAD}
                                ></TabPane>
                                <TabPane tab="Vip Club" key={CustomizeTheType.VIP_CLUB}></TabPane>
                                <TabPane
                                    tab={t("content.customizeTheme.contactInfo")}
                                    key={CustomizeTheType.CONTACT_INFO}
                                ></TabPane>
                                <TabPane
                                    tab={t("content.customizeTheme.promotion")}
                                    key={CustomizeTheType.PROMOTION}
                                ></TabPane>
                            </Tabs>
                        </Col>
                        <Col>
                            <p className="noted">{t("content.customizeTheme.noted")}</p>
                        </Col>
                    </Row>
                </div>
                <div id="customize-theme-table">
                    {dataTable.length === 0 && (
                        <div style={{ textAlign: "center" }}>
                            <Spin />
                        </div>
                    )}
                    {dataTable?.length > 0 && (
                        <div id="table-data">
                            <Table
                                columns={columns}
                                // dataSource={tabData}
                                dataSource={dataTable}
                                pagination={false}
                                // scroll={{ y: `calc(100vh - 4px)` }}
                                // tableLayout='auto'
                                // rowKey='id'
                                // scroll={{ y: 650, x: "max-content" }}
                                scroll={{ y: 650, x: true }}
                            />
                        </div>
                    )}
                </div>
            </Col>
            <ModalCreateNewTheme isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />
            <ModalCopyThemeFromOtherDomain
                isModalOpen={isModalCopyThemeFromOtherDomain}
                setIsModalOpen={setIsModalCopyThemeFromOtherDomain}
            />
            <ModalCopyThemeToOtherDomain
                isModalOpen={isModalCopyThemeToOtherDomain}
                setIsModalOpen={setIsModalCopyThemeToOtherDomain}
                originDomain={activeDomain}
            />
            <ModalConfirm
                isModalOpen={isModalConfirmOpen}
                setIsModalOpen={setIsModalConfirmOpen}
                title={t("content.customizeTheme.confirmModal.title")}
                description={t("content.customizeTheme.confirmModal.description")}
                data={createNewData}
                confirmModal={confirmTabChange}
            />
        </div>
    );
};

export default CustomizeThemes;
