import type { FormItemProps } from "antd"
import { Form } from "antd"
import moment from "moment"
import { Control, Controller } from "react-hook-form"
import { CustomDateRangePicker } from "../../../../common/components/date-range-picker"
import { getValidateStatus } from "../../helpers"

const DateRangePickerField = ({
    name,
    control,
    formItemProps,
    dateRangePickerProps,
}: {
    name: string
    control: Control<any>
    formItemProps?: FormItemProps
    dateRangePickerProps: {
        disabled?: boolean
    }
}) => {
    const { labelAlign = "left" } = formItemProps || {}
    return (
        <Controller
            name={name}
            control={control}
            render={({ field, fieldState }) => {
                const errorMessage = fieldState.error?.message
                const { startDate, endDate } = field.value

                return (
                    <Form.Item
                        labelAlign={labelAlign}
                        validateStatus={getValidateStatus(fieldState, name)}
                        help={errorMessage}
                        className='wrap-label'
                        {...formItemProps}
                    >
                        <CustomDateRangePicker
                            onApply={(event: any, picker: any) => {
                                const newStartDate = moment(picker.startDate).toDate()
                                const newEndDate = moment(picker.endDate).toDate()

                                field.onChange({
                                    startDate: newStartDate,
                                    endDate: newEndDate,
                                })
                            }}
                            onCancel={() => {
                                field.onChange({
                                    startDate,
                                    endDate,
                                })
                            }}
                            containerStyles={{
                                width: "100%",
                                padding: "3px 6px",
                                borderRadius: "6px",
                                border: "1px solid #d9d9d9",
                            }}
                            fromDate={startDate}
                            toDate={endDate}
                            resultToString
                            opens={"center"}
                            readOnly
                            disabled={dateRangePickerProps.disabled}
                        />
                    </Form.Item>
                )
            }}
        />
    )
}

export default DateRangePickerField
