import { useMemo } from "react";
import CustomSelect from "./custom-select";
import { Input } from "antd";
import { useTranslation } from "react-i18next";

const AccountInputFilter = ({
    sortKey,
    username,
    onChange,
}: {
    sortKey: string;
    username: string;
    onChange: (value: { sortKey: string; username: string }) => void;
}) => {
    const { t } = useTranslation();
    // Options for User Type
    const UserTypeOptions = useMemo(
        () => [
            {
                label: t("content.promotions.management.username"),
                value: "username",
            },
            {
                label: t("content.promotions.management.agentName"),
                value: "agent-name",
            },
        ],
        [t]
    );
    return (
        <div className="flex gap-2">
            <CustomSelect
                style={{
                    width: 220,
                }}
                options={UserTypeOptions}
                value={sortKey}
                onChange={(value) => {
                    onChange({
                        sortKey: value,
                        username,
                    });
                }}
            />

            <Input
                placeholder={
                    sortKey === "username"
                        ? t("content.promotions.management.usernamePlaceholder")
                        : t("content.promotions.management.agentPlaceholder")
                }
                className="w-[180px]"
                value={username}
                onChange={(event) => {
                    onChange({
                        sortKey,
                        username: event.target.value,
                    });
                }}
            />
        </div>
    );
};

export default AccountInputFilter;
