import { useTranslation } from "react-i18next";
import { Button, Modal, Transfer } from "antd";
import { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { apis } from "../../../apis";
import { httpService } from "../../../apis/httpService";
import "./ModalCopyThemeToOtherDomain.scss";

type FieldType = {
    isModalOpen: boolean;
    setIsModalOpen: (value: any) => void;
    originDomain: string;
};

const ModalCopyThemeToOtherDomain = ({ setIsModalOpen, isModalOpen, originDomain }: FieldType) => {
    const [domains, setDomains] = useState([]);
    const { t } = useTranslation();
    const getDomain = async () => {
        const res = await httpService.get(apis.webAdmin.domainThemes);
        if (res?.data) {
            const keyData = res.data.map((item: any) => {
                return {
                    key: item.domain,
                    ...item,
                };
            });

            setDomains(keyData);
        }
    };
    useEffect(() => {
        if (domains.length) {
            setDomains(domains.filter((item: any) => item.domain !== originDomain));
        }
    }, [isModalOpen]);

    useEffect(() => {
        getDomain();
    }, []);

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const [targetKeys, setTargetKeys] = useState<string[]>([]);
    const [selectedKeys, setSelectedKeys] = useState<string[]>([]);
    const [canSave, setCanSave] = useState(false);

    const handleChange = (nextTargetKeys: any, direction: any, moveKeys: any) => {
        setTargetKeys(nextTargetKeys);
    };

    const handleSelectChange = (sourceSelectedKeys: string[], targetSelectedKeys: string[]) => {
        setSelectedKeys([...sourceSelectedKeys, ...targetSelectedKeys]);
    };

    useEffect(() => {
        if (targetKeys?.length > 0) {
            setCanSave(true);
        } else {
            setCanSave(false);
        }
    }, [targetKeys]);

    const handleSave = async () => {
        try {
            if (canSave) {
                await httpService.post(apis.webAdmin.copyTheme, {
                    fromDomain: originDomain,
                    toDomain: targetKeys,
                });
            }
            setIsModalOpen(false);
            toast.success("Copy theme success!");
        } catch (error) {
            console.log("error", error);
            toast.error("Copy theme failed!");
        }
    };

    return (
        <div className="modal-copy-theme-to-other-domain__container">
            <Modal
                title={t("content.customizeTheme.setting.copyThemeToOtherDomainModal.title")}
                open={isModalOpen}
                onCancel={handleCancel}
                footer={null}
                className="modal-copy-theme-to-other-domain"
            >
                <div>
                    {t("content.customizeTheme.setting.copyThemeToOtherDomainModal.originDomain")}:
                    <span style={{ paddingLeft: 10, color: "#2a437c", fontWeight: 500 }}>{originDomain}</span>
                </div>
                <div style={{ marginTop: 12 }}>
                    {t("content.customizeTheme.setting.copyThemeToOtherDomainModal.applyToDomains")}:
                </div>
                <div style={{ marginTop: 8 }}>
                    <Transfer
                        dataSource={domains}
                        titles={[
                            t("content.customizeTheme.setting.copyThemeToOtherDomainModal.availableDomains"),
                            t("content.customizeTheme.setting.copyThemeToOtherDomainModal.appliedDomains"),
                        ]}
                        targetKeys={targetKeys}
                        selectedKeys={selectedKeys}
                        onChange={handleChange}
                        onSelectChange={handleSelectChange}
                        showSelectAll={false}
                        render={(item: any) => item.domain}
                        oneWay
                        style={{ marginBottom: 16 }}
                        listStyle={{ width: 450 }}
                    />
                    <div style={{ textAlign: "center", marginTop: 20 }}>
                        <Button type="primary" htmlType="submit" onClick={handleSave} disabled={!canSave}>
                            {t("content.customizeTheme.setting.copyThemeToOtherDomainModal.save")}
                        </Button>
                    </div>
                </div>
            </Modal>
            <ToastContainer autoClose={1500} />
        </div>
    );
};

export default ModalCopyThemeToOtherDomain;
