export const paths = {
  HOME: "/",
  LOGIN: "/auth/login",
  REGISTER: "/auth/register",

  USER_LIST: "/users/list",
  USER_DETAIL: "/users/:id",

  SETTING_TRACKING: "/setting/tracking",
  SETTING_CONNECT: "/setting/connect",
  SETTING_BANK: "/setting/bank",
  SETTING_DESIGN: "/setting/design",

  BANK_LIST: "/bank/list",
  BANK_LIST_ACCOUNT: "/bank/list-accounts",
  ADD_BANK: "/add-bank",
  ADD_BANK_ACCOUNT: "/add-bank-account",

  PROMOTION: {
    CREATE: "/promotions/create",
    LIST: "/promotions/list",
    APPLICATION_VERIFY: "/promotions/application-verify",
    APPLICATION_RECORD: "/promotions/application-record",
    PROMOTION_STATISTICS: "/promotions/promotion-statistics",
  },

  PERMISSION_GROUP: "/permissions-group",
  REPORT: {
    WIN_LOST: "/report/win-lost",
    WIN_LOST_BY_PLAYER: "/report/win-lost-by-player",
    WIN_LOST_BY_PLAYER_PAGE: "/report/win-lost-by-player-page",
    Daily_Report: "/report/daily",

    REVENUE_STATEMENT: "/report/revenue-statement",
    BETTING_RECORD: "/report/betting-record",
    PLAYER_SUMMARY: "/report/player-summary",
  },
  SECURITY: {
    CHANGE_PASSWORD: "/security/change-password",
    CHANGE_LOGIN_NAME: "/security/change-login-name",
  },
  SUB_ACCOUNT: {
    CREATE_SUB_ACCOUNT: "/member/create-sub-account",
    SUB_ACCOUNT_LIST: "/member/sub-account-list",
  },
  TXN: {
    DEPOSIT: "/transactions/deposit",
    WITHDRAW: "/transactions/withdraw",
    TXN_HISTORY: "/transactions/balance-history",

    PLAYER_BANK_GROUP: "/transactions/player-bank-group",
    TRANSACTIONS: "/transactions/transactions",
  },

  GAME_AND_PROVIDER: {
    GAME_PROVIDER: "/game-and-provider/game-provider",
    GAME_LIST: "/game-and-provider/game-list",
  },
  WEB_ADMIN: {
    CUSTOMIZE_THEME: "/web-admin/customize-theme",
    ADMIN_CONTROL: "/web-admin/admin-controls",
  },
  COMPANY: {
    LANGUAGE: "/company/language",
    CURRENCY: "/company/currency",
  },
  PAYMENT_GATEWAY: {
    BANK_SETTING: "/payment-gateway/setting",

    PAYMENT_TRANSACTION: "/payment-gateway/payment-transaction",
    MANUAL_PAYMENT_TRANSACTION: "/payment-gateway/manual-payment-transaction",
  },
  RESOURCE: {
    MANAGE: "/resource/manage",
  },
};
