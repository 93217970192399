import { Col, Select, Space, Table } from "antd"
import { useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import "./PlayerBankGroup.scss"

import moment from "moment"

import { AlignType } from "rc-table/lib/interface"

import { apis } from "../../apis"
import { httpService } from "../../apis/httpService"
import { TransactionType } from "../../common/constants/transaction.enum"
import { ISort } from "../../interfaces/Global"

const PlayerBankGroup = () => {
    const { t } = useTranslation()

    const { Option } = Select

    const dataSource = [{ username: "testing", bankGroup: "10", action: "" }]
    const columnsSource = [
        {
            title: "Bank Group",
            dataIndex: "bankGroup",
            key: "bankGroup",
            colSpan: "1",
        },
        {
            title: "Action",
            dataIndex: "action",
            key: "action",
            colSpan: "1",
        },
    ]

    function makeColumnsDataSource() {
        const columns = [
            {
                title: "#",
                dataIndex: "index",
                key: "index",
                width: "10%",

                align: "center" as AlignType,

                render: (text: any, record: any, index: number) => index + 1,
            },
            {
                title: "Username",
                dataIndex: "username",
                key: "username",
                width: "60%",

                render: (value: any, record: any) => {
                    return (
                        <div
                            className='flex'
                            style={{
                                justifyContent: "flex-start",
                            }}
                        >
                            {value}
                        </div>
                    )
                },
            },
        ]

        for (const col of columnsSource) {
            if (col.key === "action" || col.key === "bankGroup") {
                columns.push({
                    title: col.title,
                    key: col.key,
                    width: "10%",

                    dataIndex: col.dataIndex,

                    render: (value: any) => {
                        return (
                            <div
                                style={{
                                    textAlign: "center",
                                }}
                            >
                                {value}
                            </div>
                        )
                    },
                })
            }
        }

        return columns
    }

    const columnsSourceMade = useMemo(() => makeColumnsDataSource(), [dataSource])

    const [username, setUsername] = useState("")
    const [currency, setCurrency] = useState("")
    const [transactionType, setTransactionType] = useState("")
    const [checkColumn, setCheckColumn] = useState(() => columnsSourceMade.map((item) => item.key))

    //filter columns table data
    const columnsData = columnsSourceMade.filter((i) => checkColumn.includes(i.key))

    //emtydata
    const [emptyText, setEmptyText] = useState("Click Search to see information")
    let locale = {
        emptyText,
    }

    //date
    const currentDate = new Date()
    const fromDate = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate() - 30
    )

    const [filterDate, setFilterDate] = useState({
        fromDate: fromDate,
        toDate: currentDate,
    })

    const [txnList, setTxnList] = useState([])
    const [totalPage, setTotalPage] = useState(10)
    const [textSearch, setTextSearch] = useState("")
    const [page, setPage] = useState(1)
    const [pageSize, setPageSize] = useState(10)
    const [orderBy, setOrderBy] = useState<ISort | null>(null)
    const [loadings, setLoadings] = useState(false)

    const getTxnList = async () => {
        try {
            const orderStr =
                orderBy === null ? "" : `&sortKey=${orderBy.field}&sortOrder=${orderBy.order}`
            const res = await httpService.get(
                `${
                    apis.txn.list
                }/?page=${page}&query=${textSearch}&take=${pageSize}${orderStr}&fromDate=${filterDate.fromDate.toISOString()}&toDate=${filterDate.toDate.toISOString()}&username=${username}&type=${transactionType}&currency=${currency}`
            )

            //apply when have real data
            if (res.data?.result?.length) {
                const result = res.data.result.map((item: any) => {
                    return {
                        date: moment.utc(item.createdAt).add(7, "hours"),
                        txnNo: item.code,
                        transactionType: item.type,
                        username: item.owner.username,
                        currency: item.currency,
                        deposit: item.type === TransactionType.Deposit ? item.amount : 0,
                        withdrawal: item.type === TransactionType.Withdraw ? item.amount : 0,
                        createdBy: item.createdBy,
                        note: item.note,
                        userId: item.owner.id,
                    }
                })

                setTxnList(result)
                setTotalPage(res?.data?.meta.itemCount)
            }
        } catch (error) {
            console.log("error", error)
        }
    }

    useEffect(() => {
        getTxnList()
    }, [page, orderBy, username, transactionType, filterDate.fromDate, filterDate.toDate])

    return (
        <div>
            <Col className='player-bank-group'>
                <Space direction='vertical' size='middle' style={{ width: "100%" }}>
                    <div className='filter-main'>
                        <div className='filter-header'>
                            <div className='filter-header__name'>Player Bank Group</div>
                            <div></div>
                        </div>
                    </div>
                    <div id='table-main'>
                        <div id='table-data'>
                            <Table columns={columnsData} locale={locale} dataSource={dataSource} />
                        </div>
                    </div>
                </Space>
            </Col>
        </div>
    )
}

export default PlayerBankGroup
