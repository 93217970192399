import { useTranslation } from "react-i18next";
import "./WinLostByPlayerPage.scss";

import {
    Button,
    Checkbox,
    Col,
    Dropdown,
    Image,
    Input,
    Pagination,
    PaginationProps,
    Popover,
    Radio,
    Row,
    Space,
    Table,
} from "antd";
import { useEffect, useMemo, useState } from "react";

import { CSVLink } from "react-csv";

import moment from "moment";
import { Link } from "react-router-dom";
import { apis } from "../../../apis";

import { Loading3QuartersOutlined } from "@ant-design/icons";
import { httpService } from "../../../apis/httpService";
import { paths } from "../../../routes/path";

import { CustomDateRangePicker } from "../../../common/components/date-range-picker";
const WinLostByPlayerPage = () => {
    const { t } = useTranslation();

    const columnsSource = [
        {
            title: t("content.winLostByPlayerPage.col.currentBalance"),
            dataIndex: "currentBalance",
            key: "currentBalance",
        },
        {
            title: t("content.winLostByPlayerPage.col.depositCount"),
            dataIndex: "depositCount",
            key: "depositCount",
        },
        {
            title: t("content.winLostByPlayerPage.col.depositAmount"),
            dataIndex: "depositAmount",
            key: "depositAmount",
        },
        {
            title: t("content.winLostByPlayerPage.col.withdrawalCount"),
            dataIndex: "withdrawalCount",
            key: "withdrawalCount",
        },
        {
            title: t("content.winLostByPlayerPage.col.withdrawalAmount"),
            dataIndex: "withdrawalAmount",
            key: "withdrawalAmount",
        },
        {
            title: t("content.winLostByPlayerPage.col.betCount"),
            dataIndex: "betCount",
            key: "betCount",
        },
        {
            title: t("content.winLostByPlayerPage.col.turnover"),
            dataIndex: "turnover",
            key: "turnover",
        },

        {
            title: t("content.winLostByPlayerPage.col.netTurnover"),
            dataIndex: "netTurnover",
            key: "netTurnover",
        },
        {
            title: t("content.winLostByPlayerPage.col.memberWinLost"),
            dataIndex: "memberWinLost",
            key: "memberWinLost",
        },

        {
            title: t("content.winLostByPlayerPage.col.memberCommission"),
            dataIndex: "memberCommission",
            key: "memberCommission",
        },
        {
            title: t("content.winLostByPlayerPage.col.memberTotal"),
            dataIndex: "memberTotal",
            key: "memberTotal",
        },
        {
            title: t("content.winLostByPlayerPage.col.promotionBonus"),
            dataIndex: "promotionBonus",
            key: "promotionBonus",
        },
        {
            title: t("content.winLostByPlayerPage.col.manualBonus"),
            dataIndex: "manualBonus",
            key: "manualBonus",
        },
        {
            title: t("content.winLostByPlayerPage.col.companyWinLost"),
            dataIndex: "companyWinLost",
            key: "companyWinLost",
        },
    ];

    function makeColumnsDataSource() {
        const columns = [
            {
                title: "#",
                dataIndex: "index",
                key: "index",
                render: (text: any, record: any, index: number) => index + 1,
            },
            {
                title: t("content.winLostByPlayerPage.col.username"),
                dataIndex: "username",
                key: "username",
                render: (value: any, record: any) => {
                    return (
                        <div
                            className="flex"
                            style={{
                                justifyContent: "flex-start",
                            }}
                        >
                            <Link to={paths.USER_DETAIL.replace(":id", record?.username)}>{value}</Link>
                        </div>
                    );
                },
            },
            // {
            //     title: "Agent",
            //     dataIndex: "agent",
            //     key: "agent",
            //     render: (value: any) => {
            //         return (
            //             <div
            //                 style={{
            //                     justifyContent: "flex-start",
            //                 }}
            //             >
            //                 <Link to={""}>{value}</Link>
            //             </div>
            //         )
            //     },
            // },
            {
                title: t("content.winLostByPlayerPage.col.registerTime"),
                dataIndex: "registerTime",
                key: "register Time",
            },
            {
                title: t("content.winLostByPlayerPage.col.currency"),
                dataIndex: "currency",
                key: "currency",
                render: (value: any) => {
                    return (
                        <div
                            style={{
                                textAlign: "center",
                            }}
                        >
                            {value}
                        </div>
                    );
                },
            },
        ];

        for (const col of columnsSource) {
            if (
                col.key !== "username" &&
                col.key !== "companyWinLost" &&
                col.key !== "manualBonus" &&
                col.key !== "promotionBonus" &&
                col.key !== "memberWinLost" &&
                col.key !== "memberTotal" &&
                col.key !== "depositAmount" &&
                col.key !== "withdrawalAmount" &&
                col.key !== "depositCount" &&
                col.key !== "withdrawalCount" &&
                col.key !== "betCount"
            ) {
                columns.push({
                    title: col.title,
                    key: col.key,
                    dataIndex: col.dataIndex,
                    render: (value: any) => {
                        return (
                            <div
                                style={{
                                    textAlign: "right",
                                }}
                            >
                                {value}
                            </div>
                        );
                    },
                });
            }
            if (
                col.key === "companyWinLost" ||
                col.key === "manualBonus" ||
                col.key === "promotionBonus" ||
                col.key === "memberWinLost" ||
                col.key === "memberTotal"
            ) {
                columns.push({
                    title: col.title,
                    key: col.key,
                    dataIndex: col.dataIndex,
                    render: (value: any, record: any) => {
                        if (parseInt(value) > 0) {
                            return <div style={{ color: "rgb(0,51,153)", textAlign: "right" }}>{value}</div>;
                        } else if (parseInt(value) < 0) {
                            return <div style={{ color: "red", textAlign: "right" }}>{value}</div>;
                        } else {
                            return <div style={{ textAlign: "right" }}>{value}</div>;
                        }
                    },
                });
            }
            if (col.key === "withdrawalAmount" || col.key === "depositAmount") {
                columns.push({
                    title: col.title,
                    key: col.key,
                    dataIndex: col.dataIndex,
                    render: (value: any, record: any) => {
                        if (parseInt(value) > 0) {
                            return (
                                <div style={{ textAlign: "right" }}>
                                    <Link to={""}>{value}</Link>
                                </div>
                            );
                        } else {
                            return <div style={{ textAlign: "right" }}>{value}</div>;
                        }
                    },
                });
            }
            if (col.key === "betCount") {
                columns.push({
                    title: col.title,
                    key: col.key,
                    dataIndex: col.dataIndex,
                    render: (value: any) => {
                        return (
                            <div
                                style={{
                                    textAlign: "center",
                                }}
                            >
                                {/* <Link to={""}>{value}</Link> */}
                                {value}
                            </div>
                        );
                    },
                });
            }
            if (col.key === "depositCount" || col.key === "withdrawalCount") {
                columns.push({
                    title: col.title,
                    key: col.key,
                    dataIndex: col.dataIndex,
                    render: (value: any) => {
                        return (
                            <div
                                style={{
                                    textAlign: "center",
                                }}
                            >
                                {value}
                            </div>
                        );
                    },
                });
            }
        }

        return columns;
    }
    const [dataGameCash, setDataGameCash] = useState<any[]>([]);
    const columnsSourceMade = useMemo(() => makeColumnsDataSource(), [dataGameCash]);
    const [username, setUsername] = useState("");

    const [checkColumn, setCheckColumn] = useState(() => columnsSourceMade.map((item) => item.key));

    const handleCheckColum = (key: any) => {
        const isChecked = checkColumn.includes(key);
        if (isChecked) {
            const newState = checkColumn.filter((item) => item !== key);
            setCheckColumn(newState);
        } else {
            setCheckColumn([...checkColumn, key]);
        }
    };
    //filter columns table data
    const columnsData = columnsSourceMade.filter((i) => checkColumn.includes(i.key));
    const columnsInDropdown = useMemo(() => columnsSourceMade.filter((i) => i.key !== "index"), []);

    //emtydata
    const [emptyText, setEmptyText] = useState(t("content.winLostByPlayerPage.col.clickSearchtoSeeInformation"));
    let locale = {
        emptyText,
    };

    const currentDate = new Date();
    const fromDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - 30);

    const [filterDate, setFilterDate] = useState({
        fromDate: fromDate,
        toDate: currentDate,
    });

    const [totalPage, setTotalPage] = useState(10);
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [loadings, setLoadings] = useState(false);

    const getWinLostList = async () => {
        const payload = {
            fromDate: filterDate.fromDate.toISOString(),
            toDate: filterDate.toDate.toISOString(),
            username: username ? username : undefined,
            page,
            take: pageSize,
        };
        try {
            const res = await httpService.post(apis.report.winLostByPlayerPage, payload);
            setDataGameCash(res.data?.result);
            setTotalPage(res.data?.meta?.pageCount);
        } catch (error) {
            setDataGameCash([]);
            console.log("error", error);
        }
    };
    useEffect(() => {
        getWinLostList();
    }, [filterDate.fromDate, filterDate.toDate]);

    const onUsernameChange = (e: any) => {
        setUsername(e.target.value);
    };

    const onChangePage: PaginationProps["onChange"] = (pageNumber: number, pageSize: number) => {
        setPage(pageNumber);
        setPageSize(pageSize);
    };

    const onClickApplyDate = (event: any, picker: any) => {
        const fromDate = moment(picker.startDate).toDate();
        const toDate = moment(picker.endDate).toDate();
        setFilterDate({
            ...filterDate,
            fromDate,
            toDate,
        });
    };

    const clearDate = () => {
        setFilterDate({
            ...filterDate,
            fromDate: fromDate,
            toDate: currentDate,
        });
    };

    const contentStatisticalChildren = <div>{t("content.winLostByPlayerPage.contentStatisticalChildren")}</div>;
    return (
        <div>
            <Col id="win-lost-by-player-page">
                <Space direction="vertical" size="middle" style={{ width: "100%" }}>
                    <div className="filter-main">
                        <div className="filter-header">
                            <div className="filter-header__name">{t("content.winLostByPlayerPage.title")}</div>
                            <div className="filter-header__export">
                                <Button>
                                    <Image
                                        width={20}
                                        src="/excel_icon.png"
                                        style={{ marginBottom: 4, paddingRight: 2 }}
                                    />
                                    <CSVLink
                                        filename={"Expense_Table.csv"}
                                        data={dataGameCash}
                                        className="btn btn-primary"
                                    >
                                        <span> {t("content.winLostByPlayerPage.export")}</span>
                                    </CSVLink>
                                </Button>
                            </div>
                        </div>
                        <div className="filter-bar">
                            <Row gutter={9} className="filter">
                                <Col className="date-range-transaction-history">
                                    <span className="filter__name">
                                        {t("content.winLostByPlayerPage.statisticalDate")}
                                    </span>
                                    <Popover content={contentStatisticalChildren}>
                                        <Image preview={false} width={16} src="/question_mark_icon.svg" />
                                    </Popover>
                                    <CustomDateRangePicker
                                        onApply={onClickApplyDate}
                                        onCancel={clearDate}
                                        containerStyles={{
                                            width: "212px",
                                            padding: "3px 0px",
                                            paddingLeft: "4px",
                                            borderRadius: "6px",
                                            border: "1px solid #d9d9d9",
                                        }}
                                        fromDate={filterDate.fromDate}
                                        toDate={filterDate.toDate}
                                        resultToString
                                        opens={"center"}
                                        readOnly
                                    />
                                </Col>

                                <Col>
                                    <span className="filter__name">
                                        {t("content.winLostByPlayerPage.filterByUsername")}
                                    </span>
                                    <Input
                                        placeholder={t("content.winLostByPlayerPage.username")}
                                        onChange={onUsernameChange}
                                    />
                                </Col>

                                <Col>
                                    <span className="filter__name">
                                        {t("content.winLostByPlayerPage.decimalPointNumbers")}
                                    </span>
                                    <Radio.Group
                                        className="flex align-center"
                                        defaultValue={2}
                                        // onChange={onChangeDecimalPointNumbers}
                                        // value={decimalPointNumbers}
                                    >
                                        <Radio value={2}>2</Radio>
                                        <Radio value={3}>3</Radio>
                                        <Radio value={4}>4</Radio>
                                        <Radio value={5}>5</Radio>
                                        <Radio value={6}>6</Radio>
                                    </Radio.Group>
                                </Col>
                                <Col>
                                    <Button className="submit-btn" type="primary" onClick={getWinLostList}>
                                        <span>{t("content.winLostByPlayerPage.submit")}</span>
                                    </Button>
                                </Col>
                            </Row>
                        </div>
                    </div>
                    <div id="table-main">
                        <hr className="line" />
                        {loadings && <div className="layout-table"></div>}

                        <div className="footer-table footer-table__top">
                            <Pagination
                                defaultCurrent={1}
                                total={totalPage * 10}
                                showQuickJumper
                                onChange={onChangePage}
                                style={{ fontSize: 13 }}
                            />
                            <div>
                                <Dropdown
                                    dropdownRender={() => (
                                        <div className="footer-table__dropdown">
                                            {columnsInDropdown.map((item: any, index: any) => (
                                                <div onClick={() => handleCheckColum(item.key)}>
                                                    <Checkbox
                                                        style={{ marginRight: 10 }}
                                                        checked={checkColumn.includes(item.key)}
                                                    />
                                                    <div key={index}>{item?.title}</div>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                >
                                    <a onClick={(e) => e.preventDefault()}>
                                        <div className="footer-table__bars-icon">
                                            <img width={16} src="/bars_icon.svg"></img>
                                        </div>
                                    </a>
                                </Dropdown>
                            </div>
                        </div>
                        {loadings && (
                            <div className="loading">
                                <Loading3QuartersOutlined className="loading-icon" />
                            </div>
                        )}
                        <div id="table-data" style={{ marginTop: 6 }}>
                            <Table columns={columnsData} locale={locale} dataSource={dataGameCash} />
                        </div>
                        <div className="footer-table footer-table__bottom ">
                            <Pagination
                                defaultCurrent={1}
                                total={totalPage * 10}
                                showQuickJumper
                                onChange={onChangePage}
                                style={{ fontSize: 13 }}
                            />
                            <div>
                                <Dropdown
                                    dropdownRender={() => (
                                        <div className="footer-table__dropdown">
                                            {columnsInDropdown.map((item: any, index: any) => (
                                                <div onClick={() => handleCheckColum(item.key)}>
                                                    <Checkbox
                                                        style={{ marginRight: 10 }}
                                                        checked={checkColumn.includes(item.key)}
                                                    />
                                                    <div key={index}>{item?.title}</div>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                >
                                    <a onClick={(e) => e.preventDefault()}>
                                        <div className="footer-table__bars-icon">
                                            <img width={16} src="/bars_icon.svg"></img>
                                        </div>
                                    </a>
                                </Dropdown>
                            </div>
                        </div>
                    </div>
                </Space>
            </Col>
        </div>
    );
};

export default WinLostByPlayerPage;
