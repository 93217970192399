import { Button, Form, Input, Modal, Radio, Select } from "antd";
import { Option } from "antd/es/mentions";
import { useEffect, useLayoutEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { apis } from "../../../apis";
import { httpService } from "../../../apis/httpService";
import "./ModalEditSubAccount.scss";

type FieldType = {
    isModalOpen: boolean;
    setIsModalOpen: (value: any) => void;
    data: any;
};

const ModalEditSubAccount = ({ setIsModalOpen, isModalOpen, data }: FieldType) => {
    const [form] = Form.useForm();
    const { t } = useTranslation();

    const profile = useSelector((state: any) => state.auth?.profile);
    const onFinish = async (values: any) => {
        const payload = {
            password: values.password,
            fullName: values.fullName,
            phone: values.phone,
            mobile: values.mobile,
            status: values.status,
            suspend: values.suspend,
            roleIds: [values.permissionGroup],
        };
        try {
            await httpService.put(apis.user.update.replace(":id", profile?.id), payload);
        } catch (error) {
            console.log("error", error);
        }
        console.log("Success:", values);
    };

    const onFinishFailed = (errorInfo: any) => {
        console.log("Failed:", errorInfo);
    };
    const [confirmLoading, setConfirmLoading] = useState(false);

    const handleOk = () => {
        setIsModalOpen(false);

        toast.success("Success!");
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    useLayoutEffect(() => {
        if (isModalOpen) form.setFieldsValue(data);
    }, [isModalOpen]);

    const [permissionGroups, setPermissionGroups] = useState([]);
    const [userRole, setUserRole] = useState<any>();

    const getUserRole = async () => {
        try {
            const res = await httpService.get(`${apis.permission.userRole}/${profile?.id}`);
            if (res.data?.result?.length) {
                setUserRole(res.data.result);
            }
        } catch (error) {
            console.log("error", error);
        }
    };
    useEffect(() => {
        getUserRole();
    }, [isModalOpen]);

    const getPermissionGroups = async () => {
        try {
            const res = await httpService.get(`${apis.permission.groups}/?page=${1}&take=${100}`);

            if (res.data?.result?.length) {
                setPermissionGroups(res.data.result);
            }
        } catch (error) {
            console.log("error", error);
        }
    };
    useEffect(() => {
        getPermissionGroups();
        getUserRole();
    }, [isModalOpen]);

    return (
        <div className="modal-edit-sub-account__container">
            <Modal
                title={t("content.subAccountList.editSubAccountModal.title")}
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                confirmLoading={confirmLoading}
                footer={null}
                className="modal-edit-sub-account"
            >
                <div style={{ marginTop: 20 }}>
                    <Form
                        name="basic"
                        labelCol={{ span: 8 }}
                        wrapperCol={{ span: 12 }}
                        style={{ textAlign: "center" }}
                        onFinish={onFinish}
                        onFinishFailed={onFinishFailed}
                        form={form}
                    >
                        <Form.Item label={t("content.subAccountList.editSubAccountModal.firstName")} name="fullName">
                            <Input />
                        </Form.Item>
                        <Form.Item label={t("content.subAccountList.editSubAccountModal.lastName")} name="fullName">
                            <Input />
                        </Form.Item>

                        <Form.Item label={t("content.subAccountList.editSubAccountModal.password")} name="password">
                            <Input.Password placeholder="********" />
                        </Form.Item>
                        <p className="change-password">
                            {t("content.subAccountList.editSubAccountModal.changePasswordNotice")}
                        </p>

                        <Form.Item label={t("content.subAccountList.editSubAccountModal.phone")} name="phone">
                            <Input />
                        </Form.Item>

                        <Form.Item
                            label={t("content.subAccountList.editSubAccountModal.permissionGroup")}
                            name="permissionGroup"
                        >
                            <Select defaultValue={userRole?.id}>
                                {permissionGroups?.map((i: any) => (
                                    <Option>{i.name}</Option>
                                ))}
                            </Select>
                        </Form.Item>

                        <Form.Item label={t("content.subAccountList.editSubAccountModal.status.title")}>
                            <Radio.Group defaultValue={data?.status}>
                                <Radio value="Open">
                                    {t("content.subAccountList.editSubAccountModal.status.type.open")}
                                </Radio>
                                <Radio value="Close">
                                    {t("content.subAccountList.editSubAccountModal.status.type.close")}
                                </Radio>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item label={t("content.subAccountList.editSubAccountModal.suspend.title")} name="Suspend">
                            <Radio.Group style={{ marginRight: 34 }} defaultValue={data?.suspend}>
                                <Radio value="Yes">
                                    {t("content.subAccountList.editSubAccountModal.suspend.type.yes")}
                                </Radio>
                                <Radio value="No">
                                    {t("content.subAccountList.editSubAccountModal.suspend.type.no")}
                                </Radio>
                            </Radio.Group>
                        </Form.Item>
                        {/* <Form.Item label={t("content.subAccountList.editSubAccountModal.lock.title")}>
                            <Radio.Group defaultValue={data?.lock}>
                                <Radio value="Lock">
                                    {t("content.subAccountList.editSubAccountModal.lock.type.lock")}
                                </Radio>
                                <Radio value="Unlock">
                                    {t("content.subAccountList.editSubAccountModal.lock.type.unLock")}
                                </Radio>
                            </Radio.Group>
                        </Form.Item> */}

                        <Form.Item wrapperCol={{ offset: 8, span: 8 }} style={{ marginTop: 40 }}>
                            <Button type="primary" htmlType="submit" onClick={handleOk}>
                                {t("content.subAccountList.editSubAccountModal.save")}
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </Modal>
            <ToastContainer autoClose={1500} />
        </div>
    );
};

export default ModalEditSubAccount;
