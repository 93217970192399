import {
    Button,
    Col,
    DatePicker,
    Image,
    Input,
    Pagination,
    PaginationProps,
    Radio,
    Row,
    Select,
    Space,
    Table,
} from "antd";
import { useEffect, useMemo, useState } from "react";
import { CSVLink } from "react-csv";
import { useTranslation } from "react-i18next";

import moment from "moment";
import { AlignType } from "rc-table/lib/interface";

import { Loading3QuartersOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { apis } from "../../apis";
import { httpService } from "../../apis/httpService";
import { CustomDateRangePicker } from "../../common/components/date-range-picker";
import { TransactionType } from "../../common/constants/transaction.enum";
import { ISort } from "../../interfaces/Global";
import { paths } from "../../routes/path";
import "./TransactionHistory.scss";

const TransactionHistory = () => {
    const { t } = useTranslation();

    const { RangePicker } = DatePicker;
    const { Option } = Select;

    const dataSource = [
        {
            // key: "1",
            username: "testkm1",
            modified: "2023-12-13 20:32:38 (+7)",
            acctionType: "Payment Deposit",
            transactionID: 131313,
            currency: "VND",
            productTypeName: "",
            withdrawal: 0.0,
            createdBy: "ViLa88Test",
            beforeCashBalance: 829.0,
            cashBalanceDelta: 0,
            afterCashBalance: "5,829.00	",
            beforeOutstanding: 0,
            outstandingDelta: "0.00",
            afterOutstanding: 0.0,
            beforeUserBalance: "829.00",
            afterUserBalance: "5,829.00",
            Remark: "",
        },
        {
            // key: "1",
            username: "testkm1",
            modified: "2023-12-13 20:32:38 (+7)",
            acctionType: "Payment Deposit",
            transactionID: 131313,
            currency: "VND",
            productTypeName: "",
            withdrawal: 0.0,
            createdBy: "ViLa88Test",
            beforeCashBalance: 829.0,
            cashBalanceDelta: "5,000.00",
            afterCashBalance: "5,829.00	",
            beforeOutstanding: 0,
            outstandingDelta: "0.00",
            afterOutstanding: 0.0,
            beforeUserBalance: "829.00",
            afterUserBalance: "5,829.00",
            Remark: "",
        },
        {
            // key: "1",
            username: "testkm1",
            modified: "2023-12-13 20:32:38 (+7)",
            acctionType: "Payment Deposit",
            transactionID: 131313,
            currency: "VND",
            productTypeName: "",
            withdrawal: 0.0,
            createdBy: "ViLa88Test",
            beforeCashBalance: 82139.0,
            cashBalanceDelta: "5,000.00",
            afterCashBalance: "5,829.00	",
            beforeOutstanding: 0,
            outstandingDelta: "0.00",
            afterOutstanding: 0.0,
            beforeUserBalance: "829.00",
            afterUserBalance: "5,829.00",
            Remark: "",
        },
        {
            // key: "1",
            username: "testkm1",
            modified: "2023-12-13 20:32:38 (+7)",
            acctionType: "Payment Deposit",
            transactionID: 131313,
            currency: "VND",
            productTypeName: "",
            withdrawal: 0.0,
            createdBy: "ViLa88Test",
            beforeCashBalance: 829.0,
            cashBalanceDelta: -513000.0,
            afterCashBalance: "5,81329.0",
            beforeOutstanding: 0,
            outstandingDelta: "0.00",
            afterOutstanding: 0.0,
            beforeUserBalance: 82139.0,
            afterUserBalance: "5,81329.00",
            Remark: "",
        },
        {
            // key: "1",
            username: "testkm1",
            modified: "2023-12-13 20:32:38 (+7)",
            acctionType: "Payment Deposit",
            transactionID: 131313,
            currency: "VND",
            productTypeName: "",
            withdrawal: 0.0,
            createdBy: "ViLa88Test",
            beforeCashBalance: 82319.0,
            cashBalanceDelta: -213131341,
            afterCashBalance: "5,829.00	",
            beforeOutstanding: 0,
            outstandingDelta: "0.00",
            afterOutstanding: 0.0,
            beforeUserBalance: "829.00",
            afterUserBalance: "5,829.00",
            Remark: "",
        },
        {
            // key: "1",
            username: "testkm1",
            modified: "2023-12-13 20:32:38 (+7)",
            acctionType: "Payment Deposit",
            transactionID: 131313,
            currency: "VND",
            productTypeName: "",
            withdrawal: 0.0,
            createdBy: "ViLa88Test",
            beforeCashBalance: 829.0,
            cashBalanceDelta: "5,000.00",
            afterCashBalance: "5,829.00	",
            beforeOutstanding: 0,
            outstandingDelta: "0.00",
            afterOutstanding: 0.0,
            beforeUserBalance: "812329.00",
            afterUserBalance: "5,13829.00",
            Remark: "",
        },

        // More data...
    ];

    const columnsSource = [
        {
            title: t("content.balanceHistory.col.beforeCashBalance"),
            dataIndex: "beforeCashBalance",
            key: "beforeCashBalance",
        },
        {
            title: t("content.balanceHistory.col.cashBalanceDelta"),
            dataIndex: "cashBalanceDelta",
            key: "cashBalanceDelta",
        },
        {
            title: t("content.balanceHistory.col.afterCashBalance"),
            dataIndex: "afterCashBalance",
            key: "afterCashBalance",
        },
        {
            title: t("content.balanceHistory.col.beforeOutstanding"),
            dataIndex: "beforeOutstanding",
            key: "beforeOutstanding",
        },

        {
            title: t("content.balanceHistory.col.outstandingDelta"),
            dataIndex: "outstandingDelta",
            key: "outstandingDelta",
        },
        {
            title: t("content.balanceHistory.col.afterOutstanding"),
            dataIndex: "afterOutstanding",
            key: "afterOutstanding",
        },
        {
            title: t("content.balanceHistory.col.beforeUserBalance"),
            dataIndex: "beforeUserBalance",
            key: "beforeUserBalance",
        },
        {
            title: t("content.balanceHistory.col.afterUserBalance"),
            dataIndex: "afterUserBalance",
            key: "afterUserBalance",
        },
        {
            title: t("content.balanceHistory.col.remark"),
            dataIndex: "remark",
            key: "remark",
        },
    ];
    function makeColumnsDataSource() {
        const columns = [
            {
                title: "#",
                dataIndex: "index",
                key: "index",
                render: (text: any, record: any, index: number) => {
                    return <div style={{ textAlign: "center" }}>{index + 1}</div>;
                },
            },
            {
                title: t("content.balanceHistory.col.username"),
                dataIndex: "username",
                key: "username",
                render: (value: any, record: any) => {
                    return (
                        <Link to={paths.USER_DETAIL.replace(":id", record.userId)} style={{ color: "#7f7f7f" }}>
                            {value}
                        </Link>
                    );
                },
            },
            {
                title: t("content.balanceHistory.col.modifiedTime"),
                dataIndex: "modified",
                key: "modified",
            },
            {
                title: t("content.balanceHistory.col.actionType"),
                dataIndex: "acctionType",
                key: "acctionType",
            },
            {
                title: t("content.balanceHistory.col.transactionId"),
                dataIndex: "transactionID",
                key: "transactionID",
            },

            {
                title: t("content.balanceHistory.col.currency"),
                dataIndex: "currency",
                key: "currency",
                align: "center" as AlignType,
            },
            {
                title: t("content.balanceHistory.col.productTypeName"),
                dataIndex: "productTypeName",
                key: "productTypeName",
            },
        ];

        for (const col of columnsSource) {
            if (col.key === "remark") {
                columns.push({
                    title: col.title,
                    key: col.key,
                    dataIndex: col.dataIndex,
                    render: (value: any, record: any) => {
                        return <div>{value}</div>;
                    },
                });
            } else {
                columns.push({
                    title: col.title,
                    key: col.key,
                    dataIndex: col.dataIndex,
                    render: (value: any, record: any) => {
                        if (parseInt(value) > 0) {
                            return <div className="primary-color">{value}</div>;
                        } else if (parseInt(value) < 0) {
                            return <div className="error-color">{value}</div>;
                        } else {
                            return <div className="col-zero">{value}</div>;
                        }
                    },
                });
            }
        }

        return columns;
    }

    const columnsData = useMemo(() => makeColumnsDataSource(), [dataSource]);

    const [username, setUsername] = useState("");
    const [currency, setCurrency] = useState("");
    const [transactionType, setTransactionType] = useState("");

    const [loadings, setLoadings] = useState(false);

    //emtydata
    const [emptyText, setEmptyText] = useState(t("content.balanceHistory.clickSearchtoSeeInformation"));
    let locale = {
        emptyText,
    };

    const currentDate = new Date();
    const fromDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() - 30);

    const [filterDate, setFilterDate] = useState({
        fromDate: fromDate,
        toDate: currentDate,
    });

    const [txnList, setTxnList] = useState([]);
    const [totalPage, setTotalPage] = useState(10);
    const [textSearch, setTextSearch] = useState("");
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [orderBy, setOrderBy] = useState<ISort | null>(null);

    const getTxnList = async () => {
        try {
            const orderStr = orderBy === null ? "" : `&sortKey=${orderBy.field}&sortOrder=${orderBy.order}`;
            const res = await httpService.get(
                `${
                    apis.txn.list
                }/?page=${page}&query=${textSearch}&take=${pageSize}${orderStr}&fromDate=${filterDate.fromDate.toISOString()}&toDate=${filterDate.toDate.toISOString()}&username=${username}&type=${transactionType}&currency=${currency}`
            );

            //apply when have real data
            if (res.data?.result?.length) {
                const result = res.data.result.map((item: any) => {
                    return {
                        date: moment.utc(item.createdAt).add(7, "hours"),
                        txnNo: item.code,
                        transactionType: item.type,
                        username: item.owner.username,
                        currency: item.currency,
                        deposit: item.type === TransactionType.Deposit ? item.amount : 0,
                        withdrawal: item.type === TransactionType.Withdraw ? item.amount : 0,
                        createdBy: item.createdBy,
                        note: item.note,
                        userId: item.owner.id,
                    };
                });

                setTxnList(result);
                setTotalPage(res?.data?.meta.itemCount);
            }
        } catch (error) {
            console.log("error", error);
        }
    };

    useEffect(() => {
        getTxnList();
    }, [
        page,
        textSearch,
        pageSize,
        orderBy,
        username,
        currency,
        transactionType,
        filterDate.fromDate,
        filterDate.toDate,
    ]);

    const onUsernameChange = (e: any) => {
        setUsername(e.target.value);
    };

    const onCurrencyChange = (value: any) => {
        setCurrency(value);
    };

    const onTransactionTypeChange = (value: any) => {
        setTransactionType(value);
    };

    const onClickApplyDate = (event: any, picker: any) => {
        const fromDate = moment(picker.startDate).toDate();
        const toDate = moment(picker.endDate).toDate();
        setFilterDate({
            ...filterDate,
            fromDate,
            toDate,
        });
    };

    const clearDate = () => {
        setFilterDate({
            ...filterDate,
            fromDate: fromDate,
            toDate: currentDate,
        });
    };

    const handleSearch = async () => {
        await getTxnList();
    };

    const enterLoading = () => {
        setLoadings(true);

        setTimeout(() => {
            if (dataSource.length === 0) {
                setEmptyText(t("content.balanceHistory.noData"));
            }
            setLoadings(false);
        }, 3000);
    };
    const onChangePage: PaginationProps["onChange"] = (pageNumber: any) => {
        console.log("Page: ", pageNumber);
    };

    return (
        <div id="transactions-history">
            <Col id="filter">
                <div className="filter-header" style={{ height: "32px" }}>
                    <div className="filter-header__name">{t("content.balanceHistory.dateSetting.title")}</div>

                    <div></div>
                </div>
                <div className="filter-bar">
                    <Row gutter={9} className="filter">
                        <Col className="date-range-transaction-history">
                            <div className="filter__name">
                                {t("content.balanceHistory.dateSetting.statisticalDate")}
                            </div>

                            <CustomDateRangePicker
                                onApply={onClickApplyDate}
                                onCancel={clearDate}
                                containerStyles={{
                                    width: "212px",
                                    padding: "3px 0px",
                                    paddingLeft: "4px",
                                    borderRadius: "6px",
                                    border: "1px solid #d9d9d9",
                                }}
                                fromDate={filterDate.fromDate}
                                toDate={filterDate.toDate}
                                resultToString
                                opens={"center"}
                                readOnly
                            />
                        </Col>

                        <Col className="star">
                            <div className="filter__name">{t("content.balanceHistory.dateSetting.username")}</div>
                            <Input
                                placeholder={t("content.balanceHistory.dateSetting.username")}
                                onChange={onUsernameChange}
                            />
                        </Col>

                        <Col>
                            <div className="filter__name">{t("content.balanceHistory.dateSetting.currency")}</div>
                            <Select
                                defaultValue={t("content.balanceHistory.dateSetting.all")}
                                style={{ width: 120 }}
                                onChange={onCurrencyChange}
                            >
                                <Option value="">{t("content.balanceHistory.dateSetting.all")}</Option>
                                <Option value="tmp">TMP</Option>
                            </Select>
                        </Col>

                        <Col>
                            <div className="filter__name">{t("content.balanceHistory.dateSetting.transactionId")}</div>
                            <Input
                                placeholder={t("content.balanceHistory.dateSetting.username")}
                                onChange={onUsernameChange}
                            />
                        </Col>
                        <Col>
                            <div className="filter__name">
                                {t("content.balanceHistory.dateSetting.decimalPointNumbers")}
                            </div>
                            <Radio.Group
                                className="flex align-center"
                                defaultValue={2}
                                // onChange={onChangeDecimalPointNumbers}
                                // value={decimalPointNumbers}
                            >
                                <Radio value={2}>2</Radio>
                                <Radio value={3}>3</Radio>
                                <Radio value={4}>4</Radio>
                                <Radio value={5}>5</Radio>
                                <Radio value={6}>6</Radio>
                            </Radio.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Button className="submit-btn" type="primary" onClick={() => enterLoading()}>
                                {loadings && (
                                    <div className="loading-btn">
                                        <Image
                                            width={16}
                                            src="/loading_icon.svg"
                                            className="loading-icon-btn"
                                            preview={false}
                                        />
                                    </div>
                                )}
                                <div>{t("content.balanceHistory.dateSetting.search")}</div>
                            </Button>
                        </Col>
                    </Row>
                </div>
            </Col>
            <Col id="transactions">
                <Space direction="vertical" size="middle" style={{ width: "100%" }}>
                    <div className="filter-header">
                        <div className="filter-header__name">{t("content.balanceHistory.title")}</div>
                        <div className="filter-header__export">
                            <Button>
                                <Image width={20} src="/excel_icon.png" style={{ marginBottom: 4, paddingRight: 2 }} />
                                <CSVLink filename={"Expense_Table.csv"} data={dataSource} className="btn btn-primary">
                                    <span> {t("content.balanceHistory.export")}</span>
                                </CSVLink>
                            </Button>
                        </div>
                    </div>

                    <div id="table">
                        <div className="footer-table">
                            <Pagination
                                defaultCurrent={1}
                                total={totalPage}
                                showQuickJumper
                                onChange={onChangePage}
                                style={{ fontSize: 13 }}
                            />
                        </div>
                        <div id="table-data" style={{ marginTop: 6 }}>
                            <Table
                                columns={columnsData}
                                locale={locale}
                                dataSource={dataSource}
                                onChange={(pagination, filter, sorter: any) => {
                                    console.log("===", sorter, orderBy);
                                    setOrderBy({
                                        field: sorter.field,
                                        order: sorter.order === "descend" ? "desc" : "asc",
                                    });
                                }}
                                // pagination={{
                                //     pageSize,
                                //     total: totalPage,
                                //     onChange: (page, pageSize) => {
                                //         setPage(page);
                                //         setPageSize(pageSize);
                                //     },
                                // }}
                            />
                        </div>
                        <div className="footer-table">
                            <Pagination
                                defaultCurrent={1}
                                total={totalPage}
                                showQuickJumper
                                onChange={onChangePage}
                                style={{ fontSize: 13 }}
                            />
                        </div>
                        {loadings && (
                            <tr className="loading-mask-table">
                                <div className="loading-icon-table">
                                    <Loading3QuartersOutlined className="loading-img-table" />
                                </div>
                            </tr>
                        )}
                    </div>
                </Space>
            </Col>
        </div>
    );
};

export default TransactionHistory;
