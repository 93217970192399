import { Card, Col, Radio, Row, Table, Typography } from "antd";
import { AlignType } from "rc-table/lib/interface";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { CartesianGrid, Label, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";
import { apis } from "../../apis";
import { httpService } from "../../apis/httpService";
import "./Chart.scss";

const { Text } = Typography;

const ChartHome = () => {
    const { t } = useTranslation();
    const [activeTab, setActiveTab] = useState("onlineUser");
    const chartData = [
        { hour: "0", deposit: 400, withdraw: 200, amt: 2400 },
        { hour: "1", deposit: 500, withdraw: 400, amt: 2400 },
        { hour: "2", deposit: 600, withdraw: 700, amt: 2400 },
        { hour: "3", deposit: 600, withdraw: 700, amt: 2400 },
        { hour: "4", deposit: 600, withdraw: 700, amt: 2400 },
        { hour: "5", deposit: 600, withdraw: 700, amt: 2400 },
        { hour: "7", deposit: 700, withdraw: 400, amt: 2400 },
        { hour: "8", deposit: 700, withdraw: 400, amt: 2400 },
        { hour: "9", deposit: 700, withdraw: 400, amt: 2400 },
        { hour: "10", deposit: 700, withdraw: 400, amt: 2400 },
        { hour: "11", deposit: 700, withdraw: 400, amt: 2400 },
        { hour: "12", deposit: 700, withdraw: 400, amt: 2400 },
        { hour: "13", deposit: 700, withdraw: 400, amt: 2400 },
        { hour: "14", deposit: 700, withdraw: 400, amt: 2400 },
        { hour: "15", deposit: 700, withdraw: 400, amt: 2400 },
        { hour: "16", deposit: 700, withdraw: 400, amt: 2400 },
        { hour: "17", deposit: 700, withdraw: 400, amt: 2400 },
        { hour: "18", deposit: 700, withdraw: 400, amt: 2400 },
        { hour: "19", deposit: 700, withdraw: 400, amt: 2400 },
        { hour: "20", deposit: 700, withdraw: 400, amt: 2400 },
        { hour: "21", deposit: 700, withdraw: 400, amt: 2400 },
        { hour: "22", deposit: 700, withdraw: 400, amt: 2400 },
        { hour: "23", deposit: 700, withdraw: 400, amt: 2400 },
        { hour: "24", deposit: 700, withdraw: 400, amt: 2400 },
    ];

    // const dataSource = [
    //     {
    //         key: "1",
    //         type: t("content.dashboard.manualOperation"),
    //         "2023-12-11": "0.00",
    //         "2023-12-12": "1.00",
    //         "2023-12-13": "2.00",
    //         "2023-12-14": "4.00",
    //         // ... other dates
    //     },
    //     {
    //         key: "2",
    //         type: t("content.dashboard.bankTransfer"),
    //         // "2023-12-11": "-2",
    //         // "2023-12-12": "1.00",
    //         // "2023-12-13": "2.00",
    //         "2023-12-14": "4.00",
    //         // ... other dates
    //     },
    //     {
    //         key: "3",
    //         type: t("content.dashboard.paymentGateway"),
    //         "2023-12-11": "3",
    //         // "2023-12-12": "-8.2",
    //         // "2023-12-13": "2.00",
    //         // "2023-12-14": "4.00",
    //         // ... other dates
    //     },
    // ]

    function makeColumns(dataSource: any) {
        const columns = [
            {
                title: t("content.dashboard.type"),
                key: "type",
                dataIndex: "type",
                render: (value: any, record: any) => {
                    return <div>{value}</div>;
                },
                align: "left" as AlignType,
            },
        ];

        for (const col in dataSource[0]) {
            if (col !== "type") {
                columns.push({
                    title: col,
                    key: col,
                    dataIndex: col,
                    render: (value: any) => {
                        if (parseInt(value) < 0) {
                            return <div style={{ color: "red" }}>{value}</div>;
                        } else {
                            return <div style={{ color: "#606266" }}>{value}</div>;
                        }
                    },
                    align: "center",
                });
            }
        }

        return columns;
    }

    const [bank7DaysData, setBank7DaysData] = useState([]);
    const columnsMade = useMemo(() => makeColumns(bank7DaysData), [bank7DaysData]);

    const onPass24hChange = (e: string) => {
        setActiveTab(e);
    };

    const [data24h, setData24h] = useState([]);

    const getUserOnline24h = async () => {
        try {
            let res;
            if (activeTab === "onlineUser") {
                res = await httpService.get(apis.home.userOnlinePast24h);
            }
            if (activeTab === "depositAndWithdraw") {
                res = await httpService.get(apis.home.depositAndWithdraw24h);
            }

            if (activeTab === "registerAndFirstDeposit") {
                res = await httpService.get(apis.home.registerAndFirstDeposit24h);
            }

            if (res?.data) {
                setData24h(res.data);
            }
        } catch (error) {
            console.log("error", error);
        }
    };

    useEffect(() => {
        getUserOnline24h();
    }, [activeTab]);

    const getBankChange7Days = async () => {
        try {
            const res = await httpService.get(apis.home.bankChange7Days);
            setBank7DaysData(res.data);
        } catch (error) {
            console.log("error", error);
        }
    };

    useEffect(() => {
        getBankChange7Days();
    }, []);

    const findChart = () => {
        const data = data24h.length > 0 ? data24h : chartData;
        const key1 = Object.keys(data[0])[1];

        let key2;
        if (Object.keys(data[0]).length === 4) {
            key2 = Object.keys(data[0])[2];
        }

        return (
            <LineChart data={data}>
                <CartesianGrid strokeDasharray="3 3" />

                <XAxis dataKey="hour" />
                <YAxis>
                    <Label
                        style={{
                            textAnchor: "middle",
                            fontSize: "150%",
                        }}
                        angle={-90}
                        position="insideLeft"
                    >
                        {t("content.dashboard.numberOfMembers")}
                    </Label>
                </YAxis>
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey={key1} stroke="#8884d8" />
                {key2 && <Line type="monotone" dataKey={key2} stroke="#82ca9d" />}
            </LineChart>
        );
    };

    return (
        <div className="chart">
            <Row gutter={16} style={{ paddingBottom: "24px" }}>
                <Col span={24}>
                    <Row className="past-24h-tab">
                        <Col span={3}>
                            <p className="past-24h-text">{t("content.dashboard.past24Hours")}</p>
                        </Col>
                        <Col span={21}>
                            <Radio.Group
                                onChange={(e) => onPass24hChange(e.target.value)}
                                optionType="button"
                                buttonStyle="solid"
                                defaultValue={"onlineUser"}
                            >
                                <Radio.Button value="onlineUser">{t("content.dashboard.onlineUser")}</Radio.Button>
                                <Radio.Button value="depositAndWithdraw">
                                    {t("content.dashboard.depositAndWithdrawAmount")}
                                </Radio.Button>
                                <Radio.Button value="registerAndFirstDeposit">
                                    {t("content.dashboard.registerAndFirstDepositCount")}
                                </Radio.Button>
                            </Radio.Group>
                        </Col>
                    </Row>

                    <Row>
                        <ResponsiveContainer width="100%" height={300}>
                            {findChart()}
                        </ResponsiveContainer>
                    </Row>
                </Col>
                <Col
                    span={24}
                    style={{
                        textAlign: "center",
                        marginTop: 8,
                    }}
                >
                    <p className="chart-title">Time (GMT+7)</p>
                </Col>
            </Row>

            <Row gutter={16} style={{ marginBottom: "16px", paddingTop: 16 }}>
                <Col span={24}>
                    <Card title={t("content.dashboard.past7DaysBankBalance")}>
                        <Table
                            dataSource={bank7DaysData}
                            columns={columnsMade}
                            pagination={false}
                            summary={(pageData) => {
                                const totalData: any = {};

                                pageData.forEach((record: any) => {
                                    for (const key in record) {
                                        if (key !== "key" && key !== "type") {
                                            if (!totalData[key]) {
                                                totalData[key] = parseFloat(record[key]);
                                            } else {
                                                totalData[key] += parseFloat(record[key]);
                                            }
                                        }
                                    }
                                });

                                return (
                                    <>
                                        <Table.Summary.Row>
                                            <Table.Summary.Cell index={0}>
                                                <span style={{ fontWeight: 600 }}>{t("content.dashboard.total")}</span>
                                            </Table.Summary.Cell>
                                            {Object.keys(totalData).map((key, index) => {
                                                return (
                                                    <Table.Summary.Cell index={index} align="center">
                                                        {totalData[key] < 0 ? (
                                                            <Text type="danger">
                                                                <span style={{ fontWeight: 600 }}>
                                                                    {totalData[key]?.toFixed(2)}
                                                                </span>
                                                            </Text>
                                                        ) : (
                                                            <Text>
                                                                <span style={{ fontWeight: 600 }}>
                                                                    {totalData[key]?.toFixed(2)}
                                                                </span>
                                                            </Text>
                                                        )}
                                                    </Table.Summary.Cell>
                                                );
                                            })}
                                        </Table.Summary.Row>
                                    </>
                                );
                            }}
                        />
                    </Card>
                </Col>
            </Row>
        </div>
    );
};

export default ChartHome;
