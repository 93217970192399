import { useTranslation } from "react-i18next";
import { DragOutlined } from "@ant-design/icons";
import { Button, Form, Modal } from "antd";
import { useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./ModalGameCategoryOrder.scss";

const finalSpaceCharacters = [
    {
        id: "gary",
        name: "Gary Goodspeed",
        thumb: "/images/gary.png",
    },
    {
        id: "cato",
        name: "Little Cato",
        thumb: "/images/cato.png",
    },
    {
        id: "kvn",
        name: "KVN",
        thumb: "/images/kvn.png",
    },
    {
        id: "mooncake",
        name: "Mooncake",
        thumb: "/images/mooncake.png",
    },
    {
        id: "quinn",
        name: "Quinn Ergon",
        thumb: "/images/quinn.png",
    },
];

type FieldType = {
    isModalOpen: boolean;
    setIsModalOpen: (value: any) => void;
    data: any;
};

const ModalGameCategoryOrder = ({ setIsModalOpen, isModalOpen, data }: FieldType) => {
    const [form] = Form.useForm();
    const { t } = useTranslation();
    const [confirmLoading, setConfirmLoading] = useState(false);

    const handleOk = () => {
        setIsModalOpen(false);

        toast.success("Success!");
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    // useLayoutEffect(() => {
    //     if (isModalOpen) form.setFieldsValue(data)
    // }, [isModalOpen])

    const [characters, updateCharacters] = useState(finalSpaceCharacters);

    function handleOnDragEnd(result: any) {
        if (!result.destination) return;

        const items = Array.from(characters);
        const [reorderedItem] = items.splice(result.source.index, 1);
        items.splice(result.destination.index, 0, reorderedItem);

        updateCharacters(items);
    }

    return (
        <div>
            <Modal
                title={t("content.gameProvider.gamesCategoryDisplayOrderModal.title")}
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                confirmLoading={confirmLoading}
                footer={null}
                className="modal-game-category-order"
            >
                <div style={{ marginTop: 20 }}>
                    <div className="game-list-header">
                        <p className="game-list-header-text">
                            {t("content.gameProvider.gamesCategoryDisplayOrderModal.notice")}
                        </p>
                        <Button type="primary" size="small">
                            {t("content.gameProvider.gamesCategoryDisplayOrderModal.save")}
                        </Button>
                    </div>
                    <DragDropContext onDragEnd={handleOnDragEnd}>
                        <Droppable droppableId="characters">
                            {(provided) => (
                                <ul className="game-list" {...provided.droppableProps} ref={provided.innerRef}>
                                    {characters.map(({ id, name, thumb }, index) => {
                                        return (
                                            <Draggable key={id} draggableId={id} index={index}>
                                                {(provided) => (
                                                    <li
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                    >
                                                        <div className="game-list-content">
                                                            <p>{name}</p>
                                                            <DragOutlined />
                                                        </div>
                                                    </li>
                                                )}
                                            </Draggable>
                                        );
                                    })}
                                    {provided.placeholder}
                                </ul>
                            )}
                        </Droppable>
                    </DragDropContext>
                </div>
            </Modal>
            <ToastContainer autoClose={1500} />
        </div>
    );
};

export default ModalGameCategoryOrder;
