import {
    Checkbox,
    CheckboxProps,
    Descriptions,
    Image,
    Input,
    Popover,
    Radio,
    RadioChangeEvent,
    Select,
    Tag,
} from "antd"
import React, { useEffect, useState } from "react"

import Button from "antd/es/button"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import { toast } from "react-toastify"
import { apis } from "../../../../../apis"
import { httpService } from "../../../../../apis/httpService"
import { TagUser } from "../../../../../common/constants/tag"
import { TransactionType } from "../../../../../common/constants/transaction.enum"
import "./Information.scss"

const { TextArea } = Input
const { Option } = Select

const Information: React.FC = () => {
    const { t } = useTranslation()

    const [showPageResetPass, setShowPageResetPass] = useState(false)
    const [showManualTransfer, setShowManualTransfer] = useState(false)
    const [clickEditStatus, setClickEditStatus] = useState(true)
    const [clickEditAccountInformation, setClickEditAccountInformation] = useState(true)
    const [clickEditCreditInformation, setClickEditCreditInformation] = useState(true)
    const [clickEditDeposit, setClickEditDeposit] = useState(true)
    const [tagValue, setTagValue] = useState("")

    const handleClickResetPass = () => {
        setShowPageResetPass(!showPageResetPass)
    }

    const handleClickEditStatus = () => {
        setClickEditStatus(!clickEditStatus)
    }

    const handleClickEditAccountInformation = () => {
        setClickEditAccountInformation(!clickEditAccountInformation)
    }
    const onClickSaveAccountInformation = async () => {
        setClickEditAccountInformation(!clickEditAccountInformation)
        await onClickSave()
    }

    const handleClickEditCreditInformation = () => {
        setClickEditCreditInformation(!clickEditCreditInformation)
    }

    const handleClickEditDeposit = () => {
        setClickEditDeposit(!clickEditDeposit)
    }

    // const [valueStatus, setValueStatus] = useState("open")
    // const onChangeStatus = (e: RadioChangeEvent) => {
    //     setValueStatus(e.target.value)
    // }

    const [valueSuspend, setValueSuspend] = useState()
    const onChangeSuspend = (e: RadioChangeEvent) => {
        setValueSuspend(e.target.value)
    }

    const [valueLock, setValueLock] = useState()
    const onChangeLock = (e: RadioChangeEvent) => {
        setValueLock(e.target.value)
    }

    const [valueUserGroup, setValueUserGroup] = useState("A")
    const onChangeUserGroup = (value: string) => {
        setValueUserGroup(value)
    }

    const [checkArbitrageTag, setCheckArbitrageTag] = useState(false)
    const onChangeArbitrageTag: CheckboxProps["onChange"] = (e) => {
        setCheckArbitrageTag(e.target.checked)
        setTagValue(TagUser.ArbitragePlayer)
    }

    const [checkRiskyTag, setCheckRiskyTag] = useState(false)
    const onChangeRiskyTag: CheckboxProps["onChange"] = (e) => {
        setCheckRiskyTag(e.target.checked)
        setTagValue(TagUser.RiskyPlayer)
    }

    // Change data Account Information
    const [valueEmail, setValueEmail] = useState("")
    const onChangeEmail = (e: any) => {
        setValueEmail(e.target.value)
    }

    const [valueFirstName, setValueFirstName] = useState("")
    const onChangeFirstName = (e: any) => {
        setValueFirstName(e.target.value)
    }

    const [valueFullName, setValueFullName] = useState("")
    const onChangeFullName = (e: any) => {
        setValueFullName(e.target.value)
    }

    const [valueLastName, setValueLastName] = useState("")
    const onChangeLastName = (e: any) => {
        setValueLastName(e.target.value)
    }

    const [valueLine, setValueLine] = useState("")
    const onChangeLine = (e: any) => {
        setValueLine(e.target.value)
    }

    const [valueMobile, setValueMobile] = useState("")
    const onChangeMobile = (e: any) => {
        setValueMobile(e.target.value)
    }

    const [valueNationality, setValueNationality] = useState("")
    const onChangeNationality = (e: any) => {
        setValueNationality(e.target.value)
    }

    const [valuePersonalSecurityCode, setValuePersonalSecurityCode] = useState("")
    const onChangePersonalSecurityCode = (e: any) => {
        setValuePersonalSecurityCode(e.target.value)
    }

    const [valuePhone, setValuePhone] = useState("")
    const onChangePhone = (e: any) => {
        setValuePhone(e.target.value)
    }

    const [valueReferral, setValueReferral] = useState("")
    const onChangeReferral = (e: any) => {
        setValueReferral(e.target.value)
    }

    const [valueGender, setValueGender] = useState("")
    const onChangeGender = (e: RadioChangeEvent) => {
        setValueGender(e.target.value)
    }

    //dataCreditInfromation
    const [valueTableLimit, setValueTableLimit] = useState("")
    const onChangeTableLimit = (value: string) => {
        setValueTableLimit(value)
    }
    //dataDepositBankInformation
    const [valueCompanyBankName, setValueCompanyBankName] = useState("")

    const [valuePlayerBankACName, setValuePlayerBankACName] = useState("")
    const onChangePlayerBankACName = (e: any) => {
        setValuePlayerBankACName(e.target.value)
    }

    const [valuePlayerBankACNumber, setValuePlayerBankACNumber] = useState("")
    const onChangePlayerBankACNumber = (e: any) => {
        setValuePlayerBankACNumber(e.target.value)
    }

    const [userDetail, setUserDetail] = useState<any>()
    const [loading, setLoading] = useState(false)

    const params = useParams()

    const getUserDetail = async () => {
        try {
            setLoading(true)
            const res = await httpService.get(`${apis.user.detailWithParam}?username=${params.id}`)
            setUserDetail(res?.data)
            setLoading(false)
        } catch (error: any) {
            setLoading(false)

            if (error.response?.status === 403) {
                alert("Bạn không có quyền xem thông tin")
            }
        }
    }

    useEffect(() => {
        getUserDetail()
    }, [])

    const contentStatusChildren = (
        <div>
            <p>Open: can login to the player site!</p>
            <p>Closed: can’t login to the player site!</p>
        </div>
    )

    const contentSuspendChildren = (
        <div>
            <p>Yes: can login, but can’t play games and can’t do transactions! </p>
            <p>No: can login, and can play games and can do transactions! </p>
            <br />
            <p>
                * *System will suspend the account automatically if the player enters his password
                wrongly for more than 5 times (both login and payment password)
            </p>
        </div>
    )
    const contentRegisterFPChildren = (
        <div>
            <p>
                FP (Fingerprint) is a technique to identify and track users based on unique
                characteristics of their web browsers. <br />
                It collects data on browser type, version, plugins, screen resolution, and more.
                This information creates a distinct fingerprint.
            </p>
        </div>
    )
    const contentWithdrawalChildren = (
        <div>
            <p>
                The player’s turnover must reach the withdrawal limit you set before the player can
                withdraw. If the player has multiple deposits, the withdrawal limit will accumulate.
            </p>
        </div>
    )

    const contentTagChildren = (
        <div>
            <p>Player tags can be edited in 21.2</p>
        </div>
    )

    const dataPlayerStatus = [
        {
            label: "Username",
            children: `${userDetail?.username}`,
            span: 2,
        },
        {
            label: (
                <div className='children-label'>
                    <div>Status</div>
                    <div>
                        <Popover content={contentStatusChildren}>
                            <Image
                                preview={false}
                                width={20}
                                src='/question_mark_icon.svg'
                                style={{ marginBottom: 4, paddingRight: 2 }}
                            />
                        </Popover>
                    </div>
                </div>
            ),
            children: (
                <div className='children-item'>
                    <div
                        // className={userDetail?.status === "open" ? "color-success" : "color-error"}
                        className={
                            userDetail?.status !== "blocked" ? "color-success" : "color-error"
                        }
                        style={{ textTransform: "capitalize" }}
                    >
                        {userDetail?.status}
                    </div>
                    {/* {clickEditStatus ? (
                        <div
                            className={valueStatus === "open" ? "color-success" : "color-error"}
                            style={{ textTransform: "capitalize" }}
                        >
                            {userDetail?.status}
                        </div>
                    ) : (
                        <Radio.Group
                            onChange={onChangeStatus}
                            value={valueStatus}
                            style={{ width: "100%" }}
                        >
                            <Radio value='open'>Open</Radio>
                            <Radio value='close'>Close</Radio>
                        </Radio.Group>
                    )} */}
                </div>
            ),
            span: 2,
        },
        {
            label: "Login Name",
            children: `${userDetail?.username}`,
            span: 2,
        },
        {
            label: (
                <div className='children-label'>
                    <div>Suspend</div>
                    <div>
                        <Popover content={contentSuspendChildren}>
                            <Image
                                preview={false}
                                width={20}
                                src='/question_mark_icon.svg'
                                style={{ marginBottom: 4, paddingRight: 2 }}
                            />
                        </Popover>
                    </div>
                </div>
            ),

            children: (
                <div className='children-item'>
                    {clickEditStatus ? (
                        <div
                            className={!userDetail?.suspend ? "color-success" : "color-error"}
                            style={{ textTransform: "capitalize" }}
                        >
                            {userDetail?.suspend ? "Suspended" : "No Suspend"}
                        </div>
                    ) : (
                        <Radio.Group
                            onChange={onChangeSuspend}
                            style={{ width: "100%" }}
                            defaultValue={userDetail?.suspend ? "suspend" : "noSuspend"}
                        >
                            <Radio value='suspend'>Suspend</Radio>
                            <Radio value='noSuspend'>No Suspend</Radio>
                        </Radio.Group>
                    )}
                </div>
            ),
            span: 2,
        },
        {
            label: "Currency",
            children: `${userDetail?.currency || ""}`,
            span: 2,
        },
        {
            label: "Lock",
            children: (
                <div className='children-item'>
                    {clickEditStatus ? (
                        <div
                            className={!userDetail?.lock ? "color-success" : "color-error"}
                            style={{ textTransform: "capitalize" }}
                        >
                            {userDetail?.lock ? "Lock" : "Unlock"}
                        </div>
                    ) : (
                        <Radio.Group
                            onChange={onChangeLock}
                            style={{ width: "100%" }}
                            defaultValue={userDetail?.lock ? "lock" : "unlock"}
                        >
                            <Radio value='lock'>Lock</Radio>
                            <Radio value='unlock'>Unlock</Radio>
                        </Radio.Group>
                    )}
                </div>
            ),
            span: 2,
        },
        {
            label: "Parent Name",
            children: `${userDetail?.username}`,
            span: 2,
        },
        {
            label: "Register IP",
            children: (
                <div className='children-item'>
                    <span>{userDetail?.registerIp}</span>
                    <Tag className='ant-tag--danger'>
                        Same IP:<span>1</span>
                    </Tag>
                </div>
            ),
            span: 2,
        },
        {
            label: "Register Time",
            children: `${userDetail?.createdAt || ""}`,
            span: 2,
        },
        {
            label: "Last Login IP",
            children: (
                <div className='children-item'>
                    <span>{userDetail?.lastLoginIp}</span>
                    <Tag className='ant-tag--danger'>
                        Same IP:<span>0</span>
                    </Tag>
                </div>
            ),
            span: 2,
        },
        {
            label: "Last Login Time",
            // children: "2024-01-31 12:10:34 AM (-4)",
            children: "",
            span: 2,
        },
        {
            label: (
                <div className='children-label'>
                    <div>Register FP</div>
                    <div>
                        <Popover content={contentRegisterFPChildren}>
                            <Image
                                preview={false}
                                width={20}
                                src='/question_mark_icon.svg'
                                style={{ marginBottom: 4, paddingRight: 2 }}
                            />
                        </Popover>
                    </div>
                </div>
            ),
            children: (
                <div className='children-item'>
                    <span>{userDetail?.registerFp}</span>
                    <Tag className='ant-tag--plain'>
                        Same IP:<span>0</span>
                    </Tag>
                </div>
            ),
            span: 2,
        },
        {
            label: "User Group",
            children: (
                <div className='children-item'>
                    {clickEditStatus ? (
                        <div>{valueUserGroup}</div>
                    ) : (
                        <Select
                            onChange={onChangeUserGroup}
                            value={valueUserGroup}
                            style={{ width: "100%" }}
                        >
                            <Option value='A'>A</Option>
                            <Option value='B'>B</Option>
                            <Option value='C'>C</Option>
                            <Option value='D'>D</Option>
                            <Option value='E'>E</Option>
                            <Option value='F'>F</Option>
                        </Select>
                    )}
                </div>
            ),
            span: 2,
        },
        {
            label: "Last Login FP",
            children: (
                <div className='children-item'>
                    <span>{userDetail?.lastLoginFp}</span>
                    <Tag className='ant-tag--danger'>
                        Same IP:<span>0</span>
                    </Tag>
                </div>
            ),
            span: 2,
        },
        {
            label: "Referral",
            children: "--",
            span: 2,
        },
        {
            label: "Referral Redeemable Amount",
            children: "--",
            span: 2,
        },
        {
            label: (
                <div className='children-label'>
                    <div>Tag</div>
                    <div>
                        <Popover content={contentTagChildren}>
                            <Image
                                preview={false}
                                width={20}
                                src='/question_mark_icon.svg'
                                style={{ marginBottom: 4, paddingRight: 2 }}
                            />
                        </Popover>
                    </div>
                </div>
            ),
            children: (
                <div className='children-item'>
                    {clickEditStatus ? (
                        <div className='flex'>
                            {/* {checkArbitrageTag && (
                                <Tag className='tag-arbitrage'>Arbitrage Player</Tag>
                            )}
                            {checkRiskyTag && <Tag className='tag-risky'>Risky Player</Tag>} */}
                            {userDetail?.tag && (
                                <Tag className='tag-arbitrage'>{userDetail?.tag}</Tag>
                            )}
                        </div>
                    ) : (
                        <div style={{ width: "100%" }}>
                            <Checkbox onChange={onChangeArbitrageTag} checked={checkArbitrageTag}>
                                Arbitrage Player
                            </Checkbox>
                            <Checkbox onChange={onChangeRiskyTag} checked={checkRiskyTag}>
                                Risky Player
                            </Checkbox>
                        </div>
                    )}
                </div>
            ),
            span: 2,
        },
    ]
    const dataAccountInformation = [
        {
            label: "DateOfBirth",
            children: `${
                userDetail?.dob ? new Date(userDetail?.dob).toISOString().split("T")[0] : "--"
            }`,
            span: 2,
        },
        {
            label: "Email",
            children: (
                <div className='children-item'>
                    {clickEditAccountInformation ? (
                        <div>{userDetail?.email}</div>
                    ) : (
                        <Input defaultValue={userDetail?.email} onChange={onChangeEmail} />
                    )}
                </div>
            ),
        },
        {
            label: "FirstName",
            children: (
                <div className='children-item'>
                    {clickEditAccountInformation ? (
                        <div>{userDetail?.firstName}</div>
                    ) : (
                        <Input onChange={onChangeFirstName} defaultValue={userDetail?.firstName} />
                    )}
                </div>
            ),
            span: 2,
        },
        {
            label: "FullName",
            children: (
                <div className='children-item'>
                    <div>{userDetail?.firstName + userDetail?.lastName || ""}</div>
                    {/* {clickEditAccountInformation ? (
                        <div>{userDetail?.firstName + userDetail?.lastName || ""}</div>
                    ) : (
                        <Input onChange={onChangeFullName} defaultValue={userDetail?.firstName} />
                    )} */}
                </div>
            ),
        },
        {
            label: "Gender",
            children: (
                <div className='children-item'>
                    {clickEditAccountInformation ? (
                        <div style={{ textTransform: "capitalize" }}>{valueGender}</div>
                    ) : (
                        <Radio.Group
                            onChange={onChangeGender}
                            defaultValue={userDetail?.gender}
                            style={{ width: "100%" }}
                        >
                            <Radio value='male'>Male</Radio>
                            <Radio value='female'>Female</Radio>
                        </Radio.Group>
                    )}
                </div>
            ),

            span: 2,
        },
        {
            label: "LastName",
            children: (
                <div className='children-item'>
                    {clickEditAccountInformation ? (
                        <div>{userDetail?.lastName}</div>
                    ) : (
                        <Input onChange={onChangeLastName} defaultValue={userDetail?.lastName} />
                    )}
                </div>
            ),
        },

        {
            label: "Line",
            children: (
                <div className='children-item'>
                    {clickEditAccountInformation ? (
                        <div>{userDetail?.line}</div>
                    ) : (
                        <Input onChange={onChangeLine} defaultValue={userDetail?.line} />
                    )}
                </div>
            ),
            span: 2,
        },
        {
            label: "Mobile",
            children: (
                <div className='children-item'>
                    {clickEditAccountInformation ? (
                        <div>{userDetail?.phone}</div>
                    ) : (
                        <Input onChange={onChangeMobile} defaultValue={userDetail?.phone} />
                    )}
                </div>
            ),
        },
        {
            label: "Nationality",
            children: (
                <div className='children-item'>
                    {clickEditAccountInformation ? (
                        <div>{userDetail?.nationality}</div>
                    ) : (
                        <Input
                            onChange={onChangeNationality}
                            defaultValue={userDetail?.nationality}
                        />
                    )}
                </div>
            ),
            span: 2,
        },
        {
            label: "PersonalSecurityCode",
            children: (
                <div className='children-item'>
                    {clickEditAccountInformation ? (
                        <div>{valuePersonalSecurityCode}</div>
                    ) : (
                        <Input
                            onChange={onChangePersonalSecurityCode}
                            value={valuePersonalSecurityCode}
                        />
                    )}
                </div>
            ),
        },

        {
            label: "Phone",
            children: (
                <div className='children-item'>
                    {clickEditAccountInformation ? (
                        <div>{userDetail?.phone}</div>
                    ) : (
                        <Input onChange={onChangePhone} defaultValue={userDetail?.phone} />
                    )}
                </div>
            ),
            span: 2,
        },
        {
            label: "Referral",
            children: (
                <div className='children-item'>
                    {clickEditAccountInformation ? (
                        <div>{userDetail?.referral}</div>
                    ) : (
                        <Input onChange={onChangeReferral} defaultValue={userDetail?.refferal} />
                    )}
                </div>
            ),
        },
    ]

    const dataCreditInfromation = [
        {
            label: "Table Limit",
            children: (
                <div className='children-item'>
                    {clickEditCreditInformation ? (
                        <div style={{ textTransform: "capitalize" }}>{userDetail?.tableLimit}</div>
                    ) : (
                        <Select
                            onChange={onChangeTableLimit}
                            value={valueTableLimit}
                            style={{ width: "100%" }}
                        >
                            <Option value='low'>Low</Option>
                            <Option value='medium'>Medium</Option>
                            <Option value='high'>High</Option>
                            <Option value='vip'>Vip</Option>
                        </Select>
                    )}
                </div>
            ),
        },
    ]
    const dataDepositBankInformation = [
        {
            label: "Company Bank Name",
            children: (
                <div className='children-item'>
                    {clickEditDeposit ? (
                        <div>{userDetail?.companyBankName}</div>
                    ) : (
                        <Select
                            value={valueCompanyBankName}
                            disabled
                            style={{ width: "100%" }}
                        ></Select>
                    )}
                </div>
            ),

            span: 2,
        },
        {
            label: "Player Bank A/C Name",
            children: (
                <div className='children-item'>
                    {clickEditDeposit ? (
                        <div>{userDetail?.playerBankACName}</div>
                    ) : (
                        <Input
                            onChange={onChangePlayerBankACName}
                            defaultValue={userDetail?.playerBankACName}
                        />
                    )}
                </div>
            ),
            span: 2,
        },
        {
            label: "Player Bank Name",
            children: (
                <div className='children-item'>
                    {clickEditDeposit ? (
                        <div></div>
                    ) : (
                        <Select placeholder='Select' style={{ width: "100%" }}></Select>
                    )}
                </div>
            ),

            span: 2,
        },
        {
            label: "Player Bank A/C Number",
            children: (
                <div className='children-item'>
                    {clickEditDeposit ? (
                        <div>{userDetail?.playerBankACNumber}</div>
                    ) : (
                        <Input
                            onChange={onChangePlayerBankACNumber}
                            defaultValue={userDetail?.playerBankACNumber}
                        />
                    )}
                </div>
            ),
            span: 2,
        },
    ]

    //manual
    const [manualTransferData, setManualTransferData] = useState({
        transferType: "",
        amount: 0,
        remark: "",
        userId: "",
    })
    const onSubmitManualTransfer = async () => {
        if (!manualTransferData.transferType || !manualTransferData.amount) return
        try {
            await httpService.post(apis.user.manualTransfer, manualTransferData)
            await getUserDetail()
            toast.success("Transfer successful")
            setShowManualTransfer(false)
        } catch (error) {
            toast.error("Transfer error")
            console.log("error", error)
        }
    }

    const onTransferTypeChange = (value: string) => {
        setManualTransferData({
            ...manualTransferData,
            transferType: value,
            userId: userDetail?.id,
        })
    }

    const onChangeRemark = (e: any) => {
        setManualTransferData({ ...manualTransferData, remark: e.target.value })
    }

    const handleClickCancelManualTransfer = () => {
        setManualTransferData({
            transferType: "",
            amount: 0,
            remark: "",
            userId: userDetail?.id,
        })
        setShowManualTransfer(false)
    }

    const handleClickManualTransfer = () => {
        setShowManualTransfer(true)
    }

    const onChangeAmount = (e: any) => {
        setManualTransferData({ ...manualTransferData, amount: e.target.value })
    }
    const onClickSave = async () => {
        setClickEditStatus(true)

        try {
            const payload = {
                lock: valueLock ? (valueLock === "lock" ? true : false) : undefined,
                suspend: valueSuspend ? (valueSuspend === "suspend" ? true : false) : undefined,
                firstName: valueFirstName ? valueFirstName : undefined,
                fullName: valueFullName ? valueFullName : undefined,
                gender: valueGender ? valueGender : undefined,
                email: valueEmail ? valueEmail : undefined,
                phone: valueMobile ? valueMobile : undefined,
                referral: valueReferral ? valueReferral : undefined,
                tableLimit: valueTableLimit ? valueTableLimit : undefined,
                companyBankName: valueCompanyBankName ? valueCompanyBankName : undefined,
                playerBankACName: valuePlayerBankACName ? valuePlayerBankACName : undefined,
                playerBankACNumber: valuePlayerBankACNumber ? valuePlayerBankACNumber : undefined,
                tag: tagValue ? tagValue : undefined,
            }
            await httpService.patch(apis.user.detail.replace(":id", userDetail.id), payload)
            await getUserDetail()
            toast.success("Update successful")
        } catch (error) {
            console.log("error", error)
            toast.error("Update error")
        }
    }

    const handleClickRefresh = () => {
        getUserDetail()
    }

    return (
        <>
            <div className='information'>
                <div className='information-profile'>
                    <div className='header'>
                        <div className='header__name'>Player Profile</div>
                        <div></div>
                    </div>
                    {loading && (
                        <div style={{ padding: 16, textAlign: "center" }}>
                            <p>Loading...</p>
                        </div>
                    )}
                    {!loading && userDetail ? (
                        <>
                            <div className='body'>
                                <div className='descriptions-title'>
                                    <div>Player Status</div>
                                    {clickEditStatus ? (
                                        <div>
                                            {/* <Button
                                                className='primary-btn'
                                                style={{ marginRight: 10 }}
                                                onClick={handleClickResetPass}
                                            >
                                                Reset Password
                                            </Button> */}
                                            {showPageResetPass && (
                                                <div
                                                    className='overlay'
                                                    onClick={handleClickResetPass}
                                                ></div>
                                            )}
                                            {showPageResetPass && (
                                                <div className='reset-password'>
                                                    <div className='reset-password__title'>
                                                        <span>Reset Password</span>
                                                        <Image
                                                            className='close-btn'
                                                            preview={false}
                                                            width={20}
                                                            src='/close_icon.svg'
                                                            onClick={handleClickResetPass}
                                                        />
                                                    </div>
                                                    <div>
                                                        Please leave field blank if no changes
                                                        require
                                                        <br /> Payment password is for making
                                                        payments for account safety, which consists
                                                        of 4 digits.
                                                    </div>
                                                    <div className='reset-password__main'>
                                                        <div className='reset-password__main-item'>
                                                            <span>Login Password</span>
                                                            <Input
                                                                type='password'
                                                                className='input-btn'
                                                            />
                                                        </div>
                                                        <div className='reset-password__main-item'>
                                                            <span>Payment Password</span>
                                                            <Input
                                                                type='password'
                                                                className='input-btn'
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className='reset-password__footer'>
                                                        <Button
                                                            className='information__btn cancel-btn'
                                                            style={{ marginRight: "10px" }}
                                                            onClick={handleClickResetPass}
                                                        >
                                                            Cancel
                                                        </Button>
                                                        <Button className='information__btn submit-btn'>
                                                            Submit
                                                        </Button>
                                                    </div>
                                                </div>
                                            )}
                                            <Button
                                                onClick={handleClickEditStatus}
                                                className='primary-btn'
                                            >
                                                Edit
                                            </Button>
                                        </div>
                                    ) : (
                                        <div>
                                            <Button
                                                onClick={handleClickEditStatus}
                                                style={{ marginRight: 10 }}
                                                className='cancel-btn'
                                            >
                                                Cancel
                                            </Button>
                                            {/* {contextHolder} */}
                                            <Button onClick={onClickSave} className='primary-btn'>
                                                Save
                                            </Button>
                                        </div>
                                    )}
                                </div>
                                <Descriptions bordered items={dataPlayerStatus} />
                                <div className='descriptions-title'>
                                    <div>Account Information</div>
                                    {clickEditAccountInformation ? (
                                        <div>
                                            <Button
                                                onClick={handleClickEditAccountInformation}
                                                className='primary-btn'
                                            >
                                                Edit
                                            </Button>
                                        </div>
                                    ) : (
                                        <div>
                                            <Button
                                                onClick={handleClickEditAccountInformation}
                                                style={{ marginRight: 10 }}
                                                className='cancel-btn'
                                            >
                                                Cancel
                                            </Button>
                                            <Button
                                                onClick={onClickSaveAccountInformation}
                                                className='primary-btn'
                                            >
                                                Save
                                            </Button>
                                        </div>
                                    )}
                                </div>
                                <Descriptions bordered items={dataAccountInformation} />
                                <div className='descriptions-title'>
                                    <div>Credit Infromation</div>
                                    {clickEditCreditInformation ? (
                                        <div>
                                            <Button
                                                onClick={handleClickEditCreditInformation}
                                                className='primary-btn'
                                            >
                                                Edit
                                            </Button>
                                        </div>
                                    ) : (
                                        <div>
                                            <Button
                                                onClick={handleClickEditCreditInformation}
                                                style={{ marginRight: 10 }}
                                                className='cancel-btn'
                                            >
                                                Cancel
                                            </Button>
                                            <Button onClick={onClickSave} className='primary-btn'>
                                                Save
                                            </Button>
                                        </div>
                                    )}
                                </div>
                                <Descriptions bordered items={dataCreditInfromation} />
                                <div className='descriptions-title'>
                                    <div>Deposit Bank Information</div>
                                    {clickEditDeposit ? (
                                        <div>
                                            <Button
                                                onClick={handleClickEditDeposit}
                                                className='primary-btn'
                                            >
                                                Edit
                                            </Button>
                                        </div>
                                    ) : (
                                        <div>
                                            <Button
                                                onClick={handleClickEditDeposit}
                                                style={{ marginRight: 10 }}
                                                className='cancel-btn'
                                            >
                                                Cancel
                                            </Button>
                                            <Button onClick={onClickSave} className='primary-btn'>
                                                Save
                                            </Button>
                                        </div>
                                    )}
                                </div>
                                <Descriptions bordered items={dataDepositBankInformation} />
                            </div>
                        </>
                    ) : (
                        <>
                            {!loading && (
                                <div className='flex-center' style={{ height: "300px" }}>
                                    <h1 style={{ fontSize: "30px", color: "#777" }}>
                                        404 | {t("user.notFound")}
                                    </h1>
                                </div>
                            )}
                        </>
                    )}
                </div>

                <div className='information-balance'>
                    <div className='header'>
                        <div className='header__name'>Balance</div>
                        <div className='header__botton'>
                            <Checkbox>Display full number of decimal places</Checkbox>
                            <Button className='refresh__btn' onClick={handleClickRefresh}>
                                <Image
                                    preview={false}
                                    width={20}
                                    src='/refresh_icon.svg'
                                    style={{ marginBottom: 4, paddingRight: 2 }}
                                />
                                <span>Refresh</span>
                            </Button>
                        </div>
                    </div>
                    <div className='body'>
                        <div className='information-balance__title'>
                            <div className='information-balance__title-name'>
                                Wallet Information
                            </div>
                            <div className='flex'>
                                <Button
                                    style={{ marginRight: 10 }}
                                    className='balance__btn'
                                    onClick={() => window.open("/transactions/balance-history")}
                                >
                                    <Image
                                        preview={false}
                                        src='/text_icon.svg'
                                        style={{ paddingRight: 2, height: "12px" }}
                                    />
                                    <span>Balance History</span>
                                </Button>
                                <Button
                                    style={{ marginRight: 10 }}
                                    className='balance__btn'
                                    onClick={() => window.open("/promotions/application-record")}
                                >
                                    <Image
                                        preview={false}
                                        src='/text_icon.svg'
                                        style={{ paddingRight: 2, height: "12px" }}
                                    />
                                    <span>Promotion</span>
                                </Button>
                                <Button className='primary-btn' onClick={handleClickManualTransfer}>
                                    <span>Manual Transfer</span>
                                </Button>
                                {showManualTransfer && (
                                    <div
                                        className='overlay1'
                                        onClick={handleClickManualTransfer}
                                    ></div>
                                )}
                                {showManualTransfer && (
                                    <div className='manual-transfer'>
                                        <div className='header'>
                                            <div className='header__name'>Manual Transfer</div>
                                            <div></div>
                                        </div>

                                        <div className='manual-transfer__main'>
                                            <div className='manual-transfer__main-item'>
                                                <span className='manual-transfer__main-item-name'>
                                                    User Name
                                                </span>
                                                <div>{userDetail?.username}</div>
                                            </div>
                                            <div className='manual-transfer__main-item'>
                                                <span className='manual-transfer__main-item-name'>
                                                    Main Wallet Balance
                                                </span>
                                                <div>{userDetail?.balance || 0}</div>
                                            </div>
                                            <div className='divider--horizontal'></div>
                                            <div className='manual-transfer__main-item'>
                                                <ul className='manual-transfer__main-item-note'>
                                                    <li className='manual-transfer__main-item-note1'>
                                                        Manual deposits and withdrawals do not
                                                        require approval and directly increase or
                                                        decrease the player's main wallet balance.
                                                    </li>
                                                    <li>
                                                        Manual transfer amount do not affect the
                                                        withdrawal limit amount.
                                                    </li>
                                                    <li>
                                                        Manual transfers can be entered up to six
                                                        decimal places.
                                                    </li>
                                                </ul>
                                            </div>
                                            <div className='manual-transfer__main-item'>
                                                <span className='manual-transfer__main-item-name'>
                                                    Transfer Type
                                                </span>
                                                <Select
                                                    placeholder='Select transfer type…'
                                                    style={{ width: 120 }}
                                                    className='transfer-type'
                                                    onChange={onTransferTypeChange}
                                                >
                                                    <Option value={TransactionType.ManualDeposit}>
                                                        Manual Deposit
                                                    </Option>
                                                    <Option value={TransactionType.ManualWithdraw}>
                                                        Manual Withdrawal
                                                    </Option>
                                                    <Option value={TransactionType.ManualBonus}>
                                                        Manual Bonus
                                                    </Option>
                                                    <Option value={TransactionType.ManualRebate}>
                                                        Manual Rebate
                                                    </Option>
                                                    <Option
                                                        value={
                                                            TransactionType.ManualBalanceAddition
                                                        }
                                                    >
                                                        Manual Balance Addition (not included in
                                                        reports)
                                                    </Option>
                                                    <Option
                                                        value={
                                                            TransactionType.ManualBalanceDeduction
                                                        }
                                                    >
                                                        Manual Balance Deduction (not included in
                                                        reports)
                                                    </Option>
                                                </Select>
                                            </div>
                                            <div className='manual-transfer__main-item'>
                                                <span className='manual-transfer__main-item-name flex'>
                                                    <div style={{ color: "red" }}>*</div>
                                                    Amount
                                                </span>
                                                <Input
                                                    className='input-btn'
                                                    type='number'
                                                    min={0}
                                                    onChange={onChangeAmount}
                                                />
                                            </div>

                                            <div className='manual-transfer__main-item'>
                                                <span className='manual-transfer__main-item-name'>
                                                    Remark
                                                </span>
                                                <TextArea
                                                    className='input-btn'
                                                    onChange={onChangeRemark}
                                                />
                                            </div>
                                        </div>
                                        <div className='divider--horizontal'></div>

                                        <div className='manual-transfer__footer'>
                                            <Button
                                                onClick={handleClickCancelManualTransfer}
                                                className='ant-btn close-btn'
                                                style={{ marginRight: "10px" }}
                                            >
                                                Cancel
                                            </Button>
                                            <Button
                                                className='primary-btn submit-btn'
                                                onClick={onSubmitManualTransfer}
                                            >
                                                Submit
                                            </Button>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className='wallet-card'>
                            <div className='wallet-card__title'>Main Wallet</div>
                            <div>
                                <div className='wallet-card__item'>
                                    <div>Cash Balance</div>
                                    <div>{userDetail?.balance}</div>
                                </div>
                                <div className='wallet-card__item'>
                                    <div>Outstanding</div>
                                    <div>0.00</div>
                                </div>
                                <div className='wallet-card__item'>
                                    <div>Total Balance</div>
                                    <div>{userDetail?.balance}</div>
                                </div>
                                <div className='wallet-card__item'>
                                    <div>
                                        Withdrawal Limit
                                        <Popover content={contentWithdrawalChildren}>
                                            <Image
                                                preview={false}
                                                width={16}
                                                src='/question_mark_icon.svg'
                                                style={{ marginLeft: 4 }}
                                            />
                                        </Popover>
                                    </div>
                                    <div>0.00</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default () => <Information />
