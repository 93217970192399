import { yupResolver } from "@hookform/resolvers/yup"
import { Button, Form, Modal, Tabs } from "antd"
import { isEmpty } from "lodash"
import { useEffect, useState } from "react"
import { useFieldArray, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import {
    ConditionalInputField,
    CurrencySelect,
    DateRangePickerField,
    Divider,
    DraggerImageField,
    EligibleGameProvidersSelect,
    FormHeader,
    InputField,
    LanguageDropdown,
    MessageAlert,
    RadioGroupField,
    RichtextEditorField,
    SelectField,
    StatusSelect,
    SwitchField,
} from "../../components"
import {
    AutoRebate,
    CalculateMethod,
    MaxApplicationCountResetPeriod,
    PromotionStatus,
    PromotionType,
    RebateInterval,
    currentDate,
    fromDate,
} from "../../constants"
import { generateEligibleGameProviders, generateRequestBody } from "../../helpers"
import { useOptions, usePromotion } from "../../hooks"
import { apiCreatePromotion } from "../../services"
import { LanguageOption } from "../../types"
import { rebateOnTurnoverSchema } from "../../validation"
import "../styles.scss"
import { LanguageLabel } from "./daily-deposit-bonus"

const TabPane = Tabs.TabPane

const RebateOnTurnover = ({
    isOpen,
    onChangeOpen,
    data,
}: {
    isOpen: boolean
    onChangeOpen: (state: boolean) => void
    data?: any
}) => {
    const [isResetFormEditMode, setIsResetFormEditMode] = useState(false)
    const [activeKey, setActiveKey] = useState("0")
    const [langOptions, setLangOptions] = useState<LanguageOption[]>([])
    const [maxApplicationCountResetPeriodDisabled, setMaxApplicationCountResetPeriodDisabled] =
        useState(true)
    const { t } = useTranslation()
    const { gameProviderList, languageList } = usePromotion()
    const {
        PromotionPlayerTagOptions,
        MaxApplicationCountResetPeriodOptions,
        CalculateMethodOptions,
        RebateIntervalOptions,
        RebateCalculationOptions,
        AutoRebateOptions,
    } = useOptions()
    const methods = useForm({
        mode: "all",
        defaultValues: {
            // Basic
            date: {
                startDate: fromDate.toString(),
                endDate: currentDate.toString(),
            },
            currency: "",
            isShowToGuest: true,
            status: PromotionStatus.ACTIVE,
            eligibleGameProviders: [],
            // Rebate
            rebateInterval: RebateInterval.DAILY,
            calculateMethod: CalculateMethod.CERTAIN_AMOUNT,
            bonusAmount: 0,
            rebatePercentage: 0,
            maxRebateAmountEachTime: -1,
            rebateCalculation: [],
            minimumTurnoverRequirement: undefined,
            withdrawalLimitRatio: true,
            // Process
            autoRebate: AutoRebate.AUTO_APPLY,
            allowMultiplePromotion: false,
            // Risk Control
            notAllowedPlayerTag: [],
            maxApplicationCountResetPeriod: "",
            maxApplicationCountByPlayer: -1,
            totalBudget: -1,
            maxApplicationCountForThisPromo: -1,
            turnOverRollOverTimes: 0,

            localizations: [
                {
                    code: "",
                    content: "",
                    image: "",
                    language: "en",
                    remark: "",
                    title: "",
                    tabTitle: t("common.default"),
                },
            ],
        },
        resolver: yupResolver(rebateOnTurnoverSchema),
    })
    const { control, watch, handleSubmit, setValue, getValues } = methods
    const [
        maxApplicationCountForThisPromo,
        totalBudget,
        calculateMethod,
        maxApplicationCountByPlayer,
        autoRebate,
    ] = watch([
        "maxApplicationCountForThisPromo",
        "totalBudget",
        "calculateMethod",
        "maxApplicationCountByPlayer",
        "autoRebate",
    ])
    const { fields, append, remove } = useFieldArray({
        control,
        name: "localizations",
    })
    const isEdit = !isEmpty(data)

    const handleOk = () => {
        onChangeOpen(false)
        // Reset form
    }

    const handleCancel = () => {
        onChangeOpen(false)
        resetForm()
    }

    const resetForm = () => {
        methods.reset({
            // Basic
            date: {
                startDate: fromDate.toString(),
                endDate: currentDate.toString(),
            },
            currency: "",
            isShowToGuest: true,
            status: PromotionStatus.ACTIVE,
            eligibleGameProviders: [],
            // Rebate
            rebateInterval: RebateInterval.DAILY,
            calculateMethod: CalculateMethod.CERTAIN_AMOUNT,
            bonusAmount: 0,
            rebatePercentage: 0,
            maxRebateAmountEachTime: -1,
            rebateCalculation: [],
            minimumTurnoverRequirement: undefined,
            withdrawalLimitRatio: true,
            // Process
            allowMultiplePromotion: false,
            // Risk Control
            notAllowedPlayerTag: [],
            maxApplicationCountResetPeriod: "",
            maxApplicationCountByPlayer: -1,
            totalBudget: -1,
            maxApplicationCountForThisPromo: -1,

            turnOverRollOverTimes: 0,
            localizations: [
                {
                    code: "",
                    content: "",
                    image: "",
                    language: "en",
                    remark: "",
                    title: "",
                    tabTitle: t("common.default"),
                },
            ],
        })
    }

    const onSubmit = async (data: any) => {
        const request = generateRequestBody(data, PromotionType.REBASE_ON_TURNOVER)
        // request.rebateCalculation = data.rebateCalculation.join(",")

        try {
            await apiCreatePromotion(request)
            onChangeOpen(false)
            resetForm()
        } catch (error) {
            // Handle error
            console.log("error ", error)
        } finally {
            // Reset form
        }
    }

    const onEdit = (
        targetKey: React.MouseEvent | React.KeyboardEvent | string,
        action: "add" | "remove"
    ) => {
        if (action === "add") {
            append({
                code: "",
                content: "",
                image: "",
                language: "en",
                remark: "",
                title: "",
                tabTitle: "Langua",
            })
        } else {
            const index = fields.findIndex((field) => field.id === targetKey)
            const langKey = getValues(`localizations.${index}.language`)
            const idx = langOptions.findIndex((lang) => lang.key === langKey)
            if (idx !== -1) {
                const newLangOptions = [...langOptions]
                newLangOptions[idx].disabled = false

                setLangOptions(newLangOptions)
            }
            remove(index)
        }
    }

    const onChangeActiveKey = (key: string) => {
        setActiveKey(key)
    }

    useEffect(() => {
        if (
            totalBudget === -1 ||
            maxApplicationCountForThisPromo === -1 ||
            maxApplicationCountByPlayer === -1
        ) {
            setMaxApplicationCountResetPeriodDisabled(true)
            setValue("maxApplicationCountResetPeriod", MaxApplicationCountResetPeriod.NO_RESET)
        } else {
            setMaxApplicationCountResetPeriodDisabled(false)
        }
    }, [totalBudget, maxApplicationCountForThisPromo, maxApplicationCountByPlayer, setValue])

    useEffect(() => {
        if (fields) {
            const lastIndex = fields.length - 1

            setActiveKey(fields[lastIndex].id)
        }
    }, [fields])

    useEffect(() => {
        if (!isEmpty(data) && !isResetFormEditMode) {
            methods.reset({
                ...data,
                date: {
                    startDate: data.startDate,
                    endDate: data.endDate,
                },
                localizations: data.localizations.map((localization: any) => {
                    const lang = langOptions.find(
                        (lang) => lang.key === localization.language
                    )?.label
                    return {
                        ...localization,
                        tabTitle: lang === "en" ? t("common.default") : lang,
                    }
                }),
                applicationLimitation: data.applicationLimitation?.split(",") ?? [],
                notAllowedPlayerTag: data.notAllowedPlayerTag?.split(",") ?? [],
                eligibleGameProviders: generateEligibleGameProviders(
                    data.eligibleGameProviders ?? [],
                    gameProviderList
                ),
            })
            setIsResetFormEditMode(true)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, methods, langOptions])

    useEffect(() => {
        if (languageList.length > 0) {
            setLangOptions(
                languageList.map((lang: any) => ({
                    label: lang.language,
                    key: lang.iso,
                    disabled: lang.iso === "en",
                }))
            )
        }
    }, [languageList])

    return (
        <Modal
            title={t("content.promotions.create.type.REBASE_ON_TURNOVER.title")}
            open={isOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            centered
            className='modal-promotion-creation'
            footer={null}
        >
            <Form
                name='basic'
                labelCol={{ span: 8 }}
                wrapperCol={{ span: 16 }}
                onFinish={handleSubmit(onSubmit)}
                onFinishFailed={(error) => {
                    console.log("error gi day ,  ", error)
                }}
            >
                <LanguageDropdown
                    items={langOptions.map((lang) => ({
                        ...lang,
                        onClick: () => {
                            setLangOptions((prev) =>
                                prev.map((prevLang) => ({
                                    ...prevLang,
                                    disabled:
                                        prevLang.key === lang.key
                                            ? !prevLang.disabled
                                            : prevLang.disabled,
                                }))
                            )
                            append({
                                code: "",
                                content: "",
                                image: "",
                                language: lang.key,
                                remark: "",
                                title: "",
                                tabTitle: lang.label,
                            })
                        },
                    }))}
                />
                <Tabs
                    type='editable-card'
                    activeKey={activeKey}
                    animated={false}
                    onEdit={onEdit}
                    hideAdd
                    onChange={onChangeActiveKey}
                    tabBarExtraContent={{
                        left: <LanguageLabel />,
                    }}
                >
                    {fields.map((field, index) => {
                        const tabTitle = getValues(`localizations.${index}.tabTitle`)
                        const isDisabled = index !== 0 || isEdit

                        return (
                            <TabPane
                                key={field.id}
                                tabKey={index.toString()}
                                closable={index !== 0}
                                tab={<h4>{tabTitle}</h4>}
                            >
                                <div className='form-promotion__container'>
                                    <div className='general-settings'>
                                        <FormHeader
                                            title={t(
                                                "content.promotions.create.form.field.generalSettings"
                                            )}
                                        />
                                        {/* PROMOTION SETTING SECTION */}
                                        <section id='promotion__setting__section'>
                                            <InputField
                                                name={`localizations.${index}.title`}
                                                control={control}
                                                formItemProps={{
                                                    label: t(
                                                        "content.promotions.create.form.field.promotionTitle"
                                                    ),

                                                    required: true,
                                                }}
                                                inputProps={{
                                                    placeholder: t(
                                                        "content.promotions.create.form.placeholder.promotionTitle"
                                                    ),
                                                }}
                                            />
                                            <InputField
                                                name={`localizations.${index}.code`}
                                                control={control}
                                                formItemProps={{
                                                    label: t(
                                                        "content.promotions.create.form.field.promotionCode"
                                                    ),

                                                    required: true,
                                                }}
                                                inputProps={{
                                                    placeholder: t(
                                                        "content.promotions.create.form.placeholder.promotionCode"
                                                    ),
                                                }}
                                            />
                                            <InputField
                                                name={`localizations.${index}.remark`}
                                                control={control}
                                                formItemProps={{
                                                    label: t(
                                                        "content.promotions.create.form.field.remark"
                                                    ),
                                                }}
                                                inputProps={{
                                                    placeholder: t(
                                                        "content.promotions.create.form.placeholder.remark"
                                                    ),
                                                }}
                                            />
                                        </section>
                                        <Divider dimension='horizontal' className='my-4' />
                                        {/* UPDATE IMAGE SECTION */}
                                        <section id='upload__image__section'>
                                            <DraggerImageField
                                                name={`localizations.${index}.image`}
                                                control={control}
                                                formItemProps={{
                                                    labelCol: {
                                                        span: 24,
                                                    },
                                                    wrapperCol: {
                                                        span: 24,
                                                    },
                                                    required: true,
                                                }}
                                            />
                                        </section>
                                        <Divider dimension='horizontal' className='my-4' />
                                        {/* PROMOTION CONTENT SECTION */}
                                        <section id='promotion__content__section'>
                                            <RichtextEditorField
                                                name={`localizations.${index}.content`}
                                                control={control}
                                                formItemProps={{
                                                    label: t(
                                                        "content.promotions.create.form.field.promotionContent"
                                                    ),
                                                    labelCol: {
                                                        span: 24,
                                                    },
                                                    wrapperCol: {
                                                        span: 24,
                                                    },
                                                    required: true,
                                                }}
                                            />
                                        </section>
                                    </div>
                                    <Divider dimension='horizontal' />
                                    <div className='promotion-settings'>
                                        <FormHeader
                                            title={t(
                                                "content.promotions.create.form.field.promotionSetting"
                                            )}
                                        />
                                        {/* BASIC PROMOTION SECTION */}
                                        <section id='basic-promotion-section'>
                                            {/* {t('content.promotions.create.form.field.basicSettings')} */}
                                            <div className='heading-promotion-setting '>
                                                {t(
                                                    "content.promotions.create.form.field.basicSettings"
                                                )}
                                            </div>
                                            <DateRangePickerField
                                                name='date'
                                                control={control}
                                                formItemProps={{
                                                    label: t(
                                                        "content.promotions.create.form.field.promotionDuration"
                                                    ),
                                                    tooltip: t(
                                                        "content.promotions.create.form.tooltip.promotionDuration"
                                                    ),
                                                    labelCol: {
                                                        span: 24,
                                                    },
                                                    wrapperCol: {
                                                        span: 24,
                                                    },
                                                    required: true,
                                                }}
                                                dateRangePickerProps={{
                                                    disabled: isDisabled,
                                                }}
                                            />
                                            <CurrencySelect
                                                control={control}
                                                isDisabled={isDisabled}
                                            />
                                            <InputField
                                                name='displayOrder'
                                                control={control}
                                                formItemProps={{
                                                    label: t(
                                                        "content.promotions.create.form.field.displayOrder"
                                                    ),
                                                    tooltip: t(
                                                        "content.promotions.create.form.tooltip.displayOrder"
                                                    ),
                                                    required: true,
                                                }}
                                                inputProps={{
                                                    disabled: isDisabled,
                                                }}
                                            />
                                            <SwitchField
                                                name='isShowToGuest'
                                                control={control}
                                                formItemProps={{
                                                    label: t(
                                                        "content.promotions.create.form.field.isShowToGuest"
                                                    ),
                                                    tooltip: t(
                                                        "content.promotions.create.form.tooltip.isShowToGuest"
                                                    ),
                                                }}
                                                switchProps={{
                                                    disabled: index !== 0,
                                                    defaultChecked: true,
                                                }}
                                            />
                                            <StatusSelect
                                                control={control}
                                                isDisabled={isDisabled}
                                            />
                                            <EligibleGameProvidersSelect
                                                control={control}
                                                isDisabled={isDisabled}
                                            />
                                            {/* {t("content.promotions.create.form.field.rebateSetting")} */}
                                            <div className='heading-promotion-setting '>
                                                {t(
                                                    "content.promotions.create.form.field.rebateSetting"
                                                )}
                                            </div>
                                            <MessageAlert className='mb-3'>
                                                {t(
                                                    "content.promotions.create.form.field.baseOnGMT_4"
                                                )}
                                            </MessageAlert>
                                            <SelectField
                                                name='rebateInterval'
                                                control={control}
                                                formItemProps={{
                                                    label: t(
                                                        "content.promotions.create.form.field.rebateInterval"
                                                    ),
                                                }}
                                                selectProps={{
                                                    options: RebateIntervalOptions,
                                                    disabled: isDisabled,
                                                }}
                                            />
                                            <RadioGroupField
                                                name='calculateMethod'
                                                control={control}
                                                formItemProps={{
                                                    label: t(
                                                        "content.promotions.create.form.field.calculateMethod"
                                                    ),
                                                    required: true,
                                                }}
                                                radioProps={{
                                                    options: CalculateMethodOptions,
                                                    defaultValue: CalculateMethod.CERTAIN_AMOUNT,
                                                    disabled: isDisabled,
                                                }}
                                            />

                                            {calculateMethod === CalculateMethod.CERTAIN_AMOUNT ? (
                                                <>
                                                    <InputField
                                                        name='bonusAmount'
                                                        control={control}
                                                        formItemProps={{
                                                            label: t(
                                                                "content.promotions.create.form.field.bonusAmount"
                                                            ),
                                                            labelAlign: "left",
                                                            required: true,
                                                        }}
                                                        inputProps={{
                                                            type: "number",
                                                            disabled: isDisabled,
                                                        }}
                                                    />
                                                </>
                                            ) : (
                                                <>
                                                    <InputField
                                                        name='rebatePercentage'
                                                        control={control}
                                                        formItemProps={{
                                                            label: t(
                                                                "content.promotions.create.form.field.rebatePercentage"
                                                            ),
                                                            labelAlign: "left",
                                                            required: true,
                                                        }}
                                                        inputProps={{
                                                            type: "number",
                                                            disabled: isDisabled,
                                                            addonAfter: "%",
                                                        }}
                                                    />
                                                    <ConditionalInputField
                                                        name='maxRebateAmountEachTime'
                                                        control={control}
                                                        formItemProps={{
                                                            label: t(
                                                                "content.promotions.create.form.field.maxRebateAmountEachTime"
                                                            ),
                                                            tooltip: t(
                                                                "content.promotions.create.form.tooltip.maxRebateAmountEachTime"
                                                            ),
                                                        }}
                                                        checkboxProps={{
                                                            defaultChecked: true,
                                                            disabled: isDisabled,
                                                        }}
                                                        inputProps={{
                                                            type: "number",
                                                            disabled: isDisabled,
                                                            placeholder: t(
                                                                "content.promotions.create.form.placeholder.maxBonusAmountEachTime"
                                                            ),
                                                        }}
                                                    />
                                                </>
                                            )}
                                            {/* <CheckboxGroupField
                                                name='rebateCalculation'
                                                control={control}
                                                formItemProps={{
                                                    label: t(
                                                        "content.promotions.create.form.field.rebateCalculation"
                                                    ),
                                                    tooltip: t(
                                                        "content.promotions.create.form.tooltip.rebateCalculation"
                                                    ),
                                                    required: true,
                                                }}
                                                checkboxProps={{
                                                    options: RebateCalculationOptions,
                                                    disabled: isDisabled,
                                                }}
                                            /> */}
                                            <InputField
                                                name='minimumTurnoverRequirement'
                                                control={control}
                                                formItemProps={{
                                                    label: t(
                                                        "content.promotions.create.form.field.minimumTurnoverRequirement"
                                                    ),
                                                    labelAlign: "left",
                                                    tooltip: t(
                                                        "content.promotions.create.form.tooltip.minimumTurnoverRequirement"
                                                    ),
                                                    required: true,
                                                }}
                                                inputProps={{
                                                    type: "number",
                                                    disabled: isDisabled,
                                                }}
                                            />
                                            <InputField
                                                name='turnOverRollOverTimes'
                                                control={control}
                                                formItemProps={{
                                                    label: t(
                                                        "content.promotions.create.form.field.turnoverRolloverTimes"
                                                    ),
                                                    required: true,
                                                    tooltip: t(
                                                        "content.promotions.create.form.tooltip.turnoverRolloverTimes"
                                                    ),
                                                }}
                                                inputProps={{
                                                    disabled: isDisabled,
                                                }}
                                            />
                                            {/* <SwitchField
                                                name='withdrawalLimitRatio'
                                                control={control}
                                                formItemProps={{
                                                    label: t(
                                                        "content.promotions.create.form.field.withdrawalLimitRatio"
                                                    ),
                                                    tooltip: t(
                                                        "content.promotions.create.form.tooltip.withdrawalLimitRatio"
                                                    ),
                                                }}
                                                switchProps={{
                                                    disabled: isDisabled,
                                                    defaultChecked: true,
                                                }}
                                                withYesNo={false}
                                            >
                                                {t("content.promotions.create.form.field.ratio")} :
                                                0
                                            </SwitchField> */}
                                            <Divider dimension='horizontal' />
                                            {/* Process Setting */}
                                            <div className='heading-promotion-setting '>
                                                3.{" "}
                                                {t(
                                                    "content.promotions.create.form.field.processSettings"
                                                )}
                                            </div>
                                            <RadioGroupField
                                                name='autoRebate'
                                                control={control}
                                                formItemProps={{
                                                    label: t(
                                                        "content.promotions.create.form.field.autoRebate"
                                                    ),
                                                    required: true,
                                                }}
                                                radioProps={{
                                                    options: AutoRebateOptions,
                                                    defaultValue: AutoRebate.AUTO_APPLY,
                                                    disabled: isDisabled,
                                                    onChange: (event) => {
                                                        if (
                                                            event.target.value ===
                                                            AutoRebate.AUTO_APPLY
                                                        ) {
                                                            setValue(
                                                                "allowMultiplePromotion",
                                                                false
                                                            )
                                                        }
                                                    },
                                                }}
                                            />
                                            {autoRebate === AutoRebate.PLAYER_MANUALLY_APPLY && (
                                                <SwitchField
                                                    name='allowMultiplePromotion'
                                                    control={control}
                                                    formItemProps={{
                                                        label: t(
                                                            "content.promotions.create.form.field.needPlayerApply"
                                                        ),
                                                        tooltip: t(
                                                            "content.promotions.create.form.tooltip.needPlayerApply"
                                                        ),
                                                    }}
                                                    switchProps={{
                                                        disabled: isDisabled,
                                                    }}
                                                />
                                            )}
                                            <Divider dimension='horizontal' />
                                            {/* Risk Control Setting */}
                                            <div className='heading-promotion-setting '>
                                                {t(
                                                    "content.promotions.create.form.field.riskControlSettings"
                                                )}
                                            </div>
                                            <SelectField
                                                control={control}
                                                name='notAllowedPlayerTag'
                                                formItemProps={{
                                                    label: t(
                                                        "content.promotions.create.form.field.notAllowedPlayerTag"
                                                    ),
                                                    tooltip: t(
                                                        "content.promotions.create.form.tooltip.notAllowedPlayerTag"
                                                    ),
                                                }}
                                                selectProps={{
                                                    options: PromotionPlayerTagOptions,
                                                    placeholder: t(
                                                        "content.promotions.create.form.placeholder.notAllowedPlayerTag"
                                                    ),
                                                    mode: "multiple",
                                                    disabled: isDisabled,
                                                }}
                                            />
                                            {/* <SelectField
                                                name='maxApplicationCountResetPeriod'
                                                control={control}
                                                formItemProps={{
                                                    label: t(
                                                        "content.promotions.create.form.field.maxApplicationCountResetPeriod"
                                                    ),
                                                    tooltip: t(
                                                        "content.promotions.create.form.tooltip.maxApplicationCountResetPeriod"
                                                    ),
                                                }}
                                                selectProps={{
                                                    options: MaxApplicationCountResetPeriodOptions,
                                                    defaultValue:
                                                        MaxApplicationCountResetPeriod.NO_RESET,
                                                    disabled:
                                                        maxApplicationCountResetPeriodDisabled ||
                                                        isDisabled,
                                                }}
                                            /> */}
                                            {/* <ConditionalInputField
                                                name='maxApplicationCountByPlayer'
                                                control={control}
                                                inputProps={{
                                                    type: "number",
                                                    placeholder: t(
                                                        "content.promotions.create.form.placeholder.maxApplicationCountForThisPromotion"
                                                    ),
                                                    min: 0,
                                                    disabled: isDisabled,
                                                }}
                                                formItemProps={{
                                                    label: t(
                                                        "content.promotions.create.form.field.maxApplicationCountByPlayer"
                                                    ),
                                                    tooltip: t(
                                                        "content.promotions.create.form.tooltip.maxApplicationCountByPlayer"
                                                    ),
                                                }}
                                                checkboxProps={{
                                                    defaultChecked: true,
                                                    disabled: isDisabled,
                                                }}
                                            /> */}
                                            <ConditionalInputField
                                                name='totalBudget'
                                                control={control}
                                                inputProps={{
                                                    type: "number",
                                                    placeholder: t(
                                                        "content.promotions.create.form.placeholder.totalBudgetForThisPromotion"
                                                    ),
                                                    min: 0,
                                                    disabled: isDisabled,
                                                }}
                                                formItemProps={{
                                                    label: t(
                                                        "content.promotions.create.form.field.totalBudgetForThisPromotion"
                                                    ),
                                                    tooltip: t(
                                                        "content.promotions.create.form.tooltip.totalBudgetForThisPromotion"
                                                    ),
                                                }}
                                                checkboxProps={{
                                                    defaultChecked: true,
                                                    disabled: isDisabled,
                                                }}
                                            />
                                            <ConditionalInputField
                                                name='maxApplicationCountForThisPromo'
                                                control={control}
                                                inputProps={{
                                                    type: "number",
                                                    placeholder: t(
                                                        "content.promotions.create.form.placeholder.maxApplicationCountForThisPromotion"
                                                    ),
                                                    min: 0,
                                                    disabled: isDisabled,
                                                }}
                                                formItemProps={{
                                                    label: t(
                                                        "content.promotions.create.form.field.maxApplicationCountForThisPromotion"
                                                    ),
                                                    tooltip: t(
                                                        "content.promotions.create.form.tooltip.maxApplicationCountForThisPromotion"
                                                    ),
                                                }}
                                                checkboxProps={{
                                                    defaultChecked: true,
                                                    disabled: isDisabled,
                                                }}
                                            />
                                            <Divider dimension='horizontal' />
                                        </section>
                                    </div>
                                    <div className='cta'>
                                        <Button onClick={handleCancel}>{t("common.cancel")}</Button>
                                        <Button
                                            type='primary'
                                            htmlType='submit'
                                            onClick={(event) => {
                                                event.preventDefault()
                                                methods.handleSubmit(onSubmit)()
                                            }}
                                        >
                                            {t("common.submit")}
                                        </Button>
                                    </div>
                                </div>
                            </TabPane>
                        )
                    })}
                </Tabs>
            </Form>
        </Modal>
    )
}

export default RebateOnTurnover
