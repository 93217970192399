import * as yup from "yup"
import { CalculateMethod } from "./constants"

/**  Register Bonus */
export const registerBonusSchema = yup.object().shape({
    // Basic
    date: yup
        .object()
        .shape({
            startDate: yup.string(),
            endDate: yup.string(),
        })
        .required("Required"),
    currency: yup.string().required("Required"),
    displayOrder: yup.number().typeError("Required"),
    isShowToGuest: yup.boolean(),
    status: yup.string().required("Required"),
    eligibleGameProviders: yup.array().required("Required"),
    // Bonus
    bonusAmount: yup.number().typeError("Required"),
    turnOverRollOverTimes: yup.number().required().typeError("Required"),
    // Process
    allowMultiplePromotion: yup.boolean(),
    needPlayerApply: yup.boolean(),
    applicationDeadline: yup.number(),
    walletAutoComplete: yup.boolean().required("Required"),
    autoCompleteAmount: yup.number().when("walletAutoComplete", {
        is: true,
        then: (schema) => schema.typeError("Required"),
        otherwise: (schema) => schema.optional(),
    }),
    maxWithdrawAmount: yup.number().required("Required").typeError("Must be positive value"),
    // Risk Control
    applicationLimitation: yup.array().of(yup.string()),
    notAllowedPlayerTag: yup.array().of(yup.string()),
    arbitragePlayerChecking: yup.boolean(),
    maxApplicationCountResetPeriod: yup.string(),
    totalBudget: yup.number().typeError("Must be positive value"),
    maxApplicationCountForThisPromo: yup.number().typeError("Must be positive value"),
    localizations: yup.array().of(
        yup.object().shape({
            title: yup.string().required("Required"),
            code: yup.string().required("Required"),
            image: yup.string().required("Required"),
            remark: yup.string(),
            content: yup.string().required("Required"),
            language: yup.string().required("Required"),
            tabTitle: yup.string(),
        })
    ),
})
/**  First Deposit Bonus */
export const firstDepositBonusSchema = yup.object().shape({
    // Basic
    date: yup
        .object()
        .shape({
            startDate: yup.string(),
            endDate: yup.string(),
        })
        .required("Required"),
    currency: yup.string().required("Required"),
    displayOrder: yup.number().typeError("Required"),
    isShowToGuest: yup.boolean().nullable(),
    status: yup.string().required("Required"),
    includeManualDeposit: yup.boolean().nullable(),
    // Bonus
    turnOverRollOverTimes: yup.number().required().typeError("Required"),
    rolloverFormula: yup.string().nullable(),
    eligibleGameProviders: yup.array().required("Required"),
    calculateMethod: yup.string().required("Required"),
    bonusAmount: yup.number().when("calculateMethod", {
        is: CalculateMethod.CERTAIN_AMOUNT,
        then: (schema) => schema.typeError("Required"),
        otherwise: (schema) => schema.nullable().optional(),
    }),
    minimumDepositRequirement: yup.number().when("calculateMethod", {
        is: CalculateMethod.CERTAIN_AMOUNT,
        then: (schema) => schema.typeError("Required"),
        otherwise: (schema) => schema.nullable().optional(),
    }),
    bonusPercentage: yup.number().when("calculateMethod", {
        is: CalculateMethod.PERCENTAGE,
        then: (schema) => schema.typeError("Required"),
        otherwise: (schema) => schema.nullable().optional(),
    }),
    maxBonusAmountEachTime: yup.number().when("calculateMethod", {
        is: CalculateMethod.PERCENTAGE,
        then: (schema) => schema.required("Required").typeError("Must be positive value"),
        otherwise: (schema) => schema.nullable().optional(),
    }),
    minimumDepositAmount: yup.number().nullable().typeError("Required"),
    // Process
    needPlayerApply: yup.boolean().nullable(),
    applicationDeadline: yup.number().nullable(),
    allowMultiplePromotion: yup.boolean().nullable(),
    walletAutoComplete: yup.boolean().required("Required"),
    autoCompleteAmount: yup
        .number()
        .nullable()
        .when("walletAutoComplete", {
            is: true,
            then: (schema) => schema.typeError("Required"),
            otherwise: (schema) => schema.optional(),
        }),
    maxWithdrawAmount: yup.number().required("Required").typeError("Must be positive value"),
    // Risk Control
    applicationLimitation: yup.array().of(yup.string()).nullable(),
    notAllowedPlayerTag: yup.array().of(yup.string()).nullable(), // yup.string(),
    arbitragePlayerChecking: yup.boolean().nullable(),
    maxApplicationCountResetPeriod: yup.string().nullable(),
    totalBudget: yup.number().typeError("Must be positive value").nullable(),
    maxApplicationCountForThisPromo: yup.number().nullable().typeError("Must be positive value"),

    localizations: yup.array().of(
        yup.object().shape({
            title: yup.string().required("Required"),
            code: yup.string().required("Required"),
            image: yup.string().required("Required"),
            remark: yup.string(),
            content: yup.string().required("Required"),
            language: yup.string().required("Required"),
            tabTitle: yup.string(),
        })
    ),
})

/**  Daily Deposit Bonus */
export const dailyDepositBonusSchema = yup.object().shape({
    // Basic
    date: yup
        .object()
        .shape({
            startDate: yup.string(),
            endDate: yup.string(),
        })
        .required("Required"),
    currency: yup.string().required("Required"),
    displayOrder: yup.number().typeError("Required"),
    isShowToGuest: yup.boolean(),
    status: yup.string().required("Required"),
    includeManualDeposit: yup.boolean(),
    // Bonus
    turnOverRollOverTimes: yup.number().required().typeError("Required"),
    rolloverFormula: yup.string(),
    eligibleGameProviders: yup.array().required("Required"),
    calculationCriteria: yup.string().required("Required"),
    calculateMethod: yup.string().required("Required"),
    bonusAmount: yup.number().typeError("Required"),
    minimumDepositRequirement: yup.number().typeError("Required"),
    bonusPercentage: yup.number().typeError("Required"),
    maxBonusAmountEachTime: yup.number().required("Required").typeError("Must be positive value"),
    minimumDepositAmount: yup.number().typeError("Required"),
    // Process
    needPlayerApply: yup.boolean(),
    applicationDeadline: yup.number(),
    allowMultiplePromotion: yup.boolean(),
    walletAutoComplete: yup.boolean().required("Required"),
    autoCompleteAmount: yup.number().when("walletAutoComplete", {
        is: true,
        then: (schema) => schema.typeError("Required"),
        otherwise: (schema) => schema.optional(),
    }),
    maxWithdrawAmount: yup.number().required("Required").typeError("Must be positive value"),
    eligibleWeekdayToApply: yup.array().of(yup.string()).min(1, "Required"),
    // Risk Control
    applicationLimitation: yup.array().of(yup.string()), //yup.string(),
    notAllowedPlayerTag: yup.array().of(yup.string()), // yup.string(),
    arbitragePlayerChecking: yup.boolean(),
    maxApplicationCountResetPeriod: yup.string(),
    totalBudget: yup.number().typeError("Must be positive value"),
    maxApplicationCountForThisPromo: yup.number().typeError("Must be positive value"),
    maxApplicationCountByPlayer: yup.number().typeError("Must be positive value"),
    localizations: yup.array().of(
        yup.object().shape({
            title: yup.string().required("Required"),
            code: yup.string().required("Required"),
            image: yup.string().required("Required"),
            remark: yup.string(),
            content: yup.string().required("Required"),
            language: yup.string().required("Required"),
            tabTitle: yup.string(),
        })
    ),
})

/**  Deposit Bonus */
export const depositBonusSchema = yup.object().shape({
    // Basic
    date: yup
        .object()
        .shape({
            startDate: yup.string(),
            endDate: yup.string(),
        })
        .required("Required"),
    currency: yup.string().required("Required"),
    displayOrder: yup.number().typeError("Required"),
    isShowToGuest: yup.boolean(),
    status: yup.string().required("Required"),
    includeManualDeposit: yup.boolean(),
    // Bonus
    turnOverRollOverTimes: yup.number().required().typeError("Required"),
    rolloverFormula: yup.string(),
    eligibleGameProviders: yup.array().required("Required"),
    calculateMethod: yup.string().required("Required"),
    bonusAmount: yup.number().typeError("Required"),
    minimumDepositRequirement: yup.number().typeError("Required"),
    bonusPercentage: yup.number().typeError("Required"),
    maxBonusAmountEachTime: yup.number().required("Required").typeError("Must be positive value"),
    minimumDepositAmount: yup.number().typeError("Required"),
    // Process
    needPlayerApply: yup.boolean(),
    applicationDeadline: yup.number(),
    allowMultiplePromotion: yup.boolean(),
    walletAutoComplete: yup.boolean().required("Required"),
    autoCompleteAmount: yup.number().when("walletAutoComplete", {
        is: true,
        then: (schema) => schema.typeError("Required"),
        otherwise: (schema) => schema.optional(),
    }),
    maxWithdrawAmount: yup.number().required("Required").typeError("Must be positive value"),
    eligibleWeekdayToApply: yup.array().of(yup.string()).min(1, "Required"),
    // Risk Control
    applicationLimitation: yup.array().of(yup.string()), //yup.string(),
    notAllowedPlayerTag: yup.array().of(yup.string()), // yup.string(),
    arbitragePlayerChecking: yup.boolean(),
    maxApplicationCountResetPeriod: yup.string(),
    totalBudget: yup.number().typeError("Must be positive value"),
    maxApplicationCountForThisPromo: yup.number().typeError("Must be positive value"),
    maxApplicationCountByPlayer: yup.number().typeError("Must be positive value"),
    localizations: yup.array().of(
        yup.object().shape({
            title: yup.string().required("Required"),
            code: yup.string().required("Required"),
            image: yup.string().required("Required"),
            remark: yup.string(),
            content: yup.string().required("Required"),
            language: yup.string().required("Required"),
            tabTitle: yup.string(),
        })
    ),
})

/**  Rebate On Turnover */
export const rebateOnTurnoverSchema = yup.object().shape({
    // Basic
    date: yup
        .object()
        .shape({
            startDate: yup.string(),
            endDate: yup.string(),
        })
        .required("Required"),
    currency: yup.string().required("Required"),
    displayOrder: yup.number().typeError("Required"),
    isShowToGuest: yup.boolean(),
    status: yup.string().required("Required"),
    eligibleGameProviders: yup.array().required("Required"),
    // Bonus
    rebateInterval: yup.string().required("Required"),
    calculateMethod: yup.string().required("Required"),
    bonusAmount: yup.number().typeError("Required"),
    rebatePercentage: yup.number().typeError("Required"),
    maxRebateAmountEachTime: yup.number().required("Required").typeError("Must be positive value"),
    rebateCalculation: yup.array().of(yup.string()).min(1, "Required"),
    minimumTurnoverRequirement: yup.number().typeError("Required"),
    withdrawalLimitRatio: yup.boolean(),
    // Process
    needPlayerApply: yup.boolean(),

    // ?????
    // turnOverRollOverTimes: yup.number().required().typeError("Required"),
    rolloverFormula: yup.string(),
    minimumDepositRequirement: yup.number().typeError("Required"),
    bonusPercentage: yup.number().typeError("Required"),
    // ?????
    // maxBonusAmountEachTime: yup
    // .number()
    // .required("Required")
    // .typeError("Must be positive value"),
    minimumDepositAmount: yup.number().typeError("Required"),
    // Process
    autoRebate: yup.string(),
    allowMultiplePromotion: yup.boolean(),
    // Risk Control
    notAllowedPlayerTag: yup.array().of(yup.string()), // yup.string(),
    maxApplicationCountResetPeriod: yup.string(),
    maxApplicationCountByPlayer: yup.number().typeError("Must be positive value"),
    totalBudget: yup.number().typeError("Must be positive value"),
    maxApplicationCountForThisPromo: yup.number().typeError("Must be positive value"),
    turnOverRollOverTimes: yup.number().required().typeError("Required"),

    localizations: yup.array().of(
        yup.object().shape({
            title: yup.string().required("Required"),
            code: yup.string().required("Required"),
            image: yup.string().required("Required"),
            remark: yup.string(),
            content: yup.string().required("Required"),
            language: yup.string().required("Required"),
            tabTitle: yup.string(),
        })
    ),
})

/**  Rebate On Loss */
export const rebateOnLossSchema = yup.object().shape({
    // Basic
    date: yup
        .object()
        .shape({
            startDate: yup.string(),
            endDate: yup.string(),
        })
        .required("Required"),
    currency: yup.string().required("Required"),
    displayOrder: yup.number().typeError("Required"),
    isShowToGuest: yup.boolean(),
    status: yup.string().required("Required"),
    eligibleGameProviders: yup.array().required("Required"),
    // Rebate
    rebateInterval: yup.string().required("Required"),
    calculateMethod: yup.string().required("Required"),
    bonusAmount: yup.number().typeError("Required"),
    rebatePercentage: yup.number().typeError("Required"),
    maxRebateAmountEachTime: yup.number().required("Required").typeError("Must be positive value"),
    rebateCalculation: yup.array().of(yup.string()).min(1, "Required"),
    minimumTurnoverRequirement: yup.number().typeError("Required"),
    withdrawalLimitRatio: yup.boolean(),
    // Process
    autoRebate: yup.string(),
    allowMultiplePromotion: yup.boolean(),
    // Risk Control
    notAllowedPlayerTag: yup.array().of(yup.string()), // yup.string(),
    maxApplicationCountResetPeriod: yup.string(),
    maxApplicationCountByPlayer: yup.number().typeError("Must be positive value"),
    totalBudget: yup.number().typeError("Must be positive value"),
    maxApplicationCountForThisPromo: yup.number().typeError("Must be positive value"),
    turnOverRollOverTimes: yup.number().required().typeError("Required"),

    localizations: yup.array().of(
        yup.object().shape({
            title: yup.string().required("Required"),
            code: yup.string().required("Required"),
            image: yup.string().required("Required"),
            remark: yup.string(),
            content: yup.string().required("Required"),
            language: yup.string().required("Required"),
            tabTitle: yup.string(),
        })
    ),
})

/**  Rebate On Win/Lost */
export const rebateOnWinLostSchema = yup.object().shape({
    // Basic
    date: yup
        .object()
        .shape({
            startDate: yup.string(),
            endDate: yup.string(),
        })
        .required("Required"),
    currency: yup.string().required("Required"),
    displayOrder: yup.number().typeError("Required"),
    isShowToGuest: yup.boolean(),
    status: yup.string().required("Required"),
    eligibleGameProviders: yup.array().required("Required"),
    // Bonus
    rebateInterval: yup.string().required("Required"),
    rebatePercentage: yup.number().typeError("Required"),
    maxRebateAmountEachTime: yup.number().required("Required").typeError("Must be positive value"),
    rebateCalculation: yup.array().of(yup.string()).min(1, "Required"),
    minimumTurnoverRequirement: yup.number().typeError("Required"),
    withdrawalLimitRatio: yup.boolean(),
    // Process
    autoRebate: yup.string(),
    allowMultiplePromotion: yup.boolean(),
    // Risk Control
    notAllowedPlayerTag: yup.array().of(yup.string()), // yup.string(),
    maxApplicationCountResetPeriod: yup.string(),
    maxApplicationCountByPlayer: yup.number().typeError("Must be positive value"),
    totalBudget: yup.number().typeError("Must be positive value"),
    maxApplicationCountForThisPromo: yup.number().typeError("Must be positive value"),

    localizations: yup.array().of(
        yup.object().shape({
            title: yup.string().required("Required"),
            code: yup.string().required("Required"),
            image: yup.string().required("Required"),
            remark: yup.string(),
            content: yup.string().required("Required"),
            language: yup.string().required("Required"),
            tabTitle: yup.string(),
        })
    ),
})

/**  Rebate On Deposit and Withdrawal */
export const rebateOnDepositAndWithdrawalSchema = yup.object().shape({
    // Basic
    date: yup
        .object()
        .shape({
            startDate: yup.string(),
            endDate: yup.string(),
        })
        .required("Required"),
    currency: yup.string().required("Required"),
    displayOrder: yup.number().typeError("Required"),
    isShowToGuest: yup.boolean(),
    status: yup.string().required("Required"),
    eligibleGameProviders: yup.array().required("Required"),
    // Bonus
    rebateInterval: yup.string().required("Required"),
    rebatePercentage: yup.number().typeError("Required"),
    maxRebateAmountEachTime: yup.number().required("Required").typeError("Must be positive value"),
    rebateCalculation: yup.array().of(yup.string()).min(1, "Required"),
    minimumTurnoverRequirement: yup.number().typeError("Required"),
    withdrawalLimitRatio: yup.boolean(),
    // Process
    autoRebate: yup.string(),
    allowMultiplePromotion: yup.boolean(),
    // Risk Control
    notAllowedPlayerTag: yup.array().of(yup.string()), // yup.string(),
    maxApplicationCountResetPeriod: yup.string(),
    maxApplicationCountByPlayer: yup.number().typeError("Must be positive value"),
    totalBudget: yup.number().typeError("Must be positive value"),
    maxApplicationCountForThisPromo: yup.number().typeError("Must be positive value"),

    localizations: yup.array().of(
        yup.object().shape({
            title: yup.string().required("Required"),
            code: yup.string().required("Required"),
            image: yup.string().required("Required"),
            remark: yup.string(),
            content: yup.string().required("Required"),
            language: yup.string().required("Required"),
            tabTitle: yup.string(),
        })
    ),
})
