import { PlusOutlined } from "@ant-design/icons";
import { Button, Card, Col, Form, Input, Row, Upload } from "antd";
import React from "react";

import { useNavigate } from "react-router-dom";
import { apis } from "../../../apis";
import { httpService } from "../../../apis/httpService";
import { paths } from "../../../routes/path";
import "./AddBank.scss";

const normFile = (e: any) => {
   if (Array.isArray(e)) {
      return e;
   }
   return e?.fileList;
};

const AddBank: React.FC = () => {
   const navigate = useNavigate();

   const onFinish = async (values: any) => {
      try {
         await httpService.post(apis.bank.bank, values);
         navigate(paths.BANK_LIST);
      } catch (error) {
         console.log("error", error);
      }
   };
   return (
      <Card title='Thêm ngân hàng mới'>
         <div className='form'>
            <Form labelCol={{ span: 4 }} wrapperCol={{ span: 14 }} onFinish={onFinish}>
               <Row>
                  <Col span={3}>Tên ngân hàng:</Col>
                  <Col span={11}>
                     <Form.Item
                        wrapperCol={{ sm: 24 }}
                        labelCol={{ span: 24 }}
                        name='name'
                        rules={[{ required: true, message: "Vui lòng nhập tên ngân hàng!" }]}
                     >
                        <Input placeholder='Ngân hàng Á Châu' />
                     </Form.Item>
                  </Col>
               </Row>

               <Row>
                  <Col span={3}>Code:</Col>
                  <Col span={11}>
                     <Form.Item
                        wrapperCol={{ sm: 24 }}
                        labelCol={{ span: 24 }}
                        name='code'
                        rules={[{ required: true, message: "Vui lòng nhập code!" }]}
                     >
                        <Input placeholder='ACB' />
                     </Form.Item>
                  </Col>
               </Row>

               <Row style={{ marginTop: 10 }}>
                  <Col span={3}>Hình ảnh:</Col>

                  <Col span={12}>
                     <Form.Item valuePropName='fileList' getValueFromEvent={normFile} name='image'>
                        <Upload action='/upload.do' listType='picture-card'>
                           <div>
                              <PlusOutlined />
                              <div style={{ marginTop: 8 }}>Upload</div>
                           </div>
                        </Upload>
                     </Form.Item>
                  </Col>
               </Row>

               <Row style={{ marginTop: 10 }}>
                  <Col span={17}>
                     <div className='flex-center'>
                        <Button type='primary' size={"large"} htmlType='submit'>
                           Thêm mới
                        </Button>
                     </div>
                  </Col>
               </Row>
            </Form>
         </div>
      </Card>
   );
};

export default () => <AddBank />;
