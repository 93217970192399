import { Form, Modal } from "antd"
import { useEffect, useState } from "react"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { apis } from "../../../apis"
import { httpService } from "../../../apis/httpService"
import "./ModalConfirm.scss"

type FieldType = {
    isModalOpen: boolean
    setIsModalOpen: (value: any) => void
    title: string
    description: string
    data?: any
    confirmModal?: () => void
}

const ModalConfirm = ({
    setIsModalOpen,
    isModalOpen,
    title,
    description,
    data,
    confirmModal,
}: FieldType) => {
    const [form] = Form.useForm()

    const onFinishFailed = (errorInfo: any) => {
        console.log("Failed:", errorInfo)
    }
    const [confirmLoading, setConfirmLoading] = useState(false)
    const [fromDomain, setFromDomain] = useState("")
    const [toDomain, setToDomain] = useState("")
    const [domains, setDomains] = useState([])
    const [canSave, setCanSave] = useState(false)
    const onChangeFromDomain = (value: any) => {
        setFromDomain(value)
    }
    const onChangeToDomain = (value: any) => {
        setToDomain(value)
    }

    const getDomain = async () => {
        const res = await httpService.get(apis.webAdmin.domainThemes)
        setDomains(res.data)
    }
    useEffect(() => {
        getDomain()
    }, [isModalOpen])

    useEffect(() => {
        if (fromDomain && toDomain && fromDomain !== toDomain) {
            setCanSave(true)
        }
    }, [fromDomain, toDomain])

    const handleSave = async () => {
        try {
            if (canSave) {
                await httpService.post(apis.webAdmin.copyTheme, {
                    fromDomain,
                    toDomain: [toDomain],
                })
            }
            setIsModalOpen(false)
            toast.success("Copy theme success!")
        } catch (error) {
            console.log("error", error)
            toast.error("Copy theme failed!")
        }
    }
    const handleCancel = () => {
        setIsModalOpen(false)
    }

    return (
        <div className='modal-confirm__container'>
            <Modal
                title={title}
                open={isModalOpen}
                onCancel={handleCancel}
                confirmLoading={confirmLoading}
                // footer={null}
                onOk={confirmModal}
                className='modal-confirm'
            >
                <div style={{ margin: "30px 0px 60px 20px" }}>
                    <div>{description}</div>
                </div>
            </Modal>
            <ToastContainer autoClose={1500} />
        </div>
    )
}

export default ModalConfirm
