import { useMemo } from "react";
import CustomSelect from "./custom-select";
import { useTranslation } from "react-i18next";
import { PromotionState } from "../constants";

const PromotionStateSelect = ({
    value,
    onChange,
}: {
    value: string[];
    onChange: (value: string[]) => void;
}) => {
    const { t } = useTranslation(``);
    // Options for State
    const StateOptions = useMemo(
        () => [
            {
                label: t("common.state.pending"),
                value: PromotionState.PENDING,
            },
            {
                label: t("common.state.approved"),
                value: PromotionState.APPROVE,
            },
            {
                label: t("common.state.rejected"),
                value: PromotionState.REJECTED,
            },
            {
                label: t("common.state.completed"),
                value: PromotionState.COMPLETED,
            },
        ],
        [t]
    );
    return (
        <div className="flex gap-2 items-center ">
            <span>{t("content.promotions.create.form.field.status")}</span>
            <CustomSelect
                className="flex-1"
                withAllOption
                mode="multiple"
                allowClear
                style={{
                    width: 160,
                }}
                options={StateOptions}
                value={value}
                onChange={(value) => {
                    if (!value.length) {
                        onChange(["*"]);
                    } else {
                        onChange(value);
                    }
                }}
                maxTagCount="responsive"
            />
        </div>
    );
};

export default PromotionStateSelect;
