import {
    ActionReducerMapBuilder,
    PayloadAction,
    createAsyncThunk,
    createSlice,
} from "@reduxjs/toolkit"
import { NoInfer } from "react-redux"
import { apis } from "../../apis"
import { httpService } from "../../apis/httpService"
import { ACCESS_TOKEN, REFRESH_TOKEN } from "../../common/constants/local-storage-keys"

function createExtraActions() {
    function getProfile() {
        return createAsyncThunk("auth/profile", async (_, action) => {
            try {
                const resp = await httpService.get(apis.user.me)
                return resp.data
            } catch (e: any) {
                console.log("e", e)
                return action.rejectWithValue(e.response.data)
            }
        })
    }

    function login() {
        return createAsyncThunk("auth/login", async (value: any, action) => {
            try {
                const resp = await httpService.post(apis.auth.login, value)

                httpService.setToken(resp.data.accessToken)
                httpService.setRefreshToken(resp.data.refreshToken)
                window.localStorage.setItem(ACCESS_TOKEN, resp.data.accessToken)
                window.localStorage.setItem(REFRESH_TOKEN, resp.data.refreshToken)

                // await action.dispatch(getProfile);
            } catch (e: any) {
                return action.rejectWithValue(e.response.data)
            }
        })
    }

    return {
        getProfile: getProfile(),
        login: login(),
    }
}

const extraActions = createExtraActions()

function createExtraReducers() {
    return (builder: ActionReducerMapBuilder<NoInfer<any>>) => {
        login()
        getProfile()

        function getProfile() {
            const { fulfilled, rejected } = extraActions.getProfile
            builder
                .addCase(fulfilled.toString(), (state: any, action: any) => {
                    state.profile = action.payload
                    state.loading = false
                    state.isCheckedCredential = true
                })
                .addCase(rejected.toString(), (state: any, action: any) => {
                    state.loading = false
                    state.isCheckedCredential = true
                })
        }

        function login() {
            const { fulfilled } = extraActions.login
            builder.addCase(fulfilled.toString(), (state: any, action: any) => {
                state.profile = action.payload
            })
        }
    }
}

const authSlice = createSlice({
    name: "auth",
    initialState: {
        loading: true,
        profile: null,
        isCheckedCredential: false,
    },
    reducers: {
        toggleLoading: {
            reducer: (state, action: PayloadAction<boolean>) => {
                state.loading = action.payload
            },
            prepare: (v: boolean) => {
                return {
                    payload: v,
                }
            },
        },
        logout: {
            reducer: (state, action: PayloadAction<void>) => {
                state.profile = null
            },
            prepare: () => {
                return {
                    payload: void 0,
                }
            },
        },
    },
    extraReducers: createExtraReducers(),
})

export const { reducer: authReducer } = authSlice
export const authActions = { ...authSlice.actions, ...extraActions }
