import CascaderField from "./form/cascader-field";
import { Cascader } from "antd";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const EligibleGameProvidersSelect = ({
    name = "eligibleGameProviders",
    control,
    isDisabled,
}: {
    name?: string;
    control: any;
    isDisabled?: boolean;
}) => {
    const { gameProviderList } = useSelector((state: any) => state.promotion);
    const { t } = useTranslation();

    return (
        <CascaderField
            name={name}
            control={control}
            formItemProps={{
                label: t(
                    "content.promotions.create.form.field.eligibleGameProviders"
                ),
                tooltip: t(
                    "content.promotions.create.form.tooltip.eligibleGameProviders"
                ),
                required: true,
            }}
            cascaderProps={{
                options: gameProviderList,
                multiple: true,
                maxTagCount: "responsive",
                showCheckedStrategy: Cascader.SHOW_CHILD,
                defaultValue: [],
                disabled: isDisabled,
                placeholder: t(
                    "content.promotions.create.form.placeholder.notAllowedPlayerTag"
                ),
            }}
        />
    );
};

export default EligibleGameProvidersSelect;
