import { useTranslation } from "react-i18next";
import "./GameList.scss";

import { Button, Checkbox, CheckboxProps, Col, Input, Row, Select, Table } from "antd";
import { useEffect, useState } from "react";

import { apis } from "../../../apis";

import { CheckCircleFilled } from "@ant-design/icons";
import { httpService } from "../../../apis/httpService";
import { ModalGameCategoryOrder } from "../../../common/components/modal-game-category-order";
import { ModalSiteDisplayOrder } from "../../../common/components/modal-site-display-order";

const GameList = () => {
    const { t } = useTranslation();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isDisplayOrderOpen, setIsDisplayOrderOpen] = useState(false);
    const [dataEdit, setDataEdit] = useState();

    const { Option } = Select;

    const columnInit = [
        {
            title: t("content.gameList.col.providerCategory"),
            dataIndex: "gameProviderCategory",
            key: "gameProviderCategory",
        },

        {
            title: t("content.gameList.col.gameProvider"),
            dataIndex: "displayName",
            key: "displayName",
        },

        {
            title: t("content.gameList.col.gameType"),
            dataIndex: "gameType",
            key: "gameType",
        },
        {
            title: t("content.gameList.col.gameName"),
            dataIndex: "gameName",
            key: "gameName",
        },
        {
            title: t("content.gameList.col.platform"),
            dataIndex: "platform",
            key: "platform",
            render: (value: any, record: any) => {
                const data = value.split(",");
                const child = data?.map((item: string) => {
                    if (item === "d") {
                        return <span className="desktop">Desktop</span>;
                    } else if (item === "m") {
                        return <span className="mobile">Mobile</span>;
                    }
                });

                return <div className="checkbox-center">{child}</div>;
            },
        },

        {
            title: t("content.gameList.col.isEnabled"),
            dataIndex: "isEnabled",
            key: "isEnabled",
            render: (value: any, record: any) => {
                if (value === true) {
                    return (
                        <div className="checkbox-center">
                            <CheckCircleFilled style={{ color: "#67c23a", fontSize: 20 }} />
                        </div>
                    );
                }
            },
        },
        {
            title: t("content.gameList.col.specialSelection"),
            dataIndex: "specialSelection",
            key: "specialSelection",
            render: (value: any, record: any) => {
                if (value === true) {
                    return (
                        <div className="checkbox-center">
                            <CheckCircleFilled style={{ color: "#67c23a", fontSize: 20 }} />
                        </div>
                    );
                }
            },
        },
    ];

    const columnEdit = [
        {
            title: "#",
            dataIndex: "checkbox",
            key: "checkbox",
            render: (value: any, record: any) => {
                return <Checkbox onChange={() => onChangeCheckRow(record)} checked={rowCheck[record.id]}></Checkbox>;
            },
        },
        {
            title: t("content.gameList.col.providerCategory"),
            dataIndex: "gameProviderCategory",
            key: "gameProviderCategory",
        },

        {
            title: t("content.gameList.col.gameProvider"),
            dataIndex: "displayName",
            key: "displayName",
        },

        {
            title: t("content.gameList.col.gameType"),
            dataIndex: "gameType",
            key: "gameType",
        },
        {
            title: t("content.gameList.col.gameName"),
            dataIndex: "gameName",
            key: "gameName",
        },
        {
            title: t("content.gameList.col.platform"),
            dataIndex: "platform",
            key: "platform",
            render: (value: any, record: any) => {
                const data = value.split(",");
                const child = data?.map((item: string) => {
                    if (item === "d") {
                        return <span className="desktop">Desktop</span>;
                    } else if (item === "m") {
                        return <span className="mobile">Mobile</span>;
                    }
                });

                return <div className="checkbox-center">{child}</div>;
            },
        },

        {
            title: t("content.gameList.col.isEnabled"),
            dataIndex: "isEnabled",
            key: "isEnabled",
            render: (value: any, record: any) => {
                return (
                    <div className="checkbox-center">
                        <Checkbox onChange={() => onCheckIsEnable(record)} checked={isEnabled[record.id]}></Checkbox>
                    </div>
                );
            },
        },
        {
            title: t("content.gameList.col.specialSelection"),
            dataIndex: "specialSelection",
            key: "specialSelection",
            render: (value: any, record: any) => {
                return (
                    <div className="checkbox-center">
                        <Checkbox
                            onChange={() => onCheckSpecialSelection(record)}
                            checked={specialSelection[record.id]}
                        ></Checkbox>
                    </div>
                );
            },
        },
    ];

    const gameList = [
        {
            id: 11,
            gameProviderCategory: "Sports",
            displayName: "SBOBET",
            gameType: "SportsBook",
            gameName: "SBO Sports Classic",
            platform: "d,m",
            isEnabled: true,
            specialSelection: true,
        },
        {
            id: 12,
            gameProviderCategory: "Sports",
            displayName: "SBOBET",
            gameType: "SportsBook",
            gameName: "SBO Sports Classic",
            platform: "d,m",
            isEnabled: true,
            specialSelection: false,
        },
        {
            id: 13,
            gameProviderCategory: "Sports",
            displayName: "SBOBET",
            gameType: "SportsBook",
            gameName: "SBO Sports Classic",
            platform: "d,m",
            isEnabled: false,
            specialSelection: true,
        },
    ];

    const [isEdit, setIsEdit] = useState(false);
    const [canSave, setCanSave] = useState(false);
    const [gameProviderList, setGameProviderList] = useState([]);
    const [checkAll, setCheckAll] = useState(false);
    const [rowCheck, setRowCheck] = useState<any>({});
    const [isEnabled, setIsEnabled] = useState<any>({});
    const [specialSelection, setSpecialSelection] = useState<any>({});
    const [gameProviderArray, setGameProviderArray] = useState([]);

    const onChangeCheckRow = (record: any) => {
        setRowCheck((prev: any) => ({ ...prev, [record.id]: !rowCheck[record.id] }));
    };

    const onCheckIsEnable = (record: any) => {
        setIsEnabled((prev: any) => ({ ...prev, [record.id]: !isEnabled[record.id] }));
    };
    const onCheckSpecialSelection = (record: any) => {
        setSpecialSelection((prev: any) => ({ ...prev, [record.id]: !specialSelection[record.id] }));
    };

    const onClickEdit = () => {
        setIsEdit(true);
    };

    const onClickCancel = () => {
        setIsEdit(false);
    };
    const onClickSave = async () => {
        //   if (canSave) {
        //       try {
        //           const res = await httpService.put(apis.webAdmin.adminControlList, {
        //               data: changeData,
        //           })
        //           if (res.data) {
        //               getGameProvider()
        //               setChangeData([])
        //               setIsEdit(false)
        //           }
        //       } catch (error) {
        //           console.log("error", error)
        //       }
        //   }
    };

    const getGameProvider = async () => {
        try {
            const res = await httpService.post(apis.gameAndProvider.list);

            //update res.data later
            // const data = res.data || res

            // if (data.length) {
            //     const cate = data.reduce((acc: any[], cur: any) => {
            //         if (!acc.includes(cur.gameProviderCategory)) {
            //             acc.push(cur.gameProviderCategory)
            //         }
            //         return acc
            //     }, [])

            //     setCategory(cate)
            // }

            // setGameProviderList(data)

            setIsEnabled(
                gameList?.reduce((acc: any, curr: any) => {
                    if (!acc[curr.id]) {
                        acc[curr.id] = curr.isEnabled;
                        return acc;
                    }
                }, {})
            );
            setSpecialSelection(
                gameList?.reduce((acc: any, curr: any) => {
                    if (!acc[curr.id]) {
                        acc[curr.id] = curr.specialSelection;
                        return acc;
                    }
                }, {})
            );
        } catch (error) {
            console.log("error", error);
        }
    };

    useEffect(() => {
        getGameProvider();
    }, []);

    const onCheckAllChange: CheckboxProps["onChange"] = (e) => {
        const check = e.target.checked;

        setCheckAll(!checkAll);
        setRowCheck(
            gameList?.reduce((acc: any, curr: any) => {
                if (!acc[curr.id]) {
                    acc[curr.id] = check;
                    return acc;
                }
            }, {})
        );
    };

    const onChangeEnabled = (type: string) => {
        setIsEnabled(
            gameList?.reduce((acc: any, curr: any) => {
                if (!acc[curr.id]) {
                    acc[curr.id] = type === "enable" ? true : false;
                    return acc;
                }
            }, {})
        );
    };
    const onChangeSpecialSelection = (type: string) => {
        setSpecialSelection(
            gameList?.reduce((acc: any, curr: any) => {
                if (!acc[curr.id]) {
                    acc[curr.id] = type === "enable" ? true : false;
                    return acc;
                }
            }, {})
        );
    };

    useEffect(() => {
        getGameType();
    }, []);

    const getGameType = async () => {
        try {
            const res = await httpService.get(apis.gameAndProvider.gameType);
            setGameProviderArray(res?.data?.gameProviderArray);
        } catch (error) {
            setGameProviderArray([]);
            console.log("error", error);
        }
    };

    return (
        <div>
            <Col id="game-list">
                <div className="filter">
                    <div className="filter-header">
                        <div className="filter-header__name">{t("content.gameList.title")}</div>

                        <div className="modify-btn">
                            {!isEdit ? (
                                <div className="modify-btn">
                                    <Button size="small" type="primary" onClick={onClickEdit}>
                                        <span style={{ padding: "0 6px" }}>{t("content.gameList.edit")}</span>
                                    </Button>
                                </div>
                            ) : (
                                <div className="modify-btn">
                                    <Button
                                        size="small"
                                        style={{
                                            marginRight: 4,
                                        }}
                                        type="primary"
                                        onClick={onClickCancel}
                                        danger
                                    >
                                        <span style={{ padding: "0 6px" }}>{t("content.gameList.cancel")}</span>
                                    </Button>
                                    <Button size="small" type="primary" disabled={!canSave}>
                                        <span style={{ padding: "0 6px" }} onClick={onClickSave}>
                                            {t("content.gameList.save")}
                                        </span>
                                    </Button>
                                </div>
                            )}
                        </div>
                    </div>

                    {isEdit && (
                        <div className="filter-bar">
                            <div>
                                <Checkbox onChange={onCheckAllChange} checked={checkAll} style={{ marginRight: 10 }}>
                                    {" "}
                                </Checkbox>

                                <Button
                                    style={{ marginRight: 10 }}
                                    className={checkAll ? "btn" : "disable"}
                                    size="small"
                                    disabled={!checkAll}
                                    onClick={() => onChangeEnabled("enable")}
                                >
                                    {t("content.gameList.setAllEnabled")}
                                </Button>
                                <Button
                                    style={{ marginRight: 10 }}
                                    className={checkAll ? "btn" : "disable"}
                                    size="small"
                                    disabled={!checkAll}
                                    onClick={() => onChangeSpecialSelection("enable")}
                                >
                                    {t("content.gameList.setAllSpecialSelection")}
                                </Button>

                                <Button
                                    style={{ marginRight: 10 }}
                                    className={checkAll ? "disable-btn" : "disable"}
                                    size="small"
                                    disabled={!checkAll}
                                    onClick={() => onChangeEnabled("disable")}
                                >
                                    {t("content.gameList.removeAllEnabled")}
                                </Button>
                                <Button
                                    style={{ marginRight: 10 }}
                                    className={checkAll ? "disable-btn" : "disable"}
                                    size="small"
                                    disabled={!checkAll}
                                    onClick={() => onChangeSpecialSelection("disable")}
                                >
                                    {t("content.gameList.removeAllSpecialSelection")}
                                </Button>
                            </div>
                        </div>
                    )}

                    {!isEdit && (
                        <div className="filter-bar">
                            <Row gutter={8}>
                                <Col>
                                    <span className="filter__name">{t("content.gameList.gameProvider")}</span>
                                    <Select
                                        defaultValue="all"
                                        style={{ width: 160 }}
                                        // onChange={onTransactionTypeChange}
                                    >
                                        <Option value="all">{t("content.gameList.all")}</Option>

                                        {gameProviderArray?.map((cate) => (
                                            <Option value={cate}>{cate}</Option>
                                        ))}
                                    </Select>
                                </Col>
                                <Col>
                                    <p className="filter__name">{t("content.gameList.gameType")}</p>
                                    <Select
                                        defaultValue="all"
                                        style={{ width: 160 }}
                                        // onChange={onTransactionTypeChange}
                                    >
                                        <Option value="">{t("content.gameList.all")}</Option>

                                        {gameProviderArray?.map((cate) => (
                                            <Option value={cate}>{cate}</Option>
                                        ))}
                                    </Select>
                                </Col>
                                <Col>
                                    <p className="filter__name">{t("content.gameList.gameName")}</p>
                                    <Input placeholder={t("content.gameList.enterGameName")} style={{ width: 160 }} />
                                </Col>
                                <Col>
                                    <Button type="primary" size="small">
                                        {t("content.gameList.submit")}
                                    </Button>
                                </Col>
                            </Row>
                        </div>
                    )}
                </div>

                <div id="game-list-table">
                    <div id="table-data" style={{ marginTop: 6 }}>
                        <Table columns={isEdit ? columnEdit : columnInit} dataSource={gameList} pagination={false} />
                    </div>
                </div>
            </Col>
            <ModalGameCategoryOrder isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} data={dataEdit} />
            <ModalSiteDisplayOrder
                isModalOpen={isDisplayOrderOpen}
                setIsModalOpen={setIsDisplayOrderOpen}
                data={gameProviderList}
            />
        </div>
    );
};

export default GameList;
