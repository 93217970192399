import CustomSelect from "./custom-select";
import { useTranslation } from "react-i18next";
import { PromotionType } from "../constants";

const PromotionTypeSelect = ({
    value,
    onChange,
}: {
    value: string[];
    onChange: (value: string[]) => void;
}) => {
    const { t } = useTranslation();
    // Options for Promotion Type
    const PromotionTypeOptions = [
        {
            value: PromotionType.REGISTER_BONUS,
            label: t(`content.promotions.create.type.REGISTER_BONUS.title`),
        },
        {
            value: PromotionType.FIRST_DEPOSIT_BONUS,
            label: t(
                `content.promotions.create.type.${PromotionType.FIRST_DEPOSIT_BONUS}.title`
            ),
        },
        {
            value: PromotionType.DAILY_DEPOSIT_BONUS,
            label: t(
                `content.promotions.create.type.${PromotionType.DAILY_DEPOSIT_BONUS}.title`
            ),
        },
        {
            value: PromotionType.DEPOSIT_BONUS,
            label: t(
                `content.promotions.create.type.${PromotionType.DEPOSIT_BONUS}.title`
            ),
        },
        {
            value: PromotionType.REBASE_ON_TURNOVER,
            label: t(
                `content.promotions.create.type.${PromotionType.REBASE_ON_TURNOVER}.title`
            ),
        },
        {
            value: PromotionType.REBASE_ON_TURNOVER_LOSE_ONLY,
            label: t(
                `content.promotions.create.type.${PromotionType.REBASE_ON_TURNOVER_LOSE_ONLY}.title`
            ),
        },
        {
            value: PromotionType.REBASE_ON_WIN_LOST,
            label: t(
                `content.promotions.create.type.${PromotionType.REBASE_ON_WIN_LOST}.title`
            ),
        },
        {
            value: PromotionType.REBASE_ON_DEPOSIT_AND_WITHDRAW,
            label: t(
                `content.promotions.create.type.${PromotionType.REBASE_ON_DEPOSIT_AND_WITHDRAW}.title`
            ),
        },
    ];

    return (
        <div className="flex gap-2 items-center ">
            <span>
                {t("content.promotions.create.form.field.promotionType")}
            </span>
            <CustomSelect
                className="flex-1"
                withAllOption
                mode="multiple"
                allowClear
                style={{
                    width: 220,
                }}
                options={PromotionTypeOptions}
                value={value}
                onChange={(value) => {
                    if (!value.length) {
                        onChange(["*"]);
                    } else {
                        onChange(value);
                    }
                }}
                maxTagCount="responsive"
            />
        </div>
    );
};

export default PromotionTypeSelect;
