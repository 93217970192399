import { Button, Card, Divider, Form, Input } from "antd";

export interface IConnectSettingProps {}

const ConnectSetting: React.FC<IConnectSettingProps> = () => {
  return (
    <div>
      <Card title="Telegram config">
        <Form>
          <Form.Item label="Access Key" labelCol={{ span: 24 }}>
            <Input />
          </Form.Item>
          <Form.Item label="Deposit Group Id" labelCol={{ span: 24 }}>
            <Input />
          </Form.Item>
          <Form.Item label="Withdraw Group Id" labelCol={{ span: 24 }}>
            <Input />
          </Form.Item>
          <div className="flex-center mt-2">
            <Button type="primary">Lưu thay đổi</Button>
          </div>
        </Form>
      </Card>
    </div>
  );
};

export default ConnectSetting;
