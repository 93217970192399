import { Card, Col, Progress, Radio, Row, Statistic, Tabs } from "antd";
import TabPane from "antd/es/tabs/TabPane";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { apis } from "../../apis";
import { httpService } from "../../apis/httpService";
import { getCurrencyList } from "../../common/helpers/utils";
import ChartHome from "./Chart";
import "./Home.scss";

const HomePage: React.FC<any> = () => {
  const { t } = useTranslation();

  const DashboardCard = ({ title, value, precision, suffix }: any) => (
    <Card>
      <Statistic
        title={title}
        value={value}
        precision={precision}
        suffix={suffix}
        style={{ padding: "10px" }}
      />
    </Card>
  );

  const dataTotalWallet = [
    {
      key: "mainWallet",
      title: t("content.dashboard.totalMainWalletBalance"),
      value: 0,
    },
    // {
    //     key: "mainWallet",
    //     title: "Total Promotion Wallet Balance",
    //     value: 0,
    // },
    // {
    //     key: "mainWallet",
    //     title: "Total Referral Wallet Balance",
    //     value: 0,
    // },
    // {
    //     key: "mainWallet",
    //     title: "Total Outstanding",
    //     value: 0,
    // },
  ];

  const [capPercent, setCapPercent] = useState(80);

  const [totalUser, setTotalUser] = useState(0);
  const [currencyList, setCurrencyList] = useState<any[]>([]);
  const [currency, setCurrency] = useState("VND");

  const onChangeCurrencyTab = (value: string) => {
    setCurrency(value);
  };

  const fetchData = async () => {
    try {
      const [totalUser, currency] = await Promise.all([
        httpService.get(apis.home.totalUser),
        getCurrencyList(),
      ]);

      setTotalUser(totalUser.data);
      setCurrencyList(currency.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const onChangePerformance = (e: any) => {
    console.log("onChangePerformance", e);
  };

  return (
    <div className="dashboard">
      <Row gutter={16} style={{ marginBottom: "12px", textAlign: "center" }}>
        <Col span={12}>
          <Card style={{ minHeight: "134px" }}>
            <Row>
              <Col span={8}>
                <div>
                  <Progress
                    type="circle"
                    percent={capPercent}
                    width={80}
                    strokeColor={capPercent > 75 ? "#f56c75" : "blue"}
                    format={(percent) => `${percent}%`}
                  />
                </div>
              </Col>

              <Col span={8}>
                <Statistic
                  title={t("content.dashboard.winLoose")}
                  value={0}
                  precision={2}
                />
              </Col>

              <Col span={8}>
                <Statistic
                  title={t("content.dashboard.capLimit")}
                  value={0}
                  precision={2}
                />
              </Col>
            </Row>
          </Card>
        </Col>

        <Col span={6}>
          <DashboardCard
            title={t("content.dashboard.totalPlayer")}
            value={totalUser}
          />
        </Col>
        <Col span={6}>
          <DashboardCard title={t("content.dashboard.cashAgent")} value={0} />
        </Col>
      </Row>

      <Row style={{ marginBottom: "12px" }} className="warning-block">
        <Col span={1} className="warning-icon">
          <span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              aria-hidden="true"
              className="svg-inline--fa fa-triangle-exclamation w-8 h-8"
              data-icon="triangle-exclamation"
              data-prefix="fas"
              viewBox="0 0 512 512"
              width={36}
            >
              <path
                fill="#f56c75"
                d="M506.3 417 293 53c-16.33-28-57.54-28-73.98 0L5.82 417c-16.41 27.9 4.029 63 36.92 63h426.6c32.76 0 53.26-35 36.96-63zM232 168c0-13.25 10.75-24 24-24s24 10.8 24 24v128c0 13.25-10.75 24-23.1 24S232 309.3 232 296V168zm24 248c-17.36 0-31.44-14.08-31.44-31.44s14.07-31.44 31.44-31.44 31.44 14.08 31.44 31.44C287.4 401.9 273.4 416 256 416z"
              />
            </svg>
          </span>
        </Col>

        <Col span={20} className="warning-text" style={{ marginLeft: "22px" }}>
          <p>- Cap already reaches 75%, please notify our finance.</p>
          <p>
            - Once Cap reaches 100%, the players wont be able to Deposit,
            Withdrawal and Place bets.
          </p>
        </Col>
      </Row>

      <Row
        style={{
          backgroundColor: "#fff",
          borderRadius: 4,
          padding: "0 24px",
        }}
      >
        <Col
          span={24}
          style={{
            borderBottom: "1px solid #e1e1e1",
          }}
          className="total-wallet"
        >
          <Tabs
            defaultActiveKey="VND"
            style={{ paddingLeft: 18 }}
            onChange={onChangeCurrencyTab}
          >
            {currencyList?.map((item) => {
              return (
                <TabPane tab={item.currency} key={item.currency}>
                  <Row
                    gutter={16}
                    style={{
                      textAlign: "center",
                      marginBottom: 26,
                      alignItems: "center",
                    }}
                  >
                    {dataTotalWallet?.map((total) => {
                      return (
                        <Col span={4}>
                          <p className="total-wallet-title">{total.title}</p>
                          <div className="total-wallet-content">
                            <span className="total-wallet-content__number">
                              {total.value}
                            </span>
                            <span className="total-wallet-content__currency">
                              {item.currency}
                            </span>
                          </div>
                        </Col>
                      );
                    })}
                  </Row>
                </TabPane>
              );
            })}
          </Tabs>
        </Col>

        <Col
          span={24}
          className="performance-overview"
          style={{
            borderBottom: "1px solid #e1e1e1",
            marginBottom: 24,
          }}
        >
          <Row className="performance-overview-tab">
            <Col span={4}>
              <p className="performance-overview-text">
                {t("content.dashboard.performanceOverview")}
              </p>
            </Col>
            <Col span={20}>
              <Radio.Group
                onChange={(e) => onChangePerformance(e.target.value)}
                optionType="button"
                buttonStyle="solid"
                defaultValue={"today"}
              >
                <Radio.Button value="today">{t("calendar.today")}</Radio.Button>
                <Radio.Button value="thisWeek">
                  {t("calendar.thisWeek")}
                </Radio.Button>
                <Radio.Button value="thisMonth">
                  {t("calendar.thisMonth")}
                </Radio.Button>
              </Radio.Group>
            </Col>
          </Row>
          <Row className="performance-overview-data">
            <Col span={24}>
              <Row>
                <Col span={6}>
                  <div className="overview-data__title">
                    {t("content.dashboard.register")}
                  </div>
                  <div className="overview-data__content">
                    <span className="overview-data__content-number">0</span>
                    <span className="overview-data__content-text">
                      {t("content.dashboard.newRegister")}
                    </span>
                  </div>
                  <div className="overview-data__content">
                    <span className="overview-data__content-number">0</span>
                    <span className="overview-data__content-text">
                      {t("content.dashboard.totalAccount")}
                    </span>
                  </div>
                </Col>
                <Col span={6}>
                  <div className="overview-data__title">
                    {t("content.dashboard.deposit")}
                  </div>
                  <div className="overview-data__content">
                    <span className="overview-data__content-number">0</span>
                    <span className="overview-data__content-text">
                      {currency}
                    </span>
                  </div>
                  <div className="overview-data__content">
                    <span className="overview-data__content-number">0</span>
                    <span className="overview-data__content-text">
                      {t("content.dashboard.members")}
                    </span>
                  </div>
                </Col>
                <Col span={6}>
                  <div className="overview-data__title">
                    {t("content.dashboard.withdrawal")}
                  </div>
                  <div className="overview-data__content">
                    <span className="overview-data__content-number">0</span>
                    <span className="overview-data__content-text">
                      {currency}
                    </span>
                  </div>
                  <div className="overview-data__content">
                    <span className="overview-data__content-number">0</span>
                    <span className="overview-data__content-text">
                      {t("content.dashboard.members")}
                    </span>
                  </div>
                </Col>
                <Col span={6}>
                  <div className="overview-data__title">
                    {t("content.dashboard.depositMinusWithdrawal")}
                  </div>
                  <div className="overview-data__content">
                    <span className="overview-data__content-number-active">
                      0
                    </span>
                    <span className="overview-data__content-text">
                      {currency}
                    </span>
                  </div>
                  {/* <div className='overview-data__content'>
                                        <span className='overview-data__content-number-active'>
                                            0
                                        </span>
                                        <span className='overview-data__content-text'>
                                            Total Account
                                        </span>
                                    </div> */}
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className="performance-overview-data">
            <Col span={24}>
              <Row>
                <Col span={6}>
                  <div className="overview-data__title">
                    {t("content.dashboard.promotionClaimed")}
                  </div>
                  <div className="overview-data__content">
                    <span className="overview-data__content-number">0</span>
                    <span className="overview-data__content-text">
                      {currency}
                    </span>
                  </div>
                  <div className="overview-data__content">
                    <span className="overview-data__content-number">0</span>
                    <span className="overview-data__content-text">
                      {t("content.dashboard.members")}
                    </span>
                  </div>
                </Col>
                <Col span={6}>
                  <div className="overview-data__title">
                    {t("content.dashboard.referralClaimed")}
                  </div>
                  <div className="overview-data__content">
                    <span className="overview-data__content-number">0</span>
                    <span className="overview-data__content-text">
                      {currency}
                    </span>
                  </div>
                  <div className="overview-data__content">
                    <span className="overview-data__content-number">0</span>
                    <span className="overview-data__content-text">
                      {t("content.dashboard.members")}
                    </span>
                  </div>
                </Col>
                <Col span={6}>
                  <div className="overview-data__title">
                    {t("content.dashboard.bet")}
                  </div>
                  <div className="overview-data__content">
                    <span className="overview-data__content-number">0</span>
                    <span className="overview-data__content-text">
                      {t("content.dashboard.betCount")}
                    </span>
                  </div>
                  <div className="overview-data__content">
                    <span className="overview-data__content-number">0</span>
                    <span className="overview-data__content-text">
                      {t("content.dashboard.netTurnover")}
                    </span>
                  </div>
                </Col>
                <Col span={6}>
                  <div className="overview-data__title">
                    {t("content.dashboard.companyWinLost")}
                  </div>
                  <div className="overview-data__content">
                    <span className="overview-data__content-number-active">
                      0
                    </span>
                    <span className="overview-data__content-text">
                      {currency}
                    </span>
                  </div>
                  <div className="overview-data__content">
                    <span className="overview-data__content-number-active">
                      0%
                    </span>
                    <span className="overview-data__content-text">
                      {t("content.dashboard.margin")}
                    </span>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>

        <Col span={24}>
          <ChartHome />
        </Col>
      </Row>
    </div>
  );
};

export default HomePage;
