import { Form, Cascader } from "antd";
import { Controller, Control } from "react-hook-form";
import type { FormItemProps, CascaderProps } from "antd";
import { getValidateStatus } from "../../helpers";
import withTooltipMultiSelect from "../../hocs/with-tooltip-multi-cascader";

const CascaderField = ({
    name,
    control,
    formItemProps,
    cascaderProps,
}: {
    name: string;
    control: Control<any>;
    formItemProps?: FormItemProps;
    cascaderProps?: CascaderProps;
}) => {
    const { labelAlign = "left" } = formItemProps || {};
    return (
        <Controller
            name={name}
            control={control}
            render={({ field, fieldState }) => {
                const errorMessage = fieldState.error?.message;
                return (
                    <Form.Item
                        labelAlign={labelAlign}
                        className="wrap-label"
                        {...formItemProps}
                        validateStatus={getValidateStatus(fieldState, name)}
                        help={errorMessage}
                    >
                        <TooltipCascader {...field} {...cascaderProps} />
                    </Form.Item>
                );
            }}
        />
    );
};

const TooltipCascader = withTooltipMultiSelect(Cascader);

export default CascaderField;
