import { Button, Card, Col, Input, Row, Switch, Table } from "antd";
import { useNavigate } from "react-router-dom";
import { paths } from "../../../routes/path";
import "./BankAccountList.scss";

const dataSource = [
   {
      key: "1",
      name: "Ngân hàng Á Châu",
      sign: "ACB",
      type: "Thủ công",
      status: "Active",
   },
   {
      key: "2",
      name: "Ngân hàng Công Thương",
      sign: "VCB",
      type: "Auto",
      status: "Disabled",
   },
];

const columns = [
   {
      title: "Tên",
      dataIndex: "name",
      key: "name",
   },
   {
      title: "Ký hiệu",
      dataIndex: "sign",
      key: "sign",
   },
   {
      title: "Loại",
      dataIndex: "type",
      key: "type",
   },
   {
      title: "Trạng thái",
      dataIndex: "status",
      key: "status",
   },
   {
      title: <div className='flex-center'>Hành động</div>,
      key: "action",
      render() {
         return (
            <div className='flex-center'>
               <Switch></Switch>
            </div>
         );
      },
   },
];

const BankAccountList = () => {
   const navigate = useNavigate();

   const handleSearch = (e: any) => {
      //  setTextSearch(e.target.value);
   };

   return (
      <div>
         <Row className='bank-header'>
            <Col span={12}>
               <div className='col-inner'>
                  <Button type='primary' onClick={() => navigate(paths.ADD_BANK_ACCOUNT)}>
                     Thêm mới tài khoản
                  </Button>
               </div>
            </Col>

            <Col span={12}>
               <div className='col-inner'>
                  <Input placeholder='Tìm kiếm' onChange={handleSearch} />
               </div>
            </Col>
         </Row>
         <Card title='Danh sách tài khoản ngân hàng chưa có api' className='card-table'>
            <Table dataSource={dataSource} columns={columns} />
         </Card>
      </div>
   );
};

export default BankAccountList;
