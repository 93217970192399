import { Form, Switch } from "antd";
import { Controller, Control } from "react-hook-form";
import type { FormItemProps, SwitchProps } from "antd";
import { CheckOutlined } from "@ant-design/icons";
import { getValidateStatus } from "../../helpers";

const SwitchField = ({
    name,
    control,
    formItemProps,
    switchProps,
    withYesNo = true,
    children,
}: {
    name: string;
    control: Control<any>;
    formItemProps: FormItemProps;
    switchProps?: SwitchProps;
    withYesNo?: boolean;
    children?: React.ReactNode;
}) => {
    const { labelAlign = "left" } = formItemProps;
    return (
        <Controller
            name={name}
            control={control}
            render={({ field, fieldState }) => {
                const errorMessage = fieldState.error?.message;
                return (
                    <Form.Item
                        labelAlign={labelAlign}
                        validateStatus={getValidateStatus(fieldState, name)}
                        help={errorMessage}
                        className="wrap-label"
                        {...formItemProps}
                    >
                        <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "0.5rem",
                            }}
                        >
                            <Switch
                                checkedChildren={<CheckOutlined />}
                                {...field}
                                {...switchProps}
                            />
                            {withYesNo && (
                                <span>{field.value ? "Yes" : "No"}</span>
                            )}
                            {children}
                        </div>
                    </Form.Item>
                );
            }}
        />
    );
};

export default SwitchField;
