import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IStoreState } from "../interfaces/Store";
import { setModule } from "../store/slices/permission";

export const usePermission = () => {
    const dispatch = useDispatch();
    const appStates = useSelector((state: IStoreState) => state.auth.profile);
    const myPermissions = useSelector((state: any) => state.myPermissions);

    // const fetchPermission = async () => {
    //     // console.log("appStates.profile", appStates.profile);
    //     // if (appStates.profile.id) {
    //     try {
    //         const res = await httpService.get(
    //             // "http://api.789lava.com/v1/admin/menu?roleId=002c1b25-1d4e-4f94-ae69-e96c8c43f491"
    //             `http://api.789lava.com/v1/admin/menu/user/${appStates.profile.id}`
    //         );
    //         dispatch(setModule(res.data.admin));
    //     } catch (error) {
    //         console.log(error);
    //     }
    //     // }
    // };

    useEffect(() => {
        // fetchPermission();
        if (appStates?.menu) dispatch(setModule(appStates?.menu.filter((i) => i.name !== "unknown")));
    }, [dispatch]);

    return { myPermissions };
};
