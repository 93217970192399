import { useTranslation } from "react-i18next";
import { Button, Form, Modal, Select } from "antd";
import { Option } from "antd/es/mentions";
import { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { apis } from "../../../apis";
import { httpService } from "../../../apis/httpService";
import "./ModalCopyThemeFromOtherDomain.scss";

type FieldType = {
    isModalOpen: boolean;
    setIsModalOpen: (value: any) => void;
};

const ModalCopyThemeFromOtherDomain = ({ setIsModalOpen, isModalOpen }: FieldType) => {
    const [form] = Form.useForm();
    const { t } = useTranslation();
    const onFinishFailed = (errorInfo: any) => {
        console.log("Failed:", errorInfo);
    };
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [fromDomain, setFromDomain] = useState("");
    const [toDomain, setToDomain] = useState("");
    const [domains, setDomains] = useState([]);
    const [canSave, setCanSave] = useState(false);
    const onChangeFromDomain = (value: any) => {
        setFromDomain(value);
    };
    const onChangeToDomain = (value: any) => {
        setToDomain(value);
    };

    const getDomain = async () => {
        const res = await httpService.get(apis.webAdmin.domainThemes);
        setDomains(res.data);
    };
    useEffect(() => {
        getDomain();
    }, [isModalOpen]);

    useEffect(() => {
        if (fromDomain && toDomain && fromDomain !== toDomain) {
            setCanSave(true);
        }
    }, [fromDomain, toDomain]);

    const handleSave = async () => {
        try {
            if (canSave) {
                await httpService.post(apis.webAdmin.copyTheme, {
                    fromDomain,
                    toDomain: [toDomain],
                });
            }
            setIsModalOpen(false);
            toast.success("Copy theme success!");
        } catch (error) {
            console.log("error", error);
            toast.error("Copy theme failed!");
        }
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    return (
        <div className="modal-copy-theme-from-other-domain__container">
            <Modal
                title={t("content.customizeTheme.setting.copyThemeFromOtherDomainModal.title")}
                open={isModalOpen}
                onCancel={handleCancel}
                confirmLoading={confirmLoading}
                footer={null}
                className="modal-copy-theme-from-other-domain"
            >
                <div style={{ marginTop: 20 }}>
                    <Form
                        name="basic"
                        labelCol={{ span: 8 }}
                        wrapperCol={{ span: 12 }}
                        onFinishFailed={onFinishFailed}
                        style={{ textAlign: "center" }}
                        form={form}
                    >
                        <Form.Item
                            label={t("content.customizeTheme.setting.copyThemeFromOtherDomainModal.fromDomain")}
                            name="fromDomain"
                            style={{ marginTop: 15 }}
                        >
                            <Select style={{ textAlign: "left" }} onChange={onChangeFromDomain}>
                                {domains?.map((item: any) => {
                                    if (item.domain) {
                                        return (
                                            <Option value={item.domain} key={item.domain}>
                                                {item.domain}
                                            </Option>
                                        );
                                    }
                                })}
                            </Select>
                        </Form.Item>

                        <Form.Item
                            label={t("content.customizeTheme.setting.copyThemeFromOtherDomainModal.toDomain")}
                            name="toDomain"
                            style={{ marginTop: 15 }}
                        >
                            <Select style={{ textAlign: "left" }} onChange={onChangeToDomain}>
                                {domains?.map((item: any) => {
                                    return (
                                        <Option value={item.domain} key={item.domain}>
                                            {item.domain}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>
                        <div style={{ marginTop: 15, color: "#999", fontSize: 13 }}>
                            *{t("content.customizeTheme.setting.copyThemeFromOtherDomainModal.notice")}
                        </div>

                        <Form.Item wrapperCol={{ offset: 8, span: 8 }} style={{ marginTop: 40 }}>
                            <Button type="primary" htmlType="submit" onClick={handleSave} disabled={!canSave}>
                                {t("content.customizeTheme.setting.copyThemeFromOtherDomainModal.save")}
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </Modal>
            <ToastContainer autoClose={1500} />
        </div>
    );
};

export default ModalCopyThemeFromOtherDomain;
