import { Button, Form, Input, Modal } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { apis } from "../../../apis";
import { httpService } from "../../../apis/httpService";
import "./ModalNewGroupList.scss";

type FieldType = {
    isModalOpen: boolean;
    setIsModalOpen: (value: any) => void;
};

const ModalNewGroupList = ({ setIsModalOpen, isModalOpen }: FieldType) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const { TextArea } = Input;

    const [errorMess, setErrorMess] = useState("");
    const onFinish = async (values: any) => {
        try {
            await httpService.post(apis.permission.groups, values);
            toast.success("Success!");
            setErrorMess("");
            form.resetFields();
            setIsModalOpen(false);
        } catch (error: any) {
            console.log("error", error);
            toast.error("Create failed!");
            if (error.response?.data?.messageCode === "ROLE_ALREADY_EXIST") {
                setErrorMess("Group name has already existed");
            }
        }
    };
    const handleCancel = () => {
        setIsModalOpen(false);
    };

    return (
        <div className="modal-new-group-list__container">
            <Modal
                title={t("content.resource.manage.addNewGroupModal.title")}
                open={isModalOpen}
                onCancel={handleCancel}
                footer={null}
                className="modal-new-group-list"
            >
                <div style={{ marginTop: 20 }}>
                    <Form
                        name="basic"
                        labelCol={{ span: 8 }}
                        wrapperCol={{ span: 12 }}
                        onFinish={onFinish}
                        style={{ textAlign: "center" }}
                        form={form}
                    >
                        <Form.Item
                            label={t("content.resource.manage.addNewGroupModal.groupName")}
                            name="name"
                            rules={[{ required: true, message: "Group name is require" }]}
                            validateStatus={errorMess ? "error" : ""}
                            help={errorMess}
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item label={t("content.resource.manage.addNewGroupModal.remark")} name="description">
                            <TextArea rows={2} />
                        </Form.Item>

                        <Form.Item wrapperCol={{ offset: 8, span: 8 }} style={{ marginTop: 40 }}>
                            <Button type="primary" htmlType="submit">
                                {t("content.subAccountList.createSubAccountModal.save")}
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </Modal>
            <ToastContainer autoClose={1500} />
        </div>
    );
};

export default ModalNewGroupList;
