export enum PromotionType {
    REGISTER_BONUS = "REGISTER_BONUS",
    FIRST_DEPOSIT_BONUS = "FIRST_DEPOSIT_BONUS",
    DAILY_DEPOSIT_BONUS = "DAILY_DEPOSIT_BONUS",
    DEPOSIT_BONUS = "DEPOSIT_BONUS",
    REBASE_ON_TURNOVER = "REBASE_ON_TURNOVER",
    REBASE_ON_TURNOVER_LOSE_ONLY = "REBASE_ON_TURNOVER_LOSE_ONLY",
    REBASE_ON_WIN_LOST = "REBASE_ON_WIN_LOST",
    REBASE_ON_DEPOSIT_AND_WITHDRAW = "REBASE_ON_DEPOSIT_AND_WITHDRAW",
}

export enum PromotionStatus {
    ACTIVE = "ACTIVE",
    DISABLED = "DISABLED",
    DELETED = "DELETED",
    // ACTIVE_AND_OPEN = "ACTIVE_AND_OPEN",
}

export enum PromotionState {
    PENDING = "PENDING",
    APPROVE = "APPROVE",
    REJECTED = "REJECTED",
    COMPLETED = "COMPLETED",
    PROMOTION_TRANSFER = "PROMOTION_TRANSFER",
}

export enum CalculateMethod {
    CERTAIN_AMOUNT = "CERTAIN_AMOUNT",
    PERCENTAGE = "PERCENTAGE",
}

export enum CalculationCriteria {
    FIRST_DEPOSIT_OF_DAY = "FIRST_DEPOSIT_OF_DAY",
    LATEST_DEPOSIT_TO_THE_PROMOTION_CLAIMED = "LATEST_DEPOSIT_TO_THE_PROMOTION_CLAIMED",
}

export enum DayInWeek {
    MON = "MON",
    TUE = "TUE",
    WED = "WED",
    THU = "THU",
    PRI = "PRI",
    SAT = "SAT",
    SUN = "SUN",
}

export enum PromotionPlayerTag {
    ARBITRAGE_PLAYER = "ARBITRAGE_PLAYER",
    RISKY_PLAYER = "RISKY_PLAYER",
}

export enum RebateInterval {
    DAILY = "DAILY",
    WEEKLY = "WEEKLY",
    MONTHLY = "MONTHLY",
}
export enum MaxApplicationCountResetPeriod {
    NO_RESET = "NO_RESET",
    DAILY = "DAILY",
    WEEKLY = "WEEKLY",
    MONTHLY = "MONTHLY",
}

export enum RebateCalculation {
    MAIN_WALLET = "MAIN_WALLET",
    PROMOTION_WALLET = "PROMOTION_WALLET",
    REFERRAL_WALLET = "REFERRAL_WALLET",
}
export enum AutoRebate {
    AUTO_APPLY = "AUTO_APPLY",
    PLAYER_MANUALLY_APPLY = "PLAYER_MANUALLY_APPLY",
}

export const Formula = {
    FORMULA1: "1", // (Deposit amount + Bonus amount) * Rollover times
    FORMULA2: "2", // Bonus amount * Rollover times
    FORMULA3: "3", // Deposit amount * Rollover times
}

export const ApplicationLimitation = {
    UNIQUE_LOGIN_IP: "UNIQUE_LOGIN_IP",
    UNIQUE_DEVICE_FP: "UNIQUE_DEVICE_FP",
    UNIQUE_PHONE: "UNIQUE_PHONE",
    UNIQUE_EMAIL: "UNIQUE_EMAIL",
    UNIQUE_BANK_ACCOUNT_NO: "UNIQUE_BANK_ACCOUNT_NO",
}

export const currentDate = new Date()
export const fromDate = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    currentDate.getDate() - 30
)
export const applicationDeadlineOptions = new Array(7).fill(null).map((_, index) => ({
    label: `${index + 1}`,
    value: index + 1,
}))
