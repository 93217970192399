import { useEffect, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { getCurrencyList } from "../../../common/helpers/utils"
import {
    setCurrencyList,
    setGameProviderList,
    setLanguageList,
} from "../../../store/slices/promotions"
import { PromotionType } from "../constants"
import { getGameProviderList, getLanguageList } from "../services"

const usePromotion = () => {
    const { t } = useTranslation()
    const { currencyList, gameProviderList, languageList } = useSelector(
        (state: any) => state.promotion
    )
    const dispatch = useDispatch()

    const PROMOTION_TYPE_LIST_1 = useMemo(
        () => [
            {
                id: PromotionType.REGISTER_BONUS,
                title: t("content.promotions.create.type.REGISTER_BONUS.title"),
                description: t("content.promotions.create.type.REGISTER_BONUS.description"),
            },
            {
                id: PromotionType.FIRST_DEPOSIT_BONUS,
                title: t("content.promotions.create.type.FIRST_DEPOSIT_BONUS.title"),
                description: t("content.promotions.create.type.FIRST_DEPOSIT_BONUS.description"),
            },
            {
                id: PromotionType.DAILY_DEPOSIT_BONUS,
                title: t("content.promotions.create.type.DAILY_DEPOSIT_BONUS.title"),
                description: t("content.promotions.create.type.DAILY_DEPOSIT_BONUS.description"),
            },
            {
                id: PromotionType.DEPOSIT_BONUS,
                title: t("content.promotions.create.type.DEPOSIT_BONUS.title"),
                description: t("content.promotions.create.type.DEPOSIT_BONUS.description"),
            },
        ],
        [t]
    )
    const PROMOTION_TYPE_LIST_2 = useMemo(
        () => [
            {
                id: PromotionType.REBASE_ON_TURNOVER,
                title: t("content.promotions.create.type.REBASE_ON_TURNOVER.title"),
                description: t("content.promotions.create.type.REBASE_ON_TURNOVER.description"),
            },
            {
                id: PromotionType.REBASE_ON_TURNOVER_LOSE_ONLY,
                title: t("content.promotions.create.type.REBASE_ON_TURNOVER_LOSE_ONLY.title"),
                description: t(
                    "content.promotions.create.type.REBASE_ON_TURNOVER_LOSE_ONLY.description"
                ),
            },
            // {
            //     id: PromotionType.REBASE_ON_WIN_LOST,
            //     title: t("content.promotions.create.type.REBASE_ON_WIN_LOST.title"),
            //     description: t("content.promotions.create.type.REBASE_ON_WIN_LOST.description"),
            // },
            // {
            //     id: PromotionType.REBASE_ON_DEPOSIT_AND_WITHDRAW,
            //     title: t("content.promotions.create.type.REBASE_ON_DEPOSIT_AND_WITHDRAW.title"),
            //     description: t(
            //         "content.promotions.create.type.REBASE_ON_DEPOSIT_AND_WITHDRAW.description"
            //     ),
            // },
        ],
        [t]
    )

    useEffect(() => {
        if (!currencyList?.length) {
            // fetch currency list
            const fetchCurrencyList = async () => {
                try {
                    const res = await getCurrencyList()
                    dispatch(setCurrencyList(res.data))
                } catch (error) {
                    console.log(error)
                    dispatch(setCurrencyList(["USD"]))
                }
            }

            fetchCurrencyList()
        }
    }, [currencyList, dispatch])

    useEffect(() => {
        if (!gameProviderList?.length) {
            // fetch game provider list
            const fetchGameProviderList = async () => {
                try {
                    const res = await getGameProviderList()
                    const groupedData = res.data.reduce((acc: any, curr: any) => {
                        const category = curr.gameProviderCategory
                        const providerName = curr.displayName
                        const providerId = curr.gameProviderId

                        const categoryIndex = acc.findIndex((item: any) => item.label === category)
                        if (categoryIndex !== -1) {
                            acc[categoryIndex].children.push({
                                label: providerName,
                                value: providerId,
                            })
                        } else {
                            acc.push({
                                label: category,
                                value: category,
                                children: [
                                    {
                                        label: providerName,
                                        value: providerId,
                                    },
                                ],
                            })
                        }

                        return acc
                    }, [])

                    dispatch(setGameProviderList(groupedData))
                } catch (error) {
                    console.log(error)
                    dispatch(setGameProviderList([]))
                }
            }

            fetchGameProviderList()
        }
    }, [gameProviderList, dispatch])

    useEffect(() => {
        if (!languageList?.length) {
            // fetch currency list
            const fetchLanguageList = async () => {
                try {
                    const res = await getLanguageList()
                    dispatch(setLanguageList(res.data))
                } catch (error) {
                    console.log(error)
                    dispatch(setLanguageList([]))
                }
            }

            fetchLanguageList()
        }
    }, [languageList, dispatch])

    return {
        PROMOTION_TYPE_LIST_1,
        PROMOTION_TYPE_LIST_2,
        languageList,
        currencyList,
        gameProviderList,
    }
}

export default usePromotion
