import { useCallback, useEffect, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import { httpFileService, httpService } from "../apis/httpService"
import { ACCESS_TOKEN, REFRESH_TOKEN } from "../common/constants/local-storage-keys"
import { IStoreState } from "../interfaces/Store"
import { IProfile } from "../interfaces/User"
import { authActions } from "../store/slices/auth"

export interface IAuthenticatedData {
    accessToken: string
    refreshToken: string
    profile?: IProfile
}

export const useCredential = () => {
    const appState = useSelector((state: IStoreState) => state.auth)
    const dispatch = useDispatch<any>()

    const timeRef = useRef<ReturnType<typeof setTimeout> | null>(null)

    const handleRefreshToken = useCallback(async () => {
        const refreshToken = window.localStorage.getItem(REFRESH_TOKEN)
        const accessToken = window.localStorage.getItem(ACCESS_TOKEN)
        if (accessToken) {
            httpService.setToken(accessToken)
            httpFileService.setToken(accessToken)
        }
        if (refreshToken) {
            const resp = await httpService.requestRefreshToken(refreshToken)

            if (resp?.expiredIn) {
                const timeout = new Date(resp.expiredIn).getMilliseconds() - Date.now()
                timeRef.current = setTimeout(() => {
                    handleRefreshToken()
                }, timeout)
            }
        }
    }, [])

    useEffect(() => {
        handleRefreshToken()
        return () => {
            if (timeRef.current) {
                clearTimeout(timeRef.current)
            }
        }
    }, [handleRefreshToken])

    useEffect(() => {
        const accessToken = window.localStorage.getItem(ACCESS_TOKEN)

        if (!appState.profile && accessToken) {
            dispatch(authActions.getProfile())
                .unwrap()
                .catch(() => {
                    dispatch(authActions.toggleLoading(false))
                })
        }

        if (!accessToken) {
            dispatch(authActions.toggleLoading(false))
        } else {
            dispatch(authActions.getProfile())
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch])
}
